var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-close-box" },
        [
          _c("svg-icon", {
            staticClass: "dialog-close",
            attrs: { "icon-class": "close" },
            on: { click: _vm.close },
          }),
        ],
        1
      ),
      _vm.dialogVisible && _vm.type === "Account" ? _c("Account") : _vm._e(),
      _vm.dialogVisible && _vm.type === "Profile" ? _c("Profile") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }