<template>
  <div class="dialog-content">
    <div :class="ruleData.title? '':'error'">
      <span class="title">{{ $t('common.title') }} <span class="red">*</span></span>
      <el-input v-model="formData.title"  type="textarea" :autosize="{ minRows: 2,maxRows: 4 }" resize="none"
        :placeholder="''" @change="verifyTitle" maxlength="500"></el-input>
        <span v-if="!ruleData.title" class="error-tip">{{ error }}</span>
    </div>

    <div class="input-box" :class="ruleData.content? '':'error'">
      <span class="title">{{ $t('common.description') }} <span class="red">*</span></span>
      <el-input v-model="formData.content"  type="textarea" :autosize="{ minRows: 4 }" resize="none"
        :placeholder="''"  maxlength="5000" @change="verifyContent" class="content-input"></el-input>
    </div>
    <el-button :class="[canSave ? 'btn' : 'btn dis-btn']"  :disabled="!canSave" class="btn" :loading="isLoading" @click="save">{{ type === 'add' ? $t('common.addPrinciples') : $t('common.savePrinciples') }}</el-button>

  </div>
</template>

<script>
import * as webchat from '@/api/webchat'
export default {
  props: {
    item: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      formData: {

      },
      error: '',
      isLoading: false,
      ruleData: {
        title: true,
        content: true
      }
    }
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.formData = JSON.parse(JSON.stringify(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    canSave() {
      const res = false
      if (this.formData.title && this.formData.content) {
        return true
      }
      return res
    }
  },
  mounted() { },
  components: {},
  methods: {
    edit(item) {

    },
    verifyTitle() {
      if (this.formData.title) {
        this.ruleData.title = true
      } else {
        this.ruleData.title = false
      }
      this.ruleData = JSON.parse(JSON.stringify(this.ruleData))
    },
    verifyContent() {
      if (this.formData.content) {
        this.ruleData.content = true
      } else {
        this.ruleData.content = false
      }
      this.ruleData = JSON.parse(JSON.stringify(this.ruleData))
      console.info(333)
    },
    async save() {
      this.verifyTitle()
      this.verifyContent()
      console.info(44)
      let verify = true
      for (const i in this.ruleData) {
        console.info(i, verify)
        if (this.ruleData[i] === false) {
          verify = false
        }
      }
      if (!verify) {
        return
      }
      if (!this.formData.type) {
        this.formData.type = 'CUSTOMIZE'
      }
      console.info(this.formData.title, 'this.formData.title', this.item.title)
      if (this.formData.title !== this.item.title || this.formData.content !== this.item.content) {
        this.formData.type = 'CUSTOMIZE'
      }
      if (!this.formData.id) {
        this.formData.id = null
      }
      this.formData.created_time = null
      this.formData.updated_time = null
      this.isLoading = true
      webchat.addMP(this.formData).then(res => {
        this.isLoading = false
        if (res.code === 10007) {
          this.error = res.detail
          this.ruleData.title = false
          this.ruleData = JSON.parse(JSON.stringify(this.ruleData))
          return
        }
        console.info('val', this.formData)
        this.formData.id = res.data
        this.$emit('change', this.formData)
        this.$message.success({
          message: this.type === 'add' ? this.$t('common.addedPrinciple') : this.$t('common.savedPrinciple'),
          customClass: 'message-success',
          duration: 2000
        })
      }).catch(() => {
        this.isLoading = false
      })
    },
    del() {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('home.delPrinciple'),
        message: h('p', null, [
          h(
            'span',
            { style: 'var(--s-text)' },
            this.$t('home.delPrincipleTip')
          )
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.close()
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-family: Century-Gothic;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: var(--text);
  margin-bottom: 8px;
  display: block;
  // margin-left: 12px;
}

.red {
  color: red;
}

.dialog-content {
  padding: 20px;
}

.input-box {
  margin-top: 24px;
}
.dis-btn {
  background: var(--dis-red) !important;
  color: var(--white) !important;
}

::v-deep .el-textarea__inner::placeholder {
  font-family: Roboto;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--ss-text);
}
.error{
  ::v-deep .el-textarea__inner {
  // background: var(--err-red) !important;
  // border: 0.5px solid var(--s-red) !important;
  box-shadow: 0 0 0 0.5px var(--s-red) !important;
}
}
::v-deep .el-textarea__inner {
  background: var(--b-bg);
  color: var(--text);
  height: 100%;
  border-radius: 8px;
  // border: 0.5px solid var(--br);
  border: none;
  box-shadow: 0 0 0 0.5px var(--br) !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  // margin-top: -16px;
}
::v-deep .el-textarea__inner:focus {
  box-shadow: 0 0 0 0.5px var(--blue-t-text) !important;
}

.content-input ::v-deep .el-textarea__inner{
  max-height: calc(92vh - 360px - env(safe-area-inset-bottom));
  overflow: auto;
}
.btn {
  width: 307px;
  height: 36px;
  margin: auto;
  border-radius: 8px;
  background: var(--red);
  color: var(--white);
  font-family: Century-Gothic;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  margin-top: 24px;
}
.error-tip{
  display: block;
  margin-top: 8px;
  color: var(--s-red);
  margin-left: 12px;
}
</style>
