var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list.length > 0
    ? _c("div", [
        _c("span", { staticClass: "tip" }, [
          _vm._v(_vm._s(_vm.$t("home.tryAsking") + ":")),
        ]),
        _vm.isApp
          ? _c(
              "div",
              { staticClass: "btn-wrap" },
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  {
                    key: item.question,
                    staticClass: "item",
                    on: {
                      touchstart: function ($event) {
                        return _vm.handleTouchStart($event, item)
                      },
                      touchend: _vm.handleTouchEnd,
                      touchmove: _vm.handleTouchMove,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "title" },
                      [
                        _c("svg-icon", {
                          staticClass: "close-icon",
                          attrs: { "icon-class": _vm.getIcon(item.topic) },
                        }),
                        _vm._v(_vm._s(item.display)),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.question)),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "btn-wrap" },
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  {
                    key: item.question,
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.handleClick(item)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "title" },
                      [
                        _c("svg-icon", {
                          staticClass: "close-icon",
                          attrs: { "icon-class": _vm.getIcon(item.topic) },
                        }),
                        _vm._v(_vm._s(item.display)),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.question)),
                    ]),
                  ]
                )
              }),
              0
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }