var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-content" }, [
    _c("span", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.item.title) + " "),
    ]),
    _c("div", { staticClass: "icon-box" }, [
      _c(
        "div",
        { on: { click: _vm.edit } },
        [
          _c("svg-icon", {
            staticClass: "title-icon",
            attrs: { "icon-class": "edit" },
          }),
          _c("span", { staticClass: "tip" }, [
            _vm._v(_vm._s(_vm.$t("common.edit"))),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { on: { click: _vm.del } },
        [
          _c("svg-icon", {
            staticClass: "title-icon",
            attrs: { "icon-class": "delete" },
          }),
          _c("span", { staticClass: "tip" }, [
            _vm._v(_vm._s(_vm.$t("common.delete"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
    ]),
    _c("div", { staticClass: "content-text" }, [
      _c("span", [_vm._v(_vm._s(_vm.item.content))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }