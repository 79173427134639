<template>
  <div class="thumbnail">
    <div class="box">
      <div
        class="card"
        v-for="(item, index) in list"
        :key="index"
        @click="linkTo(item)"
      >
        <!-- <el-image :src="item.imgUrl" class="card-img">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image> -->
        <div class="card-title-box">
          <div>
            <span>{{ item.title }}</span>
          </div>
          <div>
             <svg-icon icon-class="share" class="link-right"></svg-icon>
          </div>
        </div>
        <div class="bottom">
          <el-image :src="item.ico" class="card-logo" :fit="'contain'">
            <div slot="error" class="image-slot">
              <div class="card-logo-box">
                  <i class="el-icon-picture-outline"></i>
              </div>
            </div>
          </el-image>
          <span class="card-url">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Urlbox from 'urlbox'
export default {
  props: {
    thumbnails: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    empty() {
      let res = false
      if (Object.keys(this.list).length > 0) {
        res = true
      }
      return res
    }
  },
  watch: {
    thumbnails: {
      handler() {
        this.getList()
      },
      deep: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {

    linkTo(item) {
      window.open(item.url)
    },
    getDomain(url) {
      let res = ''
      if (url) {
        const arr = url.split('//')[1]
        res = url.split('//')[0] + '//' + arr.split('/')[0]
      }
      return res
    },
    getText(url) {
      let res = ''
      if (url) {
        const arr = url.split('://')[1]
        res = arr.split('/')[0]
      }
      return res
    },
    getList() {
      this.list = []
      const urlbox = Urlbox(
        'MBNtDWEUwbc4jGL3',
        '7ce6f41e32c74b12a18d1a02b01f9572'
      )
      for (const i of this.thumbnails) {
        if (!this.list[i.link]) {
          const options = {
            url: i.link,
            thumb_width: 210,
            format: 'jpg',
            quality: 80
          }
          const url = this.getDomain(i.link) + '/favicon.ico'
          const imgUrl = urlbox.buildUrl(options)
          const res = {
            imgUrl: imgUrl,
            ico: url,
            title: i.title,
            text: this.getText(i.link),
            url: i.link
          }
          this.list.push(res)
        }
      }
      console.info(this.list)
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  position: relative;
  margin-bottom: 8px;
  max-width: calc(100vw);
  // overflow: hidden;
}
.box {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
}
.box::-webkit-scrollbar {
  display: none;
}
.card {
  background: var(--container-bg);
  width: 176px;
  height: 72px;
  position: relative;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
  display: inline-block;
  // overflow: hidden;
  // border: 1px solid #967acc99;
}
.card:first-child {
  margin-left: 0px;
}
// .card:hover {
//   border: none;
//   background: #856db4;
// }

// .card:hover::before {
//   // border: 1px solid #856db4;

//   content: "";
//   width: 102%;
//   height: 102%;
//   border-radius: 8px;
//   background-image: linear-gradient(
//     var(--rotate),
//     #5ddcff,
//     #3c67e3 43%,
//     #4e00c2
//   );
//   position: absolute;
//   z-index: -1;
//   top: -1%;
//   left: -1%;
//   animation: spin 2.5s linear infinite;
// }

.card-img {
  width: 210px;
  height: 130px;
  object-fit: cover;
  margin-bottom: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: var(--container-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
.card-title-box {
  color: var(--text);
  margin: 8px;
  width: fit-content;
  height: 32px;
  width: 130px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  span {
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
  }
}
.card-logo {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-logo-box{
  width: 16px;
  height: 16px;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: var(--s-text);
}
.bottom {
  display: flex;
  align-items: center;
  overflow: hidden;

  width: 170px;
  text-overflow: ellipsis;
  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    color: var(--s-text);
    margin-bottom: 8px;
    margin-left: 8px;
  }
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 220px;
  --card-width: 190px;
}
.link-right {
  font-size: 13px;
  right: 8px;
  top: 8px;
  position: absolute;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
@media screen and (max-width: 900px){
  // .thumbnail{
  //   overflow: hidden;
  // }
  .box{
    overflow-x: visible;
    overflow-y: visible;
  }
}
</style>
