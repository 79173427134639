var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "btn-wrap" }, [
      _c(
        "div",
        { staticClass: "pc" },
        [
          _vm.generating
            ? _c(
                "el-button",
                {
                  staticClass: "dialog-confirm",
                  on: { click: _vm.handleStop },
                },
                [_vm._v(_vm._s(_vm.$t("footer.stopGenerating")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "pc" }),
      _c(
        "div",
        { staticClass: "mobile" },
        [
          _vm.generating
            ? _c("svg-icon", {
                staticClass: "chat-icon red-btn",
                attrs: { "icon-class": "chat-stop" },
                on: { click: _vm.handleStop },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }