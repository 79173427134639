var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Dialog", {
        ref: "dialogDom",
        attrs: { evidences: _vm.evidences, id: _vm.id },
      }),
      _c(
        "div",
        { staticClass: "faq-body", on: { click: _vm.handleClickFaqSource } },
        [
          _c(
            "div",
            { staticClass: "wrap" },
            [
              _vm.info.type === "link"
                ? _c(
                    "el-image",
                    {
                      staticClass: "logo no-box",
                      attrs: { src: _vm.getUrl, fit: "contain" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  )
                : _vm._e(),
              (_vm.info.type === "book" || _vm.info.type === "rag_book") &&
              _vm.info.name === "Principles: life and work"
                ? _c(
                    "el-image",
                    {
                      staticClass: "logo no-box",
                      attrs: {
                        src: require("@/assets/images/book life and work.png"),
                        fit: "contain",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  )
                : _vm._e(),
              (_vm.info.type === "book" || _vm.info.type === "rag_book") &&
              _vm.info.name === "Principles For Navigating BIG DEBT CRISES"
                ? _c(
                    "el-image",
                    {
                      staticClass: "logo no-box",
                      attrs: {
                        src: require("@/assets/images/book BIG DEBT CRISES.png"),
                        fit: "contain",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  )
                : _vm._e(),
              (_vm.info.type === "book" || _vm.info.type === "rag_book") &&
              _vm.info.name ===
                "Principles for Dealing with the Changing World Order"
                ? _c(
                    "el-image",
                    {
                      staticClass: "logo no-box",
                      attrs: {
                        src: require("@/assets/images/book Changing World Order.png"),
                        fit: "contain",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  )
                : _vm._e(),
              (_vm.info.type === "book" || _vm.info.type === "rag_book") &&
              _vm.info.name === "Principles: Your Guided Journal"
                ? _c(
                    "el-image",
                    {
                      staticClass: "logo no-box",
                      attrs: {
                        src: require("@/assets/images/book Your Guided Journal.png"),
                        fit: "contain",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.info.type === "wmi" || _vm.info.type === "rag_wmi"
                ? _c(
                    "el-image",
                    {
                      staticClass: "logo no-box",
                      attrs: {
                        src: require("@/assets/images/WMI logo.png"),
                        fit: "contain",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info.type === "rag_about_dr",
                      expression: "info.type === 'rag_about_dr'",
                    },
                  ],
                  staticClass: "logo black-box",
                },
                [
                  _c("svg-icon", {
                    staticClass: "icon",
                    attrs: { "icon-class": "file" },
                  }),
                ],
                1
              ),
              _vm.evidences.evidences.length > 1
                ? _c("div", { staticClass: "logo" }, [
                    _vm._v(
                      " +" + _vm._s(_vm.evidences.evidences.length - 1) + " "
                    ),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "source" }, [
                _vm._v(_vm._s(_vm.$t("home.sourceFrom"))),
              ]),
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.info.name)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "link-right" },
            [
              _c("svg-icon", {
                staticClass: "link-right",
                attrs: { "icon-class": "right" },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }