<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="close"
    :append-to-body="true"
  >
    <svg-icon class="showtime-close" icon-class="close" @click="close" />
      <div class="principle-key" style="font-weight: 600; margin-bottom: 0px !important">
        <span class="principle-line">
          {{ principle.type }}
        </span>
      </div>
      <div class="content-box">
        <span class="principle-key-content">{{ principle.title }}</span>
        <div class="principle-val-info">
          <span >{{ principle.content }}</span>
        </div>
      </div>
    <div class="lint-box" v-if="principle.link" @click="linkTo">
      {{ $t("home.exploreMore") }}
      <svg-icon icon-class="share" class="link-right"></svg-icon>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    principle: {
      type: Object,
      default: () => {
        return { data: [] }
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    close(done) {
      this.dialogVisible = false
    },
    open() {
      this.dialogVisible = true
    },
    linkTo() {
      window.open(this.principle.link)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  background: var(--black);
  padding: 20px;
  border-radius: 8px;
  max-height: 60vh;
  flex: 1;
  overflow: auto;
}
::v-deep .el-dialog .el-dialog__body {
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  background: rgba(255, 255, 255, 0);
}
.content-box {
  max-height: calc(60vh - 100px);
  overflow: auto;
  // margin-top: 20px;
}
.showtime-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: 16px;
}
.lint-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
}
.link-right {
  font-size: 15px;
  margin-left: 6px;
}
.principle-val-info{
  margin: 20px 0px;
}
@media screen and (max-width: 900px) {
  ::v-deep .el-dialog {
    width: calc(100% - 64px);
    margin-left: 32px;
  }

}
</style>
