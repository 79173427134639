<template>
    <div class="maintenance">
        <h1>{{ title }}</h1>
        <p>{{ message }}</p>
        <p>We will be back online: <strong>{{ formattedReturnDate }}</strong></p>
    </div>
</template>

<script>
export default {
  data() {
    return {
      title: '"Digital Ray" System Under Upgrading',
      message: 'Our website is currently undergoing scheduled upgrading. We apologize for any inconvenience caused.',
      returnDate: new Date(2023, 9, 24)
    }
  },
  computed: {
    formattedReturnDate() {
      return this.returnDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    }
  }
}
</script>

<style scoped>
.maintenance {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
    text-align: center;
}

.maintenance h1 {
    color: #333;
    margin-bottom: 20px;
}

.maintenance p {
    color: #666;
    line-height: 1.6;
}
</style>
