/* eslint-disable */
// import { v4 as uuidv4 } from 'uuid';
// import { Permissions } from '@capacitor/core';
import store from "@/store";
export default class AutoAudio {
  constructor(props) {
    this.wsUrl = props.wsUrl;
    this.webSocket = null;
    this.audio1 = null;
    this.audio2 = null;
    this.messageHistory = [];
    this.status = "stop";
    this.current = 0;
    this.playStatus = "off";
    this.currentKey = 0;
    this.currentAudio = 1;
    this.holdPing = props.holdPing ?? true;
    this.pingTimer = null;
    this.linkStatus = "off";
    this.connectPromise = null;
    this.seq_id = props.seq_id;
    this.speechSynthesizer = null;
    this.openCloseSwitchCallBack = props.openCloseSwitchCallBack;
    if (!props.manual) {
      this.start();
    }
  }

  start() {
    // 创建两个Audio 标签
    if (document.getElementById("wiz_autoAudio--1")) {
      this.audio1 = document.getElementById("wiz_autoAudio--1");
    } else {
      this.audio1 = document.createElement("audio");
      this.audio1.id = "wiz_autoAudio--1";
      document.getElementsByTagName("body")[0].appendChild(this.audio1);
    }
    if (document.getElementById("wiz_autoAudio--2")) {
      this.audio2 = document.getElementById("wiz_autoAudio--2");
    } else {
      this.audio2 = document.createElement("audio");
      this.audio2.id = "wiz_autoAudio--2";
      document.getElementsByTagName("body")[0].appendChild(this.audio2);
    }

    const sdk = require("microsoft-cognitiveservices-speech-sdk");
    //   const readline = require('readline')

    //   const audioFile = 'YourAudioFile.wav'
    // This example requires environment variables named "SPEECH_KEY" and "SPEECH_REGION"
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      "3599a5efadaa4db0841abd0e2cb748ba",
      "eastus"
    );
    // speechConfig.authorizationToken="3599a5efadaa4db0841abd0e2cb748ba";
    // const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput()
    speechConfig.endpointId = "9d342d91-479b-42c2-a89d-ad4d8e71d91e";
    // The language of the voice that speaks.
    speechConfig.speechSynthesisVoiceName = "principleNeural";

    this.speechSynthesizer = new sdk.SpeechSynthesizer(speechConfig, null);
  }
  sendMessage(msg) {
    let speed = store.state.profile.extra.tts_speed  || 1.0;
    const ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
    <voice name="principleNeural"><prosody rate="${speed}">${msg}</prosody></voice></speak>`;
    console.log(ssml);
    if (ssml) {
      this.linkStatus = "on";
      this.playStatus = "Playing";
      // 如果是停止状态需要播放
      this.status = "play";

      this.speechSynthesizer.speakSsmlAsync(
        ssml,
        (result) => {
          if (result) {
            // this.speechSynthesizer.close()
            if (this.linkStatus !== "on") {
              return;
            }
            let blob = new Blob([result.audioData], { type: "audio/mpeg" }); // {wav:'wav' ;mp3:'mpeg'}

            let url = URL.createObjectURL(blob);
            //   let url = 'data:audio/wav;base64,'+ data.audio
            this.messageHistory.push(url);

            // return result.audioData

            this.playAudio(this.currentKey, this.currentAudio);
            if (this.currentAudio === 1) {
              // 判断当前播放的是哪个Audio
              if (
                this.audio2.src !== this.messageHistory[this.currentKey + 1]
              ) {
                // 判断待播放的Audio音频是否是下一条
                this.audio2.src = this.messageHistory[this.currentKey + 1];
              }
            } else {
              if (
                this.audio1.src !== this.messageHistory[this.currentKey + 1]
              ) {
                this.audio1.src = this.messageHistory[this.currentKey + 1];
              }
            }
          }
        },
        (error) => {
          console.log(error);
          // this.speechSynthesizer.close()
        }
      );
    }
  }

  handlePing() {
    if (this.pingTimer) {
      clearInterval(this.pingTimer);
    }
    if (this.holdPing) {
      this.pingTimer = setInterval(() => {
        this.sendMessage("ping");
      }, 50 * 1000);
    }
  }
  async close() {
    if (this.audio1) {
      this.audio1.pause();
    }
    if (this.audio2) {
      this.audio2.pause();
    }
    this.messageHistory = [];
    this.status = "stop";
    this.linkStatus = "off";
    this.playStatus = "Connect";
    this.currentKey = 0;
    this.currentAudio = 1;
  }
  async destroy() {
    if (this.pingTimer) {
      clearInterval(this.pingTimer);
    }

    if (this.audio1) {
      this.audio1.pause();
    }
    if (this.audio2) {
      this.audio2.pause();
    }
    this.messageHistory = [];
    this.status = "stop";
    this.linkStatus = "off";
    this.playStatus = "Connect";
    this.changeWsStatus("close");
    this.currentKey = 0;
    this.currentAudio = 1;
  }
  changeWsStatus(status) {
    this.wsStatus = status;
    this.openCloseSwitchCallBack && this.openCloseSwitchCallBack(status);
  }

  playAudio(index, playKey) {
    if (this.messageHistory[index]) {
      this.currentKey = index;
      this.currentAudio = playKey;
      // 判断当前播放的内容是否存在
      if (playKey === 2) {
        if (this.audio2.src !== this.messageHistory[index]) {
          this.audio2.src = this.messageHistory[index];
        } else {
          this.audio1.onended = null;
        }
        this.audio2.play();
        if (this.messageHistory[index + 1]) {
          this.audio1.src = this.messageHistory[index + 1];
        }
        this.audio2.onended = () => {
          URL.revokeObjectURL(this.messageHistory[index]); // 释放当前播放完的音频
          this.playAudio(index + 1, 1);
        };
      } else {
        if (this.audio1.src !== this.messageHistory[index]) {
          // 判断是否已经存在需要播放的音频,不一样则赋值
          this.audio1.src = this.messageHistory[index];
        } else {
          this.audio1.onended = null; // 防止多次触发监听 ，卸载绑定事件
        }
        this.audio1.play();
        if (this.messageHistory[index + 1]) {
          // 是否有下一个需要播放音频，预加载
          this.audio2.src = this.messageHistory[index + 1];
        }
        this.audio1.onended = () => {
          // 监听播放结束播放下一个音频
          this.playAudio(index + 1, 2);
          URL.revokeObjectURL(this.messageHistory[index]); // 释放当前播放完的音频
        };
      }
    } else {
      this.messageHistory = [];
      this.currentKey = 0;
      this.currentAudio = 1;
      console.info("=====播放完毕", this);
      if (this.status === "play") {
        this.status = "stop";
        this.playStatus = "done";
      }
    }
  }
  next(type) {
    // console.info("======播放完毕", this.playStatus, this.status);
    if (this.playStatus === "done") {
      this.status = "stop";
      return;
    }
    this.playAudio(this.currentKey + 1, type);
    URL.revokeObjectURL(this.messageHistory[this.currentKey]); // 释放当前播放完的音频
  }
  stopAudio() {
    console.info(this.current);
    if (this.current > -1) {
      this.playStatus = "Playing";

      if (this.current === 1) {
        this.audio1["pause"]();
      } else {
        this.audio2["pause"]();
      }
    }
  }
}
