<template>
  <div class="chat-title" v-if="value">
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-title {
  color: rgb(49, 51, 63);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 6px;
}
</style>
