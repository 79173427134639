var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "btn-tts" }, [
    _c("div", { staticClass: "btn-tts-inner", on: { click: _vm.toggleTTS } }, [
      _c(
        "div",
        { staticClass: "tts-icon", attrs: { id: "v-step-6" } },
        [
          _c("svg-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canTTS,
                expression: "canTTS",
              },
            ],
            staticClass: "icon",
            attrs: { "icon-class": "sound-on" },
          }),
          _c("svg-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.canTTS,
                expression: "!canTTS",
              },
            ],
            staticClass: "icon",
            attrs: { "icon-class": "sound-off" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }