var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.langShow
    ? _c("div", [
        _c("div", { staticClass: "tour-overlay" }),
        _c("div", { staticClass: "language" }, [
          _c("div", { staticClass: "box" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("home.selectDisplay", {
                    name: _vm.$t("home." + _vm.$store.state.langCode),
                  })
                )
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("home.selectTip", {
                    name: _vm.$t("home." + _vm.$store.state.langCode),
                  })
                )
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn main",
                  staticStyle: { "margin-bottom": "20px" },
                  on: { click: _vm.enter },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("home.display", {
                        name: _vm.$t("home." + _vm.$store.state.langCode),
                      })
                    )
                  ),
                ]
              ),
              _c(
                "el-button",
                { staticClass: "btn", on: { click: _vm.cancel } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("home.display", {
                        name: _vm.$t("home." + _vm.$i18n.locale),
                      })
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }