<template>
  <div v-if="$store.state.showSide">
    <div class="tour-overlay" @click="close"></div>
    <div
      class="side"
      ref="sideRef"
      :class="[isApp ? 'app-side' : '']"
      @click="closeSideList"
    >
      <div class="title-box" v-if="!$store.state.isMin900Width">
        <div class="icon-box pc" @click="close" id="v-step-11">
          <svg-icon icon-class="extend" class="extend-icon" />
        </div>
        <span class="mobile" v-if="false">{{ $t("common.history") }}</span>
        <div class="flag-box" v-if="false" @click="goPrinciple">
          <svg-icon class="mobile-flag" icon-class="flag" />
        </div>

        <div class="pc" @click="newChat" id="v-step-12">
          <svg-icon
            icon-class="add"
            class="chat-icon"
            style="color: var(--ss-text)"
          /><span class="new-chat">{{ $t("side.newChat") }}</span>
        </div>
      </div>
      <div class="content" ref="conversationList" @scroll="handleScroll">
        <div>
          <span class="chat-title">{{ $t("common.principles") }}</span>
          <div
            class="side-item"
            @click="goPrinciple"
            :class="!isHome ? 'is-cilck' : ''"
            style="margin-bottom: 16px;"
          >
            <div class="flex-center">
              <svg-icon icon-class="flag" class="flag-icon" />
              <span> {{ $t("home.myPrinciples") }}</span>
            </div>
          </div>
        </div>

        <span class="chat-title">{{
          $t("common.chat") + " " + $t("common.history")
        }}</span>
        <div v-for="(item, index) in listData" :key="index">
          <div v-if="item.length > 0">
            <div class="date">{{ $t("date." + item[0].date) }}</div>
            <div
              class="side-item"
              :class="{
                'is-cilck': items.click,
                'is-touch': items.id === touchId,
              }"
              v-for="(items, indexs) in item"
              :key="indexs"
              @click.stop="handleClickItem(item, items)"
              @touchstart="handleTouchStart($event, item, items, index, indexs)"
              @touchmove="handleTouchMove"
              @touchend="handleTouchEnd"
              @mousedown="handleTouchStart($event, item, items, index, indexs)"
              @mouseup="handleTouchEnd"
              @contextmenu.prevent
            >
              <div class="flex-center">
                <svg-icon icon-class="chat" class="chat-icon" />
                <el-input
                  v-if="items.edit"
                  class="item-input"
                  v-model="items.name"
                  @change="changeinputName(item, items)"
                  @blur="changeinputName(item, items)"
                ></el-input>
                <span v-else :class="items.click ? 'click-box' : 'text-box'">{{
                  items.name
                }}</span>
              </div>
              <template v-if="!isPhone">
                <div v-if="items.edit" class="flex-center">
                  <div @click.stop="changeName(item, items)">
                    <svg-icon icon-class="enter" class="enter" />
                  </div>
                  <div @click.stop="handleCancel(item, items)">
                    <svg-icon icon-class="close" class="cancel" />
                  </div>
                </div>
                <div v-else-if="items.click && !items.edit" class="flex-center">
                  <div @click="handleEdit(item, items)">
                    <svg-icon icon-class="edit" style="margin-right: 8px" />
                  </div>
                  <div @click="handleDel(item, items, index, indexs)">
                    <svg-icon icon-class="side-del" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="loading" class="loading-indicator">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <div class="title-box" @click="clear" id="v-step-13" v-if="false">
        <div style="width: 100%">{{ $t("side.clearAllChats") }}</div>
      </div>
    </div>
    <div
      class="side-list"
      :style="{
        top: sideListY + 20 + 'px',
        left: sideListX + 20 + 'px',
      }"
      v-if="isSideListOpen"
    >
      <div class="sl-line" @click="rename">
        <svg-icon icon-class="edit2" />
        <div class="sl-text">{{ $t("side.rename") }}</div>
      </div>
      <div class="sl-line" @click="deleteFromSL">
        <svg-icon icon-class="delete2" />
        <div class="sl-text">{{ $t("common.delete") }}</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="isRenameOpen"
      append-to-body
      :title="$t('side.renameChat')"
      @closed="closeSideList"
      class="rename-frame"
    >
      <el-input
        v-model="renameName"
        :placeholder="$t('side.plsInputChat')"
        class="rename-input"
        size="mini"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmRename">{{
          $t("common.done")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
  <div
    v-else-if="!$store.state.showSide && !$store.state.isMin900Width"
    class="hide-side"
  >
    <div class="hide-icon-box" id="v-step-11">
      <div class="hide-add-box" style="border: none" @click="open">
        <svg-icon icon-class="extend" class="hide-extend" />
      </div>

      <div class="hide-add-box" @click="newChat">
        <svg-icon
          icon-class="add"
          class="hide-chat-icon"
          style="color: var(--ss-text)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/session'

export default {
  provide() {
    return {
      newChat: this.newChat
    }
  },

  data() {
    return {
      listData: {
        Today: [],
        Yesterday: [],
        'Previous 7 Days': [],
        'Previous 30 Days': [],
        December: [],
        November: [],
        October: [],
        September: [],
        August: [],
        July: [],
        June: [],
        May: [],
        April: [],
        March: [],
        February: [],
        January: []
      },
      list: [],
      loading: false,
      page: 0,
      size: 100,
      total: 0,
      pressTimer: null,
      nowItemData: null,
      sideListX: 0,
      sideListY: 0,
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      beginTouchTime: 0,
      isSideListOpen: false,
      touchId: '',
      isRenameOpen: false,
      renameName: '',
      isHome: true,
      originalName: ''
    }
  },
  computed: {
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    },
    isPhone() {
      return this.$store.state.isMin900Width
    },
    isCanSwiper() {
      return this.$store.state.isSwiperOpen
    }
  },
  inject: ['reload'],
  watch: {
    '$store.state.sessionCount': {
      handler(val) {
        this.$nextTick(() => {
          this.page = 0
          this.getList('init')
        })
      },
      deep: true
    },
    isCanSwiper(val) {
      this.closeSideList()
    },
    '$route.name': {
      handler(val) {
        if (this.$route.name === 'chatHome') {
          this.isHome = true
        }
      },
      deep: true
    },
    isHome: {
      handler(val) {
        if (val && this.$route.name !== 'chatHome') {
          this.$router.push({
            name: 'chatHome'
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  // beforeDestroy() {
  //   this.close()
  // },
  created() {
    if (window.innerWidth <= 900 && process.env.VUE_APP_PLATFORM !== 'APP') {
      this.close()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(init) {
      this.loading = true
      const data = {
        page_size: this.size,
        page_index: this.page
      }
      api
        .getList(data)
        .then((res) => {
          if (res.data) {
            this.listData = {
              Today: [],
              Yesterday: [],
              'Previous 7 Days': [],
              'Previous 30 Days': [],
              December: [],
              November: [],
              October: [],
              September: [],
              August: [],
              July: [],
              June: [],
              May: [],
              April: [],
              March: [],
              February: [],
              January: []
            }
            if (init) {
              this.list = []
            }

            if (res.data.chat_list.length > 0) {
              for (const i in this.listData) {
                this.listData[i] = []
              }
              for (const i of res.data.chat_list) {
                const temp = {
                  name: i.title,
                  click: false,
                  edit: false,
                  date: this.getTimeKey(i.updated_time),
                  id: i.chat_id
                }
                this.list.push(temp)
              }

              for (const i of this.list) {
                this.listData[i.date].push(i)
              }
              this.initClick()
            } else {
              this.$store.commit('setHistory', [])
            }
          }
          this.total = res.data.total_count
          this.page++
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    getTimeKey(date) {
      let res = ''
      const today = moment().add(-1, 'd').format('YYYY-MM-DD')
      const yesterday = moment().add(-2, 'd').format('YYYY-MM-DD')
      const day7 = moment().add(-7, 'd').format('YYYY-MM-DD')
      const day30 = moment().add(-7, 'd').format('YYYY-MM-DD')
      const time = moment(date, 'YYYY-MM-DD')
      const month = moment(date).month()
      if (moment(time).isAfter(today)) {
        res = 'Today'
      } else if (moment(time).isAfter(yesterday)) {
        res = 'Yesterday'
      } else if (moment(time).isAfter(day7)) {
        res = 'Previous 7 Days'
      } else if (moment(time).isAfter(day30)) {
        res = 'Previous 30 Days'
      }
      if (!res) {
        switch (month) {
          case 0:
            res = 'January'
            break
          case 1:
            res = 'February'
            break
          case 2:
            res = 'March'
            break
          case 3:
            res = 'April'
            break
          case 4:
            res = 'May'
            break
          case 5:
            res = 'June'
            break
          case 6:
            res = 'July'
            break
          case 7:
            res = 'August'
            break
          case 8:
            res = 'September'
            break
          case 9:
            res = 'October'
            break
          case 10:
            res = 'November'
            break
          case 11:
            res = 'December'
            break
        }
      }
      return res
    },
    clear() {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('side.del'),
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('side.delTip')),
          h('span', { style: 'font-weight: 600;' }, this.$t('home.allChats'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        api.delAllList().then((res) => {
          this.$nextTick(() => {
            this.listData = {
              Today: [],
              Yesterday: [],
              'Previous 7 Days': [],
              'Previous 30 Days': [],
              January: [],
              February: [],
              March: [],
              April: [],
              May: [],
              June: [],
              July: [],
              August: [],
              September: [],
              October: [],
              November: [],
              December: []
            }
            this.newChat()
          })
        })
      })
    },
    goPrinciple() {
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])
      this.initClick()
      this.isHome = false
      if (this.$store.state.isMin900Width) {
        this.close()
      }
      this.$router.push({
        name: 'principles'
      })
    },
    async newChat() {
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])

      this.isHome = true
      // await this.$store.dispatch('initTTS')

      this.initClick()
      if (this.isPhone) {
        this.close()
      }
      this.$nextTick(() => {
        this.reload()
      })
    },
    initClick() {
      for (const i in this.listData) {
        for (const j in this.listData[i]) {
          if (this.$store.state.sessionId === this.listData[i][j].id) {
            this.listData[i][j].click = true
          } else {
            this.listData[i][j].click = false
          }
        }
      }
      this.listData = JSON.parse(JSON.stringify(this.listData))
    },
    close() {
      this.closeSideList()
      if (this.isApp) {
        console.info('close')
        this.$emit('close')
      } else {
        this.$store.commit('setShowSide', false)
      }
    },
    open() {
      this.$store.commit('setShowSide', true)
    },

    handleScroll() {
      if (this.loading || this.list.length >= this.total) {
        return
      }

      const conversationList = this.$refs.conversationList
      const threshold = 100 // pixels from bottom to trigger load

      if (
        conversationList.scrollHeight -
          (conversationList.scrollTop + conversationList.clientHeight) <=
        threshold
      ) {
        this.loading = true
        this.getList()
      }
    },
    handleClickItem(item, items, isPhoneTouch) {
      this.isHome = true
      if (
        this.$store.state.sessionId === items.id ||
        (this.isPhone && !isPhoneTouch)
      ) {
        return
      }
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])
      this.$store.commit('setChatLoading', true)
      this.$nextTick(() => {
        this.reload()
      })
      for (const i in this.listData) {
        for (const j in this.listData[i]) {
          this.listData[i][j].click = false
        }
      }
      items.click = true
      this.$store.commit('setSessionId', items.id)
      if (this.isPhone) {
        setTimeout(() => {
          this.close()
        }, 0)
      }
      const data = {
        // cursor: '',
        limit: 20
      }
      api
        .getSessionDetail(items.id, data)
        .then((res) => {
          this.$store.commit('setChatLoading', false)
          if (res.data) {
            this.$store.commit('setSessionId', items.id)
            this.$store.commit('setHistoryOrigin', res.data)
            console.info('setHistoryOrigin', res.data)
            this.reload()
          }
        })
        .catch(() => {
          this.$store.commit('setChatLoading', false)
        })

      // this.$forceUpdate()
      // this.$nextTick(() => {

      // })
    },

    handleEdit(item, items) {
      this.originalName = items.name // Store the original name
      items.edit = true
    },

    handleCancel(item, items) {
      items.name = this.originalName // Restore the original name
      items.edit = false
    },
    handleDel(item, items, index, indexs) {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('side.del'),
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('side.delTip')),
          h('span', { style: 'font-weight: 600;' }, items.name)
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      })
        .then(() => {
          item.splice(indexs, 1)
          this.reload()
          this.$store.commit('setSessionId', '')
          this.$store.commit('setHistory', [])
          api.delSession(items.id)
        })
        .finally(() => {
          this.closeSideList()
        })
    },
    changeinputName(item, items) {
      if (!this.isPhone) {
        if (items.name !== this.originalName) {
          this.changeName(item, items)
        } else {
          items.edit = false
        }
      }
    },
    changeName(item, items) {
      this.$nextTick(() => {
        setTimeout(() => {
          items.edit = false
        }, 100)
      })

      if (items.name !== this.originalName) {
        const data = {
          sessionId: items.id,
          title: items.name
        }
        api.updateSession(data)
      }
    },
    handleTouchStart(event, item, items, index, indexs) {
      if (!this.isPhone) {
        return
      }
      this.closeSideList()
      this.nowItemData = {
        item,
        items,
        index,
        indexs
      }
      this.beginTouchTime = event.timeStamp
      const clientX = event.touches ? event.touches[0].clientX : event.clientX
      const clientY = event.touches ? event.touches[0].clientY : event.clientY
      const pageX = event.touches ? event.touches[0].pageX : event.pageX
      const pageY = event.touches ? event.touches[0].pageY : event.pageY
      this.touchStartX = clientX
      this.touchStartY = clientY
      this.touchEndX = clientX
      this.touchEndY = clientY
      this.pressTimer = setTimeout(() => {
        this.isSideListOpen = true
        this.touchId = items.id
        // Determine if the div is below the viewport
        const sideWidth = this.$refs.sideRef.offsetWidth
        if (pageX + 252 > sideWidth) {
          this.sideListX = sideWidth - 262
        } else {
          this.sideListX = pageX
        }
        if (pageY + 132 > window.innerHeight) {
          this.sideListY = pageY - 142
        } else {
          this.sideListY = pageY
        }
      }, 700)
    },
    handleTouchMove(event) {
      if (this.pressTimer) {
        clearTimeout(this.pressTimer)
      }
      this.touchEndX = event.touches[0].clientX
      this.touchEndY = event.touches[0].clientY
    },
    handleTouchEnd(event) {
      const deltaX = this.touchEndX - this.touchStartX
      const deltaY = this.touchEndY - this.touchStartY
      if (this.pressTimer) {
        clearTimeout(this.pressTimer)
      }
      if (
        Math.abs(deltaX) < 10 &&
        Math.abs(deltaY) < 10 &&
        event.timeStamp - this.beginTouchTime < 700
      ) {
        this.handleClickItem(
          this.nowItemData.item,
          this.nowItemData.items,
          true
        )
      }
    },
    closeSideList() {
      this.isSideListOpen = false
      this.touchId = ''
      this.nowItemData = null
    },
    rename() {
      this.isSideListOpen = false
      this.isRenameOpen = true
      this.renameName = this.nowItemData.items.name
    },
    confirmRename() {
      this.isRenameOpen = false
      this.nowItemData.items.name = this.renameName
      this.changeName(this.nowItemData.item, this.nowItemData.items)
      this.closeSideList()
    },
    deleteFromSL() {
      this.isSideListOpen = false
      this.handleDel(
        this.nowItemData.item,
        this.nowItemData.items,
        this.nowItemData.index,
        this.nowItemData.indexs
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-side {
  width: 67px;
  height: calc(100% - 64px);
  margin-top: 64px;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: var(--side-bg);
  padding: 20px 8px;
  padding-top: calc(env(safe-area-inset-top) + 20px);
  color: var(--text);
  box-shadow: 0px 2px 4px 0px #0000001f;
}

.side {
  width: 260px;
  height: calc(100% - 64px);
  margin-top: 64px;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: var(--side-bg);
  padding: 20px 16px;
  padding-top: calc(env(safe-area-inset-top) + 20px);
  color: var(--text);
  box-shadow: 0px 2px 4px 0px #0000001f;

  .content {
    max-height: calc(100% - 4rem);
    min-height: calc(100% - 4rem);
    margin-top: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .title-box {
    font-family: "Robot";
    display: flex;
    justify-content: space-between;
    height: 42px;
    align-items: center;
    font-size: 15px;
    // font-weight: 500;
    // width: 240px;

    div {
      cursor: pointer;
      border: 1px solid var(--br);
      height: 32px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 12px 8px;
      font-size: 13px;
      color: var(--ss-text);
      font-family: Roboto;
      // width: 180px;
      justify-content: center;
    }

    .icon-box {
      width: 32px;
      text-align: center;
      justify-content: center;
      // margin-left: 8px;
      padding: 0;
      padding-left: 2px;
      border: none;
      font-size: 16px;
      color: var(--ss-text);
    }

    div:hover {
      background: var(--btn-hover);
      border: none;
      color: var(--bg);

      .chat-icon {
        color: var(--bg) !important;
      }
    }
  }

  .date {
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    color: var(--ss-text);
    padding: 0 8px;
  }

  .side-item {
    width: 100%;
    height: 36px;
    font-weight: 400;
    font-size: 13px;
    line-height: 36px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 4px;
    position: relative;
    user-select: none;
  }

  .side-item:hover {
    background: var(--h-text);
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .is-touch {
    background-color: var(--dropdown-touch-bg) !important;
  }

  .is-cilck {
    background: var(--h-text) !important;
  }

  .cancel {
    font-size: 14px;
  }

  .enter {
    font-size: 14px;
    margin-right: 8px;
  }

  .text-box {
    width: 192px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .click-box {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff0;
    background-image: none;
    border-radius: 0px;
    border: none;
    border-bottom: 0.5px solid var(--text) !important;
    box-sizing: border-box;
    color: var(--text);
    display: inline-block;
    height: 32px;
    line-height: 32px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .item-input {
    width: 140px;
  }
}

.side-list {
  position: absolute;
  width: 232px;
  height: 102px;
  padding: 10px;
  border-radius: 8px;
  background: var(--dropdown-bg2);
  left: 120px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--ss-text);
  z-index: 500;
  box-shadow: 0px 10px 24px 0px var(--dropdown-shadows);

  .sl-line {
    display: flex;
    margin-bottom: 10px;
    height: 36px;
    align-items: center;
    border-radius: 8px;

    &:active {
      background: var(--dropdown-hover-bg);

      .sl-text {
        color: var(--tab-text);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      margin: 0 8px;
    }
  }
}

.pc {
  display: inline-flex !important;
}

.mobile {
  display: none !important;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Roboto";

  color: var(--text);
}

.tour-overlay {
  display: none;
}

.chat-icon {
  margin-right: 8px;
  color: var(--side-bule);
}

.loading-indicator {
  text-align: center;
}

.rename-frame {
  ::v-deep .el-dialog {
    background-color: var(--dropdown-bg);
    margin-top: calc(50vh - 80px) !important;
    width: calc(100vw - 74px);
    border-radius: 8px;
    box-shadow: 0px 10px 24px 0px #00000026;
    box-shadow: 0px 0px 2px 0px #00000040;

    .rename-input {
      .el-input__inner:focus {
        border: 1px solid var(--blue-t-text) !important;
      }
    }

    .el-dialog__header {
      overflow: hidden;
      padding: 0;
    }

    .el-dialog__body {
      padding: 0 12px;
      margin-top: 24px;

      .el-input__inner {
        border-radius: 8px;
        border: 1px solid var(--h-text);
        background-color: var(--dropdown-bg);
        color: var(--tab-text);
        font-size: 13px;
        height: 30px;
      }
    }

    .el-dialog__title {
      font-size: 13px;
      font-weight: 600;
      display: block;
      color: var(--title-text);
      margin-top: 14px;
      margin-left: 12px;
    }

    .el-dialog__headerbtn {
      top: 12px;
      right: 12px;

      .el-icon {
        font-size: 20px;
        color: var(--title-text);
      }
    }

    .el-dialog__footer {
      padding: 0;
    }

    .dialog-footer {
      text-align: center;
      padding: 16px 0 12px;

      .el-button {
        background-color: var(--switch);
        border-radius: 8px;
        width: 55px;
        height: 32px;
        font-weight: 500;
        color: #fff;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
  }
}

.chat-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--s-text);
  display: block;
  padding: 8px 8px;
}

.flag-icon {
  color: var(--red);
  font-size: 18px;
  margin-right: 8px;
}

.hide-icon-box {
  margin-top: 5px;
}

.hide-add-box {
  width: 32px;
  height: 32px;
  color: var(--ss-text) !important;
  border: 1px solid var(--br);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 8px;
  cursor: pointer;
}

.hide-extend {
  transform: rotate(180deg);
}

.hide-add-box:hover {
  background: var(--btn-hover);
  border: none;

  .hide-extend {
    color: var(--bg) !important;
  }

  .hide-chat-icon {
    color: var(--bg) !important;
  }
}

.extend-icon {
  font-size: 13px;
}

.flag-box {
  width: 32px;
  height: 32px;
  background: var(--dropdown-hover-bg);
  display: flex !important;
  align-items: center !important;
  justify-items: center !important;
  border: none !important;
  position: relative;
}

.flag-box:hover {
  background: var(--dropdown-hover-bg) !important;
}

.mobile-flag {
  font-size: 15px;
  color: var(--text);
  margin-top: -2px;
  margin-left: 1px;
  position: absolute;
}

@media screen and (max-width: 900px) {
  .tour-overlay {
    display: block;
  }

  .side {
    position: fixed;
    width: 80%;
    bottom: 0;
    height: 100%;
    margin-top: 0px;
    top: 0;
    background: var(--side-bg);

    .side-item:hover {
      background: none;
    }

    .item-input {
      width: calc(80vw - 120px);
    }
  }

  .app-side {
    width: 100% !important;
  }

  .pc {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .side .text-box {
    width: calc(80vw - 60px);
    user-select: none;
  }

  .side .click-box {
    width: calc(80vw - 100px);
  }

  .side .title-box {
    width: calc(100% - 8px);
    margin-left: 4px;
  }
  .side .content {
    max-height: calc(100% - 2rem);
    min-height: calc(100% - 2rem);
  }
}
</style>
