<template>
    <div>

    </div>
</template>

<script>
import { App } from '@capacitor/app'
export default {
  watch: {
    '$store.state.versionDialog': {
      handler(val) {
        if (val) {
          this.handleVersion()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleVersion() {
      console.info('=========')
      if (process.env.VUE_APP_PLATFORM !== 'APP') {
        return
      }
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('common.version'),
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('common.versionTip'))

        ]),
        showCancelButton: false,
        confirmButtonText: this.$t('common.close'),
        cancelButtonText: this.$t('common.cancel')
      }).then(async () => {
        console.info('====关闭了')
        this.$store.commit('setVersionDialog', false)
        await App.exitApp()
      }).catch(async () => {
        console.info('====关闭了1')
        this.$store.commit('setVersionDialog', false)
        await App.exitApp()
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
