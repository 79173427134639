<template>
  <img
    class="image-cached"
    crossorigin="anonymous"
    alt=""
    :src="source"
    v-if="src"
  />
  <div v-else-if="$store.state.profile.name" class="userbox">
    <span class="text">{{ $store.state.profile.name.charAt(0) }}</span>
  </div>
  <img
    class="image-cached"
    crossorigin="anonymous"
    alt=""
    src="../../assets/images/default-head.png"
    v-else
  />
</template>

<script>
import { isImageCached, cacheImage, getCachedImage } from './cache'
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: true,
      source: this.src
    }
  },
  created() {
    isImageCached(this.src) && this.onCache(true)
  },
  methods: {
    cacheImage(img) {
      cacheImage(this.src, img)
    },
    onCache(done) {
      this.show = done
      this.$emit('cache', done)
      this.source = getCachedImage(this.src)
    }
  }
}
</script>
<style lang="scss" scoped>
.image-cached {
  object-fit: cover;
}
.userbox {
  background: #D1D1D1 !important;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  border: none;
}
.text{
  position: absolute;
  font-weight: bold;
  font-size: 13pt;
  color: #171717;
  top: 50%;
  left: 50%;
  display: block;
  margin-top: 0px;
  line-height: 16px;
  transform: translate(-50%,-50%) !important;
}
</style>
