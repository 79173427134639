<template>
  <div v-if="$store.state.langShow">
    <div class="tour-overlay"></div>
    <div class="language">
      <div class="box">
        <span class="title">{{
          $t("home.selectDisplay", {
            name: $t("home." + $store.state.langCode),
          })
        }}</span>
        <span>{{
          $t("home.selectTip", { name: $t("home." + $store.state.langCode) })
        }}</span>
      </div>
      <div class="btn-box">
        <el-button
          @click="enter"
          class="btn main"
          style="margin-bottom: 20px"
          >{{
            $t("home.display", { name: $t("home." + $store.state.langCode) })
          }}</el-button
        >
        <el-button @click="cancel" class="btn">{{
          $t("home.display", { name: $t("home." + $i18n.locale) })
        }}</el-button>
        <!-- <el-checkbox v-model="$store.state.remind" class="check">{{$t('home.remind')}}</el-checkbox> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  props: {},
  data() {
    return {
      visible: false,
      language: this.$store.state.profile.language || 'en'
    }
  },
  methods: {
    enter() {
      this.cancel()
      this.$i18n.locale = this.$store.state.langCode
      this.$store.commit('setRegenerate', true)
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])
      const data = {
        language: this.$i18n.locale
      }
      this.$store.dispatch('updateProfile', data)
      this.reload()
      this.$nextTick(() => {
        this.$emit('change')
      })
    },
    open() {
      if (this.$store.state.langCode) {
        this.language = this.$store.state.profile.language || 'en'
        this.visible = true
      }
    },
    cancel() {
      this.$store.commit('setLangShow', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.language {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  min-height: 228px;
  background-color: var(--container-bg);
  color: var(--text);
  justify-content: space-between;
  .box {
    margin: 0 100px;
    .title {
      font-weight: 800;
      font-size: 20px;
      display: block;
      margin-bottom: 16px;
    }
  }
  .btn {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    width: 100%;
    border-radius: 38px;
    color: var(--text);
    background-color: var(--h-text);
    border: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .btn:hover {
    background-color: var(--h-text);
    border-color: var(--br);
  }
  .main {
    border: 1px solid var(--br);
    background-color: var(--container-bg);
  }
  .main:hover {
    background-color: var(--container-bg);
    border-color: var(--br);
  }
  .btn-box {
    width: 236px;
    margin-right: 100px;
  }
}
.check {
  position: absolute;
  bottom: 20px;
  right: 100px;
}
@media screen and (max-width: 900px) {
  .language{
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    .box{
      margin: 0;
    }
    .btn-box{
        margin-right: 0px;
        margin-top: 20px;
    }
  }
  // .btn-box{
  //   margin-right: 0px;
  // }
}
</style>
