<template>
  <div class="faq-box">
    <div class="title">FAQ</div>
    <div class="content-box" v-for="(item,index) in list" :key="index">
      <span class="sub-title">{{ item.title }}</span>
      <span class="text">{{ item.content }}</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    list() {
      const res = [
        {
          title: "Is this really Ray Dalio's thoughts or just AI guesses?",
          content:
            "DIGITAL RAY provides both standard answers provided by Ray Dalio (which means Ray's thoughts) and AI-generated answers based on the data it has been trained on. However, even those AI-generated answers mimic the way Ray Dalio thinks and answers."
        },
        {
          title: "Is this really Ray Dalio's thoughts or just AI guesses?",
          content:
            "We have developed an algorithm system that automatically scrapes the latest data every day. This includes news, articles, Ray Dalio's social media posts, interviews, speeches, and more. Additionally, we have implemented a system to ensure that the latest data is used to replace the old data and train the model."
        },
        {
          title: 'What can I ask DIGITAL RAY?',
          content:
            'As our slogan states, you can ask DIGITAL RAT anything you like and receive answers similar to those given by Ray Dalio himself. We pride ourselves on providing a wide range of information and insights, ensuring that you have access to valuable knowledge. However, it is important to keep in mind that there are certain limitations. While we strive to be as helpful as possible, there are some questions that we are unable to answer. This includes inquiries related to illegal activities, unethical practices, or matters that are of a private nature. We prioritize maintaining a safe and respectful environment for all users and adhere to a strict code of ethics. Rest assured, our aim is to provide you with a wealth of information and guidance within the boundaries of what is reasonable and appropriate.'
        },
        {
          title: 'Is this a free service?',
          content:
            'This is totally free at present. However, we may ask for some money in the future for model training and consequently better service.'
        }
      ]
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-box{
    min-height: 100vh;
    background: var(--black);
    color: var(--white);
    padding: 140px 120px;
}
.title{
font-size: 32px;
font-weight: 700;
line-height: 38px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 40px;

}
.content-box{
    border-bottom: 0.5px solid var(--white);
    margin-bottom: 40px;
}
.content-box:last-child{
    border: none;
}
.sub-title{
font-size: 24px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
display: block;
margin-bottom: 40px;
}
.text{
font-size: 20px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
display: block;
margin-bottom: 40px;

}
@media screen and (max-width: 1200px) {
  .faq-box{
    padding: 140px 20px;
  }
}
</style>
