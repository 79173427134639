<template>
  <div class="page_loading" v-if="loading">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page_loading .loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 76px;
  height: 76px;
  margin: -38px 0 0 -38px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #ff0000;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.page_loading .loader:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: inherit;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.page_loading .loader::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: inherit;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
