var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "AppDialog",
        {
          ref: "initDom",
          attrs: {
            full: true,
            title: _vm.getTitle,
            titleType: _vm.type === "info" ? "flag" : "",
          },
        },
        [
          _vm.type === "info"
            ? _c("Info", {
                attrs: { item: _vm.item },
                on: { edit: _vm.handleEdit, del: _vm.handleDel },
              })
            : _vm._e(),
          _vm.type === "add" || _vm.type === "edit"
            ? _c("Add", {
                attrs: { item: _vm.item, type: _vm.type },
                on: { change: _vm.handleAdd },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }