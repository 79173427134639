var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "maintenance" }, [
    _c("h1", [_vm._v(_vm._s(_vm.title))]),
    _c("p", [_vm._v(_vm._s(_vm.message))]),
    _c("p", [
      _vm._v("We will be back online: "),
      _c("strong", [_vm._v(_vm._s(_vm.formattedReturnDate))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }