<template>
  <div class="tab-wrap">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tab">
      <slot></slot>
    </el-tabs>
    <div class="box"></div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: String,
      default: null
    }
  },
  watch: {
    active: {
      handler() {
        this.$nextTick(() => {
          setTimeout(() => {
            this.activeName = this.active
          }, 0)
        })
      },
      deep: true
      // immediate: true

      // this.activeName = this.active
    }
  },
  data() {
    return {
      activeName: 'rich'
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.activeName = this.active
      }, 500)
    })
  },
  methods: {
    handleClick(tab, event) {
      this.$emit('change', tab.name)
      console.log(tab, event)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
<style lang="scss" scoped>
::v-deep .el-tabs__header {
    margin: 0;
}
.tab-wrap{
  display: inline-block;
}
.tab{
  float: left;
}
.box{
  clear: both;
}

::v-deep .el-tabs__active-bar {
    height: 28px;
    border-radius: 40px !important;
    background-color: var(--h-text);
    z-index: 0;
    margin-bottom: 13px;
    box-shadow: 0px 2px 4px 0px #0000001F;
    color: #fff;
    // min-width: 54px !important;
    // max-width: 74px !important;
}
// ::v-deep .el-tabs__active-bar:first-child {
//     // width: 54px !important;
// }
::v-deep .el-tabs__item {
    padding: 0 0px;
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    position: relative;
    color: #fff !important;
    top:0px;
    box-sizing: content-box !important;
}
::v-deep .el-tabs__item::after {
    content: '';
    width: 13px;
    display:inline-block;
}
::v-deep .el-tabs__item::before {
    content: '';
    width: 13px;
    display:inline-block;
}
::v-deep .el-tabs__nav-wrap {
    height: 36px;
    padding: 0px 4px;
    background: var(--s-bg);
    border-radius: 40px !important;;
    line-height: 36px;
    overflow: visible;
}
::v-deep .el-tabs__nav-wrap::after {
    display: none;
}
</style>
