<template>
  <div class="home">
    <CallRay
      :show="showCallRay"
      @hangUp="handleHangUp"
      ref="callRayComponent"
    />
    <AppHelp />
    <AppComment
      v-if="$store.state.appComment"
      @change="ratingChange"
    ></AppComment>
    <ChatDialogRating
      ref="chatDialogRating"
      :msg="lastAskmeMessage"
      @change="ratingChange"
    ></ChatDialogRating>

    <div

      class="chat-wrap"
      :class="$store.state.showSide ? 'chat-wrap-width' : ''"
    >
      <div class="chart-content" id="chartContent">
        <ChatMessages
          sender="DFO"
          tips="Scroll down and start chatting"
          title=""
          :messages="visibleAskmeMessagesWithIntro"
          :loading="askmeLoading"
          :generating="isGenerating"
          @translation="handleTranslation"
          @callQuickQuestion="handleQuickQuestion"
          @makeShorter="(val) => handleShortAnwser(val, 'DFO')"
          @forceResend="handleForceResend"
          @changeFaq="faqChange"
          @loadChat="handleLoadChat"
          :showFaq="getFaqChoose === 'default'"
          @regenerate="handleChatRegenerate"
          @principleDay="handlePrincipleDay"
          ref="msg"
        />
      </div>
    </div>

      <div
      v-if="getFaqChoose !== 'default'"
      class="footer-wrap"
      :class="[
        $store.state.showSide ? 'chat-wrap-width' : '',
        $store.state.appHelps ? 'appHelps' : '',
      ]"
    >
      <div v-if="false">
        <ChatFaq :faq="getFaq" @change="faqChange"></ChatFaq>
      </div>
      <div>
        <div class="operation-cover" ref="topic"
        @touchstart="toggleSwiper(true)"
        @touchmove="toggleSwiper(true)"
        @touchend="toggleSwiper(false)">
          <div class="quick-questions" ref="quick" v-if="!showTopic">
            <QuickQuestions
              v-if="false"
              v-show="$store.state.isMin900Width"
              @callQuickQuestion="handleQuickQuestion"
            />
          </div>
          <div
            class="quick-questions"
            v-if="showTopic"
            v-show="!$store.state.appHelps"
          >
            <ChatTopic @change="handleTopic"></ChatTopic>
          </div>
          <div class="operation-header">
            <ChatOperationHeader
              :loading="isLoading"
              :generating="isGenerating"
              :generatable="isGeneratable"
              @stop="handleStop(false, true)"
              @regenerate="handleRegenerate"
            />
          </div>
        </div>
        <div class="footer" >
          <ChatOperations
            :loading="isLoading"
            :generating="isGenerating"
            :generatable="isGeneratable"
            :canTTS.sync="canTTS"
            @stop="handleStop(false, true)"
            @clear="handleClear"
            @callRay="handleCallRay"
          />
          <ChatInput
            class="input-box"
            ref="input"
            :canASR.sync="canASR"
            :generating="isGenerating"
            v-model="message"
            @send="handleSend"
          />
        </div>
        <span class="disclaimer" v-html="$t('footer.disclaimer')"></span>
      </div>
    </div>
  </div>
</template>

<script>

import forceUpdateMixin from '@/mixins/forceUpdateMixin'
import * as api from '@/api/session'
import * as webchat from '@/api/webchat'
import { Keyboard } from '@capacitor/keyboard'
import newMessage, {
  // clearMessages,
  // offStorageChange,
  // onStorageChange,
  formatContent
} from '@/utils/newMessage'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'chatHome',
  provide() {
    return {
      goBottom: this.goBottom
    }
  },
  components: {
    ChatMessages: () => import('./components/ChatMessages/index.vue'),
    ChatInput: () => import('./components/ChatInput/index.vue'),
    ChatOperations: () => import('./components/ChatOperations/index.vue'),
    CallRay: () => import('./components/CallRay/index.vue'),
    AppHelp: () => import('./components/AppHelp/index.vue'),
    // QuickQuestions: () => import('./components/QuickQuestions/index.vue'),
    ChatOperationHeader: () => import('./components/ChatOperationHeader/index.vue'),
    ChatFaq: () => import('./components/ChatFaq/index.vue'),
    AppComment: () => import('./components/AppComment/index.vue'),
    ChatTopic: () => import('./components/ChatTopic/index.vue'),
    ChatDialogRating: () => import('./components/ChatDialogRating/index.vue')
  },
  inject: ['reload', 'reloadApp'],
  mixins: [forceUpdateMixin],
  data() {
    return {
      canASR: false,
      canTTS: this.$store.state.canTTS,
      messageId: '',
      changeMessage: false,
      isShortMode: false,
      askmeLoading: false,
      arrowLeftShow: false,
      arrowRightShow: false,
      showTopic: true,
      shortIndex: 0,
      faq: {
        list: [],
        chooseId: '',
        id: ''
      },
      message: '',
      askmeMessages: [],
      makeShorderMessages: [],
      cansed: true,
      originalLastMessageContent: '',
      shortedMessageContent: '',
      generateAskmeInput: null,
      askmeWebsocket: null,
      faqWebsocket: null,
      askmeConnected: false,
      chatHistoryRound: 2,
      lastMessageTimestamp: Date.now(),
      askTimer: null,
      linkTimer: null,
      timer: null,
      showCallRay: false,
      sessionId: '',
      pendingMessage: null,
      is2m: false,
      is25s: false,
      keyboardHeight: 0,
      recordMessage: {
        ask: ''
      }
    }
  },
  watch: {
    // '$store.state.appComment': {
    //   deep: true,
    //   handler(val, old) {
    //     if (old && !val) {
    //       this.$nextTick(() => {
    //         this.$refs.chatDialogRating.reopen()
    //       })
    //     }
    //   }
    // },
    '$store.state.networkStatus': {
      deep: true,
      handler(val, old) {
        if (!val.connected) {
          this.handleStop(false)
        } else {
          if (old) {
            // this.reloadApp()
          }
        }
      }
    },
    canASR: {
      handler() {
        console.log('TODO: canASR', this.canASR)
      },
      immediate: true
    }
    // canTTS: {
    //   handler() {
    //     console.log('TODO: canTTS', this.canTTS)
    //     this.$store.commit('setCanTTS', this.canTTS)
    //     console.info(this.$store.state.canTTS, 'tts')
    //     this.$nextTick(() => {
    //       if (!this.canTTS && this.$store.state.tts) {
    //         this.$store.state.tts.destroy()
    //       }
    //     })
    //   },
    //   immediate: true
    // }
  },
  computed: {
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    },
    userProfile() {
      return this.$store.state.profile
    },
    networkStatus() {
      return this.$store.state.networkStatus
    },
    lastAskmeMessage() {
      const lastMessage = this.askmeMessages[this.askmeMessages.length - 1]
      return lastMessage
    },
    getFaqChoose() {
      let res = ''
      for (const i of this.askmeMessages) {
        if (i.faq) {
          if (i.faq.chooseId) {
            res = i.faq.chooseId
          }
        }
      }
      return res
    },
    getFaq() {
      let res = ''
      for (const i of this.askmeMessages) {
        if (i.faq) {
          if (i.faq.chooseId) {
            res = i.faq
          }
        }
      }
      return res
    },
    isConnected() {
      return this.askmeConnected
    },
    isAskmeGenerating: {
      get() {
        let lastMessage = ''
        if (this.generateAskmeInput) {
          if (this.generateAskmeInput.short) {
            lastMessage = this.askmeMessages[this.shortIndex]
          } else {
            lastMessage = this.lastAskmeMessage
          }
        }

        if (lastMessage && lastMessage.mine) {
          return true
        }
        return lastMessage.typing && !lastMessage.stopped
      },
      set(value) {
        const stopped = !value

        let lastMessage = ''
        if (this.generateAskmeInput) {
          if (this.generateAskmeInput.short) {
            lastMessage = this.askmeMessages[this.shortIndex]
          } else {
            lastMessage = this.lastAskmeMessage
          }
        }
        if (lastMessage && lastMessage.typing) {
          this.$set(lastMessage, 'stopped', stopped)
        }
        // if lastMessage haven't been loaded, add a new message of ...
        if (
          lastMessage &&
          !lastMessage.mine &&
          stopped &&
          lastMessage.typing &&
          lastMessage.error === 'stop'
        ) {
          lastMessage.content = lastMessage.content || '' + '...'
          lastMessage.formatContent = formatContent(
            this.is25s ? this.$t('error.timeOut') : this.networkStatus.connected ? this.$t('error.err') : this.$t('error.noInternetErr')
          )
          lastMessage.isSubModuleSuccess = false
          lastMessage.networkText = ''
        }
        if (
          lastMessage &&
          !lastMessage.mine &&
          stopped &&
          lastMessage.typing &&
          lastMessage.error !== 'stop'
        ) {
          // set question also as stopped
          lastMessage.content = lastMessage.content || '' + '...'
          lastMessage.formatContent = formatContent(
            this.is25s ? this.$t('error.timeOut') : this.networkStatus.connected ? this.$t('error.err') : this.$t('error.noInternetErr')
          )
          lastMessage.isSubModuleSuccess = false
          lastMessage.networkText = ''
        }
      }
    },
    isGenerating: {
      get() {
        return this.isAskmeGenerating
      },
      set(value) {
        this.isAskmeGenerating = value
      }
    },
    isGeneratable() {
      let hasItem = false
      let hasError = false
      let changeLangAlarm = true

      const temp = this.askmeMessages[this.askmeMessages.length - 1]
      if (this.lastAskmeMessage) {
        if (temp.isSubModuleSuccess === false) {
          hasError = true
        }
        if (temp.change_lang_alarm === true) {
          changeLangAlarm = false
        }
      }
      for (const i of this.askmeMessages) {
        if (!i.type && hasError && changeLangAlarm) {
          hasItem = true
        }
      }
      return Boolean(!this.isGenerating && hasItem)
    },
    isLoading: {
      get() {
        return this.askmeLoading
      },
      set(value) {
        this.askmeLoading = value
      }
    },
    introMessage() {
      const intro = this.$t('home.intro')
      return {
        content: intro,
        formatContent: formatContent(intro),
        mine: false,
        stopped: false,
        typing: false,
        finished: false,
        intro: true,
        type: 'first',
        isIntroMessage: true
      }
    },
    visibleAskmeMessagesWithIntro() {
      // return [this.introMessage].concat(this.askmeMessages)
      return this.askmeMessages
    }
  },
  created() {},
  async mounted() {
    this.clearQuickQuestions()
    this.sessionId = this.$store.state.sessionId ? this.$store.state.sessionId : ''
    if (!this.sessionId) {
      const id = uuidv4()
      this.sessionId = id
      this.$store.commit('setSessionId', id)
    }
    this.askmeMessages = []
    this.init()
    // this.initKeyborad()
  },

  async beforeDestroy() {
    // offStorageChange('DFO', this.initAskmeMessages)
    this.handleStop(false)
    // await this.$store.dispatch('delTTS')
    // await this.pause()
    clearTimeout(this.linkTimer)
    this.linkTimer = null
    if (this.changeMessage) {
      this.initErr()
    }
  },
  methods: {
    toggleSwiper(state) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (state) {
        this.$store.commit('setCanSwiper', false)
      } else {
        this.timer = setTimeout(() => {
          this.$store.commit('setCanSwiper', true)
        }, 300)
      }
    },
    initKeyborad() {
      if (this.isApp) {
        Keyboard.addListener('keyboardWillShow', (info) => {
          this.keyboardHeight = info.keyboardHeight
          console.log('keyboard will show with height', info.keyboardHeight)
        })

        Keyboard.addListener('keyboardWillHide', (info) => {
          // alert(info.keyboardHeight)
          this.$nextTick(() => {
            setTimeout(() => {
              this.keyboardHeight = 0
            }, 100)
          })
          console.log('keyboard did show with height', info.keyboardHeight)
        })
      }
    },
    goBottom(event) {
      this.$refs.msg.goBottom()
    },

    // checkForInactivity(type) {
    //   const currentTime = Date.now()
    //   const elapsedSeconds = (currentTime - this.lastMessageTimestamp) / 1000
    //   if (elapsedSeconds >= 600) {
    //     if (type === 'DFO') {
    //       if (this.askmeWebsocket) {
    //         this.askmeWebsocket.close()
    //       }
    //       if (this.$store.state.tts) {
    //         this.$store.state.tts.destroy()
    //       }
    //     }
    //     console.log('Askme WebSocket connection closed due to inactivity')
    //   }
    // },
    async init() {
      await this.initMessages()
      await this.initWebsockets()
      // onStorageChange('DFO', this.initAskmeMessages)
    },
    handleTimeOut() {
      this.askmeConnected = false
      this.isAskmeGenerating = false

      if (this.is25s) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.is25s = false
          }, 500)
        })
      }
      if (this.is2m) {
        this.handleStop(true)
        this.$nextTick(() => {
          setTimeout(() => {
            this.is2m = false
          }, 500)
        })
      } else {
        this.handleStop(false)
      }
    },

    initAskmeWebsocket(url) {
      let messageTimer = null
      let closeTimer = null

      const startTimer = () => {
        clearTimer(messageTimer)
        messageTimer = setTimeout(() => {
          this.is25s = true
          console.log('25秒无消息，断开连接', this.is25s)
          this.handleTimeOut()
          this.askmeWebsocket.close()
        }, 25000)
      }

      const resetCloseTimer = () => {
        clearTimer(closeTimer)
        closeTimer = setTimeout(() => {
          console.log('2分钟无消息，断开连接')
          this.is2m = true
          this.handleTimeOut()
          this.askmeWebsocket.close()
        }, 120000)
      }

      const clearTimer = (timer) => {
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
      }
      const clearTimers = () => {
        clearTimer(messageTimer)
        clearTimer(closeTimer)
      }
      // let timer = null
      this.askmeWebsocket = new WebSocket(url)
      this.askmeWebsocket.addEventListener('open', () => {
        // console.info('=============open', this.askmeConnected)
        this.askmeConnected = true

        if (this.pendingMessage) {
          startTimer()
          this.askmeWebsocket.send(this.pendingMessage)
          this.pendingMessage = null // 清除待发送的消息
        }

        resetCloseTimer()
      })
      this.linkTimer = setTimeout(() => {
        if (!this.askmeConnected) {
          this.handleTimeOut()
        }
      }, 10000)

      this.askmeWebsocket.addEventListener('open', () => {
        console.info('WebSocket open event triggered')
        this.askmeConnected = true // 确保 this.askmeConnected 被正确设置
        console.info('askmeConnected after open:', this.askmeConnected)

        if (this.pendingMessage) {
          startTimer()
          this.askmeWebsocket.send(this.pendingMessage)
          this.pendingMessage = null // 清除待发送的消息
        }

        resetCloseTimer()
      })

      const originalSend = this.askmeWebsocket.send

      this.askmeWebsocket.send = (data) => {
        if (this.askmeWebsocket.readyState === WebSocket.OPEN) {
          console.info('发送了')
          startTimer()
          resetCloseTimer()
          originalSend.apply(this.askmeWebsocket, [data])
        } else {
          console.error('WebSocket 连接还未建立，无法发送数据')
        // 可以选择在这里重试发送或者执行其他逻辑
        }
      }
      this.askmeWebsocket.onclose = (event) => {
        console.log('WebSocket已关闭。', event)
        this.handleTimeOut()
        clearTimers()
      }
      this.askmeWebsocket.addEventListener('message', (wsEvent) => {
        this.handleMessageEvent(wsEvent, 'DFO')

        const message = JSON.parse(wsEvent.data)

        switch (message.event) {
          case 'finish':
            clearTimer(messageTimer)
            break
          default:
            clearTimer(messageTimer)
            startTimer()
            break
        }
        resetCloseTimer()
      })
      this.askmeWebsocket.addEventListener('close', () => {
        // this.askmeConnected = false
        // this.isAskmeGenerating = false
        // clearTimers()
        // this.initErr()
        // reject(Error('websocket closed'))
      })
      this.askmeWebsocket.addEventListener('error', () => {
        this.askmeConnected = false
        this.initErr()
        // reject(Error('websocket closed'))
      })

      // setTimeout(() => {
      //   console.info('======daodian', this)
      //   if (!this.askmeConnected) {
      //     console.info('========close', this.askmeConnected)
      //     this.handleTimeOut()
      //   }
      // }, 10000)
    },

    async initWebsockets() {
      try {
        await this.initAskmeWebsocket(window.getWsUrl())
      } catch (error) {
        console.error('Failed to open a WebSocket connection')
      }
    },

    async handleMessageEvent(wsEvent, type, init) {
      let outputMessage = ''

      this.faq = {
        list: [],
        chooseId: '',
        id: ''
      }

      const event = JSON.parse(wsEvent.data)

      if (type === 'DFO') {
        outputMessage = this.lastAskmeMessage
      }
      if (event.id) {
        for (const i of this.askmeMessages) {
          if (i.id === event.id && !i.mine) {
            outputMessage = i
          }
        }
      }

      if (event.event === 'principles') {
        if (event.principles) {
          outputMessage.principles = event.principles
        }
        outputMessage.aotProcessInfo.principleFound = true
        // setTimeout(() => {
        //   outputMessage.aotProcessInfo.principleFound = true
        // }, 500)
      }
      if (event.event === 'thinking_state') {
        outputMessage.aotProcessInfo.aotSearch = event.use_thinking
        setTimeout(() => {
          outputMessage.aotProcessInfo.aotSearchShow = event.use_thinking
        }, 500)
      }
      if (event.event === 'aots') {
        const aotFaq = {
          type: 'aot',
          aot: event.aot
        }
        outputMessage.aotFaq = aotFaq
        setTimeout(() => {
          outputMessage.aotProcessInfo.aotFound = true
        }, 500)
        setTimeout(() => {
          outputMessage.aotProcessInfo.matchingAotShow = true
        }, 500)
      }
      if (event.event === 'fast_replies') {
        this.$store.commit('setQuickQuestions', event.questions)
        if (event.id !== this.lastAskmeMessage.id) {
          this.clearQuickQuestions()
        }
      }
      if (event.event === 'guess') {
        outputMessage.content = event.disclaimer
        outputMessage.formatContent = formatContent(event.disclaimer)
        outputMessage.toggleMessage = event.toggle_message
        outputMessage.extraInfo = event.intention
        outputMessage.label = event.intention
        outputMessage.isSubModuleSuccess = false
      }
      if (event.event === 'wait_for_inspiration') {
        outputMessage.waitForInspiration = true
      }
      if (event.event === 'internet_infos') {
        outputMessage.principles = event.data
        outputMessage.network = event.use_internet
        outputMessage.internetRemind = event.internet_remind
        if (event.content) {
          outputMessage.thumbnails = event.content
        }
      }
      if (event.event === 'start') {
        if (!outputMessage.principles) {
          outputMessage.aotProcessInfo.principleFound = false
          outputMessage.aotProcessInfo.aotSearch = false
        }
        setTimeout(() => {
          outputMessage.aotProcessInfo.generatedAnswer = true
        }, 1000)
        const lastQuestion = this.askmeMessages[this.askmeMessages.length - 2]
        const translation = this.generateAskmeInput.translation
        const short = this.generateAskmeInput.short
        if (!translation && !short) {
          lastQuestion.extraInfo = event.standard_question
          lastQuestion.label = event.label
        }
        if (event.data) {
          outputMessage.content = event.data
          outputMessage.formatContent = formatContent(event.data)
        }

        outputMessage.evidence = event.evidence
        outputMessage.extraInfo = event.label
        outputMessage.label = event.label
        // this.sendToTTS(event.delta)

        this.$store.commit('setAIReceivedTime', Date.now())
      } else if (event.event === 'data') {
        if (outputMessage.networkText === this.$t('home.typing')) {
          outputMessage.networkText = ''
        }

        if (!outputMessage || outputMessage.mine) {
          // in case init outputMessage not finish
          return
        }
        outputMessage.content = event.data
        outputMessage.formatContent = formatContent(event.data)
        // this.sendToTTS(event.delta)
      }
      if (event.event === 'evidence_display') {
        outputMessage.evidences = {
          evidences: event.evidences,
          displayType: event.display_type
        }
        outputMessage.faqId = event.faq_id
        outputMessage.sourceType = event.source_type
      }

      if (event.event === 'done') {
        // Update AOT process switches

        this.updateAotFinishSwitch()
        if (!outputMessage.timestamp) {
          outputMessage.timestamp = Date.now()
        }
        outputMessage.typing = false
        outputMessage.switchStatus = false
        // If the responsed content containes words less than 120 do not show concise switch
        // if (
        //   outputMessage.content &&
        //   outputMessage.content.trim().split(/\s+/).length > 120
        // ) {
        //   outputMessage.showSwitch = true
        // } else {
        //   outputMessage.showSwitch = false
        // }
        outputMessage.showSwitch = false

        // this.finishTTS()
        if (!outputMessage.content && !outputMessage.faq.list) {
          outputMessage.content = '...'

          outputMessage.formatContent = formatContent(
            this.networkStatus.connected
              ? this.$t('error.err')
              : this.$t('error.noInternetErr')
          )
          outputMessage.isSubModuleSuccess = false
          outputMessage.typing = false
        }
        if (!event.data && event.event === 'done') {
          if (outputMessage.content !== '...') {
            outputMessage.content = '...'
            outputMessage.formatContent = formatContent(
              this.networkStatus.connected
                ? this.$t('error.err')
                : this.$t('error.noInternetErr')
            )
          }
          outputMessage.isSubModuleSuccess = false
          outputMessage.typing = false
          // If the language is not match then use check language text
          if (outputMessage.change_lang_alarm) {
            const langDict = {
              change_lang:
                this.$store.state.language[this.$store.state.langCode],
              original_lang: this.$t('home.' + this.$i18n.locale)
            }
            outputMessage.formatContent = formatContent(
              this.$t('error.check_lang', langDict)
            )
          }
        }

        this.$store.commit('setHistory', this.askmeMessages)
        this.initErr()
      }
      if (event.event === 'detect_language') {
        outputMessage.detect_lang = event.detect_lang
        console.info(
          outputMessage.detect_lang,
          ' outputMessage.detect_lang',
          event.detect_lang,
          outputMessage.switchStatus
        )
        this.$store.commit('setLangCode', outputMessage.detect_lang)
        // this.code = outputMessage.detect_lang

        outputMessage.content = '...'
        outputMessage.formatContent = formatContent(
          this.$t('error.lang', {
            name: this.$t('home.' + this.$i18n.locale)
          })
        )
        console.info(outputMessage)
        outputMessage.isSubModuleSuccess = false

        if (
          this.isLongSentence(
            outputMessage.detect_lang,
            outputMessage.originMessage
          )
        ) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$store.commit('setLangShow', true)
            })
          }, 0)
        } else {
          outputMessage.change_lang_alarm = true
        }
      }
      if (event.event === 'translation') {
        outputMessage.typing = false
        if (event.message) {
          outputMessage.translateOriginMessage = event.message
        }
        if (outputMessage.switchStatus) {
          outputMessage.shortTranslateContent = event.data
        } else {
          outputMessage.translateContent = event.data
        }

        outputMessage.formatContent = formatContent(event.data)
        outputMessage.detect_lang = event.detect_lang
        // this.$store.commit('setLangShow',true)
      }
      if (event.event === 'finish') {
        outputMessage.waitForInspiration = false
        outputMessage.finished = true
        if (outputMessage.isSubModuleSuccess) {
          outputMessage.isSubModuleSuccess = event.is_submodule_success
        }

        // outputMessage.isSubModuleSuccess = false
        outputMessage.subModuleStates = event.submodule_states
        if (!init) {
          this.$store.commit('setSessionCount')
        }
      }
      if (event.event === 'faqs') {
        const faq = {
          list: event.searched_faq,
          chooseId: 'default',
          id: outputMessage.id,
          message: ''
        }
        outputMessage.extraInfo = event.intention
        outputMessage.typing = false
        outputMessage.switchStatus = false
        outputMessage.faq = faq
      }
      if (event.event === 'aots') {
        const aotFaq = {
          type: 'aot',
          aot: event.aot
        }
        if (event.aot) {
          if (event.aot.length === 0) {
            outputMessage.aotProcessInfo.principleFound = false
            outputMessage.aotProcessInfo.aotSearch = false
          }
        }
        outputMessage.aotFaq = aotFaq
      }
      if (event.event === 'time_details') {
        const showTimeData = {
          data: event.data
        }
        outputMessage.showTimeData = showTimeData
      }
      if (event.event === 'error') {
        outputMessage.typing = false
        outputMessage.content = '...'
        outputMessage.formatContent = formatContent(event.data)
        outputMessage.isSubModuleSuccess = false
        if (event.type === 'detect_language') {
          outputMessage.change_lang_alarm = true
        }
      }
    },
    updateAotFinishSwitch() {
      if (!this.lastAskmeMessage) {
        return
      }
      if (!this.lastAskmeMessage.aotProcessInfo) {
        return
      }
      this.lastAskmeMessage.aotProcessInfo.aotFound =
        this.lastAskmeMessage.aotProcessInfo.aotFound === true
      this.lastAskmeMessage.aotProcessInfo.principleFound =
        this.lastAskmeMessage.aotProcessInfo.principleFound === true
      this.lastAskmeMessage.aotProcessInfo.matchingAotShow = true
      this.lastAskmeMessage.aotProcessInfo.generatedAnswer =
        this.lastAskmeMessage.aotProcessInfo.generatedAnswer === true
    },
    async sendToTTS(delta) {
      this.$store.dispatch('sendTTS', delta)
    },
    // async finishTTS() {
    //   this.$store.dispatch('finishTTS')
    // },
    async upadteHistory(type) {
      let item = ''
      for (const i of this.askmeMessages) {
        if (!i.type) {
          item = i
          break
        }
      }
      if (item) {
        // Delete language error element
        const deleteIndex = this.askmeMessages.findIndex(
          (obj) => obj.change_lang_alarm === true
        )
        if (deleteIndex > -1 && deleteIndex !== this.askmeMessages.length - 1) {
          this.askmeMessages.splice(deleteIndex - 1, 2)
        }

        const data = {
          title: item.content,
          sessionId: this.sessionId
        }
        if (!type) {
          this.$store.commit('setHistory', this.askmeMessages)
        }
        await api.updateSession(data)
        this.$store.commit('setSessionCount')
      }
    },
    async checkChatRecord(event, type) {

    },
    async initMessages() {
      this.initAskmeMessages()
    },
    async handleLoadChat (val) {
      this.initGetMessage(val)
    },
    initGetMessage(val) {
      for (const i of val) {
        const outputMessage = {
          id: i.record_id,
          stopped: false,
          typing: false,
          finished: true,
          mine: false,
          principles: null,
          networkError: false,
          extraInfo: i.question_label,
          networkText: this.$t('home.typing'),
          networkLink: '',
          content: i.answer,
          rating: i.rating ? Number(i.rating.rating / 2) : 0,
          comments: i.comment ? i.comment.content : '',
          formatContent: formatContent(i.answer),
          aotFaq: {},
          showTimeData: {},
          showSwitch: false,
          toolHide: false,
          useInternet: '',
          thumbnails: [],
          faq: {},
          aotProcessInfo: {
            generatedAnswer: true
          },
          dest_lang: this.$store.state.profile.language || 'en',
          detect_lang: '',
          isSubModuleSuccess: true,
          change_lang_alarm: false,
          timestamp: i.updated_time
        }
        const mineMessage = {
          id: i.record_id,
          content: i.question,
          formatContent: formatContent(i.question),
          mine: true,
          stopped: false,
          typing: false,
          finished: false,
          timestamp: i.created_time,
          extraInfo: i.extra.m_question
        }

        const inputMessage = newMessage('', mineMessage)
        this.askmeMessages.unshift(outputMessage)
        this.askmeMessages.unshift(inputMessage)
        for (const j of i.event_list) {
          console.info(j.meta_data)
          if (j.event_type === 'principle_of_the_day') {
            const jsonObject = JSON.parse(j.meta_data)
            const potdMessage = {
              id: i.record_id,
              principles: [jsonObject.principles],
              mine: false,
              stopped: false,
              typing: false,
              finished: false,
              // type: 'potd',
              toolHide: true,
              content: '',
              timestamp: i.created_time,
              isSubModuleSuccess: true,
              formatContent: formatContent('')
            }
            this.askmeMessages.unshift(potdMessage)
          }

          this.handleMessageEvent({ data: j.meta_data }, 'DFO', true)
        }
        this.$store.commit('setHistory', this.askmeMessages)
      }
    },
    async initAskmeMessages() {
      // this.askmeMessages = []
      if (this.$store.state.chatLoading) {
        this.showTopic = false
      }
      if (this.$store.state.sessionId) {
        if (this.$store.state.historyOrigin) {
          if (
            this.$store.state.historyOrigin.record_list
          ) {
            if (this.$store.state.historyOrigin.record_list.length) {
              if (this.$store.state.historyOrigin.record_list.length > 0) {
                this.showTopic = false
                this.initGetMessage(this.$store.state.historyOrigin.record_list)
              }
            }
          }
        }
      }
      this.$forceUpdate()
    },
    async handleSend() {
      console.log('TODO: send', { message: this.message })
      if (!this.message) {
        this.$refs.input.focus()
        return
      }

      if (this.lastAskmeMessage) {
        if (this.$store.state.profile.extra.force_comment && this.lastAskmeMessage.isSubModuleSuccess !== false) {
          if (!this.lastAskmeMessage.rating) {
            this.$refs.chatDialogRating.open()
            return
          } else if (Number(this.lastAskmeMessage.rating) <= 3 && (!this.lastAskmeMessage.comments && !this.lastAskmeMessage.haveAudio)) {
            this.$refs.chatDialogRating.open()
            return
          }
        }
      }

      const message = this.message
      this.message = ''
      this.sendMessage(message)
    },
    async sendMessage(
      message,
      id,
      type,
      enMessage,
      potdId
    ) {
      // Clear current quick questions
      this.clearQuickQuestions()
      this.showTopic = false
      if (this.canTTS) {
        // stop last audio
        // if (this.$store.state.tts) {
        //   this.$store.state.tts.destroy()
        // }
        // this.$store.state.tts = new AutoAudio({
        //   wsUrl: process.env.VUE_APP_TTS_WS_URL,
        //   holdPing: true,
        //   manual: false
        // })
      }
      // if (choose) {
      //   this.sendToAskme(
      //     message,
      //     isShort,
      //     choose,
      //     messageId,
      //     faq,
      //     translation,
      //     fastMessage,
      //     forceLanguage,
      //     regenerate
      //   )
      // } else {
      this.sendToAskme(
        message,
        id,
        'DFO',
        type,
        enMessage,
        potdId
      )
      // }

      // Init analyse time
      this.$store.commit('setAISentTime', Date.now())
      this.$store.commit('setAIReceivedTime', null)
      this.$store.commit('setTTSReceivedTime', null)
    },
    async sendToAskme(
      message,
      id,
      type,
      msgType,
      enMessage,
      potdId
    ) {
      let inputMessage = ''
      this.messageId = uuidv4()
      // if (potdId) {
      //   const potdMessage = newMessage(type, this.get)
      //   this.askmeMessages.push(potdMessage)
      // }
      const mineMessage = {
        id: id || this.messageId,
        content: message,
        formatContent: formatContent(message),
        mine: true,
        stopped: false,
        typing: false,
        finished: false
      }

      // this.askmeMessages.push(inputMessage)
      inputMessage = newMessage(type, mineMessage)

      if (type === 'DFO') {
        if (id) {
          let canFindById = false
          let idIndex = 0
          for (const i in this.askmeMessages) {
            if (this.askmeMessages[i].mine && this.askmeMessages[i].id === id) {
              canFindById = true
              idIndex = i
            }
          }
          if (!canFindById) {
            this.askmeMessages.push(inputMessage)
          } else {
            this.askmeMessages.splice(idIndex, 1, inputMessage)
          }
        } else {
          this.askmeMessages.push(inputMessage)
        }
        this.askmeLoading = true
      }
      const gmtDateStr = new Date().toString().split(' (')[0]
      let payloadType = 'normal'
      if (msgType === 'faq') {
        payloadType = 'skip_faq'
      }
      if (msgType === 'regenerate') {
        payloadType = this.generateAskmeInput.type ? this.generateAskmeInput.type : 'normal'
        potdId = this.generateAskmeInput.principle_of_the_day ? this.generateAskmeInput.principle_of_the_day : ''
        enMessage = this.generateAskmeInput.message_in_en ? this.generateAskmeInput.message_in_en : ''
      }
      if (msgType === 'fastMessage') {
        payloadType = 'fast_reply'
      }
      if (msgType === 'forceLanguage') {
        payloadType = 'force_english'
      }
      // if (msgType === 'potd') {
      //   payloadType = 'principle_of_the_day'
      // }
      const payload = {
        message,
        cur_time: gmtDateStr,
        dest_lang: this.$store.state.profile.language || 'en',
        token: `Bearer ${this.$store.state.token}`,
        chat_id: this.sessionId,
        id: id || this.messageId,
        parent_id: id,
        type: payloadType,
        message_in_en: enMessage,
        principle_of_the_day: potdId
      }
      this.generateAskmeInput = payload
      // const payload = {
      //   message,
      //   short: isShort,
      //   search_online: this.userProfile.search_online,
      //   cur_time: gmtDateStr,
      //   enable_faq: enableFaq,
      //   dest_lang: this.$store.state.profile.language || 'en',
      //   token: `Bearer ${this.$store.state.token}`,
      //   translation: translation,
      //   fast_message: fastMessage,
      //   chat_id: this.sessionId,
      //   id: this.messageId,
      //   previous_id: id,
      //   regenerate: regenerate,
      //   force_language: forceLanguage
      // }

      const MessageItem = {
        id: id || this.messageId,
        network: true,
        mine: false,
        stopped: false,
        typing: true,
        finished: false,
        principles: null,
        networkError: false,
        networkText: this.$t('home.typing'),
        networkLink: '',
        aotFaq: {},
        showTimeData: {},
        showSwitch: false,
        toolHide: false,
        originMessage: message,
        useInternet: '',
        thumbnails: [],
        faq: {},
        dest_lang: this.$store.state.profile.language || 'en',
        detect_lang: '',
        isSubModuleSuccess: true,
        change_lang_alarm: false
      }
      const outputMessage = newMessage(type, MessageItem)
      if (type === 'DFO') {
        if (id) {
          let canFindById = false
          let idIndex = 0
          for (const i in this.askmeMessages) {
            if (
              !this.askmeMessages[i].mine &&
              !this.askmeMessages[i].type &&
                this.askmeMessages[i].id === id
            ) {
              canFindById = true
              idIndex = i
              console.info('======pip')
            }
          }
          if (!canFindById) {
            this.askmeMessages.push(outputMessage)
          } else {
            this.askmeMessages.splice(idIndex, 1, outputMessage)
          }
        } else {
          this.askmeMessages.push(outputMessage)
        }
        this.askmeLoading = false
      }

      if (
        this.askmeWebsocket &&
        this.askmeWebsocket.readyState === WebSocket.OPEN
      ) {
        this.askmeWebsocket.send(JSON.stringify(payload))
      } else {
        this.pendingMessage = JSON.stringify(payload)
        this.initWebsockets()
      }
      this.changeMessage = true
      this.$store.commit('setHistory', this.askmeMessages)
      this.$store.dispatch('delTTS')
      console.info(this.askmeMessages, 'msg')
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.msg.goBottom()
        }, 0)
      })
    },
    getChatHistory(id, isShort) {
      const res = []
      const arr = []
      for (const i of this.askmeMessages) {
        if (
          !i.mine &&
          !i.type &&
          i.label !== 'Unclear' &&
          !(i.stopped && i.typing) &&
          i.content !== '...'
        ) {
          arr.push(i)
        }
      }
      for (const i of arr) {
        if (i.originMessage && i.content && isShort) {
          res.push({
            role: 'user',
            content: i.translateOriginMessage || i.originMessage,
            translate_content: i.originMessage
          })
          res.push({
            role: 'assistant',
            content: i.content,
            translate_content: i.translateContent
          })
        }
        if (i.id === id) {
          break
        }
        if (i.originMessage && i.content && !isShort) {
          res.push({
            role: 'user',
            content: i.translateOriginMessage || i.originMessage,
            translate_content: i.originMessage
          })
          res.push({
            role: 'assistant',
            content: i.content,
            translate_content: i.translateContent
          })
        }
      }
      return res
    },
    handleCallRay() {
      this.showCallRay = true
      const outputMessage = newMessage('DFO', {
        content:
          moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') + ' Calling started',
        mine: false,
        stopped: false,
        typing: false,
        finished: false,
        type: 'call-on'
      })
      this.askmeMessages.push(outputMessage)
      if (this.$store.state.tts) {
        this.$store.state.tts.destroy()
      }
      this.$refs.callRayComponent.startCall()
    },
    handleHangUp(val) {
      this.showCallRay = false
      const outputMessage = newMessage('DFO', {
        content:
          moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') +
          ' Calling ended: ' +
          `<span style="font-weight: 500;">${val}</span>`,
        mine: false,
        stopped: false,
        typing: false,
        finished: false,
        type: 'call-off'
      })
      this.askmeMessages.push(outputMessage)
    },
    handleClear() {
      console.log('TODO: clear history')

      api.delSession(this.$store.state.sessionId).then((res) => {
        this.askmeMessages = []
        this.generateAskmeInput = null
        this.$store.commit('setHistory', [])
        this.$store.commit('setSessionId', '')
        this.$store.commit('setSessionCount')
        this.reload()
      })
    },
    stop_all_websocket(notStopTTS) {
      if (this.askmeWebsocket) {
        this.askmeWebsocket.close()
      }
      if (!notStopTTS) {
        this.$store.dispatch('delTTS')
      }
      // if (this.$store.state.tts) {
      //   this.$store.state.tts.destroy()
      //   this.$store.state.tts = ''
      // }
    },
    async handleStop(notStopTTS, isStop) {
      console.log('TODO: stop generating', notStopTTS, isStop)
      await this.stop_all_websocket(notStopTTS)
      if (this.lastAskmeMessage) {
        if (this.lastAskmeMessage.typing) {
          if (!this.lastAskmeMessage.error) {
            this.lastAskmeMessage.error = 'err'
          }
          if (this.lastAskmeMessage.aotProcessInfo) {
            this.lastAskmeMessage.aotProcessInfo.generatedAnswer = false
          }

          // this.lastAskmeMessage.typing = true
        }
        if (isStop) {
          this.lastAskmeMessage.error = 'stop'
        }
      }

      this.isGenerating = false
      this.updateAotFinishSwitch()
    },
    async handleChatRegenerate(id, forceLanguage) {
      if (this.isGenerating) {
        return
      }
      if (this.askmeMessages.length < 1) {
        return
      }
      if (!this.networkStatus.connected) {
        this.$message.warning({
          message: this.$t('error.noInternet'),
          customClass: 'message-error',
          duration: 2000
        })
        return
      }
      console.log('TODO: re-generating')
      this.isGenerating = true
      this.$nextTick(() => {
        let content = ''
        // let index = 0
        for (const i in this.askmeMessages) {
          console.info(this.askmeMessages[i].id, this.askmeMessages[i].content, this.askmeMessages[i].mine)
          if (this.askmeMessages[i].mine && id === this.askmeMessages[i].id) {
            content = this.askmeMessages[i].content
            // index = i
          }
        }
        // this.shortIndex = index
        // this.shortIndex = this.askmeMessages.length - 1
        let type = 'regenerate'
        if (forceLanguage) {
          type = 'forceLanguage'
        }
        this.sendMessage(
          content,
          id,
          type
        )
      })
    },
    handleRegenerate(forceLanguage) {
      if (this.isGenerating) {
        return
      }
      if (this.askmeMessages.length < 1) {
        return
      }
      if (!this.networkStatus.connected) {
        this.$message.warning({
          message: this.$t('error.noInternet'),
          customClass: 'message-error',
          duration: 2000
        })
        return
      }
      console.log('TODO: re-generating')
      this.isGenerating = true
      this.$nextTick(() => {
        let content = ''
        let id = ''
        let index = 0
        for (const i in this.askmeMessages) {
          if (!this.askmeMessages[i].type) {
            if (this.askmeMessages[i].mine) {
              content = this.askmeMessages[i].content
              index = i
            } else {
              id = this.askmeMessages[i].id
            }
          }
        }
        console.info(index)
        // this.shortIndex = index
        // this.shortIndex = this.askmeMessages.length - 1
        let type = 'regenerate'
        if (forceLanguage) {
          type = 'forceLanguage'
        }
        this.sendMessage(
          content,
          id,
          type
        )
      })
    },
    handleQuickQuestion(question) {
      if (this.lastAskmeMessage) {
        if (this.$store.state.profile.extra.force_comment && this.lastAskmeMessage.isSubModuleSuccess !== false) {
          if (!this.lastAskmeMessage.rating) {
            this.$refs.chatDialogRating.open()
            return
          } else if (Number(this.lastAskmeMessage.rating) <= 3 && (!this.lastAskmeMessage.comments && !this.lastAskmeMessage.haveAudio)) {
            this.$refs.chatDialogRating.open()
            return
          }
        }
      }
      this.message = ''
      this.clearQuickQuestions()

      this.sendMessage(
        question.question_translated,
        '',
        '',
        question.question_eng
      )
    },
    clearQuickQuestions() {
      this.$store.commit('setQuickQuestions', [])
    },
    handleTopic(item) {
      this.sendMessage(item.question)
    },
    handleShortAnwser(val, type) {

    },
    faqChange(val) {
      let answer = ''
      let question = ''
      for (const i in this.askmeMessages) {
        if (this.askmeMessages[i].id && this.askmeMessages[i].mine) {
          question = this.askmeMessages[i]
        }
        if (this.askmeMessages[i].id && !this.askmeMessages[i].mine) {
          answer = this.askmeMessages[i]
          // this.shortIndex = i
        }
      }
      if (!val.id) {
        this.$nextTick(() => {
          this.sendMessage(question.content, answer.id, 'faq')
        })

        return
      }
      const data = {
        sessionId: this.$store.state.sessionId,
        recordId: val.recordId,
        selected_faq_id: val.id
      }
      webchat.chooseFaq(data).then(res => {
        if (res.data) {
          answer.chooseId = val.id
          // answer.content = val.answer
          answer.typing = true
          answer.formatContent = formatContent(val.answer)
          this.$nextTick(() => {
            setTimeout(() => {
              answer.typing = false
              answer.content = val.answer
              if (this.$store.state.profile.audio_switch) {
                this.sendToTTS(answer.content)
              }
            }, 10)
          })
          answer.faq = {}
          for (const j of res.data.event_list) {
            this.handleMessageEvent({ data: j.meta_data }, 'DFO', true)
          }
        }
      })
      // console.info(val, 'zhixingl')
      // let question = ''
      // let answer = ''
      // for (const i in this.askmeMessages) {
      //   if (this.askmeMessages[i].id && this.askmeMessages[i].mine) {
      //     question = this.askmeMessages[i]
      //   }
      //   if (this.askmeMessages[i].id && !this.askmeMessages[i].mine) {
      //     answer = this.askmeMessages[i]
      //     this.shortIndex = i
      //   }
      // }
      // if (val.choose) {
      //   console.info('val', val.choose)
      //   answer.content = val.choose.answer
      //   // if (!this.$store.state.tts) {
      //   //   this.$store.state.tts = new AutoAudio({
      //   //     wsUrl: process.env.VUE_APP_TTS_WS_URL,
      //   //     holdPing: true,
      //   //     manual: false
      //   //   })
      //   // }
      //   // this.finishTTS()
      //   this.sendToTTS(answer.content)
      //   answer.translateContent = val.choose.translateAnswer
      //   answer.lang = ''
      //   if (answer.translateContent) {
      //     answer.formatContent = formatContent(val.choose.translateAnswer)
      //   } else {
      //     answer.formatContent = formatContent(val.choose.answer)
      //   }
      //   answer.isSubModuleSuccess = val.choose.isSubModuleSuccess
      //   answer.subModuleStates = val.choose.subModuleStates
      //   if (!answer.content) {
      //     answer.content = '...'
      //     answer.formatContent = formatContent(
      //       this.networkStatus.connected
      //         ? this.$t('error.err')
      //         : this.$t('error.noInternetErr')
      //     )
      //     answer.isSubModuleSuccess = false
      //     answer.typing = false
      //   }

      //   // // this.sendMessage(val.content, false, 'DFO', 'faq')

      //   const faq = JSON.parse(JSON.stringify(answer.faq))
      //   faq.chooseId = val.choose.id
      //   faq.choose = val.choose
      //   // for (const i in faq.list) {
      //   //   if (faq.list[i].id === val.choose.id) {
      //   //     faq.list[i].choose = val.choose.id
      //   //   }
      //   // }
      //   answer.faq = faq
      //   answer.aotProcessInfo = val.choose.aotProcessInfo
      //   answer.principles = val.choose.principles
      //   answer.evidences = val.choose.evidences
      //   answer.aotFaq = { type: 'aot', aot: val.choose.aots }
      // } else {
      //   // this.askmeMessages.splice(this.shortIndex - 1, 2)
      //   this.sendMessage(question.content, false, 'DFO', 'faq', answer.id)
      // }
    },
    handleTranslation(val) {
      let index = 0
      for (const i in this.askmeMessages) {
        if (this.askmeMessages[i].id === val.id) {
          index = i
        }
      }
      // this.shortIndex = index

      if (val.val === 'en') {
        this.askmeMessages[index].formatContent = formatContent(
          this.askmeMessages[index].content
        )
        this.askmeMessages[index].lang = 'en'
      }
      if (val.val === 'translate') {
        this.askmeMessages[index].formatContent = formatContent(
          this.askmeMessages[index].translateContent
        )

        this.askmeMessages[index].lang = 'translate'
      }
    },
    handlePrincipleDay(val) {
      if (val.id && val.msg) {
        const id = uuidv4()
        const potdMessage = {
          id: uuidv4(),
          principles: [val.data],
          mine: false,
          stopped: false,
          typing: false,
          finished: false,
          // type: 'potd',
          toolHide: true,
          content: '',
          isSubModuleSuccess: true,
          formatContent: formatContent('')
        }
        console.info(potdMessage, 'msg')
        const message = newMessage('DFO', potdMessage)
        this.askmeMessages.push(message)
        this.sendMessage(val.msg, id, 'potd', '', val.id)
      }
    },
    initErr() {
      console.info('执行了')
      for (const i of this.askmeMessages) {
        if (i.typing && !i.type && !i.mine) {
          // i.typing = false
          // i.stopped = true
          i.error = this.networkStatus.connected ? 'err' : 'noInternetErr'
          if (!i.content && !i.stopped) {
            i.content = '...'
            i.formatContent = formatContent(
              this.networkStatus.connected
                ? this.$t('error.err')
                : this.$t('error.noInternetErr')
            )
            i.isSubModuleSuccess = false
            i.typing = false
          } else if (i.content && !i.stopped) {
            i.content = '...'
            i.formatContent = formatContent(
              this.networkStatus.connected
                ? this.$t('error.err')
                : this.$t('error.noInternetErr')
            )
            i.isSubModuleSuccess = false
          }
          i.typing = false
          i.stopped = true
        }
      }
    },
    isLongSentence(selectedLocale, sentence) {
      if (selectedLocale === 'zh-CN') {
        return sentence.length >= 6
      } else {
        return (
          sentence.split(/\s+/).filter((word) => word.length > 0).length >= 4
        )
      }
    },
    handleForceResend() {
      this.handleRegenerate(true)
    },
    ratingChange(val) {
      for (const i in this.askmeMessages) {
        if (this.askmeMessages[i].id === val.id) {
          if (val.rating) {
            this.askmeMessages[i].rating = val.rating
          }
          if (val.comment) {
            this.askmeMessages[i].comments = val.comment
            this.$refs.chatDialogRating.getObj()
          }
          if (val.audio) {
            this.askmeMessages[i].haveAudio = true
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100%;
  max-height: max(100%, 900px);
  display: flex;
  flex-direction: column;
  position: relative;
}

::v-deep .chat-messages {
  // margin-top: 20px;
}

// ::v-deep .chat-input {
//   margin-top: 20px;
// }

::v-deep .chat-operations {
  margin-top: 10px;
}

.chat-comparison {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;

  .chat-messages:first-child {
    margin-right: 10px;
  }

  .chat-messages:last-child {
    margin-left: 10px;
  }
}

.footer-wrap {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 100%;
  padding-left: 12vw;
  padding-right: 12vw;
  padding-bottom: 16px;
  background-color: var(--s-bg);
  // transition: padding 0.35s;
  // padding-top: 8px;
  // margin-left: 0px;
  // margin-bottom: 0px;

  // overflow-y: hidden;
  // text-align: center;
  // overflow: hidden;
}

// @media screen and (max-width: 800px) {
//   .footer-wrap {
//     position: absolute;
//     bottom: -20px;
//     width: 100%;
//     left: 0;
//     width: calc(100%);
//     margin-left: 0px;
//     margin-bottom: 0px;
//     z-index: 100;
//     min-width: 640px;
//   }
// }
// .footer-cover {
//   position: absolute;
//   z-index: 1;
//   height: 20px;
//   width: 100%;
//   bottom: 0;
//   // bottom: 16.6rem;
//   // display: none;

//   // backdrop-filter:  blur(4px);
//   // backdrop-filter:opacity(0.6);
//   background: -webkit-linear-gradient(
//     top,
//     #707070 0%,
//     #70707056 66%,
//     #70707000 99%
//   ); /* Safari、Chrome */
//   background: linear-gradient(#70707000, var(--s-bg)); /* 默认渐变位置从上往下 */
// }

.footer {
  // padding: 11px 0;
  display: flex;
  align-items: flex-end;
  flex: 1;
  width: 100%;
  margin: 0px 0 0px 0;
  .input-box {
    flex: auto;
  }
  position: relative;
}

.tp {
  position: absolute;
  color: #fff;
  z-index: 10000;
}

.chat-wrap {
  position: fixed;
  width: 100%;
  height: 100vh;
  // margin-top: -20px;
  right: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.chat-wrap::-webkit-scrollbar {
  display: none;
}

.quick-questions::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.quick-questions::-webkit-scrollbar-thumb {
  width: 2px;
  background: var(--ss-text);
  border-radius: 35px;
}
// .quick-questions::-webkit-scrollbar {
//   display: none;
// }
.chart-content {
  position: relative;
  // margin-top: 40px;
  margin-bottom: 40px;
}

.quick-questions {
  display: flex;
  left: 0px;
  bottom: 0px;
  overflow-x: overlay;
  padding-bottom: 4px;
  width: 100%;
  // margin-left: 58px;
  // margin-bottom: 8px;
  // position: absolute;
}
.operation-header {
  width: max-content;
  // margin-left: 10px;
  // display: flex;
  // justify-content: flex-end;
  // right: 0px;
  // margin-bottom: 8px;
  // position: absolute;
  // width: 300px;
}
.disclaimer {
  color: var(--ss-text);
  // position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  bottom: 0;
  margin-top: 16px;
  font-size: 10px;
  line-height: 14px;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}
.operation-cover {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  margin-bottom: 8px;
}
.arrow-left {
  transform: rotate(90deg);
  color: var(--white);
  cursor: pointer;
}
.arrow-right {
  transform: rotate(270deg);
  color: var(--white);
  cursor: pointer;
}
.chat-wrap-width {
  width: calc(100% - 260px);
}
.phone-disclaimer {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--ss-text);
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: none;
}
.quick-questions::-webkit-scrollbar-thumb {
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.quick-questions:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

@media screen and (max-width: 900px) {
  .quick-questions::-webkit-scrollbar {
    display: none;
  }
  .quick-questions:hover::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
  .phone-disclaimer{
    display:block
  }

  .quick-questions {
    margin-left: -16px;
    margin-right: -24px;
    width: 100vw !important;
    padding: 0 16px;
    padding-top: 8px;
    padding-right: 24px;
  }
  .operation-cover {
    display: flex;
    padding-top: 8px;
    width: 100vw;
    margin-left: -16px;
    padding: 0 16px;
  }

  .footer-wrap {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--bg);
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
    // padding-top: 2px;
  }
  // .footer-cover {
  //   display: none;
  // }
  .disclaimer {
    display: none;
  }

  .chat-wrap {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.appHelps {
  z-index: 1000 !important;
  background: none !important;
  padding-top: 0px !important;
}
</style>
