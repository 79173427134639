var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "faq" } }, [
    _c("div", { staticClass: "mySwiper swiper" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.list, function (tab, index) {
          return _c(
            "div",
            {
              key: index,
              ref: "slide",
              refInFor: true,
              staticClass: "swiper-slide wrapper-item",
              style: { height: _vm.domH ? _vm.domH + "px" : "100%" },
            },
            [
              _c(
                "div",
                {
                  ref: "dom",
                  refInFor: true,
                  staticClass: "tab-content-wrapper chart-bubble",
                },
                [
                  index != _vm.list.length - 1
                    ? _c(
                        "div",
                        {
                          staticClass: "animate__animated",
                          class: [
                            _vm.isLeft === "left"
                              ? "animate__slideInRight"
                              : _vm.isLeft === "right"
                              ? "animate__slideInLeft"
                              : "animate__fadeIn",
                          ],
                        },
                        [
                          _c("span", { staticClass: "s-tip" }, [
                            _vm._v(_vm._s(_vm.$t("home.q"))),
                          ]),
                          _c("span", [_vm._v(_vm._s(tab.question))]),
                          _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "s-tip",
                                  staticStyle: { "margin-top": "8px" },
                                },
                                [_vm._v(_vm._s(_vm.$t("home.a")))]
                              ),
                              _c("vue-markdown", {
                                staticStyle: { display: "inline-block" },
                                attrs: { source: tab.answer },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "btn-box" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClick(tab)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("common.Select")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "animate__animated",
                          class: [
                            _vm.isLeft === "left"
                              ? "animate__slideInRight"
                              : _vm.isLeft === "right"
                              ? "animate__slideInLeft"
                              : "animate__fadeIn",
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "btn-box re-box" },
                            [
                              _c("svg-icon", {
                                staticClass: "chat-icon",
                                attrs: { "icon-class": "refresh" },
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.$t("home.re")))]),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClick(tab)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.regenerate")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "swiper-pagination pagination" }),
    ]),
    _vm.list.length > 1
      ? _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "el-button",
              { staticClass: "scroll-button scroll-left pre" },
              [
                _c("svg-icon", {
                  staticClass: "arrow-left",
                  attrs: { "icon-class": "down" },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "tip" }, [
              _vm._v(_vm._s(_vm.currentTab + "/" + _vm.list.length)),
            ]),
            _c(
              "el-button",
              { staticClass: "scroll-button scroll-right next" },
              [
                _c("svg-icon", {
                  staticClass: "arrow-right",
                  attrs: { "icon-class": "down" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }