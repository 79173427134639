<template>
  <div>
    <div class="header-icon-box" @click="toggleDropdown($event)">
      <el-badge is-dot class="item" :hidden='!$store.state.isShowPoint'>
        <svg-icon class="header-icon settings" icon-class="bell" />
      </el-badge>
    </div>
    <transition name="el-zoom-in-top">
      <Info v-if="visible"></Info>
    </transition>
  </div>
</template>

<script>
export default {
  components: {
    Info: () => import('./components/UserDialog/bell.vue')
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {

  },
  mounted() {
    this.$root.$on('dropdown-toggled', (dropdown) => {
      if (dropdown !== this) {
        this.visible = false
      }
    })
    document.addEventListener('click', this.closeDropdownsOutside)
    // addEventListener(
    //   'click',
    //   (e) => {
    //     e.preventDefault()
    //     if (
    //       this.$tours.myTour.currentStep < 9 ||
    //       this.$tours.myTour.currentStep > 12
    //     ) {
    //       this.visible = false
    //     }
    //   },
    //   false
    // )
  },
  destroyed() {
    document.removeEventListener('click', this.closeDropdownsOutside)
  },
  watch: {},
  methods: {
    toggleDropdown() {
      this.visible = !this.visible
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.dispatch('handleVersionRead')
        }, 0)
      })
      this.$root.$emit('closeDropdowns')
    },
    closeDropdownsOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.visible = false
        this.$root.$emit('closeDropdowns')
      }
    },
    handleCommand() {},
    setvisible(val) {
      this.visible = val
    }
  }
}
</script>

<style lang="scss" scoped>
.header-icon {
  color: var(--white);
  font-size: 30px;
  cursor: pointer;
  // margin-top: 28px;
}
.settings {
  margin-right: 0px;
  // margin-top: 3px;
}
.version-wrap {
  height: 425px;
  overflow: auto;
}
::v-deep .setting-dropdown {
  z-index: 10;
  background: var(--dropdown-bg);
  position: absolute;
  width: 240px;
  height: 500px;
  padding: 20px;
  border-radius: 8px;
  margin-top: 5px;
  margin-left: -200px;
  color: var(--text);
  box-shadow: 4px 4px 15px 0px #00000014;
  .setting-item-box {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 10px;
    align-items: center;
  }
  span {
    display: block;
    font-size: 15px;
    padding: 8px 0px;
    border-radius: 4px;
    line-height: 16px;
    width: 100%;
  }
  .setting-item-box {
    border-bottom: 0.5px solid #ffffff33;
    // margin-top:0px
  }
  .setting-item-box:last-child {
    border-bottom: none;
    // margin-top:0px
  }
  .user-line {
    height: 1px;
    width: 100%;
    background: var(--br);
  }
}
.settings-icon {
  font-size: 30px;
}
.header-icon-box {
  width: 40px;
  height: 40px;
  // margin-top: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 40px;
  margin-right: 22px;
}
.header-icon-box:hover {
  background: rgba(150, 122, 204, 0);
  border: 1px solid var(--white);
  cursor: pointer;
  // position: absolute;
}
.item{
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}
.version {
  font-size: 13px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.point {
  height: 5px;
  width: 5px;
  background: var(--text);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 8px;
}
.item-box {
  display: flex;
}
.list {
  // display: flex;
  // align-items: center;
  font-size: 13px !important;
  font-weight: 700;
  line-height: 18px !important;
  letter-spacing: 0em;
  text-align: left;
  span {
    display: inline-block;
    padding: 0 !important;
    line-height: 18px !important;
    font-size: 13px !important;
  }
}
.date {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #707070cc;
  margin-top: 0px;
}
.version-item {
  // padding-bottom: 12px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
}
.version-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 900px) {
  .header-icon-box{
    margin-right: 4px !important;
  }
}
::v-deep {
  .el-badge__content {
    background-color: var(--red);
    border: none;
    &.is-dot {
      width: 10px;
      height: 10px;
      right: 10px;
      top: 6px;
    }
  }
}
</style>
