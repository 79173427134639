var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-box" }, [
    _c(
      "div",
      { staticClass: "dialog-title-box" },
      [
        _c("svg-icon", {
          staticClass: "dialog-back",
          attrs: { "icon-class": "right" },
          on: { click: _vm.close },
        }),
        _c("span", [_vm._v(_vm._s(_vm.$t("home.editTheAnswer")))]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [_c("AppComments", { on: { change: _vm.handleChange } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }