var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "messages", staticClass: "chat-message-list" },
    [
      _vm._l(_vm.messages, function (msg) {
        return _c(
          "ChatMessageItem",
          _vm._b(
            {
              key: msg.id + "@" + msg.mine,
              attrs: {
                sender: _vm.sender,
                generating: _vm.generating,
                showFaq: _vm.showFaq,
              },
              on: {
                "update:typing": function ($event) {
                  return _vm.handleUpdateTyping(msg, $event)
                },
                "update:stopped": function ($event) {
                  return _vm.handleUpdateStopped(msg, $event)
                },
                "update:comments": function ($event) {
                  return _vm.handleUpdateComments(msg, $event)
                },
                "update:rating": function ($event) {
                  return _vm.handleUpdateRating(msg, $event)
                },
                "update:faq": function ($event) {
                  return _vm.handleUpdateFaq(msg, $event)
                },
                changePrinciple: function ($event) {
                  return _vm.handleChangePrinciple(msg, $event)
                },
                makeShorter: (val) => _vm.handleShortAnwser(val, msg.id),
                changeFaq: _vm.handleChangeFaq,
                regenerate: function ($event) {
                  return _vm.handleRegenerate(msg.id)
                },
                translation: (val) => _vm.handleTranslation(val, msg.id),
                forceResend: _vm.handleForceResend,
                callQuickQuestion: _vm.handleQuickQuestion,
              },
            },
            "ChatMessageItem",
            msg,
            false
          )
        )
      }),
      _vm.loading
        ? _c("ChatMessageItem", {
            attrs: { loading: true, mine: false, sender: _vm.sender },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }