var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "userDialog" }, [
      !_vm.active
        ? _c("div", { staticClass: "dialog-close-box" }, [
            _c(
              "div",
              { staticClass: "dialog-close-btn-box", on: { click: _vm.close } },
              [
                _c("svg-icon", {
                  staticClass: "dialog-close",
                  attrs: { "icon-class": "close" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.active
        ? _c(
            "div",
            {
              staticClass: "dialog-title-box",
              class: _vm.active === _vm.$t("user.profile") ? "no-border" : "",
            },
            [
              _c(
                "div",
                { staticClass: "back-box", on: { click: _vm.init } },
                [
                  _c("svg-icon", {
                    staticClass: "dialog-back",
                    attrs: { "icon-class": "right" },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v(_vm._s(_vm.active))]),
              _c(
                "div",
                { staticClass: "action-box", on: { click: _vm.edit } },
                [
                  (_vm.active === _vm.$t("user.account") ||
                    _vm.active === _vm.$t("user.profile")) &&
                  _vm.type === "edit"
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                    : _vm._e(),
                  (_vm.active === _vm.$t("user.account") ||
                    _vm.active === _vm.$t("user.profile")) &&
                  _vm.type === "save"
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("common.sSave")))])
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box",
          on: {
            touchstart: _vm.handleTouchStart,
            touchend: _vm.handleTouchEnd,
          },
        },
        [
          !_vm.active
            ? _c("Info", {
                on: {
                  logout: _vm.logout,
                  change: _vm.infoChange,
                  help: _vm.help,
                  faq: _vm.handleFaq,
                  contact: _vm.handleContact,
                },
              })
            : _vm._e(),
          _vm.active === _vm.$t("user.language")
            ? _c("Language", { on: { language: _vm.close } })
            : _vm._e(),
          _vm.active === _vm.$t("user.profile")
            ? _c("Profile", {
                ref: "profile",
                attrs: { type: _vm.type },
                on: { close: _vm.profileChange },
              })
            : _vm._e(),
          _vm.active === _vm.$t("user.account")
            ? _c("Account", {
                ref: "account",
                attrs: { type: _vm.type },
                on: { close: _vm.profileChange },
              })
            : _vm._e(),
          _vm.active === _vm.$t("home.faq") ? _c("FAQ") : _vm._e(),
          _vm.active === _vm.$t("common.notifications") ? _c("Bell") : _vm._e(),
          _vm.active === _vm.$t("user.contact")
            ? _c("div", [
                _c("span", {
                  staticClass: "contact",
                  domProps: { innerHTML: _vm._s(_vm.$t("user.contactTip")) },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }