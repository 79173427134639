<template>
  <div class="btn-tts">
    <!-- TODO: show/hide switch -->
    <!-- <el-switch
      v-model="canTTS"
      active-color="#13ce66"
      inactive-color="#ff4949"
      :active-value="true"
      :inactive-value="false"
      active-text=""
      inactive-text=""
    /> -->

    <div class="btn-tts-inner" @click="toggleTTS">
      <div class="tts-icon" id="v-step-6">
        <svg-icon class="icon" icon-class="sound-on" v-show="canTTS" />
        <svg-icon class="icon" icon-class="sound-off" v-show="!canTTS" />
        <!-- <svg-icon icon-class="sound-off-2" v-show="canTTS" /> -->
      </div>
      <!-- <span class="tts-label">
          With Voice Output {{ this.ttsStatus }}
        </span> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    canTTS: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    tips() {
      return this.canTTS ? 'TTS: ON' : 'TTS: OFF'
    },
    ttsStatus() {
      return this.canTTS ? '(ON)' : '(OFF)'
    }
  },
  methods: {
    toggleTTS() {
      this.canTTS = !this.canTTS
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-tts {
  //padding: 11px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;

  .btn-tts-inner {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .tts-icon {

    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #856db400;
    border: 1px solid var(--white);
    color: var(--white);
    line-height: 0;
    margin-right: 16px;
    margin-left: 0;
    bottom: 0;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 23px;
      margin-left: 5px;
    }
  }

  .tts-label {
    font-size: 16px !important;
    font-weight: bold;
    color: #374151;
    white-space: nowrap;
  }
}
</style>
