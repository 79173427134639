<template>
  <div class="boarding">
    <div class="top-box wrap">
      <img class="logo" src="../../assets/images/Digital Ray.png" alt="" />
    </div>
    <div class="content">
      <div class="wrap">
        <p class="tip">Select Topics of Interest</p>
        <p class="sub-tip">Select Topics of Interest</p>
        <div
          v-for="(item, index) in list"
          :key="item.text"
          class="item"
          @click="clickItem(item, index)"
          :class="[item.click ? 'able' : 'disable']"
        >
          <svg-icon :icon-class="item.icon" class="close-icon" />
          <span>{{ item.text }}</span>
          <!-- <div class="check-box" v-if="item.click">
            <svg-icon icon-class="enter" class="enter" />
          </div> -->
        </div>
        <div class="btn-box">
          <div>
            <el-button
              class="btn"
              @click="next"
              :loading="loading"
              :disabled="!canNext"
              :class="[canNext ? 'canNext' : '']"
              >NEXT</el-button
            >
          </div>

          <el-button
            type="text"
            class="skip"
            @click="close"
            :loading="skipLoading"
            >SKIP</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reloadApp'],
  data() {
    return {
      loading: false,
      skipLoading: false,
      list: [
        {
          icon: 'recommended-1',
          text: 'Problem Solving',
          val: 'Problem Solving',
          click: false
        },
        {
          icon: 'recommended-2',
          text: 'Effective Decision-Making',
          val: 'Decision-Making',
          click: false
        },
        {
          icon: 'recommended-3',
          text: 'Succeeding at Work',
          val: 'Succeeding at work',
          click: false
        },
        {
          icon: 'recommended-4',
          text: 'People Management',
          val: 'People Management',
          click: false
        },
        {
          icon: 'principles',
          text: 'Principle Fundamentals',
          val: 'Principles Fundamentals',
          click: false
        }
      ]
    }
  },
  computed: {
    canNext() {
      let res = false
      for (const i of this.list) {
        if (i.click) {
          res = true
        }
      }
      return res
    }
  },
  methods: {
    clickItem(item, index) {
      item.click = !item.click
      this.list.splice(index, 1, item)
      console.info(this.list)
    },
    close() {
      const data = []
      this.skipLoading = true
      this.$store
        .dispatch('updateProfile', { topics: data })
        .then((res) => {
          this.skipLoading = false
          this.$router.replace({ name: 'chatHome' }).then(() => {
            this.reloadApp()
          })
        })
        .catch(() => {
          this.skipLoading = false
        })
    },
    next() {
      const data = []
      for (const i of this.list) {
        if (i.click) {
          data.push(i.val)
        }
      }
      this.loading = true
      this.$store
        .dispatch('updateProfile', { topics: data })
        .then((res) => {
          this.loading = false
          this.$router.replace({ name: 'chatHome' }).then(() => {
            this.reloadApp()
          })
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  margin: auto;
}

.content {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - 40px);
}
.boarding {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--black);
  color: var(--white);
  padding-top: calc(env(safe-area-inset-top) + 20px);
  .wrap {
    width: calc(100% - 32px);
    max-width: 720px;
    position: relative;
  }
  .logo {
    width: 33vw;
    max-width: 240px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 4.5vh;
    margin-top: 10px;
  }
  .tip {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;

    text-align: center;
    color: var(--white);
    display: block;
    margin-bottom: 1vh;
  }
  .sub-tip {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--white);
    display: block;
    margin-bottom: 3vh;
  }
  .close-icon {
    margin-right: 8px;
    font-size: 20px;
    font-weight: 600;
  }
  .item {
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--blue);
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-top: 2vh;
    position: relative;
    cursor: pointer;
    color: var(--blue);
    padding-left: 20px;
    font-size: 16px;
    font-weight: 600;
  }
  .check-box {
    position: absolute;
    right: -0.5px;
    top: -0.5px;
    width: 20px;
    height: 20px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--red);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .enter {
    font-size: 14px;
  }
  .btn {
    width: 100%;
    height: 40px;

    border-radius: 8px;

    font-size: 15px;
    font-weight: 600;
    color: var(--white);
    background: var(--s-red);
    border: none;
    margin-top: 14vh;
    margin-top: 4vh;
    margin-bottom: 8px;
  }
  .able {
    color: var(--black) !important;
    border-color: var(--blue) !important;
    background: var(--blue) !important;
  }
  .canNext {
    background: var(--red);
    color: #fff;
    border: none;
  }
  .disable {
    // color: #707070;
  }
  .skip {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    color: var(--white);
    background: none;
    border: none;
    margin-top: 0vh;
  }
  .skip:disabled {
    background: none !important;
  }
}
.btn-box {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 10px);
  width: 100%;
  left: 0;
  padding: 0 16px;
  max-width: 720px;
  margin-left: 50%;
  transform: translateX(-50%);
}
::v-deep .el-button.is-loading:before {
  background-color: #70707000;
}
</style>
