<template>
  <div class="content-bg" id="pdf">
    <ChatMessages
      sender="DFO"
      tips="Scroll down and start chatting"
      title=""
      :messages="askmeMessages"
    ></ChatMessages>
  </div>
</template>

<script>
import newMessage from // offStorageChange, // clearMessages,
// onStorageChange,
// formatContent
  '@/utils/newMessage'
export default {
  components: {
    ChatMessages: () => import('../ChatMessages/index.vue')
  },
  data() {
    return {
      askmeMessages: []
    }
  },

  activated() {
    this.askmeMessages = []
    this.initAskmeMessages()
    this.$nextTick(() => {
      setTimeout(() => {
        this.pdf()
      }, 200)
    })
  },
  watch: {
    'this.$store.state.history': {
      handler(val) {
        this.askmeMessages = []
        this.initAskmeMessages()
      },
      deep: true
    }
  },
  mounted() {
    window.onbeforeprint = function () {
      console.log('确定打印')
    }
    window.onafterprint = () => {
      console.log('取消打印')
      this.closePage()
    }
  },
  methods: {
    closePage() {
      this.$router.replace({ name: 'chatHome' })
    },
    pdf() {
      window.print({
        mediaType: 'print',
        printBackground: true
      })
    },
    async initAskmeMessages() {
      // this.askmeMessages = []

      if (this.$store.state.history) {
        if (
          this.$store.state.history.length > 0 &&
            this.askmeMessages.length === 0
        ) {
          for (const i of this.$store.state.history) {
            const data = JSON.parse(JSON.stringify(i))
            if (data.switchStatus && data.shortContent) {
              data.content = data.shortContent
              if (data.shortFormatContent) {
                data.formatContent = JSON.parse(
                  JSON.stringify(data.shortFormatContent)
                )
              }
            }
            if (!data.switchStatus && data.longContent) {
              data.content = data.longContent
              if (data.longFormatContent) {
                data.formatContent = JSON.parse(
                  JSON.stringify(data.longFormatContent)
                )
              }
            }
            const outputMessage = newMessage('DFO', data)
            this.askmeMessages.push(outputMessage)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/

}
html,
body,
#app {

  background: none !important;
}
::v-deep .chat-message-list{
  height: fit-content;
}
.content-bg {
  overflow: auto;
  // background: rgb(255, 255, 255);
  // background: radial-gradient(25vw,red 0,yellow 100%);
  // background: radial-gradient(
  //   calc(50vw) calc(50vh),
  //   var(--h-text) 0,
  //   var(--s-bg) 100%
  // );
  //background: radial-gradient(ellipse 2vw,#282443, #0D0518);
}
::v-deep .chat-messages {
  min-height: 100%;
  max-height: 100%;
}
::v-deep .chart-bubble{
  // color: var(--black);
  border: 0.5px solid var(--black);
}
::v-deep .chat-messages-wra{
  margin-bottom: 0;
  // color: var(--black);
}
::v-deep .mine-chart-bubble{
  color: var(--black);
}
::v-deep #app{
  position: relative !important;
  height: fit-content !important;
}
::v-deep html, body, #app {
    position: relative !important;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: fit-content !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    font-size: 13px;
}
body{
  position: relative !important;
  height: fit-content !important;
  overflow: auto !important;
}
@media print {
 body {
    overflow: visible !important;
  }
  .page-break {
    display: none;
  }
}
</style>
