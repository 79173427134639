<template>
  <div class="about-wrap">
    <div class="about-box">
      <div class="content-box">
        <div class="title">About Ray Dalio</div>
        <div class="text">
          In 1975, Ray Dalio founded an investment firm, Bridgewater Associates,
          out of his two-bedroom apartment in New York City. Over forty years
          later, Bridgewater has grown into the fifth most important private
          company in the United States, according to Fortune magazine, and Dalio
          himself has been named to Time magazine’s list of the 100 most
          influential people in the world.
        </div>
        <div class="text">
          Along the way, Dalio discovered a set of unique principles that have
          led to Bridgewater’s exceptionally effective culture, which he
          describes as “an idea meritocracy that strives to achieve meaningful
          work and meaningful relationships through radical transparency.” It is
          these principles, and not anything special about Dalio—who grew up an
          ordinary kid in a middle-class Long Island neighborhood—that he
          believes are the reason behind his success.
        </div>
      </div>
      <div class="img-box">
        <img class="img" src="../../assets/images/about-1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    setSwiperRef() {},
    handleResize() {}
  }
}
</script>

<style lang="scss" scoped>
.about-box {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  padding: 120px 0;
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 24px;
  }
  .img {
    width: 432px;
    height: 388px;
  }
}
.news-box {
  margin-top: 80px;
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.about-wrap {
  width: 100%;
  position: relative;
}
.content-box {
  margin-right: 60px;
  margin-bottom: 24px;
}
.img-box {
  width: 432px;
  height: 388px;
  display: inline;
  margin: auto;
}
@media screen and (max-width: 1200px) {
  .about-box{
    flex-wrap: wrap;
    width: 100vw;
  }
}
@media screen and (max-width: 900px) {
  .img-box {
  width: 40vw;
  height: auto;
  display: inline;
  margin: auto;
}
  .img {
    margin: auto;
    margin-left: -15vw;
    width: 60vw !important;
    height: auto !important;

  }
}
</style>
