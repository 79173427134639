export default {
  data() {
    return {
      isForceUpdated: true
    }
  },
  methods: {
    async forceUpdate() {
      // this.isForceUpdated = false
      // await this.$nextTick()
      // this.isForceUpdated = true
    }
  }
}
