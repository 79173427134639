<template>
  <div class="my-principle" v-if="!$store.state.isMin900Width">
    <span>
          {{$t("intro.principlePre")}}
    </span>
    <svg-icon icon-class="add-p" class="close-icon" />
    <span>
      {{$t("intro.principleNext")}}
    </span>

  </div>
  <div class="my-principle" v-else>
    <span>
          {{$t("intro.principleAppPre")}}
    </span>
    <svg-icon icon-class="add-p" class="close-icon" />
    <span>
      {{$t("intro.principleAppNext")}}
    </span>
    <svg-icon icon-class="add-p" class="close-icon" />
    <span>
      {{$t("intro.principleAppLast")}}
    </span>

  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.my-principle {
  border-top: 0.5px solid var(--br);
  padding: 8px 0px;
  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--ss-text);
  }
}
.close-icon {
  color: var(--red);
}
</style>
