// import Vue from 'vue'
// import {
//   Button,
//   Input,
//   Switch,
//   Tooltip,
//   Checkbox
// } from 'element-ui'

// Vue.component(Button.name, Button)
// Vue.component(Input.name, Input)
// Vue.component(Switch.name, Switch)
// Vue.component(Tooltip.name, Tooltip)
// Vue.component(Checkbox.name, Checkbox)
