var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-info" }, [
    _c("div", { staticClass: "dialog-user-box" }, [
      _c(
        "div",
        { staticClass: "du-box" },
        [
          _c("ImageCached", {
            staticClass: "user-head",
            attrs: { src: _vm.userIcon },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$store.state.profile.name))]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-box",
          on: {
            click: function ($event) {
              _vm.handleClick(_vm.$t("user.account"))
            },
          },
        },
        [
          _c("svg-icon", {
            staticClass: "edit3",
            attrs: { "icon-class": "edit3" },
          }),
        ],
        1
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "item-box" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("common.account"))),
        ]),
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                _vm.handleClick(_vm.$t("user.profile"))
              },
            },
          },
          [
            _c(
              "span",
              [
                _c("svg-icon", {
                  staticClass: "profile left-icon",
                  attrs: { "icon-class": "profile" },
                }),
                _vm._v(_vm._s(_vm.$t("user.profile"))),
              ],
              1
            ),
            _c("svg-icon", {
              staticClass: "dialog-left",
              attrs: { "icon-class": "right2" },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                _vm.handleClick(_vm.$t("home.faq"))
              },
            },
          },
          [
            _c(
              "span",
              [
                _c("svg-icon", {
                  staticClass: "faq left-icon",
                  attrs: { "icon-class": "faq" },
                }),
                _vm._v(_vm._s(_vm.$t("home.faq"))),
              ],
              1
            ),
            _c("svg-icon", {
              staticClass: "dialog-left",
              attrs: { "icon-class": "right2" },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                _vm.handleClick(_vm.$t("user.contact"))
              },
            },
          },
          [
            _c(
              "span",
              [
                _c("svg-icon", {
                  staticClass: "contact left-icon",
                  attrs: { "icon-class": "contact" },
                }),
                _vm._v(_vm._s(_vm.$t("user.contact"))),
              ],
              1
            ),
            _c("svg-icon", {
              staticClass: "dialog-left",
              attrs: { "icon-class": "right2" },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "item-box" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("common.version"))),
        ]),
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                _vm.handleClick(_vm.$t("common.notifications"))
              },
            },
          },
          [
            _c(
              "span",
              [
                _c("svg-icon", {
                  staticClass: "new left-icon",
                  attrs: { "icon-class": "new" },
                }),
                _vm._v(_vm._s(_vm.$t("common.notifications"))),
                _vm.$store.state.isShowPoint
                  ? _c("span", { staticClass: "red-point" })
                  : _vm._e(),
              ],
              1
            ),
            _c("svg-icon", {
              staticClass: "dialog-left",
              attrs: { "icon-class": "right2" },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "item-box" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("common.interface"))),
        ]),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c(
              "span",
              [
                _c("svg-icon", {
                  staticClass: "light2 left-icon",
                  attrs: { "icon-class": "light2" },
                }),
                _vm._v(_vm._s(_vm.$t("header.lightMode"))),
              ],
              1
            ),
            _c("el-switch", {
              attrs: {
                "active-color": "var(--switch)",
                "inactive-color": "var(--switch-bg)",
                "active-text": "ON",
                "inactive-text": "OFF",
              },
              on: { change: _vm.changeTheme },
              model: {
                value: _vm.light_mode.value,
                callback: function ($$v) {
                  _vm.$set(_vm.light_mode, "value", $$v)
                },
                expression: "light_mode.value",
              },
            }),
          ],
          1
        ),
        _vm.userProfile.audio_switch !== null
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "span",
                  [
                    _c("svg-icon", {
                      staticClass: "speaking left-icon",
                      attrs: { "icon-class": "speaking" },
                    }),
                    _vm._v(_vm._s(_vm.$t("header.audio"))),
                  ],
                  1
                ),
                _c("el-switch", {
                  attrs: {
                    "active-color": "var(--switch)",
                    "inactive-color": "var(--switch-bg)",
                    "active-text": "ON",
                    "inactive-text": "OFF",
                  },
                  on: { change: _vm.changeAudio },
                  model: {
                    value: _vm.userProfile.audio_switch,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProfile, "audio_switch", $$v)
                    },
                    expression: "userProfile.audio_switch",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "item-box" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("common.interactions"))),
        ]),
        _vm.userProfile.finance_switch !== null
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("header.finance")))]),
                _c("el-switch", {
                  attrs: {
                    "active-color": "var(--switch)",
                    "inactive-color": "var(--switch-bg)",
                    "active-text": "ON",
                    "inactive-text": "OFF",
                  },
                  on: { change: _vm.changeFinance },
                  model: {
                    value: _vm.userProfile.finance_switch,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProfile, "finance_switch", $$v)
                    },
                    expression: "userProfile.finance_switch",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.userProfile.know_you_switch !== null
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "span",
                  [
                    _c("svg-icon", {
                      staticClass: "learn left-icon",
                      attrs: { "icon-class": "learn" },
                    }),
                    _vm._v(_vm._s(_vm.$t("header.allowKnowYou"))),
                  ],
                  1
                ),
                _c("el-switch", {
                  attrs: {
                    "active-color": "var(--switch)",
                    "inactive-color": "var(--switch-bg)",
                    "active-text": "ON",
                    "inactive-text": "OFF",
                  },
                  on: { change: _vm.changeKnowYou },
                  model: {
                    value: _vm.userProfile.know_you_switch,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProfile, "know_you_switch", $$v)
                    },
                    expression: "userProfile.know_you_switch",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.userProfile.guess_switch !== null
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "span",
                  [
                    _c("svg-icon", {
                      staticClass: "respond left-icon",
                      attrs: { "icon-class": "respond" },
                    }),
                    _vm._v(_vm._s(_vm.$t("header.guess"))),
                  ],
                  1
                ),
                _c("el-switch", {
                  attrs: {
                    "active-color": "var(--switch)",
                    "inactive-color": "var(--switch-bg)",
                    "active-text": "ON",
                    "inactive-text": "OFF",
                  },
                  on: { change: _vm.changeGuess },
                  model: {
                    value: _vm.userProfile.guess_switch,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProfile, "guess_switch", $$v)
                    },
                    expression: "userProfile.guess_switch",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      !_vm.$store.state.isIframe
        ? _c("div", { staticClass: "item-box status" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("common.status"))),
            ]),
            _c("div", { staticClass: "item", on: { click: _vm.logout } }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("header.logOut")))]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }