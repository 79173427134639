var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thumbnail" }, [
    _c(
      "div",
      { staticClass: "box" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "card",
            on: {
              click: function ($event) {
                return _vm.linkTo(item)
              },
            },
          },
          [
            _c("div", { staticClass: "card-title-box" }, [
              _c("div", [_c("span", [_vm._v(_vm._s(item.title))])]),
              _c(
                "div",
                [
                  _c("svg-icon", {
                    staticClass: "link-right",
                    attrs: { "icon-class": "share" },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "bottom" },
              [
                _c(
                  "el-image",
                  {
                    staticClass: "card-logo",
                    attrs: { src: item.ico, fit: "contain" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [
                        _c("div", { staticClass: "card-logo-box" }, [
                          _c("i", { staticClass: "el-icon-picture-outline" }),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("span", { staticClass: "card-url" }, [
                  _vm._v(_vm._s(item.text)),
                ]),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }