var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.showAppHelp
    ? _c("div", [
        _c("div", { staticClass: "top-cover" }),
        _c(
          "div",
          { staticClass: "app-help" },
          [
            _c(
              "el-button",
              { staticClass: "skip-btn", on: { click: this.endAppHelp } },
              [_vm._v(_vm._s(_vm.$t("help.skip")))]
            ),
            _c("img", {
              staticClass: "img-top-left",
              attrs: {
                src: require("../../../../assets/images/app-help-top-left.png"),
                alt: "",
              },
            }),
            _c("img", {
              staticClass: "img-top-right",
              attrs: {
                src: require("../../../../assets/images/app-help-top-right.png"),
                alt: "",
              },
            }),
            _c("img", {
              staticClass: "img-bottom-right",
              attrs: {
                src: require("../../../../assets/images/app-help-bottom-right.png"),
                alt: "",
              },
            }),
            _c("span", { staticClass: "step1" }, [
              _vm._v(_vm._s(_vm.$t("help.appStep1"))),
            ]),
            _c("span", { staticClass: "step2" }, [
              _vm._v(_vm._s(_vm.$t("help.appStep2"))),
            ]),
            _c("span", { staticClass: "step3" }, [
              _vm._v(_vm._s(_vm.$t("help.appStep3"))),
            ]),
            _c("span", { staticClass: "step5" }, [
              _vm._v(_vm._s(_vm.$t("help.appStep5"))),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "cover" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }