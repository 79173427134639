<template>
  <div class="tts-box" :class="[audioType ==='play'?'networking-bubble':'chart-bubble']">
    <span v-if="audioType === 'stop'">{{ $t("home.paused") }}</span>
    <span class="loading" v-if="audioType === 'play'">{{
      $t("home.speaking")
    }}</span>
    <el-button class="audio-btn" @click="open" v-if="audioType === 'stop'" :disabled="ttsLoad">
      <svg-icon class="icon" icon-class="tts-normal" v-if="!ttsLoad" />
      <i class="loading-icon el-icon-loading" v-if="ttsLoad" />
      {{ $t("common.Play") }}</el-button
    >
    <el-button class="audio-btn" @click="pause" v-if="audioType === 'play'" :disabled="closeLoad">
      <svg-icon class="icon" icon-class="tts-stop" v-if="!closeLoad" />
      <i class="loading-icon el-icon-loading" v-if="closeLoad" />
      {{ $t("common.stop") }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    typing: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isSubModuleSuccess: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy() {
    this.pause()
  },
  computed: {
    audioType() {
      let res = 'stop'
      if (this.$store.state.tts) {
        if (this.loadding) {
          res = 'play'
        }
        if (this.$store.state.tts.status === 'play') {
          res = 'play'
        } else {
          res = 'stop'
        }
      }
      this.$emit('audioType', res)
      return res
    }
  },
  data() {
    return {
      timer: null,
      ttsLoad: false,
      closeLoad: false,
      loadding: false,
      canTTS: false,
      nowConent: '',
      count: 0
    }
  },
  watch: {
    content: {
      handler() {
        if (this.$store.state.profile.audio_switch || this.canTTS) {
          this.play()
        }
      },
      deep: true
      // immediate: true
    },
    '$store.state.profile.audio_switch': {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    },
    isSubModuleSuccess: {
      handler() {
        if (this.isSubModuleSuccess === false) {
          this.pause()
        }
      },
      immediate: true,
      deep: true

    },
    audioType: {
      handler() {
        this.ttsLoad = false
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.$store.state.profile.audio_switch) {
        this.canTTS = true
      } else {
        this.canTTS = false
      }
    },
    async pause() {
      this.count = 0
      this.closeLoad = true
      setTimeout(() => {
        this.closeLoad = false
      }, 100)
      this.$store.dispatch('delTTS').then(res => {
        this.canTTS = false
      })
    },
    open() {
      this.canTTS = true
      this.ttsLoad = true
      this.nowConent = ''
      this.count = 0
      this.play()
    },
    findMaxPosition(str) {
    // 定义一个对象来存储标点符号及其位置
      const punctuationPositions = {
        '.': -1,
        '!': -1,
        '?': -1
      }

      // 遍历字符串中的每个字符
      for (let i = 0; i < str.length; i++) {
        const char = str.charAt(i)

        // 如果字符是逗号、句号、感叹号或问号，则更新其位置
        if (char in punctuationPositions) {
          punctuationPositions[char] = i
        }
      }

      // 获取最大的位置
      const maxPosition = Math.max.apply(null, Object.values(punctuationPositions))

      return maxPosition
    },
    async play() {
      if (this.timer) {
        this.count = 0
        return
      }
      if (this.isSubModuleSuccess === false) {
        this.count = 0
        this.canTTS = false
        this.$store.dispatch('delTTS')
        return
      }
      if (!this.canTTS) {
        this.count = 0
        return
      }
      let index = 0
      let data = ''
      // console.info('=======jinlail ')
      this.timer = setTimeout(async () => {
        if (!this.typing && !this.loading) {
          if (this.isSubModuleSuccess !== false && this.canTTS) {
            this.count++
            data = this.content.substring(this.nowConent.length)
            await this.$store.dispatch('sendTTS', data)
          }
          this.count = 0
          this.canTTS = true
          // this.$store.dispatch('delTTS')
        } else {
          index = this.findMaxPosition(this.content)
          console.info(index)
          if (index > this.nowConent.length) {
            data = this.content.substring(this.nowConent.length, index)
            this.nowConent = this.content.substring(0, index)
          } else {
            data = this.content.substring(this.nowConent.length)
            this.nowConent = this.content
          }
          // // this.nowConent = this.content.substring(this.nowConent.length)
          // console.info('=======jinlail ')
          if (this.isSubModuleSuccess !== false && this.canTTS) {
            this.count++
            console.info('tts:', data)
            await this.$store.dispatch('sendTTS', data)
          }
        }
        this.timer = null
        this.loadding = true
      }, 1000 + Number(this.count * 1000))
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-btn {
  color: var(--black) !important;
  background: var(--blue) !important;
  border-color: var(--blue) !important;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 100px;
}

.tts-box {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px !important;
}
.icon {
  color: var(--black);
  font-size: 16px;
}
::v-deep .el-button {
  padding: 0 12px;
}
@keyframes wait {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
.loading::after {
  animation: wait 2s linear infinite;
  content: "";
  position: absolute;
}
@media screen and (max-width: 900px) {
  .tts-box {
    width: 100%;
    justify-content: space-between;
  }
  .audio-btn{
    margin-left: 16px;
  }
}
</style>
