<template>
   <Dialog ref="contactDom" :title="$t('user.contact')" :full="true">
      <span v-html="$t('user.contactTip')"></span>
    </Dialog>
</template>

<script>
export default {
  components: {
    Dialog: () => import('@/components/Dialog/index.vue')
  },
  methods: {
    open() {
      this.$refs.contactDom.open()
    }
  }
}
</script>
