var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "faq-warp" }, [
    _c(
      "div",
      { staticClass: "title-box" },
      [
        _vm.$store.state.isMin900Width
          ? _c("svg-icon", {
              staticClass: "dialog-back",
              attrs: { "icon-class": "right" },
              on: { click: _vm.close },
            })
          : _vm._e(),
        _c("p", { staticClass: "faq-title" }, [
          _vm._v(_vm._s(_vm.$t("home.faq"))),
        ]),
        _c("div"),
        !_vm.$store.state.isMin900Width
          ? _c("svg-icon", {
              staticClass: "showtime-close",
              attrs: { "icon-class": "close" },
              on: { click: _vm.close },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [_c("Faq")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }