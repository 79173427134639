<template>
  <div class="principle-hints">
    <svg-icon icon-class="hints" class="hints-icon" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.principle-hints{
  position: relative;
  background: linear-gradient(#123656 0%, #2C70AB 100%) !important;
  color: #FFFFFF !important;
  font-size: 13px;
  border: 0.5px solid #69A7DC;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 4px 8px;
  line-height: 20px;
  padding-left: 28px;
  width: 100%;
  cursor: pointer;
}
.hints-icon{
    font-size: 24px;
    position: absolute;
    left: 2px;
    color: #B8D8F5;

}
@media screen and (max-width: 900px) {
  .principle-hints{
    background: linear-gradient(90deg, var(--hints-bg1) 0%, var(--hints-bg2) 100%) !important;
    color: var(--hints-text) !important;
    border: 0.5px solid var(--hints-br);
  }
  .hints-icon{
    color: var(--hints-icon);
  }
}
</style>
