<template>
  <div>
    <div class="guess-box">
      <span>
        {{ toggleMessage }}
      </span>
      <svg-icon icon-class="loading" class="loading-icon" v-if="isLoading" />
      <div v-else class="btn-box">
        <img src="../../../../assets//images/emoji.gif" class="emoji" alt="">
        <el-switch @change="changeSwitch" v-model="userProfile.guess_switch" active-color="var(--switch)"
          inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF">
        </el-switch>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  props: {
    toggleMessage: String
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    userProfile() {
      return this.$store.state.profile
    }
  },
  methods: {
    changeSwitch(val) {
      const data = {
        guess_switch: val
      }
      if (val) {
        this.isLoading = true
        this.$store.dispatch('updateProfile', data).then(res => {
          this.$emit('change')
        }).catch(() => {
          this.$store.state.profile.guess_switch = false
        })
      }
    },
    closeLoading() {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-box{
  display: flex;
  align-items: center;
}
.emoji{
  width: 24px;
  height: auto;
  margin-right: 4px;
}
::v-deep .el-switch.is-disabled {
  opacity: 1 !important;
}

.guess-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 8px;
  border-top: 0.5px solid var(--br);
  padding-bottom: 8px;
  overflow: hidden;

  span {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--ss-text);
    padding-right: 20px;
  }
}

.loading-icon {
  font-size: 24px !important;
  min-width: 24px;
  height: 24px;
}
</style>
