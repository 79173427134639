var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.$store.state.language, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "item",
          on: {
            click: function ($event) {
              return _vm.handleCommand(item)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(item))]),
          _vm.$i18n.locale === index
            ? _c("svg-icon", {
                staticClass: "enter",
                attrs: { "icon-class": "enter" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.changeName(item, _vm.items)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }