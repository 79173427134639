<template>
  <div >
    <Dialog ref="initDom" :title="info.title" :full="false">
      <div v-html="info.content" class="box"></div>
      <div class="rating">
      <el-rate
        v-model="rate"
        :colors="['var(--text)', 'var(--text)', 'var(--text)']"
        :void-color="'var(--text)'"
        :max="5"
        @change="handleChange"
      >
      </el-rate>
      <div class="rating-text">
        <div class="item-box">
          <div class="line"></div>
          <span>Very Bad</span>
        </div>
        <div class="item-box">
          <div class="line"></div>
          <span>Bad</span>
        </div>
        <div class="item-box">
          <div class="line"></div>
          <span>OK</span>
        </div>
        <div class="item-box">
          <div class="line"></div>
          <span>Good</span>
        </div>
        <div class="item-box">
          <div class="line"></div>
          <span>Very Good</span>
        </div>
      </div>
      </div>

      <el-input
        v-if="!$store.state.isMin900Width"
        :placeholder="$t('rating.placeholder')"
        :rows="4"
        type="textarea"
        resize="none"
        v-model="comment"
        ref="inputRef"
        @clear="handleInput"
      ></el-input>
      <div class="btn-box">
        <el-button
          class="btn"
          v-if="$store.state.isMin900Width"
          @click="openComments"
          >{{ $t("rating.leave") }}</el-button
        >
        <el-button
          :class="[info.disable ? 'dis-btn' : '']"
          class="enter-btn"
          :disabled="info.disable"
          @click="save"
          :loading="isLoading"
          >{{
            info.type === "lowRating"
              ? $t("rating.commentToContinue")
              : $t("rating.continue")
          }}</el-button
        >
      </div>
    </Dialog>
  </div>
</template>

<script>

// import { v4 as uuidv4 } from 'uuid'
import * as webchat from '@/api/webchat'
export default {
  components: {
    Dialog: () => import('@/components/Dialog/index.vue')
  },
  props: {
    msg: Object,
    default: () => {
      return {}
    }
  },
  data() {
    return {
      rate: '',
      comment: '',
      isLoading: false,
      info: {},
      ratingText: []
    }
  },
  computed: {},
  watch: {
    rate: {
      handler() {
        this.getObj()
      },
      deep: true
    },
    comment: {
      handler() {
        this.getObj()
      },
      deep: true
    },
    msg: {
      handler() {
        if (this.msg) {
          if (this.msg.rating) {
            this.rate = Number(this.msg.rating)
          }
        }
        this.getObj()
      },
      deep: true
    }
  },
  mounted() {
    this.getObj()
  },
  methods: {
    getObj() {
      this.info = {}
      const def = {
        title: this.$t('rating.defaultTitle'),
        content: `${this.$t(
          'rating.defaultPre'
        )}<span class="text-red"> ${this.$t('rating.defaultNext')}</span>`,
        disable: true,
        type: 'def'
      }
      // const rating = {
      //   title: this.$t('rating.ratingTitle'),
      //   content: `${this.$t('rating.rating')}`,
      //   disable: false
      // }
      const lowRating = {
        title: this.$t('rating.lowRatingTitle'),
        content: `<span class="text-red">${this.$t('rating.lowRating')}</span>`,
        disable: true,
        type: 'lowRating'
      }
      const finish = {
        title: this.$t('rating.finishTitle'),
        content: `${this.$t('rating.finish')}`,
        disable: false,
        type: 'finish'
      }
      this.info = def
      if (this.rate) {
        if (Number(this.rate) > 3) {
          this.info = finish
        }
        if (Number(this.rate) <= 3 && (this.comment || this.msg.comments)) {
          this.info = finish
        } else if (Number(this.rate) <= 3) {
          this.info = lowRating
        }
      }
      this.$forceUpdate()
      return this.info
    },
    openComments() {
      // this.close()
      this.$nextTick(() => {
        this.$store.commit('setAppComment', true)
        this.$store.commit('setCurrentChatId', this.msg.id)
      })
    },
    reopen() {
      this.comment = ''
      if (this.msg.comments) {
        this.comment = this.msg.comments
      }
      this.$refs.initDom.open()
    },
    open() {
      this.rate = 0
      this.comment = ''
      console.info(this.msg, 'msg')
      if (this.msg.comments) {
        this.comment = this.msg.comments
      }
      if (this.msg.comment) {
        this.comment = this.msg.comment
      }
      if (this.msg.rating) {
        this.rate = Number(this.msg.rating)
      }
      this.$refs.initDom.open()
    },
    close() {
      this.$refs.initDom.close()
    },
    async save() {
      this.isLoading = true
      let comment = ''
      if (this.comment || this.msg.comments) {
        comment = this.comment || this.msg.comments
      }
      const data = {
        sessionId: this.$store.state.sessionId,
        recordId: this.msg.id,
        content: comment,
        rating: Number(this.rate) * 2,
        type: 1
      }
      await webchat.rating(data)
      webchat
        .comment(data)
        .then((res) => {
          this.close()
          this.isLoading = false
          this.$emit('change', {
            rating: this.rate,
            comment: comment,
            id: this.msg.id
          })
          this.$message.success({
            message: this.$t('common.comment'),
            customClass: 'message-success',
            duration: 2000
          })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    handleChange() {}
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-rate__icon {
  font-size: 40px !important;
  margin: 0px 11px;
}
::v-deep .el-rate {
  height: fit-content;
}
::v-deep .c {
  height: fit-content;
}
.btn {
  background: var(--blue);
  color: var(--black);
  font-size: 15px;
  font-weight: 600;
  line-height: 0px;
  height: 40px;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  text-align: center;

  border: none;
  margin: 0;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-textarea__inner {
  background-color: rgba(255, 255, 255, 0);
  color: var(--text);
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  border: 1px solid var(--br);
  border-radius: 8px;
  min-height: 120px;
  margin-top: 20px;
}
::v-deep .el-textarea__inner:focus {
  border-color: var(--blue) !important;
}
.enter-btn {
  background: none !important;
  color: var(--text);
  font-size: 15px;
  font-weight: 600;
  line-height: 0px;
  height: 40px;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
  max-width: 280px;
  border: 1px solid var(--br);
}
.btn-box {
  display: flex;
  justify-content: flex-end;
}

.dis-btn {
  opacity: 0.6;
}
.box {
  margin-bottom: 35px;
}
.rating-text {
  display: flex;
  .item-box {
    width: 62px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: var(--ss-text);
  }
  .line{
    height: 16px;
    width: 1px;
    background: var(--br);
    margin: auto;
    margin-top: 4px;
    margin-bottom: 4px;

  }
}
@media screen and (max-width: 900px) {
  ::v-deep .el-rate {
    text-align: center;
    display: flex;
    justify-content: center;
}
  .rating-text{
    justify-content: center;
  }
  .btn-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .enter-btn {
    max-width: 100%;
    margin: 0;
    margin-top: 20px;
  }
  .enter-btn:hover {
    color: var(--text);
    background: none !important;
    border: 1px solid var(--br);
  }
}
@media screen and (max-width: 370px) {
.rating{
  zoom: 0.7;
}
}
@media screen and (max-width: 330px) {
.rating{
  zoom: 0.5;
}
}
</style>
