var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "scrollDiv", staticClass: "content" }, [
      _c("div", { staticClass: "box banner" }, [
        _c("div", { staticClass: "wrap" }, [_c("Banner")], 1),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "wrap" }, [_c("About")], 1),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "wrap" }, [_c("Product")], 1),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "wrap" }, [_c("MobileApp")], 1),
      ]),
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          { staticClass: "wrap" },
          [
            _c("Tools", {
              class: { animate__animated: true, animate__fadeInDown: true },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }