<template>
  <div class="dialog-content">
    <span class="title">{{ item.title }} </span>
    <div class="icon-box">
      <div @click="edit">
        <svg-icon class="title-icon" icon-class="edit" />
        <span class="tip">{{ $t("common.edit") }}</span>
      </div>

      <div @click="del">
        <svg-icon class="title-icon" icon-class="delete" />
        <span class="tip">{{ $t("common.delete") }}</span>
      </div>
      <div class="line"></div>
    </div>

    <div class="content-text">
      <span>{{ item.content }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    type: {
      type: String
    }
  },
  mounted() {},
  components: {},
  methods: {
    edit() {
      this.$emit('edit')
    },
    del() {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('home.delPrinciple'),
        message: h('p', null, [
          h(
            'span',
            { style: 'var(--s-text)' },
            this.$t('home.delPrincipleTip')
          )
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.$emit('del', this.item)
      })
    }

  }
}
</script>

  <style lang="scss" scoped>
.title {
  font-family: Century-Gothic;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  display: block;
  margin-bottom:  20px;
  padding: 0 20px;
  color: var(--text);
}
.title-icon {
  color: var(--ss-text);
  margin-right: 4px;
}
.tip {
  color: var(--ss-text);
  margin-right: 32px;
}
.line {
  margin-top: 48px;
  height: 0.5px;
  background: var(--br);
  width: 100vw;
  margin-left: -20px;
  position: absolute;
}
.content-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 36px;
  color: var(--text);
  padding: 0 20px;
}
.icon-box {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
  cursor: pointer;
}
::v-deep .dialog {
  padding: 20px 0px !important;
}
</style>
