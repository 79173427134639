<template>
  <el-dialog
    :class="full ? 'full' : 'nofull'"
    :visible.sync="dialogVisible"
    :before-close="close"
    :append-to-body="true"
  >
    <div class="title-box">
      <svg-icon
        class="dialog-back"
        icon-class="right"
        @click="close"
        v-if="$store.state.isMin900Width && full"
      />
      <span class="faq-title">{{ title }}</span>

      <div></div>
      <svg-icon
        class="showtime-close"
        icon-class="close"
        @click="close"
        v-if="!$store.state.isMin900Width || !full"
      />
    </div>
    <div class="content">
      <vue-markdown :source="content"></vue-markdown>
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  components: {
    VueMarkdown: () => import('vue-markdown')
  },
  props: {
    full: Boolean,
    title: String,
    content: String
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    close(done) {
      this.dialogVisible = false
    },
    open() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  background: var(--s-bg);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  max-height: 80vh;
  flex: 1;
  overflow: hidden;
}
.title-box {
  color: var(--text);
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  padding-right: 20px;
}
.faq-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

}
.full{
  ::v-deep .el-dialog .el-dialog__body {
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
    padding-top: 16px;
  }
}

::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  background: var(--s-bg);
}
.nofull {
  ::v-deep .el-dialog__body {
    max-height: 80vh;
    padding: 0px 20px !important;
  }
  .content {
    max-height: calc(80vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
  }
  .title-box{
    margin-top: 20px;
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.showtime-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: 16px;
  display: block;
}
.content {
  max-height: calc(80vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}

.dialog-back {
  transform: rotate(180deg);
  cursor: pointer;
}

.lint-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
}
.link-right {
  font-size: 10px;
  margin-left: 6px;
}
@media screen and (max-width: 900px) {
  .content::-webkit-scrollbar {
    display: none;
  }

  .full {
    ::v-deep .el-dialog__body {
      max-height: 100vh;
      border-radius: 0px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: calc(env(safe-area-inset-top) + 0px) !important;
      padding-bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
    }
    ::v-deep .el-dialog {
      width: 100%;
      height: 100%;
      margin-left: 32px;
      max-height: 100%;
      background: var(--s-bg);
      position: fixed;
      transform: translate(0%, 0%);
      bottom: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .title-box {
      height: 40px;
      border-bottom: 0.5px solid var(--br);
      font-size: 15px;
      width: 100vw;
      margin-left: -20px;
      padding-left: 20px !important;
      padding-right: 30px !important;
    }
    .dialog-back {
      display: block;
    }
    .faq-title {
      font-size: 15px;
      padding: 10px;
    }
    .showtime-close {
      display: none;
    }
    .content {
      max-height: calc(100% - 40px);
      overflow: auto;
      margin-top: 6px;
      width: calc(100vw - 40px);
    }

    .title {
      text-align: center;
      width: 100vw;
      padding-bottom: 16px;
      margin-left: -20px;
      border-bottom: 0.5px solid var(--br);
      margin-top: 0px;
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  .nofull {
    ::v-deep .el-dialog {
      width: calc(100vw - 20px);
      margin-left: 32px;
      max-height: 80vh;
      background: var(--s-bg);
    }
    .content {
      max-height: calc(80vh - 130px);
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 20px;
    }
  }
}
</style>
