var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !(_vm.$store.state.chatCanLoad && _vm.isIntroMessage)
    ? _c("div", [
        _vm.type && _vm.type != "first"
          ? _c("div", [
              _vm.type === "call-on"
                ? _c(
                    "div",
                    { staticClass: "call-time" },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "call-on" } }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.content) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "call-off"
                ? _c(
                    "div",
                    { staticClass: "call-time" },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "call-off" } }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.content) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _c(
              "div",
              {
                class: [
                  "chat-message-item",
                  {
                    "is-mine": _vm.mine,
                    "is-others": _vm.isOthers,
                    "is-fit-width": _vm.fit,
                    "is-full-width": !_vm.fit,
                  },
                ],
              },
              [
                _vm.sender == "DFO"
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isProd,
                            expression: "!isProd",
                          },
                        ],
                        staticClass: "chat-message-extra",
                      },
                      [_vm._v(" " + _vm._s(_vm.extraInfo) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "chat-message-body",
                    class: [_vm.mine ? "" : ""],
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "chat-message-head",
                        class: [
                          _vm.$store.state.profile.audio_switch !== null &&
                          _vm.audioType === "play" &&
                          _vm.isSubModuleSuccess !== false &&
                          // (!type|| type==='potd') &&
                          !_vm.mine &&
                          !_vm.change_lang_alarm &&
                          _vm.canRegenerate
                            ? "tts-head"
                            : "",
                        ],
                      },
                      [_c("ImageCached", { attrs: { src: _vm.icon } })],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "chat-message-content" },
                      [
                        _c("MessageMarkdown", {
                          attrs: {
                            toggleMessage: _vm.toggleMessage,
                            value: _vm.content,
                            principles: _vm.principles,
                            aotFaq: _vm.aotFaq,
                            useInternet: _vm.useInternet,
                            evidence: _vm.evidence,
                            stopped: _vm.isStopped,
                            toolHide: _vm.toolHide,
                            contentFinished: _vm.contentFinished,
                            typing: _vm.isTyping,
                            mine: _vm.mine,
                            network: _vm.network,
                            networkError: _vm.networkError,
                            networkText: _vm.networkText,
                            networkLink: _vm.networkLink,
                            loading: _vm.loading,
                            sender: _vm.sender,
                            showNetwork: _vm.showCommentsIcon,
                            id: _vm.id,
                            faq: _vm.faq,
                            faqId: _vm.faqId,
                            sourceType: _vm.sourceType,
                            formatContent: _vm.formatContent,
                            thumbnails: _vm.thumbnails,
                            aotProcessInfo: _vm.aotProcessInfo,
                            isIntroMessage: _vm.isIntroMessage,
                            isSubModuleSuccess: _vm.isSubModuleSuccess,
                            type: _vm.type,
                            polish: _vm.polish,
                            internetRemind: _vm.internetRemind,
                            evidences: _vm.evidences,
                            change_lang_alarm: _vm.change_lang_alarm,
                          },
                          on: {
                            done: _vm.handleTyped,
                            audioType: _vm.handleAudioType,
                            "update:faq": _vm.updateFaq,
                            forceResend: _vm.handleForceResend,
                            changeFaq: _vm.handleChangeFaq,
                            regenerate: _vm.handleRegenerate,
                            changePrinciple: _vm.handleChangePrinciple,
                          },
                        }),
                        _vm.$route.name !== "pdf" &&
                        !_vm.showFaq &&
                        _vm.toolHide !== true &&
                        _vm.finished &&
                        (this.$store.state.profile.language || "en")
                          ? _c("MessageTools", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showCommentsIcon,
                                  expression: "showCommentsIcon",
                                },
                              ],
                              attrs: {
                                toggleMessage: _vm.toggleMessage,
                                id: _vm.id,
                                aotFaq: _vm.aotFaq,
                                toolHide: _vm.toolHide,
                                showTimeData: _vm.showTimeData,
                                rating: _vm.rating,
                                showSwitch: _vm.showSwitch,
                                showTimeDetail: true,
                                switchStatus: _vm.switchStatus,
                                generating: _vm.generating,
                                content: _vm.content,
                                lang: _vm.lang,
                                polish: _vm.polish,
                                dest_lang: _vm.dest_lang,
                                detect_lang: _vm.detect_lang,
                                translateContent: _vm.translateContent,
                                isSubModuleSuccess: _vm.isSubModuleSuccess,
                              },
                              on: {
                                rating: _vm.handleRating,
                                comment: _vm.handleComment,
                                makeShorter: _vm.handleShortAnwser,
                                translation: _vm.handleTranslation,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                !_vm.mine
                  ? _c("MessageComments", {
                      attrs: {
                        isOthers: _vm.isOthers,
                        showComments: _vm.showComments,
                        comments: _vm.comments,
                      },
                      on: {
                        "update:showComments": function ($event) {
                          _vm.showComments = $event
                        },
                        "update:show-comments": function ($event) {
                          _vm.showComments = $event
                        },
                        "update:messageComments": _vm.updateMessageComments,
                      },
                    })
                  : _vm._e(),
                _vm.waitForInspiration &&
                _vm.isLast &&
                !_vm.typing &&
                _vm.sender === "DFO" &&
                !_vm.mine &&
                _vm.$store.state.quickQuestions.length === 0 &&
                _vm.isSubModuleSuccess !== false
                  ? _c(
                      "div",
                      {
                        staticClass: "reply-box",
                        staticStyle: {
                          "margin-bottom": "-2px",
                          "margin-top": "0px",
                          "font-size": "36px",
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "wait-icon",
                          attrs: {
                            "icon-class":
                              _vm.$store.state.profile.theme === "dark"
                                ? "wait-dark"
                                : "wait-light",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLast &&
                !_vm.typing &&
                _vm.sender === "DFO" &&
                !_vm.mine &&
                _vm.$store.state.quickQuestions.length > 0 &&
                _vm.isSubModuleSuccess !== false
                  ? _c(
                      "div",
                      { staticClass: "reply-box" },
                      [
                        _c("QuickQuestions", {
                          on: { callQuickQuestion: _vm.handleQuickQuestion },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isIntroMessage && _vm.type != "first" && !_vm.typing
                  ? _c("div", { staticClass: "chat-message-extra" }, [
                      _vm._v(" " + _vm._s(_vm.showTime) + " "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }