<template>
  <div>
    <div
      class="aot-process-header"
      @click.stop="
        aotProcessData.aotProcessCollapse = !aotProcessData.aotProcessCollapse
      "
    >
      <span v-if="aotProcessInfo.principleFound === true">{{
        $t("home.aotTip")
      }}</span>
      <span v-if="aotProcessInfo.principleFound === false">{{
        $t("home.thinkAnswer")
      }}</span>
      <span v-if="aotProcessInfo.principleFound === undefined"
        >{{ $t("home.principledThinking") }}:</span
      >
      <el-button
        class="extend-btn"
        @click.stop="
          aotProcessData.aotProcessCollapse = !aotProcessData.aotProcessCollapse
        "
      >
        <!-- <img src="../../../../assets/images/principle.png" alt=""> -->
        <svg-icon icon-class="principles" class="chevron-up principles" />
        <!-- <svg-icon
          v-if="!aotProcessData.aotProcessCollapse"
          icon-class="chevron-up"
          class="chevron-up"
        />
        <svg-icon
          v-if="aotProcessData.aotProcessCollapse"
          icon-class="chevron-down"
          class="chevron-down"
        /> -->
      </el-button>
    </div>
    <el-collapse-transition>
      <div
        class="apt-process-content"
        v-show="!aotProcessData.aotProcessCollapse"
      >
        <div class="process-line">
          <div class="check-icon">
            <svg-icon icon-class="check" class="check" />
          </div>
          <span>{{ $t("home.questionReceived") }}</span>
        </div>
        <div
          class="step-line"
          v-bind:class="{
            'link-line-border': aotProcessInfo.aotSearchShow === true,
          }"
        ></div>
        <div class="process-line">
          <div class="check-icon" v-if="aotProcessInfo.aotSearchShow === true">
            <svg-icon
              icon-class="check"
              class="check"
              v-if="aotProcessInfo.aotSearchShow === true"
            />
          </div>
          <div
            class="empty-icon"
            v-if="aotProcessInfo.aotSearchShow == false"
          ></div>
          <span>{{ $t("home.searchingAOT") }}</span>
          <svg-icon
            icon-class="qa"
            class="qa-icon"
            @click="handleClickQa"
          ></svg-icon>
        </div>
        <div
          class="step-line"
          v-bind:class="{
            'link-line-border': aotProcessInfo.aotFound != undefined,
          }"
        ></div>
        <div class="process-line">
          <div
            :class="aotProcessInfo.aotFound ? 'check-icon' : 'cross-icon'"
            v-if="aotProcessInfo.aotFound != undefined"
          >
            <svg-icon
              icon-class="check"
              class="check"
              v-if="aotProcessInfo.aotFound === true"
            />
            <svg-icon
              icon-class="cross"
              class="cross"
              v-if="aotProcessInfo.aotFound === false"
            />
          </div>
          <div
            class="empty-icon"
            v-if="aotProcessInfo.aotFound === undefined"
          ></div>
          <span
            @click.stop="
              aotProcessData.aotCollapse = !aotProcessData.aotCollapse
            "
            style="cursor: pointer"
            v-bind:class="{
              'gray-text': aotProcessInfo.aotFound === false,
              'aot-frame-blink':
                aotProcessInfo.aotFound === undefined &&
                aotProcessInfo.aotSearchShow === true,
            }"
            >{{ $t("home.relevantAOTFound") }}</span
          >
          <el-button
            @click.stop="
              aotProcessData.aotCollapse = !aotProcessData.aotCollapse
            "
            v-if="aotProcessInfo.aotFound === true"
            class="chevron-icon"
          >
            <svg-icon
              v-if="!aotProcessData.aotCollapse"
              icon-class="chevron-up"
              class="chevron-up"
            />
            <svg-icon
              v-if="aotProcessData.aotCollapse"
              icon-class="chevron-down"
              class="chevron-down"
            />
          </el-button>
        </div>
        <el-collapse-transition>
          <div
            id="aot-list"
            class="aot-list-div"
            v-show="!aotProcessData.aotCollapse"
          >
            <div class="aot-list-wrapper">
              <ul>
                <li
                  class="aot-list"
                  v-for="(aot, index) in aotFaq.aot"
                  :key="index"
                >
                  {{ aot }}
                </li>
              </ul>
            </div>
          </div>
        </el-collapse-transition>
        <el-collapse-transition>
          <div
            class="step-line"
            v-show="aotProcessData.aotCollapse"
            v-bind:class="{
              'link-line-border': aotProcessInfo.matchingAotShow === true,
            }"
          ></div>
        </el-collapse-transition>
        <div class="process-line">
          <div
            :class="aotProcessInfo.aotFound ? 'check-icon' : 'cross-icon'"
            v-if="
              aotProcessInfo.aotFound != undefined &&
              aotProcessInfo.matchingAotShow === true
            "
          >
            <svg-icon
              icon-class="check"
              class="check"
              v-if="aotProcessInfo.aotFound === true"
            />
            <svg-icon
              icon-class="cross"
              class="cross"
              v-if="aotProcessInfo.aotFound === false"
            />
          </div>
          <div
            class="empty-icon"
            v-if="aotProcessInfo.matchingAotShow === false"
          ></div>
          <span
            v-bind:class="{
              'gray-text':
                aotProcessInfo.aotFound != true ||
                aotProcessInfo.matchingAotShow === false,
            }"
            >{{ $t("home.matchingAOTWithPrinciples") }}</span
          >
        </div>
        <div
          class="step-line"
          v-bind:class="{
            'link-line-border': aotProcessInfo.principleFound != undefined,
          }"
        ></div>
        <div class="process-line">
          <div
            :class="aotProcessInfo.principleFound ? 'check-icon' : 'cross-icon'"
            v-if="aotProcessInfo.principleFound != undefined"
          >
            <svg-icon
              icon-class="check"
              class="check"
              v-if="aotProcessInfo.principleFound === true"
            />
            <svg-icon
              icon-class="cross"
              class="cross"
              v-if="aotProcessInfo.principleFound === false"
            />
          </div>
          <div
            class="empty-icon"
            v-if="aotProcessInfo.principleFound === undefined"
          ></div>
          <span
            v-bind:class="{
              'gray-text':
                aotProcessInfo.principleFound != true &&
                aotProcessInfo.aotFound != true,
              'aot-frame-blink':
                aotProcessInfo.principleFound === undefined &&
                aotProcessInfo.aotFound == true,
            }"
            >{{ $t("home.relevantPrinciplesFound") }}</span
          >
        </div>
        <div
          class="step-line"
          v-bind:class="{
            'link-line-border': aotProcessInfo.generatedAnswer != undefined,
          }"
        ></div>
        <div class="process-line">
          <div
            :class="
              aotProcessInfo.generatedAnswer ? 'check-icon' : 'cross-icon'
            "
            v-if="aotProcessInfo.generatedAnswer != undefined"
          >
            <svg-icon
              icon-class="check"
              class="check"
              v-if="aotProcessInfo.generatedAnswer === true"
            />
            <svg-icon
              icon-class="cross"
              class="cross"
              v-if="aotProcessInfo.generatedAnswer === false"
            />
          </div>
          <div
            class="empty-icon"
            v-if="aotProcessInfo.generatedAnswer === undefined"
          ></div>
          <span
            v-bind:class="{
              'gray-text':
                (aotProcessInfo.generatedAnswer === undefined &&
                  aotProcessInfo.principleFound === undefined) ||
                aotProcessInfo.generatedAnswer === false,
              'aot-frame-blink':
                aotProcessInfo.principleFound !== undefined &&
                aotProcessInfo.generatedAnswer === undefined,
            }"
            >{{ $t("home.generatedAnswer") }}</span
          >
        </div>
      </div>
    </el-collapse-transition>
    <Dialog
      ref="initDom"
      :content="$t('intro.aot')"
      :title="$t('intro.aotTitle')"
      :full="false"
    ></Dialog>
  </div>
</template>

<script>

export default {
  components: {
    Dialog: () => import('@/components/Dialog/index.vue')
  },
  props: {
    aotProcessInfo: {
      type: Object,
      default: () => {
        return {
          aotSearch: false,
          aotSearchShow: false,
          aotFound: undefined,
          matchingAotShow: false,
          principleFound: undefined,
          generatedAnswer: undefined
        }
      }
    },
    aotFaq: {
      type: Object,
      default: () => {
        return { type: null }
      }
    }
  },
  data() {
    return {
      aotProcessData: {
        aotCollapse: true,
        aotProcessCollapse: true
      }
    }
  },
  mounted() {
    this.aotProcessData.aotProcessCollapse =
      this.aotProcessInfo.generatedAnswer !== undefined
  },
  watch: {
    aotProcessInfo: {
      handler() {
        setTimeout(() => {
          this.aotProcessData.aotProcessCollapse =
            this.aotProcessInfo.generatedAnswer !== undefined
        }, 500)
      },
      deep: true
    }
  },
  methods: {
    handleClickQa() {
      this.$refs.initDom.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.aot-process-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  justify-content: space-between;
}
.process-line {
  display: flex;
  align-items: center;
}
.apt-process-content {
  margin-top: 10px;
}

.step-line {
  color: var(--h-text);
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 10px;
  height: 18px;
}
.aot-list-div {
  display: block;
  border-left: 2px solid var(--h-text);
  margin-left: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  ul {
    margin-block-start: 4px;
    margin-block-end: 4px;
    padding-inline-start: 16px;
  }
  li {
    line-height: 40px;
    margin-left: 22px;
    list-style: disc !important;
    color: var(--ss-text);
  }
}
.aot-list-wrapper {
  // padding-top: 10px;
}
.link-line-border {
  border-left: 2px solid var(--h-text);
}
.gray-text {
  color: gray;
}
.no-border {
  border: 1px solid var(--br) !important;
}
.extend-btn {
  background: var(--s-red);
  color: var(--white);
  font-weight: 700;
  border: none;
  width: 32px !important;
  min-width: 32px !important;
  height: 32px !important;
  border: none;
  padding: 0;
  // line-height: 0;
  border-radius: 4px;
  font-size: 13px;
  margin-left: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .chevron-up {
    font-size: 12px;
    vertical-align: 0 !important;
  }
  .chevron-down {
    font-size: 12px;
    vertical-align: 0 !important;
  }
  img {
    width: 23px;
    height: auto;
  }
}
.extend-btn:hover {
  // background: var(--red);
}
.chevron-icon {
  width: 0px;
  height: 22px;
  border-radius: 22px;
  background: none;
  border: none;
  color: var(--text);
  line-height: 0;
  margin-left: 0;
  margin-right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  .chevron-up,
  .chevron-down {
    font-size: 14px;
    vertical-align: 0 !important;
  }
}
.principles {
  font-size: 24px !important;
}
.empty-icon {
  width: 22px;
  height: 22px;
  border: none;
  color: #fff;
  line-height: 0;
  margin-left: 0;
  margin-right: 10px;
  bottom: 0;
  display: flex;
}
.check-icon {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background: var(--green);
  border: none;
  color: #fff;
  line-height: 0;
  margin-left: 0;
  margin-right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .check,
  .cross {
    font-size: 12px;
    margin-left: 0px;
  }
}
.cross-icon {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background: var(--red);
  border: none;
  color: #fff;
  line-height: 0;
  margin-left: 0;
  margin-right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .check,
  .cross {
    font-size: 12px;
    margin-left: 0px;
  }
}
.aot-frame-blink {
  animation: blink 2s infinite;
}
.svg-icon {
  margin-right: 0;
}
.qa-icon {
  margin-left: 4px;
  color: var(--blue);
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
@media screen and (max-width: 900px) {
  .aot-list-wrapper {
    //styleName: font-13;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.40799999237060547px;
    text-align: left;
    color: var(--s-text);
    line-height: 24px;
  }
  .aot-list-div li {
    line-height: 24px;
  }
  .aot-list-div {
    border-left: 1px solid var(--br) !important;
  }
  .step-line {
    height: 8px !important;
    color: var(--br) !important;
  }
  .link-line-border {
    border-left: 1px solid var(--br) !important;
  }
  .process-line {
    font-size: 13px;
  }
}
</style>
