<template>
  <div class="chat-message-tools">
    <Dialog
      ref="showTime"
      :full="false"
      :title="'Time Detail'"
    >
        <div class="showtime-content-wrapper" v-for="(timeItem, index) in showTimeData.data" :key="index" >
        <p class="showtime-content" v-for="(value, key) in timeItem" :key="key">
          {{ key + ": " + value }}
        </p>
        <br />
      </div>
    </Dialog>
    <el-dropdown
      v-if="
        ((this.dest_lang !== 'en' &&
          this.detect_lang == $store.state.profile.language) ||
          this.polish) &&
        false
      "
      trigger="click"
      placement="bottom-start"
      @command="handleCommand"
    >
      <div class="dot-box">
        <svg-icon class="tools-icon" icon-class="dot" />
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="polish" :disabled="generating">{{
          $t("home.polish")
        }}</el-dropdown-item>
        <el-dropdown-item command="en" :disabled="lang === 'en'">{{
          $t("home.viewEnglish")
        }}</el-dropdown-item>
        <el-dropdown-item command="translate" :disabled="lang !== 'en'">{{
          $t("home.viewTranslate", {
            name: $t("home." + this.dest_lang || $store.state.profile.language),
          })
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="switch-box" v-if="showSwitch && false">
      <Tab :active="active" @change="handleMakeShorter">
        <el-tab-pane
          :disabled="generating || active == 'rich'"
          :label="$t('home.original')"
          name="rich"
        ></el-tab-pane>
        <el-tab-pane
          :disabled="generating || active == 'concise'"
          :label="$t('home.concise')"
          name="concise"
        ></el-tab-pane>
      </Tab>

      <!-- <div>
        <span :class="makeShorter ? '' : 'highlight'">Rich</span>
        <el-switch
          v-model="makeShorter"
          active-color="#856DB4"
          inactive-color="var(--switch-bg)"
          @change="handleMakeShorter"
        >
        </el-switch>
        <span :class="makeShorter ? 'highlight' : ''">Concise</span>
      </div> -->

      <!-- <el-button class="btn" v-if="isLast" @click="handleMakeShorter">{{
        makeShorterButtonText
      }}</el-button> -->
    </div>
    <!-- <el-button
      class="btn"
      v-if="aotFaq.type === 'aot' && aotFaq.aot.length"
      @click="handleShowAot"
      >Show AOT</el-button
    >
    <el-button
      class="btn"
      v-if="aotFaq.type === 'faq' && (aotFaq.answer || aotFaq.question)"
      @click="handleShowAot"
      >Show FAQ</el-button
    > -->
    <div class="wrap-box">
      <div class="wrap-box-left">
        <el-button
          class="btn"
          v-if="!isProd && showTimeDetail"
          @click="handleShowTime"
          >Time Detail</el-button
        >
        <div
          v-if="this.dest_lang !== 'en' && this.translateContent"
          class="tools-box-lang"
        >
          <svg-icon
            v-if="this.lang !== 'en'"
            class="tools-icon"
            icon-class="cn-en"
            @click="changeAnsLang"
          />
          <svg-icon
            v-if="this.lang === 'en'"
            class="tools-icon"
            icon-class="en-cn"
            @click="changeAnsLang"
          />
        </div>

        <div class="rate-box" v-if="this.isSubModuleSuccess !== false">
          <el-rate
            v-model="rate"
            :colors="['var(--text)', 'var(--text)', 'var(--text)']"
            :void-color="'var(--text)'"
            :max="5"
            @change="handleChange"
          >
          </el-rate>
        </div>
        <div
          class="tools-box"
          @click="openComments"
          v-if="this.isSubModuleSuccess !== false"
        >
        <svg-icon class="tools-icon" icon-class="comment" />

          <!-- <svg-icon v-if="audioType==='normal'" @click="play" class="tools-icon" icon-class="tts-normal" />
          <i v-if="audioType==='loading'" style="font-size: 16px;" @click="pause" class="tools-icon el-icon-loading"/>
          <svg-icon v-if="audioType==='play'" @click="pause" class="tools-icon" icon-class="chat-stop" /> -->
        </div>
      </div>
      <div
        class="tools-box"
        v-if="this.isSubModuleSuccess !== false"
        style="margin-left: 2px;"
        @click="copy"
      >
      <svg-icon class="tools-icon" icon-class="copybtn"  />
      </div>
    </div>
  </div>
</template>

<script>

export default {

  components: {
    Dialog: () => import('@/components/Dialog/index.vue'),
    Tab: () => import('@/components/Tab/index.vue')
  },
  computed: {
    isProd() {
      return process.env.VUE_APP_ENV === 'prod'
    },
    isPhone() {
      return this.$store.state.isMin900Width
    },
    canPolish() {
      let res = false
      if (this.active === 'rich' && this.polish) {
        res = true
      }
      if (this.active === 'concise' && this.shortPolish) {
        res = true
      }
      return res
    }

  },
  props: {
    id: {
      type: String
    },
    dest_lang: {
      type: String,
      default: ''
    },
    detect_lang: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    translateContent: {
      type: String,
      default: ''
    },
    polish: {
      type: Boolean
    },
    shortPolish: {
      type: Boolean
    },
    lang: {
      type: String,
      default: ''
    },
    rating: {
      type: Number,
      default: 0
    },
    generating: {
      type: Boolean,
      default: false
    },
    showSwitch: {
      type: Boolean
    },
    isSubModuleSuccess: {
      type: Boolean
    },
    showTimeDetail: {
      type: Boolean
    },
    switchStatus: {
      type: Boolean,
      default: false
    },
    aotFaq: {
      type: Object,
      default: () => {
        return { type: null }
      }
    },
    showTimeData: {
      type: Object,
      default: () => {
        return { data: [] }
      }
    }
  },
  data() {
    return {
      rate: 0,
      makeShorter: false,
      active: 'rich',
      ttsController: '',
      timer: null,
      loadding: false
    }
  },
  watch: {
    rating: {
      handler() {
        this.initRate()
      }
    },
    // '$store.state.setAudioId': {
    //   handler(val) {
    //     if (this.$store.state.setAudioId !== this.id) {
    //       this.pause()
    //     }
    //   },
    //   deep: true
    // },
    switchStatus: {
      handler(value) {
        console.info('switchStatus', value)
        this.initTab()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initRate()
    this.initTab()
  },
  destroyed() {
    // this.pause()
  },
  methods: {

    copy() {
      let textToCopy = ''
      if (this.lang === 'en') {
        textToCopy = this.content.replace('##NEW_BUBBLE##', '')
      } else {
        textToCopy = this.translateContent ? this.translateContent.replace('##NEW_BUBBLE##', '') : this.content.replace('##NEW_BUBBLE##', '')
      }

      if (navigator.clipboard && window.isSecureContext) {
        // 使用 Clipboard API
        navigator.clipboard.writeText(textToCopy).then(() => {
          this.$message.success({
            message: this.$t('common.copy'),
            customClass: 'message-success',
            duration: 2000
          })
        }).catch(err => {
          console.error('Failed to copy: ', err)
        })
      } else {
        // 回退到 execCommand 方法
        const textArea = document.createElement('textarea')
        textArea.value = textToCopy
        textArea.style.position = 'fixed' // 避免滚动到底部
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
          const successful = document.execCommand('copy')
          if (successful) {
            this.$message.success({
              message: this.$t('common.copy'),
              customClass: 'message-success',
              duration: 2000
            })
          } else {
            console.error('Fallback: Copying text command was unsuccessful')
          }
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err)
        }

        document.body.removeChild(textArea)
      }
    },
    handleCommand(val) {
      console.info(val)
      this.$emit('translation', val)
    },
    initTab() {
      this.active = this.switchStatus ? 'concise' : 'rich'
    },
    initRate() {
      this.rate = Number(this.rating)
    },
    handleChange(val) {
      console.info(val, 'val')
      this.$emit('rating', val)
    },
    openComments() {
      if (this.isPhone) {
        this.$store.commit('setAppComment', true)
        this.$store.commit('setCurrentChatId', this.id)
      } else {
        this.$emit('comment', true)
      }
    },
    handleMakeShorter(val) {
      this.$emit('makeShorter', val)
    },
    handleShowAot() {
      this.$refs.aot.open()
    },
    handleShowTime() {
      this.$refs.showTime.open()
    },
    changeAnsLang() {
      if (this.lang !== 'en') {
        this.handleCommand('en')
      } else {
        this.handleCommand('translate')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-tools {
  display: flex;
  // height: 28px;
  align-items: center;
  flex-wrap: wrap;
  .tools-icon {
    cursor: pointer;
    font-size: 20px;
    color: var(--text);
    margin-left: 6px;
    // padding: 0 50px;
  }
  .thumb-wrap {
    display: flex;
    width: 52px;
    height: 28px;
    border-radius: 14px;
    align-items: center;
    background: #967acc33;
    justify-content: space-between;
    padding: 0 2px;
    margin-right: 8px;
  }
  .tools-box {
    display: flex;
    align-items: center;
    .tools-icon {
      // margin-left: 4px;
    }
  }
  .wrap-box {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }
  .wrap-box-left {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .tools-box-lang {
    width: 24px;
    height: 24px;
    line-height: 27px;
    margin-right: 10px;
  }
  // .tools-box:hover {
  //   width: 24px;
  //   height: 24px;
  //   color: #ffffff99;
  //   background: #967acc66;
  //   border-radius: 24px;
  // }
  .last-box {
    margin-left: 8px;
  }
  .btn {
    padding: 4px 10px;
    font-size: 13px;
    color: var(--text);
    font-weight: 400;
    line-height: 0;
    background: #ffffff00;
    border-radius: 28px;
    height: 28px;
    border: 1px solid var(--br);
    margin-right: 8px;
    margin-left: 0px;
  }
}
::v-deep .el-rate__icon {
  font-size: 20px;
  margin-right: 2px;
}
.switch-box {
  display: inline-block;
  // margin-right: 0px;
  transform: scale(0.86);
  margin-left: -12px;
  margin-top: 2px;
  // border: 1px solid #856db4;
  // padding: 4px 8px;
  border-radius: 20px;

  span {
    color: rgba(150, 122, 204, 0.6);
    display: inline-block;
    margin: 0px 8px;
  }
  .highlight {
    color: #ffffff;
  }
}
.dot-box {
  width: 28px;
  height: 28px;
  background: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
}
@media screen and (max-width: 900px) {
  .chat-message-tools {
  }
  .wrap-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: calc(100%) !important;
  }
}
</style>
