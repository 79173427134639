<template>
  <div class="landing-box" ref="scrollDiv">
    <div class="navs">
      <Menu></Menu>
    </div>
    <Transition
      name="custom-classes"
      enter-active-class="animate__animated animate__fadeIn  "
    >
      <router-view />
    </Transition>

  </div>
</template>

<script>

export default {
  components: {
    Menu: () => import('./menu.vue')
  },
  watch: {
    '$route.name': {
      handler() {
        const scrollElem = this.$refs.scrollDiv
        scrollElem.scrollTo({ top: 0 })
      },
      deep: true
    }
  },
  mounted() {
    const scrollElem = this.$refs.scrollDiv
    scrollElem.addEventListener('scroll', this.setHomeScroll, false)
  },
  methods: {
    setHomeScroll(e) {
      const scrollElem = this.$refs.scrollDiv
      if (scrollElem) {
        if (scrollElem.scrollTop > 10) {
          this.$store.commit('setHomeScroll', true)
        } else {
          this.$store.commit('setHomeScroll', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-box {
  width: 100vw;
  height: 100vh;
    overflow-x: hidden;
  overflow-y: auto;
  // overflow: hidden;
  background: var(--black);
}
.navs {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  // padding: 40px 120px;
}
// @media screen and (max-width: 1200px) {
//   .navs{
//     padding: 40px 20px;
//   }
// }
</style>
