var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "scrollDiv", staticClass: "landing-box" },
    [
      _c("div", { staticClass: "navs" }, [_c("Menu")], 1),
      _c(
        "Transition",
        {
          attrs: {
            name: "custom-classes",
            "enter-active-class": "animate__animated animate__fadeIn  ",
          },
        },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }