import request from '@/utils/request'

export const getUserInfo = () => {
  return request({
    url: '/rest/webchat/v2/users/me/info',
    method: 'get'
  })
}
export const getOauth2url = () => {
  return request({
    url: '/rest/webchat/v2/oauth/url',
    method: 'get'
  })
}
export const getAdminToken = (userToken) => {
  return request({
    url: `/rest/webchat/v2/oauth/admin/token?user_token=${encodeURIComponent(userToken)}`,
    method: 'get'
  })
}

export const updateUserInfo = async (data) => {
  return request({
    url: '/rest/webchat/v2/users/me/info',
    method: 'put',
    data: data
  })
}

export const updateUserSettings = async (data) => {
  return request({
    url: '/rest/webchat/v2/users/me/settings',
    method: 'put',
    data: data
  })
}
export const updateProfile = async (data) => {
  return request({
    url: '/rest/webchat/v2/users/me/profile',
    method: 'put',
    data: data
  })
}
export const getProfile = async (data) => {
  return request({
    url: '/rest/webchat/v2/users/me/profile',
    method: 'get',
    data: data
  })
}

export const setTopics = (data) => {
  return request({
    url: '/rest/webchat/v2/users/me/topics',
    method: 'put',
    data: data
  })
}
export const getTopics = (data) => {
  return request({
    url: `/rest/webchat/v2/recommend/questions?language=${data}`,
    method: 'get'
  })
}
