<template>
  <div>
    <AppDialog ref="initDom" :full="true" :title="getTitle"  :titleType="type==='info'?'flag':''">
      <Info v-if="type === 'info'" :item="item" @edit="handleEdit" @del="handleDel"></Info>
      <Add
        v-if="type === 'add' || type === 'edit'"
        :item="item"
        :type="type"
        @change="handleAdd"
      ></Add>
    </AppDialog>
  </div>
</template>

<script>
import AppDialog from '@/components/AppDialog/index.vue'
import Info from './info.vue'
import Add from './add.vue'
import * as webchat from '@/api/webchat'
export default {
  props: {
    type: {
      type: String
    },
    item: {
      type: Object
    }
  },
  computed: {
    getTitle() {
      return this.type === 'info'
        ? this.$t('home.myPrinciples')
        : this.type === 'edit'
          ? this.$t('home.modifyPrinciple')
          : this.$t('home.addPrinciple')
    }
  },
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        console.info('===========')
      },
      deep: true
    }
  },
  mounted() {},
  components: {
    AppDialog,
    Info,
    Add
  },
  methods: {
    edit() {},
    handleAdd(val) {
      const data = val
      if (!this.$store.state.isMin900Width) {
        this.close()

        this.$nextTick(() => {
          if (this.type === 'add') {
            data.action = 'add'
          } if (this.type === 'edit') {
            data.action = 'edit'
          }
          this.$emit('change', data)
        })
      } else {
        if (this.type === 'add') {
          data.action = 'add'
        } if (this.type === 'edit') {
          data.action = 'edit'
        }
        this.$emit('change', data)
      }
    },
    handleEdit(val) {
      this.$emit('edit')
    },
    handleDel(val) {
      const data = JSON.parse(JSON.stringify(val))
      data.action = 'del'

      webchat.delMP(val.id).then((res) => {
        this.$nextTick(() => {
          this.$emit('change', data)
          this.close()
        })
      })
    },
    del() {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('home.delPrinciple'),
        message: h('p', null, [
          h(
            'span',
            { style: 'var(--s-text)' },
            this.$t('home.delPrincipleTip')
          )
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.close()
      })
    },
    open() {
      console.info('=====11')
      if (this.$refs.initDom) {
        this.$refs.initDom.open()
      }
    },
    close() {
      if (this.$refs.initDom) {
        this.$refs.initDom.close()
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
.title {
  font-family: Century-Gothic;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  display: block;
  margin: 20px 0px;
  padding: 0 20px;
}
.title-icon {
  color: var(--ss-text);
  margin-right: 4px;
}
.tip {
  color: var(--ss-text);
  margin-right: 32px;
}
.line {
  margin-top: 48px;
  height: 0.5px;
  background: var(--br);
  width: 100vw;
  margin-left: -20px;
  position: absolute;
}
.content-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 36px;
  color: var(--text);
  padding: 0 20px;
}
.icon-box {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
}
::v-deep .dialog {
  padding: 20px 0px !important;
  .titel-box {
    padding: 0px 20px !important;
  }
}
</style>
