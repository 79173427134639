import * as profile from '@/api/profile'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AutoAudio from '@/utils/audio'
import { v4 as uuidv4 } from 'uuid'
// import axios from 'axios'
import { Browser } from '@capacitor/browser'
import router from '@/router'
Vue.use(Vuex)

function detectDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // 检测是否为手机
  if (
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  ) {
    return 'mobile'
  }

  // 检测是否为 iPad
  if (/iPad/.test(userAgent) && !window.MSStream) {
    return 'ipad'
  }

  // 其他设备默认为 PC
  return 'pc'
}

function filterMarkdown(text) {
  // 匹配 [link text](URL) 格式的markdown链接
  const markdownLinkRegex = /\[([^\]]+)\]\((.*?)\)/g

  // 匹配 <a href="URL">link text</a> 格式的HTML链接
  const htmlLinkRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*?>(.*?)<\/a>/gi

  // 过滤掉markdown链接
  text = text.replace(markdownLinkRegex, function(match, p1, p2) {
    return p1 // 只返回链接文本
  })

  // 过滤掉HTML链接
  text = text.replace(htmlLinkRegex, function(match, p1, p2, p3) {
    return p3 // 只返回链接文本
  })
  text = text.replace(/&/g, '&amp;')
  text = text.replace(/</g, '&lt;')
  text = text.replace(/>/g, '&gt;')

  return text
}

export default new Vuex.Store({
  state: {
    token: '',

    profile: {
      name: '',
      status: 1,
      language: 'en',
      theme: 'dark',
      search_online: true,
      extra: {
        force_comment: true,
        tts_speed: '1'
      },
      topics: null,
      audio_switch: null,
      know_you_switch: null,
      guess_switch: null
    },
    settings: {
      show_principle: true,
      tailor_answer_job: true,
      tailor_answer_personality: true,
      connect_internet: true,
      last_selected_language: 'default',
      is_topic_selected: true,
      is_entered_name: true,
      force_comment: true
    },
    isScrollToBottom: false,
    sendInput: '',
    helps: '',
    appHelps: '',
    chatCanLoad: true,
    isTourActive: false,
    analyseTime: {
      aiSentTime: null,
      aiReceivedTime: null,
      ttsSentTime: null,
      ttsReceivedTime: null
    },
    sessionId: '',
    sessionCount: 0,
    sessionOtherCount: 0,
    showSide: true,
    regenerate: false,
    disclaimerShow: true,
    isMin900Width: true,
    history: [],
    historyOrigin: '',
    quickQuestions: [],
    appComment: false,
    currentChatId: '',
    platform: detectDevice(),
    deviceId: null,
    versionDialog: false,
    appPlatform: '',
    networkStatus: null,
    theme: 'dark',
    faqHeight: '',
    isIframe: false,
    canTTS: false,
    audioId: '',
    tts: '',
    remind: false,
    chatLoading: false,
    langCode: 'en',
    langShow: false,
    homeScroll: false,
    canSwiper: true,
    isSwiperOpen: false,
    logout: '',
    language: {
      en: 'English',
      'zh-CN': '中文简体',
      es: 'Español',
      fr: 'Français',
      // ar: 'العربية',
      // 'ru-RU': 'Pусский',
      pt: 'Português',
      de: 'Deutsch'
      // ja: '日本語',
      // it: 'Italiano'
    },
    version: '0.9.18',
    isShowPoint: false
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'vueX',
      paths: ['profile', 'token', 'username']
    })
  ],
  getters: {
    // You can add getters here if needed
  },
  mutations: {

    setTheme(state, val) {
      state.theme = val
    },
    setUsername(state, val) {
      state.username = val
    },
    setToken(state, val) {
      state.token = val
    },
    setAudioId(state, val) {
      state.audioId = val
    },
    setFaqHeight(state, val) {
      state.faqHeight = val
    },
    setChatLoading(state, val) {
      state.chatLoading = val
    },
    setAppPlatform(state, val) {
      state.appPlatform = val
    },
    setChatCanLoad(state, val) {
      state.chatCanLoad = val
    },
    setQuickQuestions(state, val) {
      state.quickQuestions = val
    },
    setDisclaimerShow(state, val) {
      state.disclaimerShow = val
    },
    setAppComment(state, val) {
      state.appComment = val
    },
    setCurrentChatId(state, val) {
      state.currentChatId = val
    },
    setIsMin900Width(state, val) {
      state.isMin900Width = val
    },
    setIsIframe(state, val) {
      state.isIframe = val
    },
    setHomeScroll(state, val) {
      state.homeScroll = val
    },
    setLangCode(state, val) {
      state.langCode = val
    },
    setLangShow(state, val) {
      state.langShow = val
    },
    setUserProfile(state, profile) {
      state.profile = profile
    },
    setRegenerate(state, val) {
      state.regenerate = val
    },
    setCanTTS(state, val) {
      state.canTTS = val
    },
    setSessionId(state, val) {
      state.sessionId = val
    },
    setSessionCount(state) {
      state.sessionCount++
    },
    setSessionOtherCount(state) {
      state.sessionOtherCount++
    },
    setHistory(state, val) {
      if (val) {
        if (val.length === 0) {
          state.historyOrigin = ''
        }
      }
      state.history = val
    },
    setHistoryOrigin(state, val) {
      state.historyOrigin = val
    },
    setShowSide(state, val) {
      state.showSide = val
    },
    setScrollToBottom(state, val) {
      state.isScrollToBottom = val
    },
    setProfile(state, val) {
      state.profile = { ...state.profile, ...val }
    },
    setHelps(state, val) {
      state.helps = val
    },
    setCanSwiper(state, val) {
      state.canSwiper = val
    },
    setSwiperOpen(state, val) {
      state.isSwiperOpen = val
    },
    setAppHelps(state, val) {
      state.appHelps = val
    },
    setTourActive(state, val) {
      state.isTourActive = val
    },
    setShorted(state, val) {
      state.shorted = val
    },
    setSendInput(state, val) {
      state.sendInput = val
    },
    setAISentTime(state, val) {
      state.analyseTime.aiSentTime = val
    },
    setAIReceivedTime(state, val) {
      state.analyseTime.aiReceivedTime = val
    },
    setTTSSentTime(state, val) {
      state.analyseTime.ttsSentTime = val
    },
    setVersionDialog(state, val) {
      console.info('====2', val)
      state.versionDialog = val
    },
    setTTSReceivedTime(state, val) {
      state.analyseTime.ttsReceivedTime = val
    },
    setLogout(state, val) {
      state.logout = val
    },
    setShowPoint(state, val) {
      state.isShowPoint = val
    }
  },
  actions: {
    async fetchUserProfile({ commit }) {
      const userProfile = await profile.getUserInfo()
      if (userProfile.data !== undefined) {
        commit('setProfile', userProfile.data)
      }
    },
    async updateProfile({ commit }, data) {
      commit('setProfile', data)
      await profile.updateUserInfo(data)
    },
    initTTS({ state, commit }) {
      commit('setAudioId', uuidv4())
      if (!state.tts && state.profile.audio_switch !== null) {
        state.tts = new AutoAudio({
          wsUrl: process.env.VUE_APP_TTS_WS_URL,
          holdPing: true,
          manual: false,
          seq_id: state.audioId
        })
      }
    },
    async delTTS({ state, actions }) {
      if (state.tts) {
        await state.tts.close()
        state.tts = ''
      }
    },
    async sendTTS({ state, dispatch }, data) {
      const param = filterMarkdown(data.replace('##NEW_BUBBLE##', ''))
      if (param) {
        if (state.profile.audio_switch !== null) {
          if (state.tts) {
            state.tts.sendMessage(param)
          } else {
            await dispatch('initTTS')
            state.tts.sendMessage(param)
          }
        }
      }
    },
    finishTTS({ state }, data) {
      if (state.tts) {
        // await state.tts.sendMessage(
        //   JSON.stringify({ action: 'finish', seq_id: state.audioId })
        // )
        // actions.delTTS()
      }
    },
    startTTS({ state }, data) {
      if (state.tts) {
        // await state.tts.sendMessage(
        //   JSON.stringify({ action: 'finish', seq_id: state.audioId })
        // )
        // await state.tts.sendMessage(
        //   JSON.stringify({ action: 'start', seq_id: state.audioId, return_seq: true })
        // )
      }
    },
    playTTS({ state }, data) {
      if (state.tts) {
        state.tts.playPauseSwitch()
      }
    },
    async logout({ state, commit }) {
      window.localStorage.clear()
      commit('setHistory', [])
      commit('setSessionId', '')
      commit('setToken', '')
      commit('setLogout', 'https://principlesyou.com/sign_out')
      router.push({ name: 'login' })
      const url = `${process.env.VUE_APP_LOGOUT_URL}/sign_out?redirect_url=${process.env.VUE_APP_HOST}`
      console.info(url)
      if (process.env.VUE_APP_PLATFORM !== 'APP') {
        window.location.href = url
      } else {
        await Browser.open({ url: url })
        Browser.addListener('browserPageLoaded', async (info) => {
          await Browser.close()
          Browser.removeAllListeners()
        })
      }

      // axios.get(`https://principlesyou.com/sign_out?redirect_url=${process.env.VUE_APP_BASE_API}`)
    },
    handleVersionRead({ state, commit }) {
      localStorage.setItem('version', state.version)
      commit('setShowPoint', false)
    }
  },
  modules: {
    // Add your modules here if needed
  }
})
