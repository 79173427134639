// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/card-border.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product-box[data-v-ac0f7ba6] {\n  padding: 120px 0 60px;\n}\n.product-box .title[data-v-ac0f7ba6] {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 38px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-bottom: 36px;\n}\n.product-box .text[data-v-ac0f7ba6] {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-top: 24px;\n}\n.boder[data-v-ac0f7ba6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  width: 360px;\n  height: 280px;\n  border-radius: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.content-box[data-v-ac0f7ba6] {\n  display: grid;\n  justify-content: space-between;\n  grid-template-columns: repeat(auto-fill, 360px);\n  grid-gap: 24px;\n}\n.sub-title[data-v-ac0f7ba6] {\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  display: block;\n  margin-top: 24px;\n  margin-bottom: 8px;\n}\n.text[data-v-ac0f7ba6] {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n.item[data-v-ac0f7ba6] {\n  margin-right: 60px;\n  margin-bottom: 60px;\n  width: 360px;\n}\n@media screen and (max-width: 900px) {\n.content-box[data-v-ac0f7ba6] {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    zoom: 0.86;\n}\n.item[data-v-ac0f7ba6] {\n    margin-right: 0;\n    max-width: 360px;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
