var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["chat-tips", { "is-overflow": _vm.isOverflow }] },
    [
      _c("i", { staticClass: "tips-icon el-icon-chat-line-square" }),
      _vm.value
        ? _c("span", { staticClass: "tips-text" }, [_vm._v(_vm._s(_vm.value))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }