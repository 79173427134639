<template>
  <div >
    <!-- 导航区域 -->

    <!-- 内容区域 -->
    <div class="content" ref="scrollDiv">
      <div class="box banner">
        <div class="wrap">
          <Banner></Banner>
        </div>
      </div>
      <div class="box">
        <div class="wrap">
          <About

          ></About>
        </div>
      </div>
      <div class="box">
        <div class="wrap">
          <Product></Product>
        </div>
      </div>
      <div class="box">
        <div class="wrap">
          <MobileApp></MobileApp>
        </div>
      </div>
      <div class="box">
        <div class="wrap">
          <Tools
            :class="{ animate__animated: true, animate__fadeInDown: true }"
          ></Tools>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Banner: () => import('./banner.vue'),
    About: () => import('./about.vue'),
    Product: () => import('./product.vue'),
    MobileApp: () => import('./mobileApp.vue'),
    Tools: () => import('./tools.vue')
  },
  props: {},
  data() {
    return {
      active: 0,
      testShow: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.setHomeScroll, false)
  },
  destroy() {
    window.removeEventListener('scroll', this.setHomeScroll)
  },
  methods: {

    setHomeScroll(e) {
      const scrollElem = this.$refs.scrollDiv
      console.info('========', scrollElem, e.scrollTop)
      if (scrollElem) {
        if (scrollElem.scrollTop > 10) {
          this.$store.commit('setHomeScroll', true)
        } else {
          this.$store.commit('setHomeScroll', false)
        }
      }
    },
    onScroll() {
      const currentScroll = window.pageYOffset // 表示当前滚动的位置
      const viewHeight = window.innerHeight // 视口高度
      if (currentScroll > viewHeight) {
        this.testShow = true
      } else {
        this.testShow = false
      }

      const navContents = document.querySelectorAll('.content .box')

      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop)
      })
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop

      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      this.active = navIndex
    },

    scrollTo(index) {
      const targetOffsetTop = document.querySelector(
        `.content .box:nth-child(${index + 1})`
      ).offsetTop

      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop

      const STEP = 50

      if (scrollTop > targetOffsetTop) {
        smoothUp()
      } else {
        smoothDown()
      }

      function smoothDown() {
        if (scrollTop < targetOffsetTop) {
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop

          requestAnimationFrame(smoothDown)
        }
      }

      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100vh;
}
.content .box {
  position: relative;
  width: 100%;
  background-color: var(--black);
  color: var(--white);
}
.content .box:nth-child(2n) {
  background-color: #171717;
}

.box {
  padding: 0 120px;
}
.box:first-child {
  padding: 0px;
}
.wrap {
  max-width: 1200px;
  margin: auto;
}
.banner {
  background: url("../../assets/images/banner.png") no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 1200px) {
  .box{
    padding: 0 20px;
  }
}
</style>
