<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="close"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="dialog-close-box">
      <svg-icon class="dialog-close" icon-class="close" @click="close" />
    </div>

    <!-- <span class="dialog-title">{{ $t("user.profile") }}</span> -->
    <Account v-if="dialogVisible && type ==='Account'" ></Account>
    <Profile v-if="dialogVisible && type ==='Profile'" ></Profile>
  </el-dialog>
</template>
<script>
import Profile from './profile.vue'
import Account from './account.vue'
export default {
  components: {
    Account,
    Profile
  },
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {},
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        this.close()
        this.dialogVisible = false
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    open() {
      this.$store.commit('setShowSide', false)
      this.dialogVisible = true
    },
    openProfile() {
      this.dialogVisible = true
    },
    reset() {
      this.$nextTick(() => {
        // this.$refs.ruleForm.resetFields()
        this.exampleText = ''
      })
    },
    close() {
      if (!this.$store.state.isMin900Width) {
        this.$store.commit('setShowSide', true)
        this.dialogVisible = false
      } else {
        this.$store.commit('close', false)
      }
      // this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  padding: 80px 120px 40px 120px !important;
  border-radius: 0px !important;
  width: 100vw;
  height: 100%;
  position: fixed;
  min-height: 100%;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0 !important;
  background: var(--s-bg);
  color: var(--text);
  position: relative;
  .el-dialog__header {
    padding: 0 !important;
    /* padding: 20px 20px 10px; */
  }

  .el-dialog__body {
    max-width: 1200px;
    margin: auto;
    padding: 0 !important;
  }

  .el-dialog__headerbtn {
    position: absolute;
    top: 20px;
    right: 40px;
  }
}

.dialog-title {
  font-size: 32px;
  color: var(--text);
  display: flex;
  font-weight: 600;
  font-family: "Century-Gothic";
}

.dialog-cancel {
  padding: 8px 12px;
  background: #fff;
  color: var(--text);
  font-size: 15px;
  border-radius: 26px;
  border: 1px solid #16182380;
}

.dialog-confirm {
  background: var(--black);
  border: 1px solid var(--white);
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  border-radius: 26px;
  width: 285px;
  height: 48px;
  font-family: "Century-Gothic";
}
.dialog-confirm-no {
  background: #967acc66;
  color: #ffffff66;
  font-size: 20px;
  font-weight: 700;
  border-radius: 26px;
  width: 285px;
  height: 48px;
  border: none;
  cursor: not-allowed;
}

.dialog-footer {
  position: relative;
  display: flex;
  justify-content: center;
}

.dialog-text {
  display: block;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 60px;
  color: var(--text);
}

.dialog-line {
  height: 1px;
  width: 100%;
  background: #16182333;
  position: absolute;
  left: 0;
}

.red-text {
  display: inline-block;
  color: #e71611;
  margin-left: 5px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text);
  display: flex;
  align-items: center;
  margin-top: 24px;
  font-family: "Century-Gothic" !important;
}

.title-wrap {
  margin-top: 48px;
  margin-bottom: 16px;
  position: relative;
  width: max-content;
  .dropdown-box {
    margin-top: -9px;
  }
}

.tip {
  font-size: 15px;
  font-weight: 400;
  color: #16182380;
  display: block;
  margin-bottom: 40px;
}

.tip-title {
  font-weight: 700;
  font-size: 15px;
}

::v-deep .el-textarea__inner {
  border: none;
  border-bottom: 0.5px solid var(--br);
  border-radius: 0;
  padding: 0;
  background: #ffffff00;
  font-size: 15px;
  color: var(--text);
  padding-right: 20px;
}
::v-deep .el-textarea__inner:focus {
  border: none;
  border-bottom: 0.5px solid var(--br);
  border-radius: 0;
  padding: 0;
  background: #ffffff00;
  font-size: 15px;
  color: var(--text);
  padding-right: 20px;
}

::v-deep .el-textarea .el-textarea__count .el-textarea__count-inner {
  padding: 0 0px;
  background: #ffffff00;
}

::v-deep .el-textarea .el-input__count {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  bottom: -20px;
  background: #ffffff00;
}

::v-deep .el-form--label-top .el-form-item__label {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 26px;
  color: var(--text);
  font-family: "Century-Gothic" !important;
}

.example {
  width: 100%;
  margin-top: 10px;
  min-height: 58px;
  line-height: 18px;
  color: #4b4c5280;
}

.form {
  margin-bottom: 30px;
}

.down {
  color: #16182380;
  margin-left: 6px;
}
.dialog-close-box {
  width: 100%;
  text-align: right;
  margin-bottom: 30px;
  cursor: pointer;
}
.dialog-close {
  font-size: 18px;
  color: var(--text);
  font-weight: 600;
}
::v-deep .el-form-item__content {
  line-height: 14px !important;
}
::v-deep .el-textarea__inner::placeholder {
  color: #ffffff99;
  font-weight: 400px;
  font-size: 15px;
}

::v-deep .popper__arrow {
  display: none !important;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-form-item {
  margin-bottom: 60px;
}
.principles-tip {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 60px;
  flex-wrap: wrap;
  span {
    display: inline-block;
    color: var(--white);
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
  }
  span:first-child {
    color: #ffffff99;
    font-weight: 400;
    font-size: 15px;
    cursor: auto;
  }
  .link-right {
    font-size: 15px;
  }
}
.dropdown-box {
  // position: absolute;
  float: right;
  right: 0;
  margin-top: -60px;
  z-index: 100;
  cursor: pointer;
  .btn-wrap {
    padding: 8px 12px 8px 16px;
    border: 1px solid var(--br);
    height: 36px;
    border-radius: 18px;
    line-height: 36px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }
  .arrow-down {
    font-size: 15px;
    margin-left: 4px;
  }
}
.arrow-down-close {
  font-size: 13px;
  margin-left: 4px;
}
.input-close {
  font-size: 12px;
  color: #ffffff99;

  font-weight: 400;
}
.input-close-box {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 10;
  line-height: 14px !important;
  height: 14px;
}
::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1200px) {
  // ::v-deep .el-dialog {
  //   padding: 40px 20px 40px 20px !important;
  // }
}
@media screen and (max-width: 900px) {
    ::v-deep .el-dialog {
    padding: 40px 16px 40px 16px !important;
  }
  .dropdown-box {
    position: relative;
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
