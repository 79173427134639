var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "slot", on: { click: _vm.handleClick } },
        [_vm._t("default")],
        2
      ),
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _vm.visible
          ? _c("div", { staticClass: "dropdown" }, [
              _c(
                "div",
                { staticClass: "dropdown-wrap" },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "span",
                    {
                      key: item[_vm.label],
                      class: item.disable ? "dropdown-disable" : "",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommand(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item[_vm.label]))]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }