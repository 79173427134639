<template>
  <div v-if="list.length > 0">
    <span class="tip">{{ $t("home.tryAsking") + ":" }}</span>
    <div class="btn-wrap" v-if="isApp">
      <div
        class="item"
        v-for="item in list"
        :key="item.question"
        @touchstart="handleTouchStart($event,item)"
        @touchend="handleTouchEnd"
        @touchmove="handleTouchMove"
      >
        <span class="title">
          <svg-icon :icon-class="getIcon(item.topic)" class="close-icon" />{{
            item.display
          }}</span
        >
        <span class="text">{{ item.question }}</span>
      </div>
    </div>
    <div class="btn-wrap" v-else>
      <div
        class="item"
        v-for="item in list"
        :key="item.question"
        @click="handleClick(item)"
      >
        <span class="title">
          <svg-icon :icon-class="getIcon(item.topic)" class="close-icon" />{{
            item.display
          }}</span
        >
        <span class="text">{{ item.question }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from '@/api/profile'
export default {

  props: {},
  data() {
    return {
      list: [],
      nowItem: '',
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      scrollTop: 0
    }
  },
  watch: {},
  computed: {
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    }
  },
  created() {
    const data = this.$i18n.locale
    if (this.$store.state.history.length === 0) {
      profile.getTopics(data).then((res) => {
        this.list = res.data.questions
      })
    }
  },
  mounted() {
    // const data = this.$i18n.locale
    // if (this.$store.state.history.length === 0) {
    //   profile.getTopics(data).then((res) => {
    //     this.list = res.data.questions
    //   })
    // }
  },
  beforeDestroy() {},
  methods: {
    handleClick(item) {
      this.$emit('change', item)
    },
    handleTouchStart(event, item) {
      this.nowItem = item
      this.touchStartX = event.touches[0].clientX
      this.touchStartY = event.touches[0].clientY
      this.touchEndX = event.touches[0].clientX
      this.touchEndY = event.touches[0].clientY
    },
    handleTouchEnd(item) {
      const deltaX = this.touchEndX - this.touchStartX
      const deltaY = this.touchEndY - this.touchStartY
      console.info('====执行', deltaX, deltaY)
      if (Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10) {
        // 如果移动距离很小，认为是点击而非滑动

        if (this.nowItem) {
          this.handleClick(this.nowItem)
        }
      } else {
        this.nowItem = ''
      }
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX
      this.touchEndY = event.touches[0].clientY
    },
    getIcon(type) {
      let res = ''

      switch (type) {
        case 'Problem Solving':
          res = 'recommended-1'
          break
        case 'Decision-Making':
          res = 'recommended-2'
          break
        case 'Succeeding at work':
          res = 'recommended-3'
          break
        case 'People Management':
          res = 'recommended-4'
          break
        case 'Product FAQ':
          res = 'recommended-5'
          break
        case 'Principles Fundamentals':
          res = 'principles'
          break
      }
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  padding-top: 24px;
}
.dialog-confirm {
  padding: 4px 8px;
  background: none;
  color: var(--s-text);
  font-size: 13px;
  // font-weight: 400;
  border: 1px solid var(--br);
  border-radius: 28px;
  // margin-bottom: 8px;
  margin-left: 8px;
  // max-width: 100%;
  width: fit-content;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  height: 28px;
  // border:1px solid var(--white);
  // box-shadow: 0px 0px 10px 5px rgba(255,255,255,.1);
}
.item {
  border: none;
  border-radius: 8px;
  max-width: 80vw;
  width: 280px;
  padding: 8px;
  margin-right: 8px;
  background: var(--blue-bg);
}
.item:last-child {
  margin-right: calc(-8px - env(safe-area-inset-right));
}
.tip {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ss-text);
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100vw - 20px);
  margin-top: -2px;
  margin-left: 12px;
}
.title {
  display: block;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--blue-t-text);
}
.text {
  //styleName: Body/2-mobile;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--blue-text);
  // word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}
.close-icon {
  margin-right: 4px;
  font-size: 16px;
}
@media screen and (max-width: 900px) {
  .dialog-confirm {
    height: 24px;
  }
}
</style>
