<template>
  <div>
    <div class="setting-dropdown" :class="isPhone ? 'st-phone' : ''">
      <span class="title">{{ $t("common.notifications") }}</span>
      <div class="version-wrap">
        <div class="version-item" v-for="(item, index) in $t('version')" :key="item">
          <div class="line-box" v-if="!$store.state.isMin900Width">
            <div class="line"></div>
            <span class="date">{{ item.date }}</span>
            <div class="line"></div>
          </div>
          <div class="version-box">
            <div :class="index===0? 'new-point':'mobile-point'"></div>
            <span class="new" v-if="index === 0">({{ $t('common.new') }})</span>
            <span class="version">{{ $t('common.version') + ' ' + item.version }}</span>
            <div class="time-box"  v-if="$store.state.isMin900Width">
              <span class="date">{{ item.date }}</span>
            </div>
          </div>
          <div class="list" v-for="items in item.list" :key="items">
            <div class="item-box">
              <div class="point"></div>
              <span>{{ items }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="line"></div> -->
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {}
  },
  computed: {
    isPhone() {
      return this.$store.state.isMin900Width
    }
  },
  mounted() { },
  watch: {},
  methods: {
    handleCommand() { },
    setDropdownShow(val) { }
  }
}
</script>

<style lang="scss" scoped>
.header-icon {
  color: var(--white);
  font-size: 23px;
  cursor: pointer;
  // margin-top: 28px;
}

.settings {
  margin-right: 0px;
  margin-top: 7px;
}

.version-wrap {
  height: 425px;
  overflow: auto;
}

.setting-dropdown {
  z-index: 10;
  //   background: var(--black);
  //   position: absolute;
  //   width: 240px;
  //   height: 500px;
  //   padding: 20px;
  border-radius: 8px;
  margin-top: 5px;
  //   margin-left: -100px;
  color: var(--text);
  &.st-phone {
    padding: 0 16px;
  }

  //   box-shadow: 4px 4px 15px 0px #00000014;
  .setting-item-box {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 10px;
    align-items: center;
  }

  span {
    display: block;
    font-size: 15px;
    padding: 8px 0px;
    border-radius: 4px;
    line-height: 16px;
    width: 100%;
  }

  .setting-item-box {
    border-bottom: 0.5px solid #ffffff33;
    // margin-top:0px
  }

  .setting-item-box:last-child {
    border-bottom: none;
    // margin-top:0px
  }

  .user-line {
    height: 1px;
    width: 100%;
    background: #ffffff66;
  }
}

.settings-icon {
  font-size: 30px;
}

.header-icon-box {
  width: 40px;
  height: 40px;
  margin-top: 8px;
  border-radius: 50%;
  // display: flex;
  text-align: center;
  line-height: 40px;
  margin-right: 16px;
}

.header-icon-box:hover {
  background: rgba(150, 122, 204, 0);
  border: 1px solid var(--white);
  cursor: pointer;
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.version {
  font-size: 13px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.point {
  height: 4px;
  width: 4px;
  background: var(--text);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 8px;

}

.item-box {
  display: flex;
  margin-bottom: 6px;

  span {
    font-weight: 400 !important;
    line-height: 20px;
    font-size: 13px !important;
  }
}

.list {
  // display: flex;
  // align-items: center;
  font-size: 13px !important;
  font-weight: 700;
  line-height: 18px !important;
  letter-spacing: 0em;
  text-align: left;

  span {
    display: inline-block;
    padding: 0 !important;
    line-height: 20px !important;
    font-size: 13px !important;
  }
}

.date {
  font-size: 12px !important;
  display: block;
  width: fit-content !important;
  color: var(--br);
  // font-weight: 400;
  // line-height: 16px;
  // letter-spacing: 0em;
  // text-align: left;
  // color: var(--s-text);
  // margin-top: 0px;
}

.version-item {
  padding-bottom: 16px;
  // border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
}

.version-item:last-child {
  border-bottom: none;
}

.line-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  width: 36px;
  height: 0.5px;
  background: var(--br);
  margin: 0 10px;
}

.version {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 12px;
}

.new {
  font-size: 13px;
  font-weight: 700;
  margin-right: 4px;
  color: var(--red);
  width: 54px !important;
}

.version-box {
  display: flex;
}

@media screen and (max-width: 900px) {
  .new {
    width: 78px !important;
  }
  .time-box{
    width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
  }
  .header-icon-box {
    margin-right: 4px !important;
  }
  .setting-dropdown{
    margin-top: 24px;
  }
  .mobile-point{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--br);
    display: inline-block;
    position: absolute;
    // margin-top: 12px;
    left: -4px;
  }
  .new-point{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--red);
    display: inline-block;
    position: absolute;
    // margin-top: 12px;
    left: -7px;
  }

  .version-wrap {
    height: 100%;
    padding-left: 24px;
    position: relative;
    border-left: 0.5px solid var(--br);
    margin-left: 8px;
    overflow: visible;
  }
  .version-box{
    padding-top: 0px !important;
    span{
      padding-top: 0px !important;
    }
  }
  .title {
    display: none !important;
  }
}
.list{
  padding-left: 8px;
  span{
    line-height: 24px;
  }
}

::v-deep {
  .el-badge__content {
    background-color: var(--red);
    border: none;
  }
}
</style>
