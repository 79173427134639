import request from '@/utils/request'

export const login = (username, password) => {
  const form = new FormData()
  form.append('username', username)
  form.append('password', password)
  return request({
    url: '/rest/webchat/v1/token',
    method: 'post',
    data: form
  })
}

export const rating = (data) => {
  return request({
    url: `/rest/webchat/v2/chats/${data.sessionId}/records/${data.recordId}/rating`,
    method: 'put',
    data: data
  })
}
export const comment = (data) => {
  return request({
    url: `/rest/webchat/v2/chats/${data.sessionId}/records/${data.recordId}/comment`,
    method: 'put',
    data: data
  })
}

export const updateAudioComment = (data) => {
  return request({
    url: '/rest/webchat/v1/record/audio_comment',
    method: 'post',
    data: data
  })
}

export const getAudioComment = (id) => {
  return request({
    url: `/rest/webchat/v1/record/audio_comment?id=${id}`,
    method: 'get'
  })
}
export const getTextComment = (id) => {
  return request({
    url: `/rest/webchat/v1/record/text_comment?id=${id}`,
    method: 'get'
  })
}
export const delAudioComment = (id) => {
  return request({
    url: `/rest/webchat/v1/record/audio_comment?audio_id=${id}`,
    method: 'delete'
  })
}
// export const faqSource = (data) => {
//   return request({
//     url: '/rest/webchat/v1/evidence/source',
//     method: 'post',
//     data: data
//   })
// }
export const faqSource = (data) => {
  return request({
    url: `/rest/webchat/v2/chats/${data.sessionId}/records/${data.recordId}/evidence`,
    method: 'get',
    params: data
  })
}
export const faqList = (data) => {
  return request({
    url: '/rest/webchat/v2/about/faq',
    method: 'get',
    params: data
  })
}
export const chooseFaq = (data) => {
  return request({
    url: `/rest/webchat/v2/chats/${data.sessionId}/records/${data.recordId}/answer`,
    method: 'put',
    data: data
  })
}
export const getPotd = (data) => {
  return request({
    url: '/rest/webchat/v2/principle/potd',
    method: 'get',
    params: data
  })
}

export const getMP = (data) => {
  return request({
    url: '/rest/webchat/v2/principle/my',
    method: 'get',
    params: data
  })
}

export const getMPInfo = (id) => {
  return request({
    url: `/rest/webchat/v2/principle/my/${id}`,
    method: 'get'
  })
}
export const delMP = (id) => {
  return request({
    url: `/rest/webchat/v2/principle/my/${id}`,
    method: 'delete'
  })
}
export const addMP = (data) => {
  return request({
    headers: {
      'X-Content-Type': 'PRINCIPLE'
    },
    url: '/rest/webchat/v2/principle/my',
    method: 'post',
    data: data
  })
}
