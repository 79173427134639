import '@/styles/light.scss'
import '@/styles/dark.scss'
import '@/styles/app.scss'
import '@/styles/global.scss'
import '@/assets/fonts/style.css'
import '@/config'
import '@/polyfills'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import VueClipBoard from 'vue-clipboard2'
import { SplashScreen } from '@capacitor/splash-screen'
import * as Sentry from '@sentry/vue'
import { longPress } from './utils/longPress'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

import enLocale from './lang/en'
// import zhLocale from './lang/zh'
// import esLocale from './lang/es'
// import frLocale from './lang/fr'
// import ptLocale from './lang/pt'
// import deLocale from './lang/de'

Vue.directive('long', longPress)
require('vue-tour/dist/vue-tour.css')
const messages = {
  en: enLocale
  // 'zh-CN': zhLocale,
  // es: esLocale,
  // fr: frLocale,
  // pt: ptLocale,
  // de: deLocale
}
window.Vue = Vue // 要在vue-i18n实例化之前执行
const i18n = new VueI18n({
  locale: store.state.profile.language || 'en',
  messages
})
Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.use(VueClipBoard)

// document.addEventListener('click', (event) => {
//   console.info(event.target.tagName, event.target.closest('P'))
//   if (!(event.target.tagName === 'P' || event.target.tagName === 'SPAN')) {
//     window.getSelection().removeAllRanges()
//   }
// })
if (process.env.VUE_APP_PLATFORM === 'APP') {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = `
  * {
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
  }
  body {
    -webkit-touch-callout: none; /* iOS Safari */
  }
`
  document.head.appendChild(style)
}

window.getWsUrl = () => {
  const protocolStr = document.location.protocol
  let pre = ''
  if (protocolStr === 'http:') {
    pre = 'ws://'
  } else {
    pre = 'wss://'
  }
  let res
  if (document.domain === 'localhost') {
    if (process.env.VUE_APP_PLATFORM !== 'APP') {
      res = 'wss://dev.digitalray.ai' + process.env.VUE_APP_ASKME_WS
    } else {
      res = 'wss://coreapi.digitalray.ai' + process.env.VUE_APP_ASKME_WS
    }
  } else {
    res = pre + process.env.VUE_APP_ENV_DOMAIN + process.env.VUE_APP_ASKME_WS
  }

  return res
}

Sentry.init({
  Vue,
  dsn: 'https://9890f8c64ab0ab0a961e0679e9b925a0@sentry.digitalray.ai/1',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/digitalray\.ai\/rest/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    const loadingElement = document.getElementById('loading')
    if (loadingElement) {
      loadingElement.style.display = 'none'
    }
    if (window.VueTour) {
      Vue.use(window.VueTour)
    }
    this.$nextTick(() => {
      setTimeout(() => {
        SplashScreen.hide()
      }, 2000)
    })
  }
}).$mount('#app')
