var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-content" },
    [
      _c(
        "div",
        { class: _vm.ruleData.title ? "" : "error" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("common.title")) + " "),
            _c("span", { staticClass: "red" }, [_vm._v("*")]),
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 4 },
              resize: "none",
              placeholder: "",
              maxlength: "500",
            },
            on: { change: _vm.verifyTitle },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "title", $$v)
              },
              expression: "formData.title",
            },
          }),
          !_vm.ruleData.title
            ? _c("span", { staticClass: "error-tip" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "input-box",
          class: _vm.ruleData.content ? "" : "error",
        },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("common.description")) + " "),
            _c("span", { staticClass: "red" }, [_vm._v("*")]),
          ]),
          _c("el-input", {
            staticClass: "content-input",
            attrs: {
              type: "textarea",
              autosize: { minRows: 4 },
              resize: "none",
              placeholder: "",
              maxlength: "5000",
            },
            on: { change: _vm.verifyContent },
            model: {
              value: _vm.formData.content,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "content", $$v)
              },
              expression: "formData.content",
            },
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "btn",
          class: [_vm.canSave ? "btn" : "btn dis-btn"],
          attrs: { disabled: !_vm.canSave, loading: _vm.isLoading },
          on: { click: _vm.save },
        },
        [
          _vm._v(
            _vm._s(
              _vm.type === "add"
                ? _vm.$t("common.addPrinciples")
                : _vm.$t("common.savePrinciples")
            )
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }