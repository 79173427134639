<template>
  <div v-if="!($store.state.chatCanLoad && isIntroMessage)">
    <div v-if="type && type != 'first'">
      <div v-if="type === 'call-on'" class="call-time">
        <svg-icon icon-class="call-on" /> <span v-html="content"></span>
      </div>
      <div v-if="type === 'call-off'" class="call-time">
        <svg-icon icon-class="call-off" /> <span v-html="content"></span>
      </div>
    </div>
    <div
      v-else
      :class="[
        'chat-message-item',
        {
          'is-mine': mine,
          'is-others': isOthers,
          'is-fit-width': fit,
          'is-full-width': !fit,
        },
      ]"
    >
      <div class="chat-message-extra" v-show="!isProd" v-if="sender == 'DFO'">
        {{ extraInfo }}
      </div>
      <div class="chat-message-body" :class="[mine ? '' : '']">
        <div
          class="chat-message-head"
          :class="[
            $store.state.profile.audio_switch!==null&&
            audioType === 'play' &&
            isSubModuleSuccess !== false &&
            // (!type|| type==='potd') &&
            !mine &&
            !change_lang_alarm &&
            canRegenerate
              ? 'tts-head'
              : '',
          ]"
        >
          <ImageCached :src="icon" />
        </div>
        <div class="chat-message-content">
          <MessageMarkdown
            :toggleMessage="toggleMessage"
            :value="content"
            :principles="principles"
            :aotFaq="aotFaq"
            :useInternet="useInternet"
            :evidence="evidence"
            :stopped="isStopped"
            :toolHide="toolHide"
            :contentFinished="contentFinished"
            :typing="isTyping"
            :mine="mine"
            :network="network"
            :networkError="networkError"
            :networkText="networkText"
            :networkLink="networkLink"
            @done="handleTyped"
            @audioType="handleAudioType"
            :loading="loading"
            :sender="sender"
            :showNetwork="showCommentsIcon"
            :id="id"
            :faq="faq"
            :faqId="faqId"
            :sourceType="sourceType"
            :formatContent="formatContent"
            :thumbnails="thumbnails"
            :aotProcessInfo="aotProcessInfo"
            :isIntroMessage="isIntroMessage"
            @update:faq="updateFaq"
            :isSubModuleSuccess="isSubModuleSuccess"
            :type="type"
            :polish="polish"
            :internetRemind="internetRemind"
            :evidences="evidences"
            :change_lang_alarm="change_lang_alarm"
            @forceResend="handleForceResend"
            @changeFaq="handleChangeFaq"
            @regenerate="handleRegenerate"
            @changePrinciple="handleChangePrinciple"
          />
          <!-- <h1>{{ toolHide }}</h1> -->
          <MessageTools
            v-if="
              $route.name !== 'pdf' &&
              !showFaq &&
              toolHide!==true &&
              finished &&
              (this.$store.state.profile.language || 'en')
            "
            v-show="showCommentsIcon"
            @rating="handleRating"
            @comment="handleComment"
            @makeShorter="handleShortAnwser"
            @translation="handleTranslation"
            :toggleMessage="toggleMessage"
            :id="id"
            :aotFaq="aotFaq"
            :toolHide="toolHide"
            :showTimeData="showTimeData"
            :rating="rating"
            :showSwitch="showSwitch"
            :showTimeDetail="true"
            :switchStatus="switchStatus"
            :generating="generating"
            :content="content"
            :lang="lang"
            :polish="polish"
            :dest_lang="dest_lang"
            :detect_lang="detect_lang"
            :translateContent="translateContent"
            :isSubModuleSuccess="isSubModuleSuccess"
          ></MessageTools>
        </div>
      </div>

      <MessageComments
        v-if="!mine"
        :isOthers="isOthers"
        :showComments.sync="showComments"
        :comments="comments"
        @update:messageComments="updateMessageComments"
      />
      <div  v-if="waitForInspiration && isLast &&
          !typing &&
          sender === 'DFO' &&
          !mine &&
          $store.state.quickQuestions.length === 0 &&
          isSubModuleSuccess !== false"  class="reply-box" style="margin-bottom: -2px;margin-top: 0px;font-size: 36px;">
           <svg-icon
            class="wait-icon"
            :icon-class="$store.state.profile.theme === 'dark'? 'wait-dark':'wait-light'"
          />
      </div>
      <div
        v-if="
          isLast &&
          !typing &&
          sender === 'DFO' &&
          !mine &&
          $store.state.quickQuestions.length > 0 &&
          isSubModuleSuccess !== false
        "
        class="reply-box"
      >
        <QuickQuestions
            @callQuickQuestion="handleQuickQuestion"
          ></QuickQuestions>
      </div>

      <div
        class="chat-message-extra"
        v-if="!isIntroMessage && type != 'first' && !typing"
      >
        {{ showTime }}
      </div>
    </div>
  </div>
</template>

<script>
import * as webchat from '@/api/webchat'
import ImageCached from '@/components/ImageCached'
export default {
  components: {
    ImageCached,
    // MessageTyped,
    MessageMarkdown: () => import('./message-markdown.vue'),
    MessageComments: () => import('./message-comments.vue'),
    MessageTools: () => import('./message-tools.vue'),
    QuickQuestions: () => import('../../components/QuickQuestions/index.vue')
  },
  props: {
    showFaq: {
      type: Boolean,
      default: false
    },
    finished: {
      type: Boolean,
      default: false
    },
    showTools: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    internetRemind: {
      type: Boolean,
      default: false
    },
    toggleMessage: String,
    waitForInspiration: {
      type: Boolean,
      default: false
    },
    dest_lang: {
      type: String,
      default: ''
    },
    detect_lang: {
      type: String,
      default: ''
    },
    toolHide: {
      type: Boolean,
      default: true
    },
    faqId: {
      type: String
    },
    sourceType: {
      type: String
    },
    generating: {
      type: Boolean,
      default: false
    },
    formatContent: {
      type: Array
    },
    content: {
      type: String,
      default: ''
    },
    translateContent: {
      type: String,
      default: ''
    },
    lang: {
      type: String,
      default: ''
    },
    principles: {
      type: Array
    },
    isSubModuleSuccess: {
      type: Boolean
    },
    polish: {
      type: Boolean
    },
    showSwitch: {
      type: Boolean
    },
    switchStatus: {
      type: Boolean,
      default: false
    },
    useInternet: {
      type: String,
      default: ''
    },
    evidence: {
      type: Array
    },
    extraInfo: {
      type: String,
      default: ''
    },
    timestamp: {
      type: Number,
      default: Date.now()
    },
    comments: {
      type: String,
      default: ''
    },
    rating: {
      type: Number,
      default: 0
    },
    mine: {
      type: Boolean,
      default: false
    },
    contentFinished: {
      type: Boolean,
      default: false
    },
    network: {
      type: Boolean,
      default: false
    },
    evidences: {
      type: Object,
      default: () => {
        return { evidences: [], displayType: '' }
      }
    },
    aotFaq: {
      type: Object,
      default: () => {
        return { type: null }
      }
    },
    faq: {
      type: Object
    },
    showTimeData: {
      type: Object,
      default: () => {
        return { data: [] }
      }
    },
    networkError: {
      type: Boolean,
      default: true
    },
    networkText: {
      type: String,
      default: ''
    },
    networkLink: {
      type: String
    },
    typing: {
      type: Boolean,
      default: false
    },
    stopped: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    fit: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    sender: {
      type: String,
      default: ''
    },
    intro: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    thumbnails: {
      type: Array,
      default: () => []
    },
    change_lang_alarm: {
      type: Boolean,
      default: false
    },
    isIntroMessage: {
      type: Boolean,
      default: false
    },
    aotProcessInfo: {
      type: Object,
      default: () => {
        return {
          aotSearch: false,
          aotSearchShow: false,
          aotFound: undefined,
          matchingAotShow: false,
          principleFound: undefined,
          generatedAnswer: undefined
        }
      }
    }
  },
  data() {
    return {
      showComments: false,
      audioType: 'stop'
    }
  },
  computed: {
    canRegenerate() {
      let res = true
      const history = this.$store.state.history
      if (history.length > 0) {
        if (history[history.length - 1].id === this.id) {
          res = true
        } else {
          res = false
        }
      } else {
        res = false
      }
      return res
    },
    isLast() {
      let res = false
      const history = this.$store.state.history
      if (history.length > 0) {
        if (history[history.length - 1].id === this.id) {
          res = true
        }
      }
      return res
    },

    isProd() {
      return process.env.VUE_APP_ENV === 'prod'
    },
    messageComments: {
      get() {
        return this.comments || ''
      },
      set(value) {
        this.$emit('update:comments', value)
      }
    },
    messageRating: {
      get() {
        return this.rating || 0
      },
      set(value) {
        this.$emit('update:rating', value)
      }
    },
    showTime() {
      if (this.timestamp) {
        const fmt = 'YYYY-MM-DD HH:mm:ss'
        return moment.utc(this.timestamp).local().format(fmt)
      } else {
        return ''
      }
    },
    showIcons() {
      return !this.intro || process.env.VUE_APP_FIRST_MSG_SHOW_ICON === 'true'
    },
    showCommentsIcon() {
      return (
        this.sender === 'DFO' &&
        this.isOthers &&
        !this.isTyping &&
        !this.loading &&
        this.showIcons
      )
    },
    hasError() {
      return Boolean(this.error)
    },
    icon() {
      if (this.mine) {
        if (this.$store.state.profile.name) {
          return ''
        } else {
          return require('@/assets/images/default-head.png')
        }
      }
      switch (this.sender) {
        case 'DFO':
          return require('@/assets/images/head1.png')
        case 'ChatGPT':
          return require('@/assets/images/head3.png')
        default:
          return ''
      }
    },
    isOthers() {
      return !this.mine
    },
    isTyping: {
      get() {
        return this.typing
      },
      set(value) {
        this.$emit('update:typing', value)
      }
    },
    isStopped: {
      get() {
        return this.stopped
      },
      set(value) {
        this.$emit('update:stopped', value)
      }
    }
  },
  methods: {
    handleAudioType(val) {
      this.audioType = val
    },
    updateFaq(faq) {
      this.$emit('update:faq', faq)
    },
    updateMessageComments(comments) {
      this.messageComments = comments
      const data = {
        sessionId: this.$store.state.sessionId,
        recordId: this.id,
        content: comments,
        type: 1
      }
      this.showComments = false
      this.$store.commit('setSessionOtherCount')
      webchat.comment(data).then((res) => {
        this.$message.success({
          message: this.$t('common.comment'),
          customClass: 'message-success',
          duration: 2000
        })
      })
    },
    handleRating(val) {
      this.messageRating = val
      const data = {
        sessionId: this.$store.state.sessionId,
        recordId: this.id,
        rating: Number(val) * 2,
        type: 1
      }
      webchat.rating(data)
      this.$store.commit('setSessionOtherCount')
      this.$emit('update:rating', val)
    },
    handleComment(val) {
      this.showComments = true
    },
    handleTyped() {
      this.isTyping = false
    },
    handleShortAnwser(val) {
      this.$emit('makeShorter', val)
    },
    handleTranslation(val) {
      this.$emit('translation', val)
    },
    handleForceResend() {
      this.$emit('forceResend')
    },
    handleChangeFaq(val) {
      this.$emit('changeFaq', val)
    },
    handleQuickQuestion(val) {
      this.$emit('callQuickQuestion', val)
    },
    handleRegenerate() {
      console.info('regenerate')
      this.$emit('regenerate')
    },
    handleChangePrinciple(val) {
      console.info('changePrinciple', val)
      this.$emit('changePrinciple', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-item {
  margin: 5px 0px;

  &:first-child {
    margin-top: 16px;
  }

  // &:last-child {
  //   margin-bottom: 16px;
  // }
}

.chat-message-extra {
  margin-bottom: 2px;
  color: var(--text);
  font-size: 10px;
  margin-top: 4px;
  margin-left: 60px;
  // .is-others & {
  //   margin-top: 4px;
  //   margin-left: 60px;
  // }

  // .is-mine & {
  //   margin-top: 4px;
  //   margin-right: 60px;
  //   text-align: right;
  // }
}

.chat-message-body {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex: 1;
}
.chat-message-content {
  max-width: calc(100% - 60px);
  position: relative;
  // padding-right: 10vw;
}

.chat-message-loading-cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.chat-message-head {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;

    // box-shadow: 0px 0px 10px var(--blue);
  }
}
.tts-head {
  img {
    animation: link 4s linear infinite;
  }
}
@keyframes link {
  25% {
    box-shadow: 0px 0px 1px var(--blue);
  }
  50% {
    box-shadow: 0px 0px 5px var(--blue);
  }
  75% {
    box-shadow: 0px 0px 10px var(--blue);
  }
  75% {
    box-shadow: 0px 0px 15px var(--blue);
  }
}
.call-time {
  width: 100%;
  // text-align: center;
  color: var(--s-text);
  margin-left: 50px;
  margin-top: 8px;
  font-size: 15px;
}
.message-info {
  position: relative;
  display: inline;
  float: left;
}
.mine {
  flex-direction: row-reverse;
  .chat-message-content {
    padding-right: 0;
    padding-left: 10vw;
    //  display: flex;
    //  justify-content: flex-end;
  }
}
.reply-box {
  display: block;
  padding-left: 60px;
  margin-top: 8px;
  margin-bottom: -4px;
}
@media screen and (max-width: 900px) {
  .chat-message-content {
    // padding-right: 38px;
  }
  .chat-message-content {
    max-width: calc(100% - 40px);
    position: relative;
    // padding-right: 10vw;
  }
  .reply-box{
    padding-left: 38px;
  }
  .mine {
    .chat-message-content {
      padding-right: 0;
      padding-left: 38px;
      //  display: flex;
      //  justify-content: flex-end;
    }
    .chat-message-head {
      margin-right: 0px !important;
      margin-left: 10px !important;
    }
  }
  .chat-message-head {
    width: 28px;
    height: 28px;
    max-width: 28px;
    max-height: 28px;
    margin-left: 0px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 28px;
      max-height: 28px;
      border-radius: 50%;
      border: none;
    }
  }

  .chat-message-extra {
    margin-top: 2px;
    margin-left: 40px;
    // .is-others & {
    //   margin-top: 2px;
    //   margin-left: 45px;
    // }

    // .is-mine & {
    //   margin-top: 2px;
    //   margin-right: 45px;
    //   text-align: right;
    // }
  }
}
</style>
