<template>
  <div class="setting-info">
    <div class="dialog-user-box">
      <div class="du-box">
        <ImageCached :src="userIcon" class="user-head" />
        <span>{{ $store.state.profile.name }}</span>
      </div>
      <div class="edit-box" @click="handleClick($t('user.account'))">
        <svg-icon class="edit3" icon-class="edit3" />
      </div>

    </div>
    <div>
      <div class="item-box">
        <span class="title">{{ $t("common.account") }}</span>
        <div class="item" @click="handleClick($t('user.profile'))">
          <span><svg-icon icon-class="profile" class="profile left-icon" />{{ $t("user.profile") }}</span>
          <svg-icon class="dialog-left" icon-class="right2" />
        </div>
        <div class="item" @click="handleClick($t('home.faq'))">
          <span><svg-icon icon-class="faq" class="faq left-icon" />{{ $t("home.faq") }}</span>
          <svg-icon class="dialog-left" icon-class="right2" />
        </div>
        <div class="item" @click="handleClick($t('user.contact'))">
          <span><svg-icon icon-class="contact" class="contact left-icon" />{{ $t("user.contact") }}</span>
          <svg-icon class="dialog-left" icon-class="right2" />
        </div>
      </div>
      <div class="item-box">
        <span class="title">{{ $t("common.version") }}</span>
        <div class="item" @click="handleClick($t('common.notifications'))">
          <span><svg-icon icon-class="new" class="new left-icon" />{{ $t("common.notifications") }}<span
              class="red-point" v-if="$store.state.isShowPoint"></span></span>
          <svg-icon class="dialog-left" icon-class="right2" />
        </div>
      </div>
      <div class="item-box">
        <span class="title">{{ $t("common.interface") }}</span>
        <div class="item">
          <span><svg-icon icon-class="light2" class="light2 left-icon" />{{ $t("header.lightMode") }}</span>
          <el-switch @change="changeTheme" v-model="light_mode.value" active-color="var(--switch)"
            inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF">
          </el-switch>
        </div>
        <div class="item" v-if="userProfile.audio_switch !== null">
          <span><svg-icon icon-class="speaking" class="speaking left-icon" />{{ $t("header.audio") }}</span>
          <el-switch @change="changeAudio" v-model="userProfile.audio_switch" active-color="var(--switch)"
            inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF">
          </el-switch>
        </div>
      </div>
      <div class="item-box">
        <span class="title">{{ $t("common.interactions") }}</span>
        <div class="item" v-if="userProfile.finance_switch !== null">
          <span>{{ $t("header.finance") }}</span>
          <el-switch @change="changeFinance" v-model="userProfile.finance_switch" active-color="var(--switch)"
            inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF">
          </el-switch>
        </div>
        <div class="item" v-if="userProfile.know_you_switch !== null">
          <span><svg-icon icon-class="learn" class="learn left-icon" />{{ $t("header.allowKnowYou") }}</span>
          <el-switch @change="changeKnowYou" v-model="userProfile.know_you_switch" active-color="var(--switch)"
            inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF">
          </el-switch>
        </div>
        <div class="item" v-if="userProfile.guess_switch !== null">
          <span><svg-icon icon-class="respond" class="respond left-icon" />{{ $t("header.guess") }}</span>
          <el-switch @change="changeGuess" v-model="userProfile.guess_switch" active-color="var(--switch)"
            inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF">
          </el-switch>
        </div>
      </div>
      <div class="item-box status" v-if="!$store.state.isIframe">
        <span class="title">{{ $t("common.status") }}</span>
        <div class="item" @click="logout">
          <span>{{ $t("header.logOut") }}</span>
        </div>
      </div>
    </div>
    <!-- <el-button class="btn" @click="logout" v-if="!$store.state.isIframe">
      {{ $t("header.logOut") }}
    </el-button> -->
  </div>
</template>
<script>
import ImageCached from '@/components/ImageCached'
export default {
  components: {
    ImageCached
  },
  data() {
    return {
      light_mode: {
        label: 'header.lightMode',
        value: false,
        disable: false,
        icon: 'search-online'
      }
    }
  },
  computed: {
    userIcon() {
      if (this.$store.state.profile.name) {
        return ''
      } else {
        return require('@/assets/images/default-head.png')
      }
    },
    userLang() {
      return this.$store.state.language[this.$store.state.profile.language || 'en']
    },
    userLightMode() {
      return this.$store.state.lightMode === 'light'
    },
    userProfile() {
      return this.$store.state.profile
    }
  },
  watch: {},
  activated() {
    this.light_mode.value = this.$store.state.profile.theme === 'light'
    this.$store.dispatch('fetchUserProfile').then(res => {
      this.light_mode.value = this.$store.state.profile.theme === 'light'
    })
  },
  mounted() {
    this.light_mode.value = this.$store.state.profile.theme === 'light'
    this.$store.dispatch('fetchUserProfile').then(res => {
      this.light_mode.value = this.$store.state.profile.theme === 'light'
    })
  },
  methods: {
    handleClick(val) {
      this.$emit('change', val)
    },
    handleFaq() {
      this.$emit('faq')
    },
    changeSwitch(val) {
      const data = {
        search_online: val
      }
      this.$store.dispatch('updateProfile', data)
    },
    changeTheme(val) {
      const data = {
        theme: val ? 'light' : 'dark'
      }
      this.$store.dispatch('updateProfile', data)
    },
    changeGuess(val) {
      const data = {
        guess_switch: val
      }
      this.$store.dispatch('updateProfile', data)
    },
    changeAudio(val) {
      const data = {
        audio_switch: val
      }
      this.$store.dispatch('updateProfile', data)
    },
    changeKnowYou(val) {
      const data = {
        know_you_switch: val
      }
      this.$store.dispatch('updateProfile', data)
    },
    logout() {
      //   window.localStorage.clear()
      this.$emit('logout')

      //   this.$router.push({ name: 'login' })
      // window.location.reload(true)
    },
    help() {
      this.$emit('help')
    },
    handleContact() {
      this.$emit('contact')
    }
  }
}
</script>
<style lang="scss" scoped>
.setting-info {
  margin-top: 40px;
  padding: 0 16px;
}

.dialog-user-box {
  .du-box {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    color: #707070;
  }

  img {
    width: 64px;
    height: 64px;
    margin-right: 13px;
  }

  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.user-head {
  width: 64px;
  height: 64px;
  min-width: 64px;

  ::v-deep .text {
    font-size: 24px !important;
  }

  margin-right: 16px;
  // margin: 4px 8px 0px 4px;
}

.item-box {
  margin-top: 24px;

  .title {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--tab-text);
    display: block;
  }

  &.status {
    padding-bottom: 50px;
    .item {
      color: var(--setting-red);
    }
  }
}

.item {
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  height: 42px;
  border-bottom: 0.5px solid var(--br);
  align-items: center;
  color: var(--ss-text);

  .dialog-left.svg-icon {
    width: 24px;
    height: 24px;
  }

  span {
    display: flex;
    align-items: center;
    .red-point {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--red);
      margin-left: 8px;
    }

    .left-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      color: #8C8C8C;

      &.faq {
        margin-left: 0;
      }

      &.new {
        position: relative;
        top: 3px;
        left: 3px;
      }

      &.speaking,
      &.respond {
        color: var(--s-bg);
        stroke: #8C8C8C;
      }
    }
  }
}

.btn {
  height: 44px;
  border-radius: 8px;
  border: 1px solid var(--br);
  color: var(--text);
  width: 100%;
  background: none;
  // position: fixed;
  // bottom: 80px;
  margin-top: 60px;
  margin-bottom: calc(60px + env(safe-area-inset-bottom));
}

.tip {
  color: var(--s-text);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 4px;
  text-align: left;
}

.tip-box {
  display: flex;
  align-items: center;
}
.edit-box{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 900px) {}
</style>
