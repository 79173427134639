var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOthers && _vm.showComments
    ? _c(
        "div",
        { staticClass: "chat-message-comments" },
        [
          _c("el-input", {
            ref: "commentInput",
            attrs: {
              type: "textarea",
              rows: 4,
              placeholder: _vm.$t("footer.comment"),
            },
            model: {
              value: _vm.draftComments,
              callback: function ($$v) {
                _vm.draftComments = $$v
              },
              expression: "draftComments",
            },
          }),
          _c("div", { staticClass: "btn-wrap" }, [
            _c(
              "button",
              { staticClass: "cancel-btn", on: { click: _vm.cancelComments } },
              [_vm._v(_vm._s(_vm.$t("common.cancel")))]
            ),
            _c(
              "button",
              { staticClass: "save", on: { click: _vm.saveComments } },
              [_vm._v(_vm._s(_vm.$t("common.sSave")))]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }