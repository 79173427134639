var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "faq" }, [
    _vm.faq.list
      ? _c("div", { staticClass: "faq-box" }, [
          _c("div", { staticClass: "faq-box-span" }, [
            _vm.faq.list.length > 1
              ? _c("span", [_vm._v(_vm._s(_vm.$t("home.whichFAQ")))])
              : _vm._e(),
            _vm.faq.list.length == 1
              ? _c("span", [_vm._v(_vm._s(_vm.$t("home.isThisFAQ")))])
              : _vm._e(),
          ]),
          _vm.faq.list.length > 1
            ? _c(
                "div",
                { staticClass: "box" },
                [
                  _vm._l(_vm.faq.list, function (item, index) {
                    return _c(
                      "el-button",
                      {
                        key: item.value,
                        staticClass: "btn faq-btn",
                        on: {
                          click: function ($event) {
                            return _vm.handleClick(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s("FAQ # " + Number(index + 1)))]
                    )
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn faq-btn",
                      on: { click: _vm.handleRgenerate },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "tread",
                        attrs: { "icon-class": "refresh" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("home.findAnother"))),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.faq.list.length == 1
            ? _c(
                "div",
                { staticClass: "box" },
                [
                  _vm._l(_vm.faq.list, function (item) {
                    return _c(
                      "el-button",
                      {
                        key: item.value,
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.handleClick(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("home.yes")) + " ")]
                    )
                  }),
                  _c(
                    "el-button",
                    { staticClass: "btn", on: { click: _vm.handleRgenerate } },
                    [
                      _c("svg-icon", {
                        staticClass: "tread",
                        attrs: { "icon-class": "refresh" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("home.findAnother"))),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }