var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "guess-box" },
      [
        _c("span", [_vm._v(" " + _vm._s(_vm.toggleMessage) + " ")]),
        _vm.isLoading
          ? _c("svg-icon", {
              staticClass: "loading-icon",
              attrs: { "icon-class": "loading" },
            })
          : _c(
              "div",
              { staticClass: "btn-box" },
              [
                _c("img", {
                  staticClass: "emoji",
                  attrs: {
                    src: require("../../../../assets//images/emoji.gif"),
                    alt: "",
                  },
                }),
                _c("el-switch", {
                  attrs: {
                    "active-color": "var(--switch)",
                    "inactive-color": "var(--switch-bg)",
                    "active-text": "ON",
                    "inactive-text": "OFF",
                  },
                  on: { change: _vm.changeSwitch },
                  model: {
                    value: _vm.userProfile.guess_switch,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProfile, "guess_switch", $$v)
                    },
                    expression: "userProfile.guess_switch",
                  },
                }),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }