<template>
  <div>
    <Dialog ref="initDom" :title="info.title" :full="true">
      <div v-if="infoData.length > 1" class="nav-box">
        <div class="container borderXwidth">
          <div
            class="item-box"
            :key="index"
            @click="clickMenu(item, index)"
            v-for="(item, index) in infoData"
          >
            <a :class="[activeName == index ? 'red-name' : 'name']">{{
              $t("home.sources") + " " + intToRoman(Number(index+1))
            }}</a>
            <div :class="[activeName == index ? 'on' : '']"></div>
          </div>
        </div>
      </div>

      <div class="content-box">
        <!-- <div class="title">
          <svg-icon class="icon" icon-class="book" v-show="info.type==='book'||info.type==='rag_book'" v-if="info.title" />
          <svg-icon class="icon" icon-class="hat" v-show="info.type==='wmi'||info.type==='rag_wmi'" v-if="info.title" />
          <svg-icon class="icon" icon-class="file" v-show="info.type==='rag_about_dr'" v-if="info.title" />
          {{ info.title }}
        </div> -->
        <span class="chapter" v-if="info.chapter">
          {{ info.chapter }}
        </span>
        <div class="sub-title">
          {{ info.sub_title }}
        </div>
        <div class="tag-box" v-if="info.tag?.length>0">
          <div class="tag" v-for="(item,index) in info.tag"  :key="index">{{ item }}</div>
        </div>
        <div class="text-box">
          <vue-markdown :source="showContent"></vue-markdown>
        </div>
      </div>
      <div v-if="info.url" class="lint-box" @click="linkTo">
        {{ $t("home.exploreMore") }}
        <svg-icon icon-class="share" class="link-right"></svg-icon>
      </div>
      <el-button v-if="info.url" class="btn" @click="linkTo">
        {{ $t("home.exploreMore") }}
         <svg-icon icon-class="share" class="link-right"></svg-icon>
         </el-button
      >
    </Dialog>
  </div>
</template>

<script>
import * as webchat from '@/api/webchat'
export default {
  props: {
    evidences: {
      type: Object,
      default: () => {
        return { evidences: [], displayType: '' }
      }
    },
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    Dialog: () => import('@/components/Dialog/index.vue'),
    VueMarkdown: () => import('vue-markdown')
  },
  computed: {
    info() {
      return this.infoData[this.activeName] || {}
    },
    showContent() {
      let res = ''
      const start = '... \n\n'
      const end = '\n\n ...'
      if (this.infoData[this.activeName]) {
        if (this.infoData[this.activeName].content) {
          res = start + this.infoData[this.activeName].content + end
        }
      }

      return res
    }
  },
  data() {
    return {
      activeName: 0,
      infoData: {}
      // info: {
      //   source_type: 'book',
      //   title: 'xxx',
      //   sbu_title: 'xxx',
      //   content: 'xxx',
      //   link: 'https://www.youtube.com/embed/UjDmFq08XWI?si=TH7bL8HwcavXNgdp'
      // }
    }
  },
  methods: {
    intToRoman(num) {
      const map = {
        1: 'I',
        5: 'V',
        10: 'X',
        50: 'L',
        100: 'C',
        500: 'D',
        1000: 'M'
      }
      // 标识位数
      let digits = 1
      // 结果
      let result = ''
      while (num) {
        const current = num % 10
        if (current < 4) {
          result = map[digits].repeat(current) + result
        } else if (current === 4) {
          result = map[digits] + map[digits * 5] + result
        } else if (current > 4 && current < 9) {
          result = map[digits * 5] + map[digits].repeat(current - 5) + result
        } else {
          result = map[digits] + map[digits * 10] + result
        }
        digits *= 10
        num = Math.trunc(num / 10)
      }
      return result
    },
    clickMenu(item, index) {
      this.activeName = index
    },
    open() {
      if (this.evidences.evidences.length > 0) {
        const data = {
          evidences: this.evidences.evidences,
          sessionId: this.$store.state.sessionId,
          recordId: this.id
        }
        webchat.faqSource(data).then((res) => {
          this.infoData = res.data.evidences
          this.$refs.initDom.open()
        })
      }
    },
    linkTo() {
      window.open(this.info.url)
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  text-align: center;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
}
.principle-val {
  display: -webkit-box;
  color: var(--s-white);
}
::v-deep .content{
  overflow: hidden !important;
}
.frame-video {
  width: 100%;
  height: 25vw;
}
.lint-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
}
.link-right {
  font-size: 15px;
  margin-left: 6px;
}
.sub-title {
  border-left: 2px solid var(--red);
  padding-left: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  display: block;
  margin-bottom: 16px;
  color: var(--s-text);
}

div.container {
  margin: 0 auto;
  text-align: center;
}
.container {
  display: flex;
}
.item-box {
  position: relative;
  min-width: fit-content;
}

div.container a {
  color: var(--text);
  text-decoration: none;
  font: 15px;
  // margin: 0px 12px;
  padding: 10px 16px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  font-weight: 400;
}
.red-name {
  color: var(--red) !important;
  font-size: 15px;
  font-weight: 700 !important;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  font-family: "Roboto";
}
.name {
  color: var(--tabs-text) !important;
  font-size: 15px;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  font-family: "Roboto";
}
.text-box{

    line-height: 24px;

}

div.borderXwidth a:after {
  position: absolute;
  opacity: 0;
  width: 0%;
  height: 2px;
  content: "";
  background: var(--red);
  transition: all 0.3s;
}

div.borderXwidth a:after {
  left: 20%;
  bottom: 0px;
}

// div.borderXwidth a:hover:after {
//   opacity: 1;
//   width: 60%;
// }
.on {
  opacity: 1;
  width: 100%;
  //transform: scaleX(0.6);
  margin-top: 7.5px;
  //margin-left: 24px;
  height: 2px;
  content: "1";
  background: var(--red);
  position: absolute;
  z-index: 10;
}
.content-box {
  margin-top: 24px;
  max-height: calc(80vh - 250px);
  overflow: auto;
}
.icon {
  margin-right: 5px;
}
.btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 0px;
  letter-spacing: 0px;
  text-align: center;
  width: calc(100% - 0px);
  // height: 40px;
  left: 0;
  bottom:0;
  background: none;
  color: var(--text);
  margin-top: 16px;
  display: none;
  position: fixed;
  padding-bottom:  calc(env(safe-area-inset-bottom) + 20px);
  border: none;
}
.nav-box {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 20px;
}
.chapter{
  display: block;
  color: var(--red);
  font-weight: 600 !important;
  font-size: 13px;
  margin-bottom: 8px;
  height: 15px;
  display: flex;
  align-items: center;
  font-family: Century-Gothic;
}
.tag-box{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: calc(100% - 12px);
}
.tag{
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--ss-text);
  border: 1px solid var(--chart-bg);
  padding: 6px 8px;
  margin-right: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
}

@media screen and (max-width: 900px) {
  .content-box {
    margin-top: 24px;
    max-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 160px);
    overflow: auto;
  }
  .nav-box {
    margin-top: 8px;
  }
  .nav-box::-webkit-scrollbar {
    display: none;
  }
  .content-box::-webkit-scrollbar {
    display: none;
  }
  .drawer-wrap::-webkit-scrollbar {
    display: none;
  }
  .btn {
    display: block;
  }
  .frame-video {
    width: 100%;
    height: 50vw;
  }
  .lint-box {
    display: none;
  }
}
</style>
