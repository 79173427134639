const cache = {}

const isImageCached = (src) => {
  return cache[src]
}

const isImageBase64 = (src) => {
  return /^data:/.test(src)
}

const getCachedImage = (src) => {
  return cache[src] || src
}

const toBase64 = (img) => {
  try {
    const canvas = document.createElement('canvas')
    canvas.width = img.naturalWidth
    canvas.height = img.naturalHeight
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight)
    const extension = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
    const dataURL = canvas.toDataURL(`image/${extension}`)
    return dataURL
  } catch (e) {
    console.error(e.message)
    return ''
  }
}

const cacheImage = (src, img) => {
  if (isImageCached(src)) return
  if (isImageBase64(src)) {
    cache[src] = src
    return
  }
  cache[src] = toBase64(img)
}

export { cacheImage, getCachedImage, isImageCached }

export default cache
