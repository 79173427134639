<template>
  <div id="app">
    <Version></Version>
    <KeepAlive v-if="isReloadAlive">
      <router-view  :include="['chatHome']" />
    </KeepAlive>
  </div>
</template>
<script>
import { Network } from '@capacitor/network'
import { Device } from '@capacitor/device'
import Version from '../src/views/version/index.vue'

export default {
  components: {
    Version
  },
  data() {
    return {
      isReloadAlive: true,
      width: window.innerWidth,
      touchStartX: 0,
      touchStartY: 0
    }
  },
  watch: {

    '$store.state.showSide': {
      handler(val) {
        this.$nextTick(() => {
          setTimeout(() => {
            let w = '0px'
            if (this.$route.name === 'chatHome' || this.$route.name === 'principles') {
              if (val) {
                w = '130px'
              }
            }
            document.body.style.setProperty('--message-margin-left', w)
          }, 100)
        })
      },
      deep: true,
      immediate: true

    },
    $route: {
      handler(val) {
      },
      deep: true
    },
    '$store.state.profile.theme': {
      handler(val) {
        this.applyTheme()
      },
      deep: true
    },
    '$store.state.profile.audio_switch': {
      handler(val) {
        if (!val) {
          this.$store.dispatch('delTTS')
        }
      },
      deep: true
    },
    '$store.state.isMin900Width': {
      handler(val) {
        console.info('=change')
        if (val && process.env.VUE_APP_PLATFORM !== 'APP') {
          this.$store.commit('setShowSide', false)
        } else {
          this.$store.commit('setShowSide', true)
        }
      },
      deep: true
    }
  },
  provide() {
    return {
      reloadApp: this.reloadApp
    }
  },
  async created() {
    this.validToken()
    this.applyTheme()
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.applyTheme)
    const info = await Device.getInfo()
    const platform = info.platform
    this.$store.commit('setAppPlatform', platform)
    const infoId = await Device.getId()
    console.info('info', info, platform)
    this.$store.state.deviceId = infoId.uuid
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateVH)
    Network.removeAllListeners()
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.applyTheme)
  },

  async mounted() {
    this.handleResize()
    // document.addEventListener('touchstart', this.handleTouchStart, { passive: false })
    // document.addEventListener('touchmove', this.handleTouchMove, { passive: false })
    this.$store.state.networkStatus = await Network.getStatus()
    // 监听网络状态变化
    Network.addListener('networkStatusChange', (status) => {
      this.$store.state.networkStatus = status
      // none 无网络
    })

    // 配置设备屏幕高度
    document.body.style.setProperty('--screen-height', `${window.screen.height}px`)

    this.updateVH()
    window.addEventListener('resize', this.updateVH)
    const isShowPoint = this.$store.state.version !== localStorage.getItem('version')
    this.$store.commit('setShowPoint', isShowPoint)
  },
  methods: {

    handleTouchStart(event) {
      console.info(event, event.touches[0].pageX)
      this.touchStartX = event.touches[0].pageX
      this.touchStartY = event.touches[0].pageY
    },
    handleTouchMove(event) {
      // const touchX = event.touches[0].pageX
      // const touchY = event.touches[0].pageY
      const edgeThreshold = window.innerWidth * 0.5
      // 判断是否在边缘阈值内
      if (this.touchStartX < edgeThreshold || this.touchStartX > window.innerWidth - edgeThreshold) {
        // 可以进一步判断滑动方向是水平还是垂直，这里简化处理
        event.preventDefault()
        // 可以在这里添加更多的逻辑，比如提供反馈给用户
      }

      // 判断触摸开始是否在边缘区域
      if (this.touchStartX < edgeThreshold || this.touchStartX > window.innerWidth - edgeThreshold) {
        event.preventDefault() // 阻止默认行为
        // 你可以在这里添加逻辑来提供用户反馈或其他操作
      }
    },
    async validToken() {
      if (this.$store.state.token) {
        await this.$store.dispatch('fetchUserProfile')
      }
    },
    reloadApp() {
      this.isReloadAlive = false
      this.$nextTick(() => {
        this.isReloadAlive = true
      })
    },
    applyTheme() {
      if (!this.$store.state.profile.theme || this.$store.state.profile.theme === 'dark') {
        document.documentElement.classList.add('dark-theme')
      } else {
        document.documentElement.classList.remove('dark-theme')
      }
    },
    handleResize() {
      this.width = window.innerWidth // 更新宽度
      if (this.width <= 900) {
        this.$store.commit('setIsMin900Width', true)
      } else {
        this.$store.commit('setIsMin900Width', false)
      }
    },
    updateVH() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      this.handleResize()
    },
    reload() {
      this.isReloadAlive = false
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])
      // this.$store.commit('setSessionCount')
      this.$nextTick(() => {
        this.$store.commit('setSessionId', '')
        this.$store.commit('setHistory', [])
        this.isReloadAlive = true
      })
    }

  }
}
</script>
<style lang="scss" scoped>
</style>
