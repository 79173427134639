<template>
  <div class="boarding">
    <div class="wrap">
      <img class="logo" src="../../assets/images/Digital Ray.png" alt="" />
      <p style="color: #ffffff88">(Alpha)</p>
      <div class="box">
        <img src="../../assets/images/product-3.png" alt="" />
        <span>Solving Problems via Principled-Thinking Process</span>
      </div>
      <div class="box">
        <img src="../../assets/images/product-4.png" alt="" />
        <span
          >Understanding the Essentials of Ray Dalio and the Insights from His
          Previous Experience</span
        >
      </div>
      <div class="box">
        <img src="../../assets/images/product-10.png" alt="" />
        <span>Dialogue with Ray Dalio for Diverse Tasks</span>
      </div>

      <el-button class="btn" @click="goHome">{{$t('login.btn')}}</el-button>
      <div class="a-box">
        <a target="_blank" rel="noopener noreferrer">Disclaimer</a>
        <a target="_blank" rel="noopener noreferrer">Terms of Service</a>
        <a target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </div>
      <span class="Powered">Powered by VR OPCO LTD (V {{$store.state.version}})</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.replace({ name: 'chatHome' })
    }
  }
}
</script>

<style lang="scss" scoped>
.boarding {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--black);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  .wrap {
    width: calc(100% - 32px);
  }
  .logo {
    width: 50vw;
  }
  .btn {
    margin-top: 22px;
    margin-bottom: 60px;
    min-height: 48px;
    padding: 0px, 10px;
    border-radius: 43px;
    background: #e71313;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--white);
    border: none;
    width: 100%;
  }
  .box {
    min-height: 108px;
    border-radius: 20px;
    border: 1px dashed #707070;
    margin-bottom: 12px;
    margin-top: 20px;
    // display: flex;
    // align-items: center;
    span {
      display: block;
      margin-top: 12px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }

    img {
      margin-top: 16px;
      height: 40px;
    }
  }
  .a-box {

    a {
      text-decoration: underline;
      display: inline-block;
      margin-right: 16px;
      color: var(--white);
    }
    margin-bottom: 8px;
  }
  .Powered {
    color: #8c8c8c;
    font-family: Century-Gothic;
  }
}
</style>
