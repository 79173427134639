var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.isDay ? "princeple" : "" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "principle-key",
          staticStyle: { "font-weight": "600" },
          style: _vm.isDay ? "margin-bottom:0px !important" : "",
        },
        [
          _c("div", { staticClass: "principle-select" }, [
            _c(
              "span",
              { staticClass: "principle-line" },
              [
                _vm._v(" " + _vm._s(_vm.principle.type) + " "),
                _vm.principles?.length > 1 && !_vm.$store.state.isMin900Width
                  ? _c(
                      "Dropdown",
                      {
                        attrs: { option: _vm.principles, label: "label" },
                        on: { change: _vm.handleClick },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "btn-wrap" },
                          [
                            _c("span", { staticClass: "date" }, [
                              _vm._v(_vm._s(_vm.principles[_vm.count].label)),
                            ]),
                            _c("svg-icon", {
                              staticClass: "arrow-down",
                              attrs: { "icon-class": "down" },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.principles?.length > 1 && _vm.$store.state.isMin900Width
                  ? _c("div", { staticClass: "btn-wrap" }, [
                      _c("span", { staticClass: "date" }, [
                        _vm._v(_vm._s(_vm.principles[_vm.count].label)),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c("span", { staticClass: "principle-key-content" }, [
            _vm._v(_vm._s(_vm.principle.title)),
          ]),
          !_vm.isDay
            ? _c(
                "div",
                {
                  ref: "textContainer",
                  staticClass: "principle-val-info",
                  class: [
                    _vm.showMore && !_vm.isExtend ? "only10" : "",
                    _vm.showMore ? "" : "buttom",
                  ],
                },
                [_c("span", [_vm._v(_vm._s(_vm.principle.content))])]
              )
            : _vm._e(),
          !_vm.isDay && _vm.showMore && !_vm.isExtend
            ? _c(
                "div",
                { staticClass: "showMore", on: { click: _vm.handleShowMore } },
                [
                  _c(
                    "span",
                    [
                      _vm._v(_vm._s(_vm.$t("home.showMore"))),
                      _c("svg-icon", {
                        staticClass: "tooltip-down",
                        attrs: { "icon-class": "down" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isDay && _vm.showMore && _vm.isExtend
            ? _c(
                "div",
                { staticClass: "showMore", on: { click: _vm.handleShowMore } },
                [
                  _c(
                    "span",
                    [
                      _vm._v(_vm._s(_vm.$t("home.showLess"))),
                      _c("svg-icon", {
                        staticClass: "tooltip-up",
                        attrs: { "icon-class": "down" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }