import router from '@/router'
import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store/index'

let hasAlerted = false
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 60 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (config.baseURL === 'https://api.openai.com/') {
      return config
    }
    const token = store.state.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers.Version = store.state.version
    // config.headers.Version = '0.0.0'
    config.headers.Platform = store.state.appPlatform
    // config.headers.Platform = 'ios'
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    if (response.data?.code === 10010) {
      store.commit('setVersionDialog', true)
    }
    if (response.data?.code !== 1000 && response.data?.code !== 10007) {
      Message.error({
        message: response.data?.detail || 'ERROR!',
        customClass: 'message-error',
        duration: 2000
      })
      return Promise.reject(response.data?.detail || 'ERROR!')
    }

    console.info(response)
    return response.data
  },
  (error) => {
    console.error('Axios error:', error) // 记录完整的错误对象
    if (error.response) {
      console.info(error.response, '==========')
      if (error.response.status === 401) {
        // Token is expired or invalid, redirect to login page
        if (!hasAlerted) {
          Message.warning({
            message: error.response.data?.detail || error.message || error.response.statusText || 'ERROR!',
            customClass: 'message-error',
            duration: 2000
          })
          hasAlerted = true
        }
        if (router.app._route.name !== 'login') {
          router.push({ name: 'login' }) // assuming you have a route named 'Login'
        }
      } else if (error.response.status !== 200) {
        console.info(error, 'error')
        Message.warning({
          message: error.response.data?.detail || error.message || error.response.statusText || 'ERROR!',
          customClass: 'message-error',
          duration: 2000
        })
      }
    } else {
      // Handle cases where error.response is undefined
      Message.warning({
        message: error.message || 'Unknown error occurred!',
        customClass: 'message-error',
        duration: 2000
      })
    }

    return Promise.reject(error)
  }
)

export default service
