import request from '@/utils/request'

export const getList = async (data) => {
  return request({
    url: '/rest/webchat/v2/chats/list',
    method: 'get',
    params: data
  })
}
export const getSessionDetail = async (sessionId, data) => {
  return request({
    url: `/rest/webchat/v2/chats/${sessionId}/records/list`,
    method: 'get',
    params: data
  })
}
export const delSession = async (sessionId) => {
  return request({
    url: `/rest/webchat/v2/chats/${sessionId}`,
    method: 'delete'
  })
}
export const delAllList = async (data) => {
  return request({
    url: '/rest/webchat/v2/chats/list',
    method: 'delete',
    data: data
  })
}
export const updateSession = async (data) => {
  return request({
    url: `/rest/webchat/v2/chats/${data.sessionId}/title`,
    method: 'put',
    data: data
  })
}
