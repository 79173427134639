<template>
  <div class="day" v-if="principles?.length > 0">
    <AppDialog :title="$store.state.isMin900Width ? '   ' : ''"  ref="initDom" :full="true">
      <div class="day-content">
        <div class="principle-contet">
          <Princeple
            class="principle-bubble pd0"
            @change="handleClick"
            :count="count"
            :principles="list"
            :principle="principles[count]"
          >
          <div
          class="day-choose"
          v-if="principles[count]?.question_list?.length > 0 && !$store.state.isMin900Width"
        >
          <span class="day-tip">{{ $t('day.tip') }}</span>
          <div
            v-for="(item, index) in principles[count].question_list"
            :key="index"
            class="day-choose-items"
            @click="chooseItem(item)"
          >
          <PrincipleHints>{{ item }}</PrincipleHints>
            <!-- <span>{{ item }}</span> -->
          </div>
        </div>
          </Princeple>
        </div>
        <div
          class="day-choose"
          v-if="principles[count]?.question_list?.length > 0 && $store.state.isMin900Width"
        >
          <span class="day-tip">{{ $t('day.tip') }}</span>
          <div
            v-for="(item, index) in principles[count].question_list"
            :key="index"
            class="day-choose-items"
            @click="chooseItem(item)"
          >
          <PrincipleHints>{{ item }}</PrincipleHints>
            <!-- <span>{{ item }}</span> -->
          </div>
        </div>
        <div v-if="!principles[count].url" class="lint-box" @click="linkTo">
          {{ $t("home.exploreMore") }}
          <svg-icon icon-class="share" class="share-icon"></svg-icon>
        </div>
      </div>
      <div class="tiem-box" v-if="list?.length >= 1">
          <span v-if="count > 0" @click="pre">
            <svg-icon icon-class="right" class="link-left"></svg-icon>
            {{ list[count - 1].label }}
          </span>
          <span v-else></span>
          <span v-if="count < list?.length - 1 && count >= 0" @click="next">
            {{ list[count + 1].label }}
            <svg-icon icon-class="right" class="link-right"></svg-icon>
          </span>
        </div>
    </AppDialog>

  </div>
</template>

<script>
export default {
  components: {
    AppDialog: () => import('@/components/AppDialog/index.vue'),
    Princeple: () => import('./index.vue'),
    PrincipleHints: () => import('@/components/PrincipleHints/index.vue')
  },
  props: {
    principles: {
      type: [],
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      list: [],
      count: 0
    }
  },
  watch: {
    principles() {
      this.init()
    },
    '$store.state.isMin900Width': {
      handler(val) {
        this.close()
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.init()
    // console.info(moment.months(new Date()))
  },
  methods: {
    next() {
      // console.info('=========', this.count)
      if (this.count < this.list.length) {
        this.count++
      }
    },
    pre() {
      if (this.count > 0) {
        this.count--
      }
    },
    init() {
      this.list = []
      for (const i in this.principles) {
        const item = this.principles[i]
        item.label = moment(this.principles[i].date)
          .format('MMMM DD')
          .toUpperCase()
        this.list.push(item)
      }
      if (this.list.length > 0) {
        this.count = this.list.length - 1
      }

      console.info(this.list)
    },
    open() {
      this.$refs.initDom.open()
      this.init()
      // this.count = 0
    },
    close() {
      if (this.$refs.initDom) {
        this.$refs.initDom.close()
      }
    },
    chooseItem(item) {
      const data = {
        id: this.principles[this.count].id,
        msg: item,
        data: this.principles[this.count]
      }
      console.info('data', data)
      this.$emit('change', data)
      this.close()
    },
    handleClick(val) {
      console.info(val, 'val')
      for (const i in this.list) {
        if (this.list[i].label === val.label) {
          this.count = Number(i)
        }
      }
    },
    linkTo() {
      window.open(this.principles[this.count].link)
    }
  }
}
</script>

<style lang="scss" scoped>
.day-choose {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0px;
  // margin-right: -8px;
}

.lint-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;

}

.day-choose-items {
  width: fit-content;
  margin-bottom: 12px;
  margin-right: 8px;
  cursor: pointer;
}

.tiem-box {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 20px);
  width: calc(100vw - 40px);
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  // background: red;
  span {
    cursor: pointer;
    color: var(--ss-text) !important;
    font-size: 13px;
    font-weight: 600;
    height: 20px;
    z-index: 1000;
  }
}

.link-left {
  transform: rotate(180deg);
  margin-right: 8px;
}

.link-right {
  margin-left: 8px;
}

.share-icon {
  margin-left: 8px;
}
::v-deep .principle-bubble {
  width: 100%;
  // .principle-bubble{
  //   padding: 0;
  // }
}
.pd0 {
  padding: 0;
}
.day-tip{
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  display: block;
  min-width: 100%;
  // margin-top: 12px;
  color: #a3a3a3;
  margin-bottom: 8px;
}
@media screen and (min-width: 900px) {
  ::v-deep .dialog{
    background: #000 !important;
    color: #fafafa !important;
  }
  ::v-deep .title-box {
    color: #fafafa !important;
    height: 0px !important;
  }
  ::v-deep .close-icon {
    color: var(--white) !important;
    // height: 0px !important;
  }
  .tiem-box {
    display: none;
  }
  .principle-bubble-content:hover {
    background: #000;
  }
}

@media screen and (max-width: 900px) {
  // ::v-deep .dialog-content-wrap{
  //   max-height: calc(94vh - 100px - env(safe-area-inset-bottom)) !important;
  // }
  .pd0 {
    padding: 16px;
    // margin-top: 16px;
    background: #000;
  }
  .day-choose-items {
    width: 100%;
    // background: var(--s-blue-bg);
    // color: var(--blue-text);
  }
  .day-tip{
    margin-bottom: 16px;
    color: var(--ss-text);
  }
  .day-choose {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 32px;
  }
  ::v-deep .hints{
    min-width: 100%;
  }
  .day-content {
    // position: fixed;
    height: calc(
      92vh - 100px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    ) !important;
    max-height: calc(
      92vh - 100px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    ) !important;
    padding-bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
    overflow-y: auto;
  }
  .lint-box {

  color: var(--text);
}
}
</style>
