<template>
  <div class="banner-box">
    <div class="content-box">
      <div class="title">
        <span class="red-text">DIGITAL RAY</span> - Chat with the Wisdom of Ray Dalio
      </div>
      <div class="text">
        Embark on a transformative journey with DIGITAL RAY, a revolutionary
        product that channels Ray Dalio's principles into a unique "Principled
        Thinking Frame" to navigate complex challenges.
      </div>
      <div class="text last">
        Improve your decision-making, personal growth, and financial expertise
        under the guidance, quality-assured by Ray Dalio himself. DIGITAL RAY is
        more than a product—it's a partner for excellence. Start exploring Ray's
        digital wisdom for a better future now.
      </div>
      <el-button class="btn" @click="$router.replace({ name: 'chatHome' })"
        >Chat with DIGITAL RAY</el-button
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.title {
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}
.red-text {
  color: var(--red);
}
.last {
  margin-top: 12px;
}
.content-box {
  padding-top: 164px;
  padding-bottom: 90px;
  float: right;
  max-width: 700px;
}
.btn {
  height: 48px;
  padding: 0 37.5px;
  border-radius: 38px;
  background: var(--red);
  color: var(--white);
  border: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 40px;
}
.banner-box {
  // height: 713px;
  // background: url("../../assets/images/banner.png") no-repeat center;
  // background-size: cover;
  min-height: 710px;
}
@media screen and (max-width: 900px) {
  .content-box {
    float: none;
    margin-left: 0px;
    width: 100%;
    padding: 20px;
    padding-top: 164px;
    padding-bottom: 90px;
  }
}
</style>
