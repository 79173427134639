var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "div",
        { on: { click: _vm.toggleDropdown } },
        [
          _c("ImageCached", {
            staticClass: "user-head",
            attrs: { src: _vm.userIcon },
          }),
        ],
        1
      ),
      _c("Contact", { ref: "contact" }),
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible",
              },
            ],
            staticClass: "user-dropdown",
          },
          _vm._l(_vm.list, function (udItem) {
            return _c("div", { key: udItem.value, staticClass: "ud-item" }, [
              !udItem.isHide
                ? _c(
                    "div",
                    {
                      staticClass: "ui-content",
                      class:
                        udItem.value === "logout" && _vm.$store.state.isIframe
                          ? "disabled"
                          : "",
                      on: {
                        click: function ($event) {
                          return _vm.handleCommand(udItem.value)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "ui-left" }, [
                        _c(
                          "div",
                          { staticClass: "ui-icon" },
                          [
                            _c("svg-icon", {
                              class: udItem.icon,
                              attrs: { "icon-class": udItem.icon },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ui-label" }, [
                          _vm._v(_vm._s(udItem.label)),
                        ]),
                      ]),
                      udItem.isWithSwitch
                        ? _c(
                            "div",
                            { staticClass: "ui-switch" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "var(--switch)",
                                  "inactive-color": "var(--switch-bg)",
                                  "active-text": "ON",
                                  "inactive-text": "OFF",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                  change: _vm.handleSwitchChange,
                                },
                                model: {
                                  value: udItem.switchValue,
                                  callback: function ($$v) {
                                    _vm.$set(udItem, "switchValue", $$v)
                                  },
                                  expression: "udItem.switchValue",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              udItem.isHasLine
                ? _c("div", { staticClass: "ui-line" })
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _c("ProfileDialog", {
        ref: "profileDialog",
        attrs: { type: _vm.profileType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }