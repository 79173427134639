<template>
  <div class="login-dialog">
    <Dialog ref="initDom" :title="$t('login.login')" :full="false">
      <div class="dialog-box">
        <span class="login-tip">{{ $t("login.loginTip") }}</span>
        <img class="login-img" src="../../assets/images/py.png" alt="" />
        <div class="login-box">
          <el-button class="login-btn" :icon="loading ? 'el-icon-loading' : ''"
            :class="[loading ? 'btn-noloading' : 'btn-loading']" :disabled="loading" @click.stop="login">{{
              $t("login.continue") }}</el-button>
        </div>
      </div>
    </Dialog>
    <!-- <PCLogin v-if="false" @change="open"></PCLogin> -->
    <div v-if="!isApp" class="login pc">
      <el-button class="btn" :class="[agree ? '' : 'dis-btn']" :disabled="!agree" @click="open">Chat with me</el-button>
    </div>
    <div class="login" v-if="isApp">
      <div class="wrap index">
        <img class="logo" src="../../assets/images/Digital Ray.png" alt="" />
        <div v-for="(item, index) in list" :key="item.label">
          <div v-if="index === count" class="item">
            <span v-html="item.label" class="item-text animate__animated animate__fadeInUp"></span>
            <div class="icon-box">
              <svg-icon :icon-class="item.icon" class="icon animate__animated animate__fadeIn" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="wrap">
          <el-button class="btn" :class="[agree ? '' : 'dis-btn']" :disabled="!agree"
            @click="open">{{ $t('login.btn') }}</el-button>
          <div class="disclaimer-box">
            <div v-if="!agree" @click="handleClick" class="nocheck"></div>
            <div v-if="agree" @click="handleClick" class="check">
              <svg-icon icon-class="enter" class="enter" />
            </div>
            <div>
              <span v-html="disclaimer" class="tip"></span>
            </div>
          </div>
          <span class="Powered">Powered by VR OPCO LTD (V {{ $store.state.version }})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from '@/api/profile'
import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { Device } from '@capacitor/device'
// import PCLogin from './pcLogin.vue'
export default {
  components: {
    // PCLogin,
    Dialog: () => import('@/components/Dialog/index.vue')
  },
  inject: ['reloadApp'],
  data() {
    return {
      page: null,
      agree: true,
      loginUrl: '',
      loading: false,
      userToken: '',
      disclaimer:
        'By chatting with Digital Ray, you agree to our <span class="l-text-blod">Terms of Service</span> and acknowledge the <span class="l-text-blod">Privacy Policy</span> and <span class="l-text-blod">Disclaimer</span>',
      count: 0,
      isApp: false
    }
  },
  computed: {
    list() {
      return [
        {
          label: this.$t('login.app1'),
          icon: 'recommended-2'
        },
        {
          label: this.$t('login.app2'),
          icon: 'recommended-3'
        },
        {
          label: this.$t('login.app3'),
          icon: 'principles'
        }
      ]
    }
  },
  async created() {
    this.handleIframe()
    this.anime()
    window.addEventListener('message', this.handleMessage)
    // this.$store.commit('setToken', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEyLCJpYXQiOjE3MjE3MjgyODIsImV4cCI6MTczNzI4MDI4Mn0.gbaxKK1lXNSbA8MtNaNpSI6-f-h4v5g0XlFpsC67uEo')
    // this.$router.replace({ name: 'chatHome' })
    const deviceInfo = await Device.getInfo()
    this.isApp = deviceInfo.platform !== 'web'

    if (this.isApp) {
      App.addListener('appUrlOpen', this.handleAppUrlOpen)
    }
    const isMobile = deviceInfo.platform === 'android' || deviceInfo.platform === 'ios'
    const token = this.$route.query.token
    const type = this.$route.query.result_type
    if (token) {
      if (isMobile) {
        const urlScheme = `digitalray://login?token=${token}&type=${type}`
        window.location.href = urlScheme
        setTimeout(() => {
          this.handleMessage({ data: { token, type } })
        }, 3000)
      } else {
        this.handleMessage({ data: { token, type } })
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
    if (this.isApp) {
      App.removeAllListeners()
    }
  },
  methods: {
    handleIframe() {
      this.$store.commit('setIsIframe', window !== window.top)
    },
    open() {
      this.loading = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.initDom.open()
        }, 100)
      })
    },
    close() {
      this.$refs.initDom.close()
    },
    async login() {
      this.loading = true
      try {
        const res = await profile.getOauth2url()
        this.loading = false
        this.close()
        if (res.data.authorize_url) {
          this.loginUrl = res.data.authorize_url
          if (this.isApp) {
            await this.openInApp(this.loginUrl)
          } else {
            this.openInBrowser(this.loginUrl)
          }
        }
      } catch (error) {
        console.error('Login process failed:', error)
        this.loading = false
        this.close()
      }
    },
    async openInApp(url) {
      try {
        await Browser.open({ url })
      } catch (error) {
        console.error('Failed to open browser in app:', error)
        window.open(url, '_system')
      }
    },
    openInBrowser(url) {
      window.location.href = url
    },
    handleAppUrlOpen(data) {
      const { token, type } = this.parseTokenAndType(data.url)
      this.handleMessage({ data: { token, type } })
    },
    parseTokenAndType(url) {
      const urlObj = new URL(url)
      return {
        token: urlObj.searchParams.get('token'),
        type: urlObj.searchParams.get('result_type') || urlObj.searchParams.get('type')
      }
    },
    async handleMessage(event) {
      const type = event.data.type !== undefined ? Number(event.data.type) : ''
      const token = event.data.token
      if (token) {
        this.$store.commit('setToken', token)
        await this.$store.dispatch('fetchUserProfile')
        // if (this.$store.state.profile.topics === null && !this.$store.state.isIframe) {
        //   await this.$router.replace({ name: 'recommended' })
        // } else {
        // await this.$router.replace({ name: 'chatHome' })
        // this.reloadApp()
        // }
        await this.$router.replace({ name: 'chatHome' })
        this.reloadApp()
        if (this.isApp) {
          Browser.close()
        }
      } else if (type === -1 || type === 2 || type === -2) {
        let errorMessage = ''
        switch (type) {
          case -1:
            errorMessage = this.$t('error.loginFailed')
            break
          case 2:
            errorMessage = this.$t('error.restricted')
            break
          case -2:
            errorMessage = this.$t('error.invalid')
            break
        }
        this.$message.error({
          message: errorMessage,
          customClass: 'message-error',
          duration: 5000
        })
        this.$store.dispatch('logout')
      }
    },
    handleClick() {
      this.agree = !this.agree
    },
    anime() {
      setInterval(() => {
        this.count++
        if (this.count > 2) {
          this.count = 0
        }
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">

  .logo {
  width: 33vw;
  max-width: 240px;
  margin-top: 28vh;
  margin-bottom: 6vh;
}

.login {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--black);
  text-align: center;
  display: flex;
  justify-content: center;
  color: var(--white);
  flex-wrap: wrap;
}

.pc {
  align-items: center;

  .btn {
    width: 340px;
  }
}

.item {
  width: 100%;
}

.item-text {
  display: block;
  height: 60px;
}

.wrap {
  width: calc(100% - 32px);
  max-width: 720px;
  position: relative;
}

.index {
  z-index: 10;
}

.icon-box {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 6vh;
  background: var(--black);
  z-index: 100;
}

.disclaimer-box {
  display: flex;
  margin-top: 6vh;
  margin-bottom: 3vh;
  align-items: center;
}

.icon {
  font-size: 24px;
}

.nocheck {
  height: 16px;
  width: 16px;
  display: inline-block;
  border: 1px solid #dedede;
  min-width: 16px;
  border-radius: 4px;
}

.check {
  height: 16px;
  width: 16px;
  display: inline-block;
  min-width: 16px;
  border: 1px solid var(--blue);
  border-radius: 4px;
  background: var(--blue);
}

.enter {
  color: var(--black);
  font-size: 16px;
  margin-top: 0px;
}

.footer {
  height: 100vh;
  width: 100vw;
  margin-top: -32px;
  background: #171716;
  z-index: 1;
  text-align: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: var(--white);
  background: var(--red);
  border: none;
  margin-top: 14vh;
}

.dis-btn {
  background: var(--dis-red) !important;
}

.tip {
  font-family: Century-Gothic;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
  margin-left: 8px;
}

.Powered {
  font-family: Century-Gothic;
  color: #8c8c8c;
}

.login-tip {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #dedede;
}

.login-btn {
  width: 280px;
  height: 40px;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 0px;
  letter-spacing: 0px;
  color: #000 !important;
  background: #69a7dc;
  border: none;
  position: absolute;
  bottom: 0;
  top: 0;
}

.login-box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 60px;
  height: 40px;
  position: relative;
}

.login-img {
  height: 30px;
  width: auto;
}

.dialog-box {
  max-height: calc(80vh - 130vh);
}

.btn-loading {
  background: #69a7dc !important;
}

.btn-noloading {
  background: #194c76 !important;
}

::v-deep .el-dialog__body {
  background: #171716 !important;
}

::v-deep .el-dialog {
  background: #171716 !important;
}

::v-deep .title-box {
  color: #fff !important;
}

@media screen and (max-width: 900px) {
  .login-box {
    margin-top: 30px;
  }

  .login-btn {
    width: 100%;
    font-size: 15px;
  }

  .login-img {
    height: 24px;
  }

  .login-tip {
    color: #adadad;
  }
}

</style>
