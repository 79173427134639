var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "before-close": _vm.close,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("svg-icon", {
        staticClass: "showtime-close",
        attrs: { "icon-class": "close" },
        on: { click: _vm.close },
      }),
      _c(
        "div",
        {
          staticClass: "principle-key",
          staticStyle: {
            "font-weight": "600",
            "margin-bottom": "0px !important",
          },
        },
        [
          _c("span", { staticClass: "principle-line" }, [
            _vm._v(" " + _vm._s(_vm.principle.type) + " "),
          ]),
        ]
      ),
      _c("div", { staticClass: "content-box" }, [
        _c("span", { staticClass: "principle-key-content" }, [
          _vm._v(_vm._s(_vm.principle.title)),
        ]),
        _c("div", { staticClass: "principle-val-info" }, [
          _c("span", [_vm._v(_vm._s(_vm.principle.content))]),
        ]),
      ]),
      _vm.principle.link
        ? _c(
            "div",
            { staticClass: "lint-box", on: { click: _vm.linkTo } },
            [
              _vm._v(" " + _vm._s(_vm.$t("home.exploreMore")) + " "),
              _c("svg-icon", {
                staticClass: "link-right",
                attrs: { "icon-class": "share" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }