<template>
  <div class="login">

  </div>
</template>

<script>
// import { Capacitor } from '@capacitor/core'
// import { login } from '@/api/webchat'
export default {
  inject: ['reloadApp'],
  data() {
    return {
    }
  },
  created() {
    this.getToken()
  },
  mounted() {
    // this.getToken()
  },
  beforeDestroy() {

  },
  methods: {

    parseTokenAndType(url) {
      const regex = /[?&]([^=#]+)=([^&#]*)/g
      const params = {}
      let match
      while ((match = regex.exec(url))) {
        params[match[1]] = match[2]
      }
      return params
    },
    openApp(url, loginUrl) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      // 简单的移动设备检测逻辑
      const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())

      if (isMobileDevice) {
        window.location.href = url
        const onBlur = () => {
          document.removeEventListener('visibilitychange', onBlur)
          clearTimeout(failTimeout)
        }

        document.addEventListener('visibilitychange', onBlur)

        // 设置失败超时检测
        const failTimeout = setTimeout(() => {
          document.removeEventListener('visibilitychange', onBlur)
          window.location.href = loginUrl
        }, 5000)
      } else {
        window.location.href = loginUrl
      }
    },

    async getToken() {
      setTimeout(async () => {
        const data = this.parseTokenAndType(window.location.href)
        const token = this.$route.query.token || data.token
        const type = this.$route.query.result_type || data.result_type
        const url = `digitalray://login?token=${token}&type=${type}`
        const loginUrl = `/login?token=${token}&type=${type}`
        this.$nextTick(async () => {
          try {
            await window.opener.postMessage({ token: token, type: type }, '*')
            setTimeout(() => {
              window.close()
            }, 1500)
          } catch (error) {
            this.openApp(url, loginUrl)
          }
        })
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
