// const callbacksMap = {}

// const getCallbacks = (name) => {
//   let callbacks = callbacksMap[name]
//   if (!callbacks) {
//     callbacks = callbacksMap[name] = []
//   }
//   return callbacks
// }

// const storageChangeHandlerMap = {}

// const getStorageChangeHandler = (name) => {
//   let storageChangeHandler = storageChangeHandlerMap[name]
//   if (!storageChangeHandler) {
//     storageChangeHandler = storageChangeHandlerMap[name] = newStorageChangeHandler(name)
//   }
//   return storageChangeHandler
// }

// const newStorageChangeHandler = (name) => _.throttle(
//   () => {
//     const callbacks = getCallbacks(name)
//     callbacks.forEach((cb) => cb())
//   },
//   1000,
//   { trailing: true }
// )

// const monitorStorageChange = (name) => {
//   const callbacks = getCallbacks(name)
//   if (!callbacks.length) return
//   Dexie.on('storagemutated', getStorageChangeHandler(name))
// }

// const unmonitorStorageChange = (name) => {
//   const callbacks = getCallbacks(name)
//   if (callbacks.length) return
//   Dexie.on('storagemutated').unsubscribe(getStorageChangeHandler(name))
// }

// const onStorageChange = (name, callback) => {
//   const callbacks = getCallbacks(name)
//   if (!callback || callbacks.includes(callback)) return
//   callbacks.push(callback)
//   monitorStorageChange(name)
// }

// const offStorageChange = (name, callback) => {
//   const callbacks = getCallbacks(name)
//   if (!callback) return
//   const index = callbacks.indexOf(callback)
//   if (index === -1) return
//   callbacks.splice(index, 1)
//   unmonitorStorageChange(name)
// }

// export { onStorageChange, offStorageChange }
