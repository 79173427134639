var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "box",
      staticClass: "chat-input",
      class: [_vm.message.length > 900 ? "showLimit" : ""],
    },
    [
      _vm.showInput
        ? _c("el-input", {
            ref: "input",
            attrs: {
              id: "v-step-8",
              placeholder: _vm.isFocused ? "" : _vm.$t("footer.ask"),
              maxlength: "1000",
              "show-word-limit": _vm.message.length > 900,
              type: "textarea",
              autosize: { minRows: 1, maxRows: 4 },
              resize: "none",
            },
            on: {
              focus: _vm.adjustInputPosition,
              blur: _vm.resetInputPosition,
            },
            nativeOn: {
              keydown: function ($event) {
                return _vm.handleKeyUp.apply(null, arguments)
              },
            },
            model: {
              value: _vm.message,
              callback: function ($$v) {
                _vm.message = $$v
              },
              expression: "message",
            },
          })
        : _vm._e(),
      _vm.isApp
        ? _c(
            "div",
            {
              staticClass: "suffix",
              attrs: { slot: "suffix" },
              on: { touchstart: _vm.handleSend },
              slot: "suffix",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-send",
                  class: [_vm.canSned ? "noSent" : "canSent"],
                  attrs: {
                    slot: "suffix",
                    "data-action": "send",
                    id: "v-step-10",
                    type: "text",
                    disabled: !_vm.canSned,
                  },
                  slot: "suffix",
                },
                [
                  _c("svg-icon", {
                    staticClass: "send",
                    attrs: { "icon-class": "send" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "suffix",
              attrs: { slot: "suffix" },
              on: { click: _vm.handleSend },
              slot: "suffix",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-send",
                  class: [_vm.canSned ? "noSent" : "canSent"],
                  attrs: {
                    slot: "suffix",
                    "data-action": "send",
                    id: "v-step-10",
                    type: "text",
                    disabled: !_vm.canSned,
                  },
                  slot: "suffix",
                },
                [
                  _c("svg-icon", {
                    staticClass: "send",
                    attrs: { "icon-class": "send" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }