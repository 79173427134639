var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-dialog" },
    [
      _c(
        "Dialog",
        {
          ref: "initDom",
          attrs: { title: _vm.$t("login.login"), full: false },
        },
        [
          _c("div", { staticClass: "dialog-box" }, [
            _c("span", { staticClass: "login-tip" }, [
              _vm._v(_vm._s(_vm.$t("login.loginTip"))),
            ]),
            _c("img", {
              staticClass: "login-img",
              attrs: { src: require("../../assets/images/py.png"), alt: "" },
            }),
            _c(
              "div",
              { staticClass: "login-box" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    class: [_vm.loading ? "btn-noloading" : "btn-loading"],
                    attrs: {
                      icon: _vm.loading ? "el-icon-loading" : "",
                      disabled: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.login.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("login.continue")))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      !_vm.isApp
        ? _c(
            "div",
            { staticClass: "login pc" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  class: [_vm.agree ? "" : "dis-btn"],
                  attrs: { disabled: !_vm.agree },
                  on: { click: _vm.open },
                },
                [_vm._v("Chat with me")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isApp
        ? _c("div", { staticClass: "login" }, [
            _c(
              "div",
              { staticClass: "wrap index" },
              [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: require("../../assets/images/Digital Ray.png"),
                    alt: "",
                  },
                }),
                _vm._l(_vm.list, function (item, index) {
                  return _c("div", { key: item.label }, [
                    index === _vm.count
                      ? _c("div", { staticClass: "item" }, [
                          _c("span", {
                            staticClass:
                              "item-text animate__animated animate__fadeInUp",
                            domProps: { innerHTML: _vm._s(item.label) },
                          }),
                          _c(
                            "div",
                            { staticClass: "icon-box" },
                            [
                              _c("svg-icon", {
                                staticClass:
                                  "icon animate__animated animate__fadeIn",
                                attrs: { "icon-class": item.icon },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticClass: "footer" }, [
              _c(
                "div",
                { staticClass: "wrap" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      class: [_vm.agree ? "" : "dis-btn"],
                      attrs: { disabled: !_vm.agree },
                      on: { click: _vm.open },
                    },
                    [_vm._v(_vm._s(_vm.$t("login.btn")))]
                  ),
                  _c("div", { staticClass: "disclaimer-box" }, [
                    !_vm.agree
                      ? _c("div", {
                          staticClass: "nocheck",
                          on: { click: _vm.handleClick },
                        })
                      : _vm._e(),
                    _vm.agree
                      ? _c(
                          "div",
                          {
                            staticClass: "check",
                            on: { click: _vm.handleClick },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "enter",
                              attrs: { "icon-class": "enter" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", [
                      _c("span", {
                        staticClass: "tip",
                        domProps: { innerHTML: _vm._s(_vm.disclaimer) },
                      }),
                    ]),
                  ]),
                  _c("span", { staticClass: "Powered" }, [
                    _vm._v(
                      "Powered by VR OPCO LTD (V " +
                        _vm._s(_vm.$store.state.version) +
                        ")"
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }