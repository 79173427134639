var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "setting-dropdown", class: _vm.isPhone ? "st-phone" : "" },
      [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("common.notifications"))),
        ]),
        _c(
          "div",
          { staticClass: "version-wrap" },
          _vm._l(_vm.$t("version"), function (item, index) {
            return _c(
              "div",
              { key: item, staticClass: "version-item" },
              [
                !_vm.$store.state.isMin900Width
                  ? _c("div", { staticClass: "line-box" }, [
                      _c("div", { staticClass: "line" }),
                      _c("span", { staticClass: "date" }, [
                        _vm._v(_vm._s(item.date)),
                      ]),
                      _c("div", { staticClass: "line" }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "version-box" }, [
                  _c("div", {
                    class: index === 0 ? "new-point" : "mobile-point",
                  }),
                  index === 0
                    ? _c("span", { staticClass: "new" }, [
                        _vm._v("(" + _vm._s(_vm.$t("common.new")) + ")"),
                      ])
                    : _vm._e(),
                  _c("span", { staticClass: "version" }, [
                    _vm._v(
                      _vm._s(_vm.$t("common.version") + " " + item.version)
                    ),
                  ]),
                  _vm.$store.state.isMin900Width
                    ? _c("div", { staticClass: "time-box" }, [
                        _c("span", { staticClass: "date" }, [
                          _vm._v(_vm._s(item.date)),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._l(item.list, function (items) {
                  return _c("div", { key: items, staticClass: "list" }, [
                    _c("div", { staticClass: "item-box" }, [
                      _c("div", { staticClass: "point" }),
                      _c("span", [_vm._v(_vm._s(items))]),
                    ]),
                  ])
                }),
              ],
              2
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }