var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible || _vm.closing,
          expression: "visible || closing",
        },
      ],
      staticClass: "app-dialog tour-overlay",
    },
    [
      _c(
        "Transition",
        {
          attrs: {
            name: "custom-classes",
            "enter-active-class": _vm.enterClass,
            "leave-active-class": _vm.leaveClass,
          },
        },
        [
          _vm.visible || _vm.closing
            ? _c(
                "div",
                {
                  staticClass: "dialog",
                  class: _vm.full ? "full" : "",
                  style: {
                    transform: _vm.$store.state.isMin900Width
                      ? `translate(0, ${_vm.translateY}px)`
                      : "",
                    transition: _vm.transitionStyle,
                    marginLeft:
                      !_vm.$store.state.isMin900Width &&
                      _vm.$store.state.showSide
                        ? "130px"
                        : "0px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "touch-box",
                      on: {
                        touchstart: _vm.onTouchStart,
                        touchmove: _vm.onTouchMove,
                        touchend: _vm.onTouchEnd,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "titel-box",
                          class: _vm.title ? "" : "notitle",
                        },
                        [
                          _vm.titleType === "flag"
                            ? _c(
                                "div",
                                { staticClass: "flag-title" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "flag-icon",
                                    attrs: { "icon-class": "flag" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "title flag-title" },
                                    [_vm._v(_vm._s(_vm.title))]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                { staticClass: "title title-center" },
                                [_vm._v(_vm._s(_vm.title))]
                              ),
                          _c(
                            "div",
                            {
                              staticClass: "close-box",
                              on: { click: _vm.close },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "close-icon",
                                attrs: { "icon-class": "close" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "dialog-content-wrap" },
                    [_vm._t("default")],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }