var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      class: _vm.full ? "full" : "nofull",
      attrs: {
        visible: _vm.dialogVisible,
        "before-close": _vm.close,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "title-box" },
        [
          _vm.$store.state.isMin900Width && _vm.full
            ? _c("svg-icon", {
                staticClass: "dialog-back",
                attrs: { "icon-class": "right" },
                on: { click: _vm.close },
              })
            : _vm._e(),
          _c("span", { staticClass: "faq-title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div"),
          !_vm.$store.state.isMin900Width || !_vm.full
            ? _c("svg-icon", {
                staticClass: "showtime-close",
                attrs: { "icon-class": "close" },
                on: { click: _vm.close },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("vue-markdown", { attrs: { source: _vm.content } }),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }