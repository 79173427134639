var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$store.state.isMin900Width
    ? _c(
        "div",
        { staticClass: "my-principle" },
        [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("intro.principlePre")) + " "),
          ]),
          _c("svg-icon", {
            staticClass: "close-icon",
            attrs: { "icon-class": "add-p" },
          }),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("intro.principleNext")) + " "),
          ]),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "my-principle" },
        [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("intro.principleAppPre")) + " "),
          ]),
          _c("svg-icon", {
            staticClass: "close-icon",
            attrs: { "icon-class": "add-p" },
          }),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("intro.principleAppNext")) + " "),
          ]),
          _c("svg-icon", {
            staticClass: "close-icon",
            attrs: { "icon-class": "add-p" },
          }),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("intro.principleAppLast")) + " "),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }