<template>
  <div class="faq-warp">
    <div class="title-box">
      <svg-icon
        class="dialog-back"
        icon-class="right"
        @click="close"
        v-if="$store.state.isMin900Width"
      />
      <p class="faq-title">{{ $t("home.faq") }}</p>
      <div></div>
      <svg-icon
        class="showtime-close"
        icon-class="close"
        @click="close"
        v-if="!$store.state.isMin900Width"
      />
    </div>

    <div class="content">
      <Faq></Faq>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Faq: () => import('./content.vue')
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$router.push({
        name: 'chatHome'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  min-height: calc(100% - env(safe-area-inset-bottom) - 20px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  margin-top: 0px;
}
.title-box {
  background: var(--s-bg);
  color: var(--text);
  height: 100px;
  display: flex;
  justify-content: space-between;

  font-size: 15px;
  line-height: 20px;
  align-items: center;
  padding: 0 120px;
  border-bottom: 0.5px solid var(--br);
}
.dialog-back {
  transform: rotate(180deg);
  cursor: pointer;
  font-size: 18px;
  color: var(--text);
}
.faq-warp {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: env(safe-area-inset-top);
  background: var(--bg);
}
.faq-title {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0em;
  font-weight: 600px;
  text-align: left;
}
.showtime-close {
  cursor: pointer;
  margin-right: 5px;
}

@media screen and (max-width: 900px) {
  .content {
    min-height: calc(100% - env(safe-area-inset-bottom) - 20px);
  }
  .title-box {
    height: 40px;
    padding: 0 16px;
  }
  .faq-title {
    font-size: 15px;
    font-weight: 500px !important;
    color: var(--text);
  }
}
</style>
