<template>
  <div>
    <div
      class="menu-box"
      :class="[
        $store.state.homeScroll
          ? 'scroll animate__animated animate__fadeInDown'
          : 'noScroll',
      ]"
    >
      <img src="../../assets/images/Digital Ray.png" alt="" />
      <div class="btn-box">
        <div class="container borderXwidth">
          <div
            class="item-box"
            :key="index"
            @click="clickMenu(item, index)"
            v-for="(item, index) in list"
          >
            <a>{{ item.label }}</a>
            <div :class="[$route.name == item.name ? 'on' : '']"></div>
          </div>
        </div>
        <el-button class="btn" @click="$router.replace({ name: 'login' })"
          >Log In</el-button
        >
      </div>
    </div>
    <div
      class="mobile-box"
      :class="[$store.state.homeScroll && !menuActive ? 'scroll' : 'noScroll']"
    >
      <img src="../../assets/images/Digital Ray.png" alt="" />
      <div
        class="button_container"
        :class="[menuActive ? 'active' : '']"
        @click="handleClick"
      >
        <span class="top"></span><span class="middle"></span
        ><span class="bottom"></span>
      </div>
      <div class="overlay" id="overlay" :class="[menuActive ? 'open' : '']">
        <nav class="overlay-menu">
          <ul>
            <li
              :key="index"
              @click="clickMobileMenu(item, index)"
              v-for="(item, index) in list"
            >
              <a>{{ item.label }}</a>
            </li>

            <li @click="login"><a>Log In</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuActive: false
    }
  },
  computed: {
    list() {
      const res = [
        {
          label: 'Home',
          name: 'home'
        },
        {
          label: 'FAQ',
          name: 'faq'
        },
        {
          label: 'Pricing',
          name: 'pricing'
        }
      ]

      return res
    }
  },
  methods: {
    clickMenu(item, index) {
      this.$router.replace({
        name: item.name
      })
    },
    clickMobileMenu(item, index) {
      this.$router.replace({
        name: item.name
      })
      this.handleClick()
    },
    login() {
      this.$router.replace({ name: 'login' })
      this.handleClick()
    },
    handleClick() {
      this.menuActive = !this.menuActive
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-box {
  height: 120px;
  display: flex;
  justify-content: space-between;
  padding: 40px 120px 40px 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 40px;
    width: auto;
  }
  .btn {
    font-size: 13px;
    font-weight: 400;
    line-height: 0;
    text-align: center;
    padding: 4px 8px;
    height: 28px;
    color: var(--white);
    border: 1px solid var(--white);
    background: none;
    border-radius: 28px;
  }
}
.btn-box {
  display: flex;
  align-items: center;
}
div.container {
  margin: 0 auto;
  text-align: center;
}
.container {
  display: flex;
}
.item-box {
  position: relative;
}

div.container a {
  color: #fff;
  text-decoration: none;
  font: 15px;
  margin: 0px 24px;
  padding: 6px 6px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  font-weight: 400;
}

div.borderXwidth a:after {
  position: absolute;
  opacity: 0;
  width: 0%;
  height: 2px;
  content: "";
  background: #fff;
  transition: all 0.3s;
}

div.borderXwidth a:after {
  left: 20%;
  bottom: 0px;
}

div.borderXwidth a:hover:after {
  opacity: 1;
  width: 60%;
}
.on {
  opacity: 1;
  width: calc(100% - 48px);
  transform: scaleX(0.6);
  margin-top: 4px;
  margin-left: 24px;
  height: 2px;
  content: "1";
  background: #fff;
  position: absolute;
}
.scroll {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
}
.noScroll {
  background: rgba(255, 255, 255, 0);
}

.mobile-box {
  height: 65px;
  z-index: 100;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  img {
    height: 27px;
  }
}

$color-background: #963434;
$color-main: #fff;
$color-active: var(--black);
$color-link: var(--black);

$button-height: 27px;
$button-width: 35px;

body {
  background: $color-background;
}

h1 {
  position: relative;
  text-align: center;
}

.button_container {
  position: fixed;
  top: 20px;
  right: 20px;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $color-active;
    }
    .middle {
      opacity: 0;
      background: $color-active;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $color-active;
    }
  }

  span {
    background: $color-main;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

.overlay {
  position: fixed;
  background: $color-main;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;

  &.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;

      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.5s;
      }
    }
  }
  nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    font-weight: 400;
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 4);
      min-height: 50px;
      position: relative;
      opacity: 0;

      a {
        display: block;
        position: relative;
        color: $color-link;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;

        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: $color-link;
          transition: 0.35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .menu-box {
    padding: 40px 20px;
  }
}
@media screen and (max-width: 900px) {
  .menu-box {
    display: none;
  }
}
@media screen and (min-width: 900px) {
  .mobile-box {
    display: none;
  }
}
</style>
