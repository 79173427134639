<template>
  <div class="profile-box">
    <div class="loading-box" v-if="pageLoading">
      <Loading :loading="pageLoading"></Loading>
    </div>
    <div class="box-wrap" v-else>
      <div class="user-title-box">
        <span class="title">{{ $t("user.profile") }}</span>
      </div>

      <div class="tabs-container" v-if="!$store.state.isMin900Width">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.name"
            :name="item.name"
            v-for="(item, index) in list"
            :key="index"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="tabs-container m-tab-box" v-else>
        <div
          v-for="(item, index) in list"
          :key="index"
          @click.stop="handleClick({ item: item, name: item.name, index: index })"
          class="m-tab"
          :class="{ active: itemIndex === index }"
        >
          <span :class="{ 'active-name': itemIndex === index }">
            {{ item.name }}
          </span>

        </div>
      </div>

      <div v-if="!list[itemIndex]?.values?.length > 0" class="no-data">
        <span>{{ $t("user.profileNodata") }}</span>
      </div>
      <div class="item-box" v-if="list[itemIndex]">
        <div
          class="item"
          v-for="(item, index) in list[itemIndex].values"
          :key="index"
          :class="{
            'item-focused': focusedIndex === index,
            'edit-item': type === 'save' && $store.state.isMin900Width,
          }"
          @click.stop="onFocus(index)"

        >
          <svg-icon :icon-class="getUrl(item.name)" class="item-icon" />
          <div class="input-box">
            <span class="tip">{{ item.name }}</span>
            <span class="content-text">{{ item.value }}</span>
            <!-- <el-input
              @focus="onFocus(index)"
              @input="changeInput"
              @blur="handleBlur(index)"
              v-model="item.value"
              type="textarea"
              autosize
              resize="none"
              v-else
            ></el-input> -->
          </div>
          <div
            class="del-box"
            v-if="
              index === focusedIndex ||
              (type === 'save' && $store.state.isMin900Width)
            "
            @click.stop.prevent="handleDelete(index, $event)"
            @mousedown.prevent
          >
            <svg-icon icon-class="delete" class="del-icon" />
          </div>
        </div>
      </div>
      <span class="app-tip" v-if="$store.state.isMin900Width && list[itemIndex]?.values?.length > 0">{{ $t('user.profileAppTip') }}</span>
      <div class="btn-box" v-if="!$store.state.isMin900Width">
        <el-button
          :class="[canSave ? 'btn' : 'btn dis-btn']"
          @click="confirm"
          :loading="loading"
          :disabled="!canSave"
        >
          {{ $t("common.saveChange") }}
        </el-button>
        <span class="profile-tip">
          {{ $t("user.profileBottomTip") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from '@/api/profile'

export default {
  components: {
    Loading: () => import('@/components/Loading/index.vue')
  },
  props: {
    type: {
      type: String,
      default: 'save'
    }
  },
  data() {
    return {
      loading: false,
      pageLoading: true,
      canSave: false,
      focusedIndex: null,
      activeName: '',
      itemIndex: 0,
      svgContext: '',
      svgIcons: '',
      list: [],
      user: {
        name: '',
        topics: []
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
    this.itemIndex = 0
    this.svgContext = require.context('@/icons/svg', false, /\.svg$/)
    this.svgIcons = this.svgContext
      .keys()
      .map((icon) => icon.match(/\.\/(.*)\.svg/)[1])
    this.user.name = this.$store.state.profile.name
    this.getList()
    if (
      this.$store.state.profile.topics &&
      this.$store.state.profile.topics.length > 0
    ) {
      for (const i of this.$store.state.profile.topics) {
        for (const j of this.list) {
          if (j.val === i) {
            j.click = true
          }
        }
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  methods: {
    handleOutsideClick(event) {
      this.focusedIndex = null
    },
    handleClick(val) {
      console.log('Tab clicked:', val)
      this.focusedIndex = null
      this.itemIndex = Number(val.index)
    },
    iconExists(iconName) {
      return this.svgIcons.includes(iconName)
    },
    getUrl(url) {
      if (this.iconExists('p-' + url.replace('/', ' '))) {
        return 'p-' + url.replace('/', ' ')
      } else {
        return 'p-Default'
      }
    },
    handleDelete(index, event) {
      console.log('Delete clicked for index:', index)
      event.stopPropagation()
      event.preventDefault()
      this.del(index)
    },

    del(index) {
      console.log('Deleting item at index:', index)
      // 使用 Vue.set 或 this.$set 来确保响应式更新
      const newValues = [...this.list[this.itemIndex].values]
      newValues.splice(index, 1)
      this.$set(this.list[this.itemIndex], 'values', newValues)

      this.focusedIndex = null
      this.canSave = true
      console.log('Item deleted, list updated')
    },

    onFocus(index) {
      console.log('Focus changed to index:', index)
      if (this.type === 'save') {
        this.focusedIndex = index
      }
    },

    handleBlur(index) {
      console.log('Blur event for index:', index)
      // 使用 requestAnimationFrame 来延迟focusedIndex的重置
      requestAnimationFrame(() => {
        if (this.focusedIndex === index) {
          this.focusedIndex = null
        }
      })
    },
    getList() {
      this.list = []
      profile
        .getProfile()
        .then((res) => {
          this.pageLoading = false
          this.list = res.data
          this.activeName = this.list[0].name
          this.itemIndex = 0
        })
        .catch(() => {
          this.pageLoading = false
        })
    },

    changeInput(value) {
      console.log('Input changed:', value)
      this.canSave = true
    },
    async confirm() {
      this.loading = true
      try {
        await profile.updateProfile(this.list)
        this.$message.success({
          message: this.$t('common.profile'),
          customClass: 'message-success',
          duration: 2000
        })
        this.$emit('close')
      } catch (error) {
        console.error('Error updating profile:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
  <style lang="scss" scoped>
.profile-box {
  position: relative;
  // padding: 138px 12vw;
}

.tabs-container {
  width: 100%;
}

::v-deep .el-tabs__item {
  color: var(--ss-text);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  max-width: fit-content;
  padding: 0 20px;
}

::v-deep .el-tabs__item.is-active {
  color: var(--blue-t-text);
}

::v-deep .el-tabs__item:hover {
  color: var(--blue-t-text);
}

::v-deep .el-tabs__nav-wrap::after {
  height: 0.5px;
  background-color: var(--br);
}

::v-deep .el-tabs__active-bar {
  background-color: var(--blue-t-text);
}
.title {
  font-family: Century-Gothic;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  display: block;
  margin-bottom: 32px;
  color: var(--text);
}
.box-wrap {
  position: relative;
  width: 100%;
  margin: auto;
}
.dialog-close-box {
  width: 100%;
  text-align: right;
  margin-bottom: 40px;
  cursor: pointer;
  color: var(--text) !important;
}
.input-close-box {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 10;
  line-height: 20px;
  margin-top: 10px;
}
.topics {
  margin-top: 60px;
}
.dialog-user-box {
  img {
    width: 64px;
    height: 64px;
  }
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--text);
  display: flex;
  align-items: center;
}
.user-head {
  width: 64px;
  height: 64px;
  min-width: 64px;
  ::v-deep .text {
    font-size: 24px !important;
  }
  margin-bottom: 20px;
}
.item-box {
  overflow-x: visible;
  padding-right: 48px;
  width: calc(100% + 48px);
  max-height: calc(100vh - 435px);
  overflow: auto;
  overflow-x: hidden;
}
.item {
  border: 1px solid var(--br);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  align-items: center;
  position: relative;
}
.del-box {
  position: absolute;
  right: -48px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  // background: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.del-icon {
  color: var(--ss-text);
  font-size: 24px;
}
.able {
  color: var(--black) !important;
  border-color: var(--blue) !important;
  background: var(--blue) !important;
}
.dis-btn {
  background: var(--dis-red) !important;
  color: var(--white) !important;
}
.tip {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
  color: var(--ss-text);
}
.btn-box {
  width: 100%;
  text-align: center;
  margin: auto;
  padding-top: 24px;
  position: fixed;
  left: 0;
  bottom: 0px;
  box-shadow: 0px 10px 24px 0px #0000003d;
}
.btn {
  height: 40px;
  border-radius: 8px;
  padding: 0px 16px;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
  background: var(--red);
  border: none;
  margin: auto;
  margin-bottom: 8px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Century-Gothic";
}
.btn:hover {
  background: var(--red-hover);
}
::v-deep .el-textarea__inner {
  border: none !important;
  border-radius: 0;
  background-color: #ffffff00;
  padding: 0;
  color: var(--text);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
}
::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background-color: #ffffff00 !important;
    border-color: none !important;
    color: var(--text) !important;
    cursor: not-allowed;
}
::v-deep .el-textarea.is-disabled .el-textarea__inner{
  background-color: #ffffff00 !important;
}
::v-deep .el-textarea__inner:focus {
  border: none;
}
.item-icon {
  font-size: 32px;
  margin-right: 16px;
  color: var(--blue-t-text);
  margin-left: 6px;
}
.input-box {
  position: relative;
  width: 100%;
}
.item-focused {
  border-color: var(--blue-t-text);
}
.profile-tip {
  margin: auto;
  margin-top: 16px;
  margin-bottom: 54px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  width: 486px;
  color: var(--text);
  display: block;
}
.loading-box {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-data {
  width: 458px;
  font-family: "Century-Gothic";
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: var(--ss-text);
  margin: auto;
  margin-top: calc(50vh - 240px);
}
.edit-item-box {
  width: calc(100% - 24px);
}
.m-tab {
  position: relative;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  margin-top: 8px;
  color: var(--ss-text);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 33px;
  padding: 0 8px;
  z-index: 2;
  -webkit-tap-highlight-color: transparent; /* 防止iOS点击时的高亮 */
}
.m-tab span {
  position: relative; /* 使 z-index 生效 */
  z-index: 2; /* 确保文本在 .active 元素之上 */
}
.m-tab-box {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  cursor: pointer;
  border-bottom: 1px solid var(--br);
  margin-bottom: 32px;
  overflow-x: auto; /* 允许在小屏幕上滚动 */
  -webkit-overflow-scrolling: touch; /* 增加iOS的滚动性能 */
}
.active {

  border-bottom: 2px solid var(--blue-t-text);

}
.active-name {
  color: var(--blue-t-text) !important;
}
.content-text{
  display: block;
  color: var(--text);
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    min-height: 24px;
    line-height: 24px;
}
.app-tip{
  //styleName: Body/2;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  display: flex;
  justify-content: center;
  // margin: 0 24px;
  color: var(--ss-text);
  text-align: center;
  position: sticky;
  bottom: 0;
  text-overflow: -o-ellipsis-lastline;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
  // bottom: 0;
  // margin-bottom: calc(env(safe-area-inset-bottom) + 24px);
}
@media screen and (max-width: 900px) {

  .title {
    display: none;
  }
  .edit-item {
    width: calc(100% - 36px);
  }
  .profile-box {
    width: calc(100vw - 32px) !important;
    margin-left: 16px !important;
    padding: 0;
  }
  .no-data {
    width: 100vw;
    padding-left: 60px;
    padding-right: 60px;
    margin-left: -16px;
    span {
      max-width: 458px;
      display: inline-block;
    }
  }
  .item-box {
    max-height: calc(100vh - env(safe-area-inset-bottom) - 212px);
    min-height: calc(100vh - env(safe-area-inset-bottom) - 212px) !important;
    position: relative;
    overflow: auto;
    // overflow-x: hidden;
  }
}
@media (hover: hover) {
  .m-tab:hover .active-name {
    color: var(--blue-t-text);
  }
}
</style>
