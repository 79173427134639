<template>
  <div class="user">
    <div @click="toggleDropdown">
      <ImageCached :src="userIcon" class="user-head" />
    </div>
    <Contact ref='contact'></Contact>
    <transition name="el-zoom-in-top">
      <div class="user-dropdown" v-show="visible">
        <!-- <span :class="item.disable ? 'disable' : ''" @click="handleCommand(item.value)" v-for="item in list"
          :key="item.value" :id="item.id" v-show="!item.hidden">{{ item.label }}</span>
        <div class="user-line"></div> -->
        <!-- <span
          @click="handleClick(item.value)"
          v-for="item in dropdownItem"
          :key="item.value"
          :id="item.id"
          >{{ $t(item.label) }}</span
        >
        <div class="user-line"></div> -->
        <!-- <span @click="logout" id="v-step-logout" :disabled="$store.state.isIframe"
          :class="$store.state.isIframe ? 'disable' : ''">{{
            $t("header.logOut")
          }}</span> -->
        <div class="ud-item" v-for="udItem in list" :key="udItem.value">
          <div class="ui-content" :class="udItem.value === 'logout' && $store.state.isIframe ? 'disabled' : ''"
            @click="handleCommand(udItem.value)" v-if="!udItem.isHide">
            <div class="ui-left">
              <div class="ui-icon"><svg-icon :icon-class="udItem.icon" :class="udItem.icon" /></div>
              <div class="ui-label">{{ udItem.label }}</div>
            </div>
            <div class="ui-switch" v-if="udItem.isWithSwitch"><el-switch v-model="udItem.switchValue"
                active-color="var(--switch)" inactive-color="var(--switch-bg)" active-text="ON" inactive-text="OFF"
                @click.prevent @change="handleSwitchChange">
              </el-switch></div>
          </div>
          <div class="ui-line" v-if="udItem.isHasLine"></div>
        </div>
      </div>
    </transition>
    <ProfileDialog ref="profileDialog" :type="profileType"></ProfileDialog>
  </div>
</template>

<script>
import ImageCached from '@/components/ImageCached'

export default {
  components: {
    ImageCached,
    Contact: () => import('./contact.vue'),
    ProfileDialog: () => import('./components/ProfileDialog/index.vue')
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      profileType: 'Profile',
      list: [
        {
          icon: 'account',
          label: this.$t('header.myAccount'),
          value: 'account',
          isWithSwitch: false,
          switchValue: false
        },
        {
          icon: 'profile',
          label: this.$t('user.profile'),
          value: 'editProfile',
          isWithSwitch: false,
          switchValue: false
        },
        {
          icon: 'faq',
          label: this.$t('home.faq'),
          value: 'faq',
          isWithSwitch: false,
          switchValue: false
        },
        {
          icon: 'contact',
          label: this.$t('user.contact'),
          value: 'contact',
          isHasLine: true,
          isWithSwitch: false,
          switchValue: false
        },
        {
          icon: 'light2',
          label: this.$t('header.lightMode'),
          value: 'theme',
          isWithSwitch: true,
          switchValue: false
        },
        {
          icon: '',
          label: this.$t('header.finance'),
          value: 'finance_switch',
          isWithSwitch: true,
          switchValue: false,
          isHide: true
        },
        {
          icon: 'speaking',
          label: this.$t('header.audio'),
          value: 'audio_switch',
          isHasLine: true,
          isWithSwitch: true,
          switchValue: false,
          isHide: false
        },
        {
          icon: 'learn',
          label: this.$t('header.allowKnowYou'),
          value: 'know_you_switch',
          isWithSwitch: true,
          switchValue: true,
          isHide: false
        },
        {
          icon: 'respond',
          label: this.$t('header.guess'),
          value: 'guess_switch',
          isHasLine: true,
          isWithSwitch: true,
          switchValue: true,
          isHide: false
        },
        {
          icon: 'logout2',
          label: this.$t('header.logout'),
          value: 'logout',
          isWithSwitch: false,
          switchValue: false
        }
      ]
    }
  },
  computed: {
    userIcon() {
      if (this.$store.state.profile.name) {
        return ''
      } else {
        return require('@/assets/images/default-head.png')
      }
    },
    isPhone() {
      return this.$store.state.isMin900Width
    },
    isPrdo() {
      return process.env.VUE_APP_ENV === 'prod'
    },
    isDev() {
      return process.env.VUE_APP_ENV === 'dev'
    },
    isStage() {
      return process.env.VUE_APP_ENV === 'stage'
    }
  },
  mounted() {
    this.$root.$on('dropdown-toggled', (dropdown) => {
      if (dropdown !== this) {
        this.visible = false
      }
    })
    document.addEventListener('click', this.closeDropdownsOutside)
    // addEventListener(
    //   'click',
    //   () => {
    //     if (this.$tours.myTour.currentStep < 13) {
    //       this.visible = false
    //     }
    //   },
    //   false
    // )
  },
  methods: {
    toggleDropdown() {
      this.initItem()
      this.visible = !this.visible
      this.$root.$emit('closeDropdowns')
      if (this.visible) {
        this.$store.dispatch('fetchUserProfile').then(res => {
          this.initItem()
        })
      }
    },
    initItem() {
      const profile = this.$store.state.profile
      for (const key of ['know_you_switch', 'guess_switch', 'finance_switch', 'audio_switch']) {
        this.list.forEach((item) => {
          if (item.value === key) {
            if (profile[key] === null) {
              item.isHide = true
            } else {
              item.isHide = false
            }
            item.switchValue = profile[key]
          }
          if (item.value === 'theme') {
            item.switchValue = profile.theme === 'light'
          }
        })
      }
    },
    closeDropdownsOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.visible = false
        this.$root.$emit('closeDropdowns')
      }
    },
    handleCommand(val) {
      switch (val) {
        case 'editProfile':
          this.profileType = 'Profile'
          this.$refs.profileDialog.open()
          console.info('editProfile')
          break
        case 'faq':
          this.$router.push('/faq')
          break
        case 'contact':
          this.$refs.contact.open()
          this.visible = false
          break
        case 'logout':
          this.logout()
          break
        case 'account':
          this.profileType = 'Account'
          this.$refs.profileDialog.open()
          break
      }
    },
    handleSwitchChange() {
      const data = {}
      for (const key of ['know_you_switch', 'guess_switch', 'finance_switch', 'audio_switch']) {
        this.list.forEach((item) => {
          if (item.value === key) {
            data[key] = item.switchValue
          }
          if (item.value === 'theme') {
            data.theme = item.switchValue ? 'light' : 'dark'
          }
        })
      }
      this.$store.dispatch('updateProfile', data)
    },
    expPdf() {
      if (this.$store.state.history.length === 0) {
        return
      }
      this.$router.push({ name: 'pdf' })
    },
    handleHelp() {
      if (this.isPhone) {
        this.$store.commit('setAppHelps', true)
      } else {
        this.$store.commit('setHelps', true)
      }
    },
    logout() {
      if (this.$store.state.isIframe) {
        return
      }
      const h = this.$createElement
      this.$msgbox({
        title: `${this.$t('header.logOut')}?`,
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('header.logOutTip'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('header.logout'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.$store.dispatch('logout')
      })
    },
    setvisible(val) {
      this.visible = val
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  // background: #707070;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.user-head {
  width: 40px;
  height: 40px;
  min-width: 40px;
  // margin: 4px 8px 0px 4px;
}

.user-icon {
  cursor: pointer;
}

.user-name {
  color: var(--white);
  font-size: 15px;
  display: inline-block;
  margin-right: 12px;
}

.el-button--default:focus,
.el-button--default:hover {
  color: #666;
  border-color: #dfdfdf;
  background-color: #fdfdfd;
}

.user-dropdown {
  z-index: 300;
  border-radius: 8px;
  position: absolute;
  background-color: var(--dropdown-bg2);
  box-shadow: 0px 10px 24px 0px #00000026;
  width: max-content;
  top: 46px;
  right: 0;
  color: var(--ss-text);
  padding: 10px 10px 0;

  .ud-item {
    .ui-content {
      display: flex;
      align-items: center;
      padding: 0 8px;
      height: 36px;
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-radius: 8px;

      &.disabled {
        cursor: not-allowed !important;
        background: none !important;
        color: var(--link4);

        svg {
          stroke: var(--link4) !important;
        }

        &:hover {
          background: none !important;
          svg {
            stroke: var(--link4) !important;
            color: var(--dropdown-bg2) !important;
          }
        }
      }

      &:hover {
        background: var(--dropdown-hover-bg);

        svg {
          color: var(--dropdown-hover-bg) !important;
        }
      }

      .ui-left {
        display: flex;
        align-items: center;

        .ui-icon {
          margin-right: 8px;

          svg {
            width: 24px;
            height: 24px;
            color: var(--dropdown-bg2);
            stroke:  var(--ss-text);
          }

          .profile,
          .faq,
          .contact,
          .light2,
          .learn {
            stroke: none;
            color:  var(--ss-text) !important;
          }
        }
      }

      .ui-switch {
        margin-left: 24px;
      }
    }

    .ui-line {
      height: 1px;
      background-color: var(--link3);
      margin-bottom: 10px;
    }
  }
}

.disable {
  cursor: not-allowed !important;
  background: none !important;
  color: #bbb;
}

.drawer-content {
  padding: 20px 24px 40px 24px;
  overflow-x: hidden;
}
</style>
