<template>
  <div>
    <div class="btn-wrap">
      <div class="pc">
        <el-button
          v-if="generating"
          class="dialog-confirm"
          @click="handleStop"
          >{{ $t("footer.stopGenerating") }}</el-button
        >
      </div>
      <div class="pc">
        <!-- <el-button
          v-if="generatable"
          class="dialog-confirm"
          @click="handleRegenerate"
          >{{ $t("footer.regenerate") }}</el-button
        > -->
      </div>
      <div class="mobile">
        <svg-icon
          v-if="generating"
               @click="handleStop"
          icon-class="chat-stop"
          class="chat-icon red-btn"
        ></svg-icon>
        <!-- <svg-icon
          v-if="generatable"
              @click="handleRegenerate"
          icon-class="refresh"
          class="chat-icon"
        ></svg-icon> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    generating: {
      type: Boolean,
      default: false
    },
    generatable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      example: 0
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleStop() {
      this.$emit('stop')
    },
    handleRegenerate() {
      this.$emit('regenerate')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  padding: 4px 10px;
  font-size: 13px;
  color: var(--text);
  font-weight: 400;
  line-height: 0;
  background: #856db4;
  border-radius: 28px;
  height: 28px;
  border: none;
}
.dialog-confirm {
  padding: 4px 10px;
  background: var(--s-bg);
  color: var(--text);
  font-size: 13px;
  font-weight: 400;
  border-radius: 28px;
  line-height: 0;
  height: 28px;
  border: 1px solid var(--br);
}
.dialog-confirm-no {
  padding: 4px 10px;
  background: var(--h-text);
  color: var(--s-text);
  font-size: 13px;
  font-weight: 400;
  line-height: 0;
  border-radius: 28px;
  height: 28px;
  border: none;
  cursor: not-allowed;
}
.btn-wrap {
  display: flex;
  padding-top: 8px;
}
.pc {
  display: block;
}
.mobile {
  display: none;
}
.chat-icon{
  color: var(--text);
  font-size: 24px;
}
.red-btn{
  color: var(--red);
}
@media screen and (max-width: 900px) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>
