<template>
  <div
    class="header scroll"
  >
    <div class="header-wrap" >
      <div class="header-content">
        <div
          class="header-img-box"
        >
          <img src="../../assets/images/Digital Ray.png" alt="" />
          <div class="text-box">
            <span class="header-version">{{'V ' + $store.state.version}}</span>
            <span class="header-title" v-if="isDev">(Dev Environment)</span>
            <span class="header-title" v-if="isStage">(Staging Environment)</span>
          </div>

        </div>
        <div class="header-icon-wrap">
          <Bell></Bell>
          <!-- <Language id="v-step-2" class="header-language"></Language> -->
          <!-- <Setting ref="settingRef"></Setting> -->
          <User id="v-step-4" class="header-user" ref="userRef" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    User: () => import('./user.vue'),
    Bell: () => import('./bell.vue')
  },
  inject: ['reload'],
  computed: {
    isPrdo() {
      return process.env.VUE_APP_ENV === 'prod'
    },
    isDev() {
      return process.env.VUE_APP_ENV === 'dev'
    },
    isStage() {
      return process.env.VUE_APP_ENV === 'stage'
    },
    isPhone() {
      return this.$store.state.isMin900Width
    }
  },
  methods: {
    configModel() {
      this.$refs.drawer.open()
    },
    expPdf() {
      if (this.$store.state.history.length === 0) {
        return
      }
      this.$router.push({ name: 'pdf' })
    },
    handleHelp() {
      this.$store.commit('setHelps', true)
    },
    setSettingDropdownShow(val) {
      // const settingInstance = this.$refs.settingRef
      // if (settingInstance) {
      //   settingInstance.setDropdownShow(val)
      // }
    },
    setUserDropdownShow(val) {
      // const userInstance = this.$refs.userRef
      // if (userInstance) {
      //   userInstance.setDropdownShow(val)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  height: 64px;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  width: 100%;
  right: 0;
  top: 0;
  // padding-top: env(safe-area-inset-top);
  z-index: 10;
}
.header-img-box {
  display: flex;
  align-items: flex-end;
  // width: 280px;
}
.text-box{
  margin-bottom: 3px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.scroll {
  background: var(--black);
}
.noScroll {
  background: rgba(255, 255, 255, 0);
}
.header-wrap {
  width: 100%;
  padding: 0;
  padding: 0 24px;
  margin-top: env(safe-area-inset-top);
  background: var(--head-bg);
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  img {
    // width: 198px;
    height: 28px;
    margin-top: 4px;
  }
  .dev {
    height: 16px;
    width: auto;
    margin-left: 12px;
    margin-bottom: 0px;
  }
}
.header-icon {
  color: #fff;
  font-size: 23px;
  cursor: pointer;
  // margin-top: 28px;
}
.header-title {
  color: #9e9d9d;
  font-size: 18px;
  font-weight: 400;
  font-family: "Arial Narrow";
  // line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}
.collect {
  margin-right: 0px;
  margin-top: 7px;
}
.header-user {
  // margin-top: 4px;
}
.header-language {
  margin-top: 12px;
  margin-right: 16px;
}
.header-icon-wrap {
  display: flex;
}
.header-icon-box {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-top: 8px;
  border-radius: 50%;
  // display: flex;
  text-align: center;
  line-height: 40px;
  margin-right: 8px;
}
.header-version{

font-size: 13px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: var(--white);
margin-left: 8px;

}
.header-icon-box:hover {
  background: rgba(150, 122, 204, 0);
  border: 1px solid var(--h-text);
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.showSide {
  // margin-left: 50px;
}

.disable {
  cursor: not-allowed !important;
}

// @media screen and (max-width: 900px) {
//   .header{
//     width: 100%;
//     height: 48px;
//   }
//   .scroll{
//     background: #1a1a1a;
//   }
//   .header-title{
//     display: none;
//   }
//  .header-version{
//     display: none;
//   }

//   .header-icon-wrap{
//     display: none;
//   }
//   // .header-content{
//   //   justify-content: center;
//   //    height:47px;
//   //    align-items: center;
//   // }
//   .header-img-box{

//     img{
//       height: 22px;
//     }
//   }
//   //  .header-version{
//   //   display: none;
//   // }
// }
@media screen and (max-width: 900px) {
  .header{
    width: 100%;
    height: calc(env(safe-area-inset-top) + 48px);
  }
  .scroll{
    background: var(--black);
  }
  .noScroll{
    background: var(--black);
  }
  .header-title{
    display: none;
  }
 .header-version{
    display: none;
  }
  .header-icon-wrap{
    display: none;
  }
    .header-content{
    justify-content: center;
     height:47px;
     align-items: center;
  }
  .showSide{
    margin: 0;
  }

  .header-img-box{
    img{
      margin-top: 0px;
      height: 18px;
    }
  }
  //  .header-version{
  //   display: none;
  // }
}
</style>
