var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "principle-hints" },
    [
      _c("svg-icon", {
        staticClass: "hints-icon",
        attrs: { "icon-class": "hints" },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }