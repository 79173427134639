import { render, staticRenderFns } from "./recommended.vue?vue&type=template&id=e4ec28ea&scoped=true"
import script from "./recommended.vue?vue&type=script&lang=js"
export * from "./recommended.vue?vue&type=script&lang=js"
import style0 from "./recommended.vue?vue&type=style&index=0&id=e4ec28ea&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.27_css-loader@6.11.0_webpack@5.91.0__lodash@4.17.21__tv5ylwlogvyqkf7iidyao6zbr4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4ec28ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/dfoadmin/agent3/_work/2/s/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e4ec28ea')) {
      api.createRecord('e4ec28ea', component.options)
    } else {
      api.reload('e4ec28ea', component.options)
    }
    module.hot.accept("./recommended.vue?vue&type=template&id=e4ec28ea&scoped=true", function () {
      api.rerender('e4ec28ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/recommended.vue"
export default component.exports