<template>
  <div v-if="isOthers && showComments" class="chat-message-comments">
    <!-- <p>Please write your comments below:</p> -->
    <el-input
      v-model="draftComments"
      type="textarea"
      :rows="4"
      :placeholder="$t('footer.comment')"
      ref="commentInput"
    ></el-input>
    <div class="btn-wrap">
      <button
        class="cancel-btn"
        @click="cancelComments"

        >{{ $t("common.cancel") }}</button
      >
      <button
        class="save"
        @click="saveComments"

        >{{ $t("common.sSave") }}</button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageComments',
  props: {
    isOthers: {
      type: Boolean,
      default: false
    },
    showComments: {
      type: Boolean,
      default: false
    },
    comments: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      draftComments: ''
    }
  },
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        this.cancelComments()
      },
      deep: true
    },
    comments: {
      handler(value) {
        this.draftComments = value
      }
    },
    showComments(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.commentInput.focus()
        })
      }
    }
  },
  mounted() {
    this.draftComments = this.comments
  },
  methods: {
    cancelComments() {
      this.$emit('update:showComments', false)
      this.draftComments = this.comments
    },
    saveComments() {
      this.$emit('update:messageComments', this.draftComments)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-comments {
  background-color: #ffffff00;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  padding-right: calc(100vw / 10);
  padding-left: 60px;
  p {
    margin-bottom: 4px;
  }

  ::v-deep .el-textarea__inner {
    background-color: rgba(255, 255, 255, 0);
    color: var(--text);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    border: 1px solid var(--br);
    border-radius: 14px;
  }
  ::v-deep .el-textarea__inner:focus {
    border-color: var(--blue) !important;
  }

  // ::v-deep .el-textarea__inner:hover,
  // ::v-deep .el-input.is-active .el-textarea__inner,
  // ::v-deep .el-textarea__inner:focus {
  //   border-color: #dcdfe6;
  // }

  .btn-wrap {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .cancel-btn {
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    height: 32px;
    background: none;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
    color: var(--ss-text) !important;
    border: 0.5px solid var(--ss-text) !important;
  }
  .cancel-btn:hover {
    background: none;
    color: var(--ss-text) !important;
    border: 0.5px solid var(--ss-text) !important;
  }

  .save,.save:hover {
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    height: 32px;
    cursor: pointer;
    margin-top: 8px;
    background: var(--blue);
    border: none;
    color: var(--bg);
  }
}
::v-deep .el-textarea__inner::placeholder{
  font-family: Roboto;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--ss-text);
}
@media screen and (max-width: 900px) {
  .chat-message-comments {
    padding-right: 50px;
  }
}
</style>
