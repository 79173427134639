var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "app-box" }, [
      _c("div", { staticClass: "content-box" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(" Introduce DIGITAL RAY on 'Principles in Action' "),
        ]),
        _c("span", [
          _vm._v(
            " Our API is now available for integration with our partner product, 'Principles in Action.' Users are invited to experience enriched conversations with DIGITAL RAY within this platform, particularly on topics pertaining to principles-based discussions. "
          ),
        ]),
      ]),
      _c("img", {
        staticClass: "phone",
        attrs: { src: require("../../assets/images/phone.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }