var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-box" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Product Highlights")]),
    _c(
      "div",
      { staticClass: "content-box" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c("div", { staticClass: "boder" }, [
            _c("img", { attrs: { src: item.img, alt: "" } }),
          ]),
          _c("span", { staticClass: "sub-title" }, [
            _vm._v(_vm._s(item.title)),
          ]),
          _c("span", { staticClass: "text" }, [_vm._v(_vm._s(item.text))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }