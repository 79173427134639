var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Dialog", {
        ref: "dialog",
        attrs: { type: _vm.type, item: _vm.nowItem },
        on: { change: _vm.handleChange, edit: _vm.handleEdit },
      }),
      !_vm.$store.state.isMin900Width
        ? _c("div", {}, [
            _c(
              "div",
              { staticClass: "title-box" },
              [
                _c("span", { staticClass: "pc-title" }, [
                  _vm._v(_vm._s(_vm.$t("home.myPrinciples"))),
                ]),
                _c(
                  "el-button",
                  { staticClass: "add-btn", on: { click: _vm.add } },
                  [
                    _c("svg-icon", {
                      staticClass: "add-icon",
                      attrs: { "icon-class": "add" },
                    }),
                    _vm._v(_vm._s(_vm.$t("home.myPrinciple"))),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.list.length === 0
              ? _c("div", { staticClass: "wrap" }, [
                  _vm.loadding
                    ? _c("div", { staticClass: "page_loading" }, [
                        _c("div", { staticClass: "loader" }),
                      ])
                    : _c(
                        "div",
                        [
                          _c("span", { staticClass: "myPrinciplesTip" }, [
                            _vm._v(_vm._s(_vm.$t("home.myPrinciplesTip"))),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn pc-btn",
                              on: { click: _vm.add },
                            },
                            [_vm._v(_vm._s(_vm.$t("home.createMyPrinciples")))]
                          ),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "content-box" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item-box",
                    on: {
                      click: function ($event) {
                        return _vm.handleClick(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "item-box-title" },
                      [
                        _c("span", { staticClass: "item-title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("svg-icon", {
                          staticClass: "arrow",
                          class: item.click ? "tooltip-up" : "tooltip-down",
                          attrs: { "icon-class": "right" },
                        }),
                      ],
                      1
                    ),
                    _c("el-collapse-transition", [
                      item.click
                        ? _c("div", { staticClass: "item-content" }, [
                            !item.content
                              ? _c(
                                  "div",
                                  { staticClass: "loading-indicator" },
                                  [_c("i", { staticClass: "el-icon-loading" })]
                                )
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(item.content))]),
                            _c("div", { staticClass: "item-content-icon" }, [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.edit(item, index)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "edit" },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.edit"))),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.del(item, index)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "side-del" },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.delete"))),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.$store.state.isMin900Width
        ? _c("div", { staticClass: "userDialog" }, [
            _c(
              "div",
              { staticClass: "dialog-title-box" },
              [
                _c("svg-icon", {
                  staticClass: "dialog-back",
                  attrs: { "icon-class": "right" },
                  on: { click: _vm.close },
                }),
                _c("span", [_vm._v(_vm._s(_vm.$t("home.myPrinciples")))]),
                _c("svg-icon", {
                  staticClass: "dialog-back",
                  attrs: { "icon-class": "add" },
                  on: { click: _vm.add },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "box",
                on: {
                  touchstart: _vm.handleTouchStart,
                  touchend: _vm.handleTouchEnd,
                },
              },
              [
                _vm.list.length === 0
                  ? _c("div", { staticClass: "wrap" }, [
                      _vm.loadding
                        ? _c("div", { staticClass: "page_loading" }, [
                            _c("div", { staticClass: "loader" }),
                          ])
                        : _c(
                            "div",
                            [
                              _c("span", { staticClass: "myPrinciplesTip" }, [
                                _vm._v(_vm._s(_vm.$t("home.myPrinciplesTip"))),
                              ]),
                              _c(
                                "el-button",
                                { staticClass: "btn", on: { click: _vm.add } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("home.createMyPrinciples"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "content" },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.clickItem(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "point" }),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }