var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-box" }, [
    _c("div", { staticClass: "box-wrap" }, [
      _c("div", { staticClass: "user-title-box" }, [
        _c(
          "div",
          { staticClass: "dialog-user-box" },
          [
            _c("ImageCached", {
              staticClass: "user-head",
              attrs: { src: _vm.userIcon },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "item",
            class: { "item-focused": _vm.focusedIndex === 1 },
            on: {
              click: function ($event) {
                return _vm.onFocus(1)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", { staticClass: "tip" }, [
                  _vm._v(_vm._s(_vm.$t("user.username"))),
                ]),
                _vm.type === "edit"
                  ? _c("span", { staticClass: "content-text" }, [
                      _vm._v(_vm._s(_vm.user.name)),
                    ])
                  : _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: "",
                        resize: "none",
                        maxlength: "20",
                      },
                      on: {
                        focus: function ($event) {
                          return _vm.onFocus(1)
                        },
                        blur: function ($event) {
                          return _vm.onFocus(0)
                        },
                        input: _vm.changeInput,
                      },
                      model: {
                        value: _vm.user.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "name", $$v)
                        },
                        expression: "user.name",
                      },
                    }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "item-box" }, [
        _c(
          "div",
          { staticClass: "btn-box" },
          [
            _c(
              "el-button",
              {
                class: [_vm.canSave ? "btn" : "btn dis-btn"],
                attrs: { loading: _vm.loading, disabled: !_vm.canSave },
                on: { click: _vm.confirm },
              },
              [_vm._v(" " + _vm._s(_vm.$t("common.saveChange")) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }