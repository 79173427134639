<template>
  <div class="boarding">
    <div class="top-box wrap">
      <div class="skip">
        <el-button
          type="text"
          class="skip"
          @click="close"
          :loading="skipLoading"
          >Skip</el-button
        >
      </div>

      <img class="logo" src="../../assets/images/Digital Ray.png" alt="" />
    </div>
    <div class="content">
      <div class="wrap">
        <p class="tip">Hi, how can I call you?</p>
        <p class="name">Name</p>
        <el-input
          class="inp"
          v-model.trim="form.name"
          maxlength="20"
          :placeholder="'Name'"
        ></el-input>
        <el-button
          class="btn"
          @click="next"
          :loading="loading"
          :disabled="!canNext"
          :class="[canNext ? 'canNext' : '']"
          >Next</el-button
        >
        <span v-html="tip" class="sub-tip"></span>

        <div v-if="!form.opt" @click="handleClick" class="nocheck"></div>
        <div v-if="form.opt" @click="handleClick" class="check">
          <svg-icon icon-class="enter" class="enter" />
        </div>
        <span class="check-tip">Opt in for email updates</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from '@/api/profile'
export default {
  inject: ['reloadApp'],
  data() {
    return {
      loading: false,
      skipLoading: false,
      tip: 'By signing up, you agree to our <span style="color:#fff; font-weight: bold;">Terms of Service</span> and <span style="color:#fff; font-weight: bold;">End User License Agreement</span> and acknowledge the <span style="color:#fff; font-weight: bold;">Privacy Policy</span>.',
      form: {
        name: '',
        opt: true
      }
    }
  },
  computed: {
    canNext() {
      return this.form.name !== ''
    }
  },
  methods: {
    clickItem(item, index) {
      item.click = !item.click
      this.list.splice(index, 1, item)
      console.info(this.list)
    },
    handleClick() {
      this.form.opt = !this.form.opt
    },
    close() {
      const data = []
      this.skipLoading = true
      profile
        .setTopics({ topics: data })
        .then((res) => {
          this.skipLoading = false
          this.$router.replace({ name: 'recommended' }).then(() => {
            this.reloadApp()
          })
        })
        .catch(() => {
          this.skipLoading = false
        })
    },
    next() {
      const data = []
      for (const i of this.list) {
        if (i.click) {
          data.push(i.val)
        }
      }
      this.loading = true
      profile
        .setTopics({ topics: data })
        .then((res) => {
          this.loading = false
          this.$router.replace({ name: 'recommended' }).then(() => {
            this.reloadApp()
          })
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  margin: auto;
}
.content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 40px);
}
.boarding {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--black);
  color: var(--white);
  padding-top: calc(env(safe-area-inset-top) + 20px);

  .wrap {
    width: calc(100% - 32px);
    max-width: 720px;
    position: relative;
  }
  .logo {
    width: 33vw;
    max-width: 240px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 4.5vh;
    margin-top: 10px;
  }
  .tip {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--white);
    display: block;
    margin-bottom: 4vh;
  }
  .close-icon {
    margin-right: 4px;
  }
  .item {
    height: 56px;
    border-radius: 8px;
    border: 0.5px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    position: relative;
    cursor: pointer;
  }
  .check-box {
    position: absolute;
    right: -0.5px;
    top: -0.5px;
    width: 20px;
    height: 20px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--red);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .enter {
    font-size: 14px;
  }
  ::v-deep .el-input__inner {
    background-color: #ffffff00;
    border: 1px solid #adadad;
    height: 40px;
    color: var(--white);
  }
  .btn {
    width: 100%;
    height: 40px;

    border-radius: 8px;

    font-size: 15px;
    font-weight: 600;
    color: var(--white);
    background: var(--s-red);
    border: none;
    margin-top: 14vh;
    margin-top: 4vh;
    margin-bottom: 40px;
  }
  .able {
    color: var(--red) !important;
    border-color: var(--red) !important;
  }
  .canNext {
    background: var(--red);
    color: #fff;
    border: none;
  }
  .disable {
    // color: #707070;
  }
  .nocheck {
    height: 24px;
    width: 24px;
    display: inline-block;
    border: 1px solid #dedede;
    position: absolute;
    margin-left: -32px;
    margin-top: 0px;
    border-radius: 4px;
  }
  .check {
    height: 24px;
    width: 24px;
    display: inline-block;
    border: 1px solid var(--blue);
    position: absolute;
    margin-left: -32px;
    margin-top: 0px;
    border-radius: 4px;
    background: var(--blue);
  }
  .enter {
    color: var(--black);
    font-size: 20px;
    margin-top: 2px;
  }
  .sub-tip {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #dedede;
    display: block;
    margin-bottom: 3vh;
  }
  .tip {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
  }
  .skip {
    color: #dedede;
    text-align: right;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    cursor: pointer;
    background: none !important;
    position: absolute;
    right: 0px;
  }
  .skip:disabled {
    background: none !important;
  }
}
.name {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--white);
  margin-left: 14px;
}
.check-tip {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
::v-deep .el-button.is-loading:before {
  background-color: #70707000;
}
</style>
