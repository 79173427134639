var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isApp
    ? _c("div", { staticClass: "swiper home" }, [
        _c(
          "div",
          { ref: "transformedElement", staticClass: "swiper-wrapper" },
          [
            _c(
              "div",
              { staticClass: "swiper-slide menu" },
              [
                _c("Side", {
                  ref: "side",
                  staticClass: "side-box",
                  on: { close: _vm.close },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "swiper-slide content" },
              [
                _vm.showOverlay && _vm.opacity > 0.15
                  ? _c("div", {
                      staticClass: "tour-overlay",
                      style: { opacity: _vm.opacity },
                    })
                  : _vm._e(),
                _vm._m(0),
                !(
                  _vm.$route.name !== "chatHome" &&
                  _vm.$store.state.isMin900Width
                )
                  ? _c("div", { staticClass: "mobile-box mobile" }, [
                      _c(
                        "div",
                        { staticClass: "click-box", on: { click: _vm.open } },
                        [
                          _c("svg-icon", {
                            staticClass: "header-icon history",
                            class: [
                              _vm.$store.state.appHelps ? "appHelps" : "",
                            ],
                            attrs: { "icon-class": "new-history" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "fixed",
                            "text-align": "center",
                            width: "100%",
                            top: "22px",
                            "z-index": "100",
                            height: "0px",
                            "font-size": "10px",
                          },
                        },
                        [
                          _c("p", { staticClass: "mobile prototype" }, [
                            _vm._v(
                              "(Alpha)" + _vm._s(_vm.$store.state.deviceId)
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "right-box",
                          class: [_vm.$store.state.appHelps ? "appHelps" : ""],
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "header-icon",
                            staticStyle: {
                              "margin-right": "16px",
                              "font-size": "24px",
                            },
                            attrs: { "icon-class": "add" },
                            on: { click: _vm.add },
                          }),
                          _c(
                            "div",
                            { staticClass: "right-item" },
                            [
                              _c("svg-icon", {
                                staticClass: "header-icon",
                                attrs: { "icon-class": "menu" },
                                on: { click: _vm.user },
                              }),
                              _vm.$store.state.isShowPoint
                                ? _c("span", { staticClass: "red-point" })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("User", { ref: "user", on: { help: _vm.add } }),
                _c("Helps", {
                  on: {
                    setSettingDropdownShow: _vm.setSettingDropdownShow,
                    setUserDropdownShow: _vm.setUserDropdownShow,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "layout-box" },
                  [
                    _c("LanguageChange"),
                    _c(
                      "el-container",
                      [
                        !(
                          _vm.$route.name !== "chatHome" &&
                          _vm.$store.state.isMin900Width
                        )
                          ? _c(
                              "el-header",
                              [
                                !_vm.isLoginPage
                                  ? _c("Header", { ref: "headerRef" })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-main",
                          [_vm.isReloadAlive ? _c("router-view") : _vm._e()],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ])
    : _c(
        "div",
        { staticClass: "layout" },
        [
          _c("Side", { ref: "side", staticClass: "side-box" }),
          !(_vm.$route.name !== "chatHome" && _vm.$store.state.isMin900Width)
            ? _c("div", { staticClass: "mobile-box mobile" }, [
                _c(
                  "div",
                  { staticClass: "click-box", on: { click: _vm.open } },
                  [
                    _c("svg-icon", {
                      staticClass: "header-icon history",
                      class: [_vm.$store.state.appHelps ? "appHelps" : ""],
                      attrs: { "icon-class": "new-history" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "fixed",
                      "text-align": "center",
                      width: "100%",
                      top: "22px",
                      "z-index": "100",
                      height: "0px",
                      "font-size": "10px",
                    },
                  },
                  [
                    _c("p", { staticClass: "mobile prototype" }, [
                      _vm._v("(Alpha)" + _vm._s(_vm.$store.state.deviceId)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "right-box",
                    class: [_vm.$store.state.appHelps ? "appHelps" : ""],
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "header-icon",
                      staticStyle: {
                        "margin-right": "16px",
                        "font-size": "24px",
                      },
                      attrs: { "icon-class": "add" },
                      on: { click: _vm.add },
                    }),
                    _c(
                      "div",
                      { staticClass: "right-item" },
                      [
                        _c("svg-icon", {
                          staticClass: "header-icon",
                          attrs: { "icon-class": "menu" },
                          on: { click: _vm.user },
                        }),
                        _vm.$store.state.isShowPoint
                          ? _c("span", { staticClass: "red-point" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("Helps", {
            on: {
              setSettingDropdownShow: _vm.setSettingDropdownShow,
              setUserDropdownShow: _vm.setUserDropdownShow,
            },
          }),
          _c(
            "div",
            { staticClass: "layout-box" },
            [
              _c("LanguageChange"),
              _c(
                "el-container",
                [
                  !(
                    _vm.$route.name !== "chatHome" &&
                    _vm.$store.state.isMin900Width
                  )
                    ? _c(
                        "el-header",
                        [
                          !_vm.isLoginPage
                            ? _c("Header", { ref: "headerRef" })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-main",
                    [_vm.isReloadAlive ? _c("router-view") : _vm._e()],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menu-button" }, [
      _c("div", { staticClass: "bar" }),
      _c("div", { staticClass: "bar" }),
      _c("div", { staticClass: "bar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }