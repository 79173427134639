<template>

<div class="app-box">
    <div class="dialog-title-box">
      <svg-icon class="dialog-back" icon-class="right" @click="close"  />
      <span>{{ $t("home.editTheAnswer") }}</span>
    </div>
    <div class="content">
      <AppComments @change="handleChange"></AppComments>
    </div>
</div>
</template>

<script>

export default {
  components: {
    AppComments: () => import('./comment.vue')
  },
  data() {
    return {
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector('body')
      if (body.append) {
        body.append(this.$el)
      } else {
        body.appendChild(this.$el)
      }
    })
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val)
    },
    open() {
      this.$store.commit('setAppComment', true)
    },
    close(e) {
      this.$store.commit('setAppComment', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-box {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--s-bg);
  z-index: 3000;
  color: var(--text);
  padding: 16px;
  padding-top: 0;
}
.dialog-title-box {
  width: 100vw;
  margin-left: -16px;
  text-align: center;
  top: 16px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  color: var(--text);
  border-bottom: 0.5px solid var(--br);
  margin-top: env(safe-area-inset-top);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-back{
  font-size: 18px;
  color: var(--text);
  transform:rotate(180deg);
  text-align: left;
  position: absolute;
  left: 16px;
  margin-top: 2px;
}
</style>
