<template>
  <div :class="['chat-tips', { 'is-overflow': isOverflow }]">
    <i class="tips-icon el-icon-chat-line-square" />
    <span class="tips-text" v-if="value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOverflow: false
    }
  },
  mounted() {
    this.observeTipsWidth()
  },
  beforeDestroy() {
    this.unobserveTipsWidth()
  },
  methods: {
    observeTipsWidth() {
      if (this.resizeObserver) return
      this.resizeObserver = new ResizeObserver(() => {
        this.isOverflow = this.$el.clientWidth < this.$el.scrollWidth
      })
      this.resizeObserver.observe(this.$el)
    },
    unobserveTipsWidth() {
      if (!this.resizeObserver) return
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-tips {
  padding: 4px 6px;
  border-right: solid 1px #e5e7eb;
  border-bottom: solid 1px #e5e7eb;
  border-bottom-right-radius: 4px;
  width: max-content;
  max-width: 100%;
  min-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.is-overflow {
    border-right: none;
    // border-bottom-right-radius: 0;
  }
}
.tips-icon {
  color: #888e99;
}
.tips-text {
  color: #27272c;
  margin-left: 4px;
}
</style>
