<template>
  <div class="btn-wrap">
    <span class="hintTips">{{ $t('footer.hintTips') }}</span>
    <div
      v-for="(item, index) in $store.state.quickQuestions"
      :key="index"
      @click.stop="handleQuickQuestion(item)"
      class="item"
    >
      <Hints class="dialog-confirm">{{ item.question_translated }}</Hints>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Hints: () => import('../../../../components/Hints/index.vue')
  },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleQuickQuestion(question) {
      console.info('dianji', question)
      this.$emit('callQuickQuestion', question)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-confirm {
  // padding: 0px 8px;
  // background: var(--s-blue-bg);
  // color: var(--blue-text);
  // font-size: 13px;
  // // font-weight: 400;
  // border: none;
  // border-radius: 8px;
  // // margin-bottom: 8px;
  // margin-left: 8px;
  // // max-width: 100%;
  // width: fit-content;
  // // overflow: hidden;
  // // white-space: nowrap;
  // // text-overflow: ellipsis;
  // height: 36px;
  // border:1px solid var(--white);
  // box-shadow: 0px 0px 10px 5px rgba(255,255,255,.1);
}
.item{
  min-width: fit-content;
  margin-right: 8px;
  margin-bottom: 12px;
}
.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  // position: absolute;
  // bottom: -40px;
  // align-items:flex-end;
  // margin-left: -8px;
}
.hintTips{
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: var(--ss-text);
  display: block;
  margin-bottom: 8px;
  min-width: 100%;
}
@media screen and (max-width: 900px) {
  .btn-wrap {
    // height: 24px;
  }
   .item {
    min-width: 100%;
  }
}
</style>
