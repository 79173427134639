/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import store from "@/store";
// import { getStorage } from "./storage";

const newMessageProxy = (name, message) => {
  return new Proxy(message, {
    get: function (target, propKey, receiver) {
      // console.log(`getting ${propKey.toString()}`)
      return Reflect.get(target, propKey, receiver);
    },
    set: function (target, propKey, value, receiver) {
      const id = target.id;
      const key = propKey.toString();
      return Reflect.set(target, propKey, value, receiver);
    },
  });
};

function formatContent(messages, id) {
  if (!messages) {
    return [];
  }

  const type = []
  let array = messages
  // array = array.replace('\n', '<br>');
  array = array.split('##NEW_BUBBLE##')
    // for (let i in array) {
    //   for (let j of type) {
    //     if (array[i].indexOf(j) > -1) {
    //       let temp = array[i].split(j);
    //       array.splice(i, 1, j, temp[temp.length - 1]);
    //     }
    //   }
    // }
  // array = array.flat(Infinity);
  const filtered = array.filter((item) => {
    return item !== "";
  });
  const arrLowerCase = []
  for(let i of filtered){
    arrLowerCase.push(i.toUpperCase())
  }
  const res = [];
  for (const i in filtered) {

    const obj = {
      id: uuidv4(),
      tools: false,
      content: filtered[i],
      type: "",
      rating: 0,
      comment: "",
      showComment: false,
      record_id: id,
      reg:false,
      finish:false
    };
    if(i>0){
      if(res[res.length-1].finish === false){
        res[res.length-1].finish = true
      }
    }
    for(let j of type){
      const regExp = new RegExp(`\\${j}`, 'gi');
      if( regExp.test(obj.content)){
        // let html = `<span style="font-weight: 600;font-size: 14px;">${j}</span>`;
        // obj.content = obj.content.replace(regExp, html);
        obj.reg = true;
        obj.tools = false;
        obj.type = j;
      }
    }
    res.push(obj);
  }

  return res;
}

function getHref(content) {
  if (!content) {
    return "";
  }
  let urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[\(\)~#&\-=?\+\%/\.\w]+)?/g;
  content = content.replace(urlPattern, function(match) {
    var href = match;
    if (match.indexOf("http") == -1) {
      href = "http://" + match;
    }
    return "<a target=\"_blank\" href=\"" + href + "\">" + match + "</a>";
  });
  return content;
}


const newMessage = (
  name,
  {
    id,
    content,
    principles,
    evidence,
    extraInfo,
    label,
    mine,
    stopped,
    typing,
    finished,
    comments,
    rating,
    error,
    timestamp,
    network,
    networkError,
    networkText,
    aotFaq,
    showTimeData,
    newMessage,
    useInternet,
    originMessage,
    translateOriginMessage,
    switchStatus,
    formatContent,
    intention,
    showSwitch,
    thumbnails,
    shortContent,
    shortTranslateContent,
    shortPolish,
    shortComments,
    shortRating,
    longComments,
    longRating,
    type,
    isIntroMessage,
    haveAudio,
    aotProcessInfo,
    faq,
    contentFinished,
    isSubModuleSuccess,
    subModuleStates,
    translateContent,
    lang,
    polish,
    dest_lang,
    detect_lang,
    change_lang_alarm,
    internetRemind,
    evidences,
    display_type,
    toolHide,
    toggleMessage,
    waitForInspiration
  }
) => {
  const message = {
    id: id ? id : uuidv4(),
    content,
    principles,
    evidence,
    extraInfo,
    label,
    mine,
    stopped,
    typing,
    finished,
    comments,
    rating,
    error,
    network,
    networkError,
    networkText,
    useInternet,
    aotFaq,
    showTimeData,
    newMessage,
    originMessage,
    translateOriginMessage,
    switchStatus,
    formatContent,
    intention,
    showSwitch,
    thumbnails,
    shortContent,
    shortComments,
    shortRating,
    shortTranslateContent,
    shortPolish,
    longComments,
    longRating,
    type:type,
    isIntroMessage:isIntroMessage ? true : false,
    haveAudio:haveAudio? true : false,
    aotProcessInfo: aotProcessInfo || {
      aotSearch: false,
      aotSearchShow: false,
      matchingAotShow: false,
      aotFound: undefined,
      principleFound: undefined,
      generatedAnswer: undefined
    },
    faq,
    isSubModuleSuccess,
    subModuleStates,
    translateContent,
    lang,
    polish,
    dest_lang,
    detect_lang,
    change_lang_alarm,
    internetRemind,
    evidences,
    display_type,
    toolHide,
    toggleMessage,
    waitForInspiration,
    contentFinished: contentFinished || false,
    timestamp: timestamp || Date.now(),
  };

  // getStorage(name).messages.add(message);

  return newMessageProxy(name, message);
};

export default newMessage;

export * from "./monitor";
export * from "./storage";
export { formatContent };
