<template>
  <div ref="bubble">
    <!-- <div class="chart-bubble" v-if="isIntroMessage">
      <span v-if="this.$store.state.profile.name" class="introTitle">
        {{
          $t("home.intro", { name: this.$store.state.profile.name })
        }}
      </span>
      <span v-else class="introTitle">
        {{
          $t("home.introNoName")
        }}
      </span>
      <span class="introTip">{{ $t('home.introTip') }}</span>
      <svg-icon
        icon-class="qa"
        class="qa-icon"
        @click="handleClickQa"
      ></svg-icon>
    </div> -->

    <div v-if="loading" class="chart-bubble">
      <span class="chat-message-cursor" v-if="loading">▍</span>
    </div>
    <div class="message-content-container" v-else>
      <TTS
        @audioType="handleAudioType"
        :id="id"
        :content="value"
        :typing="typing"
        :loading="loading"
        :isSubModuleSuccess="isSubModuleSuccess"
        v-if="
          $store.state.profile.audio_switch !==null&&
          value &&
          isSubModuleSuccess !== false &&
          !type &&
          !mine &&
          !change_lang_alarm &&
          isLast&&
          !toggleMessage
        "
      ></TTS>
      <div
        v-if="
          sender === 'DFO' &&
          !mine &&
          thumbnails.length > 0 &&
          isSubModuleSuccess !== false
        "
        @touchstart="toggleSwiper(true)"
        @touchmove="toggleSwiper(true)"
        @touchend="toggleSwiper(false)"
        class="thumbnail"
        ref="thumbnail"
      >
        <ScrollBox v-if="!$store.state.isMin900Width">
          <Thumbnail :thumbnails="thumbnails"></Thumbnail>
        </ScrollBox>
        <Thumbnail v-else :thumbnails="thumbnails"></Thumbnail>
      </div>
      <!-- Show AOT and message process block -->
      <div
        class="chart-bubble"
        v-show="
          !mine &&
          !isIntroMessage &&
          aotProcessInfo.aotSearch &&
          showAot &&
          isSubModuleSuccess !== false
        "
      >
        <AOT :aotProcessInfo="aotProcessInfo" :aotFaq="aotFaq"></AOT>
      </div>
      <!-- Finish Show AOT and message process block -->

      <div
        class="principles"
        v-show="hasPrinciple && false"
        :class="mine ? 'mine-chart-bubble' : 'principle-bubble'"
      >
        {{ $t("home.aotTip") }}
      </div>
      <div
        v-for="(principle, index) in principles"
        :key="index"
        class="principle-bubble"
        :class="
          true ? 'principle-bubble-content' : 'principle-bubble-nolink-content'
        "
        @click="linkTo(principle)"
        v-show="isSubModuleSuccess !== false"
      >
        <!-- <el-tooltip class="item" effect="dark" content="Clickable"> -->
        <div class="principle-key">
          <span class="principle-line">
            {{ principle.type }}
          </span>
          <div v-if="principle.type!=='PRINCIPLE OF THE DAY'" class="flag-box" v-long="(val)=>addPrinciple(val,principle)" @click.stop>
            <svg-icon :icon-class="principle.saved_to_my_principle? 'flag':'add-flag'" class="add-p" />
          </div>
          <span class="principle-key-content">{{ principle.title }}</span>

        </div>
        <div class="principle-val only2">
          <span style="font-size: 13px">{{ principle.content }}</span>
        </div>
        <!-- </el-tooltip> -->
      </div>

      <div
        class="chart-bubble"
        v-if="sender === 'DFO' && network && networkError && networkText"
      >
        <span v-html="networkText"></span>
      </div>
      <div
        class="networking-bubble loading"
        v-else-if="sender === 'DFO' && networkText && typing && !value"
      >
        {{ networkText }}
      </div>

      <div
        class="chart-bubble"
        v-show="!aotProcessInfo.aotSearch"
        v-if="
          (formatContent.length == 0 && sender !== 'DFO') ||
          (!networkText && formatContent.length == 0 && typing)
        "
      >
        <span class="chat-message-cursor">▍</span>
      </div>
      <!-- <div class="chart-bubble" v-if="faq&&typing">
        <span class="chat-message-cursor">▍</span>
      </div> -->
      <div v-show="!mine && faq && faq.chooseId === 'default'">
        <div class="chart-bubble">
          {{ $t("home.faqTip") }}
          <!-- <span class="green">
            {{ $t("home.faqWidth") }}
          </span> -->
        </div>

        <FAQ
          v-if="getFaqList.length > 0"
          :tabs="getFaqList"
          @changeFaq="changeFaq"
        ></FAQ>

        <!-- <div
          class="chart-bubble green-bubble"
          v-for="(item, index) in getFaqList"
          :key="index"
          style="width: 100%"
          :class="[
            typeof item.isSubModuleSuccess === 'boolean' &&
            item.isSubModuleSuccess === false
              ? 'red'
              : '',
          ]"
        >
          <FAQ
            :faq="item"
            :message="faq.message"
            :id="faq.id"
            @change="changeFaq"
            @changeFaq="handleChangeFaq"
            :index="index"
          ></FAQ>
        </div> -->
        <!-- <div class="chart-bubble faq-box green-bubble" @click="handleRgenerate">
          <div class="green">
            {{ $t("home.findAnswer") }}
          </div>
          <div class="radio-box">
            <el-radio v-model="radio" label="regenerate" name=""></el-radio>
          </div>
        </div> -->
      </div>
      <div v-if="formatContent.length > 0">
        <div
          v-for="(item, index) in formatContent"
          :key="item.content"
          :class="[
            mine ? 'mine-chart-bubble' : 'chart-bubble markdown',
            toggleMessage? 'toggle-message-bubble':'',
            isSubModuleSuccess === false && !type && !mine ? 'red' : '',
            language !== 'en' && typing && !stopped && !polish
              ? 'translation'
              : '',
          ]"
          :style="{
            width:
              !typing && sender === 'DFO' && item.tools && item.showComment
                ? '100%'
                : 'fit-content',
          }"
        >
          <div
            :class="[
              'chat-message-markdown',
              { 'is-stopped': stopped, 'is-typing': typing },
            ]"
            v-if="isForceUpdated"
          >
            <div v-if="mine">
              <vue-markdown
                style="display: inline-block"
                :source="item.content"
                :html="false"
                :typographer="false"
              ></vue-markdown>
            </div>
            <div v-if="!mine && !isIntroMessage">
              <div v-if="typing && !stopped">
                <div v-if="item.finish">
                  <vue-markdown
                    style="display: inline-block"
                    :source="item.content"
                  ></vue-markdown>
                </div>
                <div v-else>
                  <vue-markdown
                    style="display: inline-block"
                    :source="
                      item.content +
                      '<span class=' +
                      'chat-message-cursor' +
                      '>▍</span>'
                    "
                  ></vue-markdown>
                </div>
              </div>
              <div v-else>
                <vue-markdown :source="item.content"></vue-markdown>
                <!-- <el-button
                  v-if="change_lang_alarm"
                  class="change-lang-btn"
                  @click="change_lang"
                >
                  {{ $store.state.language[$store.state.langCode] }}
                </el-button>
                <el-button
                  v-if="change_lang_alarm"
                  class="original-lang-btn"
                  @click="keep_original_lang"
                >
                  {{ $t("home." + $i18n.locale) }}
                </el-button> -->
              </div>
            </div>

            <!-- <SearchOnline
              class="logo"
              v-if="
                !typing && index == formatContent.length - 1 && internetRemind
              "
            ></SearchOnline> -->
            <Guess  :toggleMessage="toggleMessage" v-if="
                !typing && index == formatContent.length - 1 && toggleMessage &&
                sender === 'DFO' &&
                isSubModuleSuccess === false && isLast
              "
              @change="handleRegenerate"
              ref="guessRef"
              ></Guess>
            <Evidences
              @click="handleClickFaqSource"
              :evidences="evidences"
              :id="id"
              v-if="
                !typing &&
                index == formatContent.length - 1 &&
                evidences.evidences &&
                evidences.evidences.length > 0 &&
                sender === 'DFO' &&
                isSubModuleSuccess !== false
              "
            ></Evidences>
            <MyPrinciple   v-if="
                !typing &&
                index == formatContent.length - 1 &&
                principles?.length > 0 &&
                sender === 'DFO' &&
                !(evidences.evidences &&
                evidences.evidences.length > 0) &&
                isSubModuleSuccess !== false
              "></MyPrinciple>
            <div>

            </div>
            <div
              class="logo"
              v-if="
                !typing &&
                index == formatContent.length - 1 &&
                useInternet === 'internet search success' &&
                item.isSubModuleSuccess === false
              "
            >
              <svg-icon
                style="margin-left: 8px; font-size: 20px; margin-right: 4px"
                icon-class="net-search"
              />
              <span>{{ $t("home.information") }}</span>
            </div>
            <el-button
              @click="handleRegenerate"
              :loading="isLoading"
              class="regenerate"
              v-if="
                isSubModuleSuccess === false &&
                !type &&
                !mine &&
                !change_lang_alarm &&
                isLast&&
                !toggleMessage
              "
            >
              <svg-icon
                icon-class="refresh"
                class="chat-icon"
                v-if="!isLoading"
              ></svg-icon>

              {{ $t("footer.regenerate") }}</el-button
            >
          </div>
        </div>
      </div>

      <PrincipleDialog
        ref="principleDom"
        :principle="principle"
      ></PrincipleDialog>
      <Dialog
        ref="initDom"
        :content="$t('intro.content')"
        :title="$t('intro.title')"
        :full="true"
      ></Dialog>
      <MyPrincipleDialog
      ref="dialog"
      :type="principleType"
      @edit="()=>{principleType = 'edit'}"
      @change="handleChangePrinciple"
      :item="nowItem"></MyPrincipleDialog>
    </div>
  </div>
</template>

<script>
import forceUpdateMixin from '@/mixins/forceUpdateMixin'
// import metaJson from './meta.json'
import MarkdownIt from 'markdown-it'

// import { v4 as uuidv4 } from 'uuid'
import * as webchat from '@/api/webchat'

export default {
  components: {
    PrincipleDialog: () => import('./principle-dialog.vue'),
    // Principle: () => import('../PrincipleDay/index.vue'),
    Dialog: () => import('@/components/Dialog/index.vue'),
    Thumbnail: () => import('./thumbnail.vue'),
    FAQ: () => import('./faq.vue'),
    VueMarkdown: () => import('vue-markdown'),
    AOT: () => import('./aot.vue'),
    // SearchOnline: () => import('./search-online.vue'),
    Guess: () => import('./guess.vue'),
    Evidences: () => import('./evidences.vue'),
    ScrollBox: () => import('@/components/ScrollBox/index.vue'),
    TTS: () => import('./tts.vue'),
    MyPrinciple: () => import('./myPrinciple.vue'),
    MyPrincipleDialog: () => import('../../components/MyPrinciple/dialog.vue')

  },
  mixins: [forceUpdateMixin],
  inject: ['reload'],
  props: {
    id: {
      type: String,
      default: ''
    },
    polish: {
      type: Boolean,
      default: false
    },
    toolHide: {
      type: Boolean,
      default: true
    },
    internetRemind: {
      type: Boolean,
      default: false
    },
    toggleMessage: String,
    waitForInspiration: {
      type: Boolean,
      default: false
    },
    isSubModuleSuccess: {
      type: Boolean,
      default: true
    },
    type: {
      type: String
    },
    formatContent: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    showNetwork: {
      type: Boolean,
      default: true
    },
    principles: {
      type: Array,
      default: () => []
    },
    aotFaq: {
      type: Object,
      default: () => {
        return { type: null }
      }
    },
    useInternet: {
      type: String,
      default: ''
    },
    network: {
      type: Boolean,
      default: true
    },
    networkLink: {
      type: String
    },
    networkError: {
      type: Boolean,
      default: false
    },
    sender: {
      type: String,
      default: ''
    },
    networkText: {
      type: String,
      default: ''
    },
    evidence: {
      type: Array
    },
    stopped: {
      type: Boolean,
      default: true
    },
    contentFinished: {
      type: Boolean,
      default: false
    },
    mine: {
      type: Boolean,
      default: false
    },
    typing: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    change_lang_alarm: {
      type: Boolean,
      default: false
    },
    isIntroMessage: {
      type: Boolean,
      default: false
    },
    evidences: {
      type: Object,
      default: () => {
        return { evidences: [], displayType: '' }
      }
    },
    faqId: {
      type: String
    },
    sourceType: {
      type: String
    },
    faq: {
      type: Object
    },
    thumbnails: {
      type: Array,
      default: () => []
    },
    aotProcessInfo: {
      type: Object,
      default: () => {
        return {
          aotSearch: false,
          aotSearchShow: false,
          aotFound: undefined,
          matchingAotShow: false,
          principleFound: undefined,
          generatedAnswer: undefined
        }
      }
    }
  },
  data() {
    return {
      // metaData: metaJson,
      valueWithBreaks: [],
      language: this.$store.state.profile.language || 'en',
      radio: '',
      isLoading: false,
      principle: '',
      principleType: 'edit',
      nowItem: '',
      aotProcessData: {
        aotCollapse: true,
        aotProcessCollapse: true
      },
      timer: null
    }
  },
  computed: {
    isLast() {
      let res = true
      const history = this.$store.state.history
      if (history.length > 0) {
        if (history[history.length - 1].id === this.id) {
          res = true
        } else {
          res = false
        }
      } else {
        res = false
      }

      return res
    },
    customRenderer() {
      const renderer = new MarkdownIt()
      const defaultRender =
        renderer.renderer.rules.link_open ||
        function (tokens, idx, options, env, self) {
          return self.renderToken(tokens, idx, options)
        }

      renderer.renderer.rules.link_open = function (
        tokens,
        idx,
        options,
        env,
        self
      ) {
        tokens[idx].attrPush(['target', '_blank']) // 添加 target="_blank"
        return defaultRender(tokens, idx, options, env, self)
      }

      return renderer
    },
    showAot() {
      let res = true
      if (
        this.aotProcessInfo.aotFound === false ||
        this.aotProcessInfo.principleFound === false ||
        this.aotProcessInfo.generatedAnswer === false
      ) {
        res = false
      }
      return res
    },
    hasPrinciple() {
      if (!this.principles) {
        return false
      }
      return this.principles.length !== 0
    },
    getFaqList() {
      let res = []
      if (this.faq) {
        if (this.faq.list) {
          res = this.faq.list
        }
      }
      return res
    },
    hasEvidence() {
      if (!this.evidence) {
        return false
      }
      return this.evidence.length !== 0
    },
    showEvidenceIcon() {
      return this.hasEvidence && !this.typing && !this.stopped
    },
    showTitle() {
      // TODO: Disable evidence !this.mine && (this.hasEvidence || this.hasPrinciple)
      return !this.mine && this.hasPrinciple
    }
  },
  watch: {

    value() {
      this.forceUpdate()
    }
  },
  mounted() {
    this.aotProcessData.aotProcessCollapse =
      this.aotProcessInfo.generatedAnswer !== undefined
    this.$refs.bubble.addEventListener('click', (event) => {
      let target = event.target
      while (target && target.tagName !== 'A') {
        target = target.parentNode
      }
      if (target && target.href) {
        event.preventDefault()
        const href = target.getAttribute('href')
        if (href) {
          if (/^https?:\/\//.test(href)) {
            window.open(href, '_blank')
          } else {
            console.info(href)
            this.$router.replace(href)
          }
        }
      }
    })
  },
  methods: {
    handleChangePrinciple(val) {
      console.info('changePrinciple', val)
      this.nowItem = val
      if (val.action === 'add' && this.$store.state.isMin900Width) {
        this.$refs.dialog.close()
      }
      if (val.action === 'edit' && this.$store.state.isMin900Width) {
        this.principleType = 'info'
      }
      if (val.type !== 'CUSTOMIZE') {
        this.$emit('changePrinciple', val)
      }
    },
    addPrinciple(type, val) {
      if (val.saved_to_my_principle) {
        return
      }
      if (type.type === 'long' && this.$store.state.isMin900Width) {
        this.principleType = 'edit'
        this.$nextTick(() => {
          if (this.$refs.dialog) {
            console.info(val)
            this.nowItem = val
            this.$refs.dialog.open()
          }
        })
      } else if (type.type === 'click') {
        if (!this.$store.state.isMin900Width) {
          this.principleType = 'edit'
          this.$nextTick(() => {
            if (this.$refs.dialog) {
              console.info(val)
              this.nowItem = val
              this.$refs.dialog.open()
            }
          })
        } else {
          val.action = 'add'
          const data = JSON.parse(JSON.stringify(val))
          if (!data.id) {
            data.id = null
          }

          webchat.addMP(data).then(res => {
            if (res.code === 10007) {
              this.$message.warning({
                message: res.detail,
                customClass: 'message-error',
                duration: 2000
              })
              return
            }

            this.$message.success({
              message: this.$t('common.addedPrinciple'),
              customClass: 'message-success',
              duration: 2000
            })
            val.id = res.data
            this.$emit('changePrinciple', val)
          })
        }
      }
    },
    toggleSwiper(state) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (state) {
        this.$store.commit('setCanSwiper', false)
      } else {
        this.timer = setTimeout(() => {
          this.$store.commit('setCanSwiper', true)
        }, 300)
      }
    },
    handleAudioType(val) {
      this.$emit('audioType', val)
    },
    changeFaq(val) {
      const data = {
        recordId: this.id,
        id: val.id,
        answer: val.answer,
        question: val.question
      }
      this.$emit('changeFaq', data)
    },
    linkTo(val) {
      this.principle = val
      this.$refs.principleDom.open()
    },
    changeComment(val) {
      val.showComment = true
    },
    handleRegenerate() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.$emit('regenerate')
        if (this.$refs.guessRef) {
          this.$refs.guessRef.closeLoading()
        }
      }, 2000)
    },
    saveComment(val) {
      const data = {
        sessionId: val.id,
        recordId: val.record_id,
        content: val.comment,
        type: 1
      }

      webchat.comment(data)
      this.$store.commit('setSessionOtherCount')
    },
    handleRating(val, item) {
      item.rating = val
      this.saveComment(item)
    },
    handleComment(val, item) {
      item.comment = val
      item.showComment = false
      this.saveComment(item)
    },
    handleContinue() {
      this.$emit('forceResend')
    },
    handleChangeFaq(item) {
      const data = {
        faq: this.faq,
        choose: item
      }
      this.$emit('changeFaq', data)
    },
    handleRgenerate() {
      this.radio = 'regenerate'
      const data = {
        faq: this.faq
      }
      this.$emit('changeFaq', data)
    },
    handleClickQa() {
      this.$refs.initDom.open()
    },

    change_lang() {
      this.$i18n.locale = this.$store.state.langCode
      this.$store.commit('setRegenerate', true)
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])
      const data = {
        language: this.$i18n.locale
      }
      this.$store.dispatch('updateProfile', data)
      this.reload()
      this.$nextTick(() => {
        this.$emit('change')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.message-content-container {
  font-size: 15px;
}

.chat-message-markdown {
  font-size: 15px;
  font-family: "Roboto", "Century-Gothic", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.title {
  display: flex;
  justify-content: center;
  font-size: 18px;
  height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;

  .evidence-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.el-divider--horizontal {
  border-top: 1px dashed #000;
  margin: 10px 0;
}

.chat-message-cursor {
  animation: blink 1s infinite;
}
.aot-frame-blink {
  animation: blink 2s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
.logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  align-items: center;
}
.search-online {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.tools-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: -1em;
  margin-bottom: 0.5em;
  ::v-deep .chat-message-comments {
    padding: 0px;
    margin-top: 8px;
    width: 100%;
  }
}

.markdown {
  padding: 0px 16px;
}

.translation {
  color: var(--ss-text) !important;
}
.original-lang-btn {
  font-size: 13px;
  color: black;
  font-weight: 400;
  line-height: 0;
  background: #fff;
  border-radius: 28px;
  height: 28px;
  border: 1px solid var(--white);
  margin-bottom: 10px;
  margin-top: 5px;
}
.change-lang-btn {
  font-size: 13px;
  color: var(--white);
  font-weight: 400;
  line-height: 0;
  background: transparent;
  border-radius: 28px;
  height: 28px;
  border: 1px solid var(--white);
  margin-bottom: 10px;
  margin-top: 5px;
}
.faq-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.green {
  color: var(--green);
  display: block;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.radio-box {
  cursor: pointer;
  padding-left: 8px;
}
::v-deep .el-radio__inner {
  border: 1px solid var(--br);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 0;
  box-shadow: none !important;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--green);
  background: var(--green);
}
::v-deep .el-radio__label {
  padding-left: 0;
  display: none;
}
::v-deep .el-radio__inner::after {
  width: 6px;
  height: 6px;
}
.qa-icon {
  color: var(--blue);
}
.regenerate {
  height: 32px;
  border: none !important;
  color: var(--text);
  background: var(--blue);
  padding: 0 16px;
  //styleName: Subtitle /4;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 12px;
  border-radius: 8px;
}
.chat-icon {
  font-size: 13px;
  margin-right: 4px;

}
.add-p{
  font-size: 22px;
  margin-top: -2px;
}
.principle-key{
  position: relative;
}
.flag-box{
  position: absolute;
  width: 30px;
  height: 30px;
  color: var(--red);
  right: 0;
  top: -16px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .logo {
    font-size: 13px;
  }
  p {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
  .thumbnail{
    width: 100%;
    min-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 100;
  }
  .thumbnail::-webkit-scrollbar {
    display: none;
  }

}
</style>
