var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { ref: "mask", staticClass: "mask" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "callray-close-box" },
            [
              _c("svg-icon", {
                staticClass: "callray-close",
                attrs: { "icon-class": "close" },
                on: { click: _vm.callrayClose },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-icon" },
            [_c("ImageCached", { attrs: { src: _vm.icon } })],
            1
          ),
          _vm._m(0),
          this.userSpeaking
            ? _c("div", { staticClass: "sound-wave" }, [_vm._m(1)])
            : _vm._e(),
          !this.userSpeaking
            ? _c("div", [
                this.rayThinking === undefined
                  ? _c("div", { staticClass: "text-status-box" })
                  : _vm._e(),
                this.rayThinking === true
                  ? _c("div", { staticClass: "text-status-box" }, [
                      _c("p", [_vm._v("Ray is thinking...")]),
                    ])
                  : _vm._e(),
                this.rayThinking === false
                  ? _c("div", { staticClass: "text-status-box" }, [
                      _c("p", [_vm._v("Ray is speaking...")]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "hangup-wrapper" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-hang-up",
                  attrs: { disabled: this.callStatus === "connecting" },
                  on: { click: _vm.handleHangUp },
                },
                [
                  _vm._v(
                    " " + _vm._s(this.callBtnMsgDict[this.callStatus]) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "instruction" }, [
      _c("p", [
        _vm._v("Hold down the "),
        _c("b", [_vm._v('"T"')]),
        _vm._v(" key ("),
        _c("span", { staticClass: "keyboard-key" }, [_vm._v("T")]),
        _vm._v(") on your keyboard while speaking to Ray. Release the "),
        _c("b", [_vm._v('"T"')]),
        _vm._v(" key to stop recording."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sound-wave-wrapper" }, [
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }