<template>
  <div class="product-box">
    <div class="title">Product Highlights</div>
    <div class="content-box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="boder">
          <img :src="item.img" alt="" />
        </div>
        <span class="sub-title">{{ item.title }}</span>
        <span class="text">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    list() {
      const res = [
        {
          img: require('../../assets/images/product-1.png'),
          title: 'Principled Thinking Frame',
          text: "DIGITAL RAY effectively implements the 'Principled Thinking Frame', an innovation by Ray Dalio. This is achieved by aligning queries with AOTs and subsequently correlating them with specific principles."
        },
        {
          img: require('../../assets/images/product-2.png'),
          title: 'Tailored Answers to job/personality',
          text: "DIGITAL RAY is adept at customizing responses based on an individual's profession and personality traits. This allows users to understand how distinct principles can be applied specifically to their role and character, facilitating effective problem-solving."
        },
        {
          img: require('../../assets/images/product-3.png'),
          title: 'Principles Personalization',
          text: 'Daily, you will receive curated life principles aligned with your career and professional interests, which have demonstrated value for users like yourself. These personalized insights promise to enrich your daily experience with proven wisdom.'
        },
        {
          img: require('../../assets/images/product-4.png'),
          title: 'Frequently asked question (FAQ)',
          text: 'DIGITAL RAY delivers standardized responses to frequently asked questions, sourced directly from Ray Dalio. It employs sophisticated algorithms to precisely align user queries with these FAQs. On certain occasions, we present users with multiple FAQ options for enhanced clarity.'
        },
        {
          img: require('../../assets/images/product-5.png'),
          title: 'Instant access to Ray Dalio’s works',
          text: "With a single click, access foundational evidence from works like 'Principles: Life & Work', 'The Changing World Order' and 'Big Debt Crises'. Ray Dalio's books are also available for complimentary reading on our platform."
        },
        {
          img: require('../../assets/images/product-6.png'),
          title: 'Conversation with DIGITAL RAY',
          text: 'Engage in phone call with an DIGITAL RAY that emulates the voice and thought process of Ray Dalio, providing an experience akin to interacting with the esteemed expert himself.'
        },
        {
          img: require('../../assets/images/product-7.png'),
          title: 'Real - time information',
          text: 'DIGITAL RAY is sophisticatedly designed with the capability to access and furnish current information by conducting comprehensive online searches, ensuring users receive timely and relevant data at their fingertips.'
        },
        {
          img: require('../../assets/images/product-8.png'),
          title: 'inquiries Recommendation',
          text: "Navigate with ease through popular and personalized user inquiries to spark dynamic dialogues with Digital Ray. This intuitive feature enables quick engagement, providing valuable insights from our platform's wealth of shared knowledge and user interactions."
        },
        {
          img: require('../../assets/images/product-9.png'),
          title: 'Multilingual support',
          text: 'DIGITAL RAY supports the 5 most prevalent languages, enabling users from diverse linguistic backgrounds to engage with it in their preferred language.'
        },
        {
          img: require('../../assets/images/product-10.png'),
          title: 'Quality Control by Ray Himself',
          text: 'DIGITAL RAY underwent rigorous testing and was directly supervised by Ray Dalio, ensuring that the chatbot precisely reflects his concepts and tone.'
        }
      ]
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  padding: 120px 0 60px;
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 36px;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 24px;
  }
}
.boder {
  background-image: url("../../assets/images/card-border.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 360px;
  height: 280px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-box {
   display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 360px);
    grid-gap: 24px;

}
.sub-title{
font-size: 20px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
display: block;
margin-top: 24px;
margin-bottom: 8px;

}
.text{

font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.item {
  margin-right: 60px;
  margin-bottom: 60px;
  width: 360px;
    // width: 100px; height:100px;
    // background-color: skyblue;
    // margin-top: 5px;
}
@media screen and (max-width: 900px) {
  .content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
      zoom: 0.86;

  }
  .item{
    margin-right: 0;
    max-width: 360px;
  }
}
</style>
