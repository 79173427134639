var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.principles?.length > 0
    ? _c(
        "div",
        { staticClass: "day" },
        [
          _c(
            "AppDialog",
            {
              ref: "initDom",
              attrs: {
                title: _vm.$store.state.isMin900Width ? "   " : "",
                full: true,
              },
            },
            [
              _c("div", { staticClass: "day-content" }, [
                _c(
                  "div",
                  { staticClass: "principle-contet" },
                  [
                    _c(
                      "Princeple",
                      {
                        staticClass: "principle-bubble pd0",
                        attrs: {
                          count: _vm.count,
                          principles: _vm.list,
                          principle: _vm.principles[_vm.count],
                        },
                        on: { change: _vm.handleClick },
                      },
                      [
                        _vm.principles[_vm.count]?.question_list?.length > 0 &&
                        !_vm.$store.state.isMin900Width
                          ? _c(
                              "div",
                              { staticClass: "day-choose" },
                              [
                                _c("span", { staticClass: "day-tip" }, [
                                  _vm._v(_vm._s(_vm.$t("day.tip"))),
                                ]),
                                _vm._l(
                                  _vm.principles[_vm.count].question_list,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "day-choose-items",
                                        on: {
                                          click: function ($event) {
                                            return _vm.chooseItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("PrincipleHints", [
                                          _vm._v(_vm._s(item)),
                                        ]),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.principles[_vm.count]?.question_list?.length > 0 &&
                _vm.$store.state.isMin900Width
                  ? _c(
                      "div",
                      { staticClass: "day-choose" },
                      [
                        _c("span", { staticClass: "day-tip" }, [
                          _vm._v(_vm._s(_vm.$t("day.tip"))),
                        ]),
                        _vm._l(
                          _vm.principles[_vm.count].question_list,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "day-choose-items",
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseItem(item)
                                  },
                                },
                              },
                              [_c("PrincipleHints", [_vm._v(_vm._s(item))])],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                !_vm.principles[_vm.count].url
                  ? _c(
                      "div",
                      { staticClass: "lint-box", on: { click: _vm.linkTo } },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("home.exploreMore")) + " "),
                        _c("svg-icon", {
                          staticClass: "share-icon",
                          attrs: { "icon-class": "share" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.list?.length >= 1
                ? _c("div", { staticClass: "tiem-box" }, [
                    _vm.count > 0
                      ? _c(
                          "span",
                          { on: { click: _vm.pre } },
                          [
                            _c("svg-icon", {
                              staticClass: "link-left",
                              attrs: { "icon-class": "right" },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.list[_vm.count - 1].label) + " "
                            ),
                          ],
                          1
                        )
                      : _c("span"),
                    _vm.count < _vm.list?.length - 1 && _vm.count >= 0
                      ? _c(
                          "span",
                          { on: { click: _vm.next } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.list[_vm.count + 1].label) + " "
                            ),
                            _c("svg-icon", {
                              staticClass: "link-right",
                              attrs: { "icon-class": "right" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }