var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "about-wrap" }, [
      _c("div", { staticClass: "about-box" }, [
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("About Ray Dalio")]),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " In 1975, Ray Dalio founded an investment firm, Bridgewater Associates, out of his two-bedroom apartment in New York City. Over forty years later, Bridgewater has grown into the fifth most important private company in the United States, according to Fortune magazine, and Dalio himself has been named to Time magazine’s list of the 100 most influential people in the world. "
            ),
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " Along the way, Dalio discovered a set of unique principles that have led to Bridgewater’s exceptionally effective culture, which he describes as “an idea meritocracy that strives to achieve meaningful work and meaningful relationships through radical transparency.” It is these principles, and not anything special about Dalio—who grew up an ordinary kid in a middle-class Long Island neighborhood—that he believes are the reason behind his success. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "img-box" }, [
          _c("img", {
            staticClass: "img",
            attrs: { src: require("../../assets/images/about-1.png"), alt: "" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }