<template>
  <div>
    <Dialog
      ref="dialog"
      :type="type"
      @change="handleChange"
      @edit="handleEdit"
      :item="nowItem"
    ></Dialog>
    <div class="" v-if="!$store.state.isMin900Width">
      <div class="title-box">
        <span class="pc-title">{{ $t("home.myPrinciples") }}</span>
        <el-button class="add-btn" @click="add">
          <svg-icon icon-class="add" class="add-icon" />{{
            $t("home.myPrinciple")
          }}</el-button
        >
      </div>

      <div class="wrap" v-if="list.length === 0">
        <div class="page_loading" v-if="loadding">
            <div class="loader"></div>
        </div>
        <div v-else>
          <span class="myPrinciplesTip">{{ $t("home.myPrinciplesTip") }}</span>
          <el-button class="btn pc-btn" @click="add">{{
            $t("home.createMyPrinciples")
          }}</el-button>
        </div>
      </div>
      <div class="content-box">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item-box"
          @click="handleClick(item)"
        >
          <div class="item-box-title">
            <span class="item-title">{{ item.title }}</span>
            <svg-icon
              icon-class="right"
              class="arrow"
              :class="item.click ? 'tooltip-up' : 'tooltip-down'"
            />
          </div>
          <el-collapse-transition>
            <div class="item-content" v-if="item.click">
              <div v-if="!item.content" class="loading-indicator">
                <i class="el-icon-loading"></i>
              </div>
              <span>{{ item.content }}</span>
              <div class="item-content-icon">
                <div @click.stop="edit(item, index)">
                  <svg-icon icon-class="edit" />
                  <span>{{ $t("common.edit") }}</span>
                </div>
                <div @click.stop="del(item, index)">
                  <svg-icon icon-class="side-del" />
                  <span>{{ $t("common.delete") }}</span>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
    <div class="userDialog" v-if="$store.state.isMin900Width">
      <div class="dialog-title-box">
        <svg-icon class="dialog-back" icon-class="right" @click="close" />
        <span>{{ $t("home.myPrinciples") }}</span>
        <svg-icon class="dialog-back" icon-class="add" @click="add" />
      </div>
      <div
        class="box"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <div class="wrap" v-if="list.length === 0">
          <div class="page_loading" v-if="loadding">
            <div class="loader"></div>
          </div>

          <div v-else>
            <span class="myPrinciplesTip">{{
              $t("home.myPrinciplesTip")
            }}</span>
            <el-button class="btn" @click="add">{{
              $t("home.createMyPrinciples")
            }}</el-button>
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) in list" :key="index" class="item" @click="clickItem(item)">
            <div class="point"></div>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Capacitor } from '@capacitor/core'
import Dialog from './dialog.vue'
import * as webchat from '@/api/webchat'
export default {
  inject: ['reloadApp'],
  components: {
    Dialog
  },
  data() {
    return {
      dialogVisible: true,
      type: 'info',
      nowItem: '',
      list: [],
      online: false,
      startX: 0,
      endX: 0,
      loadding: true,
      edgeThreshold: 30, // 边缘阈值
      minSwipeDistance: 50 // 最小滑动距离，避免误触发
    }
  },
  watch: {
    '$store.state.isMin900Width': {
      // handler(val) {
      //   this.close()
      // },
      deep: true
    },
    dialogVisible() {
      // this.$router.go(-1)
    }
  },
  computed: {
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    }
  },
  activated() {
    this.dialogVisible = true
  },
  created() {
    this.dialogVisible = true
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleChange(val) {
      this.getList()
      this.nowItem = val
      console.info('this.type', this.type)
      if (this.type === 'edit' && this.$store.state.isMin900Width) {
        this.$nextTick(() => {
          if (this.$refs.dialog) {
            this.type = 'info'
            this.$refs.dialog.open()
          }
        })
      } else {
        this.$refs.dialog.close()
      }
    },
    getList() {
      webchat
        .getMP()
        .then((res) => {
          this.loadding = false
          this.list = []
          if (res.data && res.data?.length > 0) {
            for (const i of res.data) {
              const item = i
              item.click = false
              this.list.push({ item })
            }
            this.list = res.data
          }
        })
        .catch(() => {
          this.loadding = false
        })
    },
    handleClick(item) {
      this.nowItem = item
      this.$set(item, 'click', !item.click)
      if (item.content) {
        return
      }
      webchat.getMPInfo(item.id).then((res) => {
        this.$set(item, 'content', res.data.content)
      })
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX
    },
    handleTouchEnd(event) {
      this.endX = event.changedTouches[0].clientX
      const swipeDistance = this.endX - this.startX

      // 检查边缘左滑触发
      if (Capacitor.getPlatform() === 'ios') {
        if (
          this.startX < this.edgeThreshold &&
          swipeDistance > this.minSwipeDistance
        ) {
          this.move()
        }

        // 检查边缘右滑触发
        if (
          this.startX > window.innerWidth - this.edgeThreshold &&
          swipeDistance < -this.minSwipeDistance
        ) {
          this.move()
        }
      }
    },
    move() {
      if (this.isApp) {
        if (this.active) {
          this.init()
        } else {
          this.close()
        }
      }
    },

    close() {
      this.$router.push({
        name: 'chatHome'
      })
      this.dialogVisible = false
    },
    init() {},
    clickItem(item) {
      this.handleClick(item)
      this.$nextTick(() => {
        if (this.$refs.dialog) {
          this.type = 'info'
          this.$refs.dialog.open()
        }
      })
    },
    edit(item) {
      this.nowItem = item
      this.$nextTick(() => {
        if (this.$refs.dialog) {
          this.type = 'edit'
          this.$refs.dialog.open()
        }
      })
    },
    handleEdit() {
      console.info('========edit')
      this.type = 'edit'
    },
    del(item) {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('home.delPrinciple'),
        message: h('p', null, [
          h(
            'span',
            { style: 'var(--s-text)' },
            this.$t('home.delPrincipleTip')
          )
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        webchat.delMP(item.id).then((res) => {
          this.list = this.list.filter((listItem) => listItem.id !== item.id)
        })
      })
    },
    add() {
      this.nowItem = ''
      this.$nextTick(() => {
        if (this.$refs.dialog) {
          this.type = 'add'
          this.$refs.dialog.open()
        }
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
::v-deep .el-dialog {
  padding: 20px 16px !important;
  border-radius: 0px !important;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  margin: 0 !important;
  background: var(--s-bg);
  color: var(--text);
  position: relative;
  .el-dialog__header {
    padding: 0 !important;
    /* padding: 20px 20px 10px; */
  }

  .el-dialog__body {
    // max-width: 1200px;
    margin: auto;
    padding: 0 !important;
  }

  .el-dialog__headerbtn {
    position: absolute;
    top: 20px;
    right: 40px;
  }
}

.dialog-close-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
}
.dialog-close {
  font-size: 16px;
  color: var(--text);
  font-weight: 600;
}
.dialog-back {
  font-size: 18px;
  color: var(--text);
  transform: rotate(180deg);
  text-align: left;
}
.dialog-title-box {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 16px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  color: var(--text);
  margin-left: -16px;
  padding: 0 20px;
  border-bottom: 0.5px solid var(--br);
  // display: flex;
  // align-items: center;
}
.box {
  position: fixed;
  width: 100vw;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  height: calc(100vh - 40px - env(safe-area-inset-bottom));
  // background: red;
  overflow: auto;
  overflow-x: hidden;
}
.box::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.box::-webkit-scrollbar-thumb {
  width: 4px;
  background: var(--ss-text);
  border-radius: 35px;
}

.userDialog {
  padding: env(safe-area-inset-top) 16px 20px 16px !important;
  background: var(--s-bg);
  color: var(--text);
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.dialog-close-btn-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap {
  width: 100%;
  text-align: center;
  margin-top: calc(50vh - 90px - env(safe-area-inset-bottom));
}

.myPrinciplesTip {
  font-family: Century-Gothic;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: var(--ss-text);
  width: 233px;
  display: block;
  margin: auto;
  margin-bottom: 40px;
}
.btn,
.btn:hover {
  width: 292px;
  height: 36px;
  border-radius: 8px;
  background: var(--red);
  color: var(--white);
  border: none;
  font-family: Century-Gothic;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.item {
  display: flex;
  // align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  .point {
    width: 6px;
    height: 6px;
    min-width: 6px;
    border-radius: 50%;
    background: var(--red);
    margin-right: 8px;
    margin-top: 8px;
  }
  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }
}
.add-btn {
  height: 32px;
  padding: 8px 12px;
  border-radius: 8px;
  background: var(--red);
  color: var(--white);
  border: none;
  font-size: 13px;
}
.add-btn:hover{
  background: var(--red-hover);
}
.pc-btn:hover{
  background: var(--red-hover);
}
.add-icon {
  margin-right: 8px;
  font-size: 13px;
  color: var(--white);
}
.pc-title {
  font-family: Century-Gothic;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--text);
}
.title-box {
  width: 100%;
  padding: 0 12vw;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--br);
}
.content-box {
  padding: 0 12vw;
  min-height: calc(100vh - 86px);
  max-height: calc(100vh - 86px);
  overflow: auto;
  padding-bottom: 40px;
}
.item-box {
  padding: 32px 0px;
  width: 100%;
  border-bottom: 1px solid var(--br);
  cursor: pointer;
}
.item-box:last-child {
  border: none;
}
.item-box-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.item-title {
  //styleName: Title / 4;
  font-family: Century-Gothic;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--text);
}
.tooltip-up {
  transform: rotate(90deg);
  margin-left: 8px;
}
.tooltip-down {
  // transform: rotate(90deg);
  margin-left: 8px;
}
.item-content {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 16px;
  color: var(--ss-text);
}
.item-content-icon {
  margin-top: 16px;
  display: flex;
  span {
    margin-right: 24px;
    margin-left: 4px;
  }
}
.arrow {
  color: var(--text);
  min-width: 18px;
}
.loading-icon {
  font-size: 160px;
  color: var(--red);
  margin-top: -80px;
}

  .page_loading .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 76px;
    height: 76px;
    margin: -38px 0 0 -38px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #ff0000;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .page_loading .loader:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: inherit;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  .page_loading .loader::after {
    content: "";
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: inherit;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
@media screen and (max-width: 1200px) {
  ::v-deep .el-dialog {
    padding: 20px 16px !important;
    // padding-top: calc(env(safe-area-inset-top) + 20px);
    padding-top: calc(env(safe-area-inset-top) + 20px) !important;
  }
}
@media screen and (max-width: 900px) {
  ::v-deep .el-dialog {
    padding: 20px 16px !important;
    // padding-top: calc(env(safe-area-inset-top) + 20px);
    padding-top: calc(env(safe-area-inset-top) + 0px) !important;
  }
  .box {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
</style>
