<template>
  <div>
    <Dialog ref="dialogDom" :evidences="evidences" :id="id"></Dialog>
    <div class="faq-body" @click="handleClickFaqSource">
      <div class="wrap">
        <el-image
          v-if="info.type === 'link'"
          :src="getUrl"
          class="logo no-box"
          :fit="'contain'"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          v-if="
            (info.type === 'book' || info.type === 'rag_book') &&
            info.name === 'Principles: life and work'
          "
          :src="require('@/assets/images/book life and work.png')"
          class="logo no-box"
          :fit="'contain'"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          v-if="
            (info.type === 'book' || info.type === 'rag_book') &&
            info.name === 'Principles For Navigating BIG DEBT CRISES'
          "
          :src="require('@/assets/images/book BIG DEBT CRISES.png')"
          class="logo no-box"
          :fit="'contain'"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          v-if="
            (info.type === 'book' || info.type === 'rag_book') &&
            info.name === 'Principles for Dealing with the Changing World Order'
          "
          :src="require('@/assets/images/book Changing World Order.png')"
          class="logo no-box"
          :fit="'contain'"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          v-if="
            (info.type === 'book' || info.type === 'rag_book') &&
            info.name === 'Principles: Your Guided Journal'
          "
           :src="require('@/assets/images/book Your Guided Journal.png')"
          class="logo no-box"
          :fit="'contain'"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>

        <!-- <div
          class="logo red-box"
          v-if="info.type === 'book' || info.type === 'rag_book'"
        >
          <svg-icon class="icon" icon-class="faq-book" />
        </div> -->
        <el-image
          v-if="info.type === 'wmi' || info.type === 'rag_wmi'"
          :src="require('@/assets/images/WMI logo.png')"
          class="logo no-box"
          :fit="'contain'"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div class="logo black-box" v-show="info.type === 'rag_about_dr'">
          <svg-icon class="icon" icon-class="file" />
        </div>

        <div class="logo" v-if="evidences.evidences.length > 1">
          +{{ evidences.evidences.length - 1 }}
        </div>
        <span class="source">{{ $t("home.sourceFrom") }}</span>
        <span class="title">{{ info.name }}</span>
      </div>
      <div class="link-right">
        <svg-icon icon-class="right" class="link-right"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    Dialog: () => import('./faq-source.vue')
  },
  props: {
    evidences: {
      type: Object,
      default: () => {
        return { evidences: [], displayType: '' }
      }
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [],
      faqId: ''
    }
  },
  computed: {
    info() {
      let res = {}
      for (const i of this.evidences.evidences) {
        if (i.type === 'book' || i.type === 'rag_book') {
          res = i
          break
        }
        if (i.type === 'wmi' || i.type === 'rag_wmi') {
          res = i
          break
        }
        if (i.type === 'rag_about_dr') {
          res = i
          break
        }
        if (i.type === 'link') {
          res = i
          break
        }
      }
      return res
    },
    getUrl() {
      let res = ''
      if (this.info) {
        if (this.info.url) {
          res = this.getDomain(this.info.url)
          if (res) {
            res = res + '/favicon.ico'
          }
        }
      }
      return res
    }
  },
  watch: {},
  mounted() {},
  methods: {
    handleClickFaqSource() {
      if (this.evidences.displayType !== 'link') {
        this.$refs.dialogDom.open()
      } else {
        if (this.evidences.evidences.length > 0) {
          if (this.evidences.evidences[0].url) {
            window.open(this.evidences.evidences[0].url)
          }
        }
      }
    },
    getDomain(url) {
      let res = ''
      if (url) {
        const arr = url.split('//')[1]
        res = url.split('//')[0] + '//' + arr.split('/')[0]
      }
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;
  border-top: 0.5px solid var(--br);
  padding-top: 8px;
  position: relative;
}
.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e4e7ed00;
  z-index: 1;
}
.wrap {
  // height: 28px;
  width: 100%;
  border-radius: 34px;
  // padding: 0px 4px;
  // background: red;
  display: flex;
  align-items: center;
  width: max-content;
  flex-wrap: nowrap;
  width: calc(100% - 20px);
  .logo {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    margin-right: 4px;
    border: 2px solid var(--chart-bg);
    z-index: 1;
    margin-left: -10px;
    background: var(--blue);
    font-size: 11px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Century-Gothic";
  }
  .logo:first-child {
    z-index: 100;
    margin-left: 0;
  }
  .red-box {
    background: var(--red);
    color: var(--white);
    justify-content: center;
  }
  .no-box {
    background: none !important;
  }
  .black-box {
    background: var(--black);
    color: var(--white);
    justify-content: center;
  }
  .source {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--ss-text);
    display: block;
    min-width: fit-content;
  }
  .title {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 4px;
    color: var(--text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .date {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--s-text);
  }
}
.link-right {
  font-size: 13px !important;
  margin-left: 6px;
}
</style>
