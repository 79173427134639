var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "boarding" }, [
    _c("div", { staticClass: "top-box wrap" }, [
      _c(
        "div",
        { staticClass: "skip" },
        [
          _c(
            "el-button",
            {
              staticClass: "skip",
              attrs: { type: "text", loading: _vm.skipLoading },
              on: { click: _vm.close },
            },
            [_vm._v("Skip")]
          ),
        ],
        1
      ),
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../../assets/images/Digital Ray.png"), alt: "" },
      }),
    ]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "wrap" },
        [
          _c("p", { staticClass: "tip" }, [_vm._v("Hi, how can I call you?")]),
          _c("p", { staticClass: "name" }, [_vm._v("Name")]),
          _c("el-input", {
            staticClass: "inp",
            attrs: { maxlength: "20", placeholder: "Name" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.name",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "btn",
              class: [_vm.canNext ? "canNext" : ""],
              attrs: { loading: _vm.loading, disabled: !_vm.canNext },
              on: { click: _vm.next },
            },
            [_vm._v("Next")]
          ),
          _c("span", {
            staticClass: "sub-tip",
            domProps: { innerHTML: _vm._s(_vm.tip) },
          }),
          !_vm.form.opt
            ? _c("div", {
                staticClass: "nocheck",
                on: { click: _vm.handleClick },
              })
            : _vm._e(),
          _vm.form.opt
            ? _c(
                "div",
                { staticClass: "check", on: { click: _vm.handleClick } },
                [
                  _c("svg-icon", {
                    staticClass: "enter",
                    attrs: { "icon-class": "enter" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticClass: "check-tip" }, [
            _vm._v("Opt in for email updates"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }