<template>
  <div class="hints">
    <svg-icon icon-class="hints" class="hints-icon" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.hints{
  position: relative;
  background: linear-gradient(var(--hints-bg1) 0%, var(--hints-bg2) 100%) !important;
  color: var(--hints-text) !important;
  font-size: 13px;
  border: 0.5px solid var(--hints-br);
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 6.5px 12px;
  line-height: 20px;
  padding-left: 28px;
  width: 100%;
  cursor: pointer;
}
.hints-icon{
    font-size: 24px;
    position: absolute;
    left: 4px;
    margin-top: -2px;
    color: var(--hints-icon);
}

@media screen and (max-width: 900px) {
  .hints{
    background: linear-gradient(90deg, var(--hints-bg1) 0%, var(--hints-bg2) 100%) !important;
  }
}
</style>
