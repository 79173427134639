<template>
  <div :class="isDay ? 'princeple' : ''">
    <div class="content">
      <div
        class="principle-key"
        style="font-weight: 600"
        :style="isDay ? 'margin-bottom:0px !important' : ''"
      >
        <div class="principle-select">
          <span class="principle-line">
            {{ principle.type }}
            <Dropdown
              :option="principles"
              :label="'label'"
              @change="handleClick"
              v-if="principles?.length > 1 && !$store.state.isMin900Width"
            >
              <div class="btn-wrap">
                <span class="date">{{ principles[count].label }}</span
                ><svg-icon class="arrow-down" icon-class="down" />
              </div>
            </Dropdown>
            <div
              class="btn-wrap"
              v-if="principles?.length > 1 && $store.state.isMin900Width"
            >
              <span class="date">{{ principles[count].label }}</span>
            </div>
          </span>
        </div>

      </div>
      <div class="content-box">
        <span class="principle-key-content">{{ principle.title }}</span>
        <div class="principle-val-info" ref="textContainer"  v-if="!isDay"  :class="[showMore && !isExtend? 'only10':'',showMore? '':'buttom']">
          <span >{{ principle.content }}</span>
        </div>
        <div class="showMore" v-if="!isDay && showMore && !isExtend" @click="handleShowMore">
        <span
          >{{ $t("home.showMore")
          }}<svg-icon icon-class="down" class="tooltip-down"
        /></span>
      </div>
      <div
        class="showMore"
        v-if="!isDay && showMore && isExtend"
        @click="handleShowMore"
      >
        <span
          >{{ $t("home.showLess")
          }}<svg-icon icon-class="down" class="tooltip-up"
        /></span>
      </div>
      <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Dropdown: () => import('@/components/Dropdown/index.vue')
  },
  props: {
    principle: {
      type: Object,
      default: () => {
        return {}
      }
    },
    principles: {
      type: Array,
      default: () => {
        return []
      }
    },
    count: {
      type: Number,
      default: 0
    },
    isDay: {
      type: Boolean,
      default: false
    },
    titleFull: {
      type: Boolean,
      default: true
    },
    contentFull: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showMore: false,
      isExtend: false
    }
  },
  watch: {
    count() {
      this.checkTextLength()
    }
  },
  mounted() {
    this.showMore = false
    this.isExtend = false
    this.getTime()
    this.checkTextLength()
  },
  methods: {
    checkTextLength() {
      this.$nextTick(() => {
        const textContainer = this.$refs.textContainer
        if (textContainer && !this.isDay) {
          const lineHeight = parseFloat(
            window.getComputedStyle(textContainer).lineHeight
          )
          const containerHeight = Number(textContainer.scrollHeight - 6)
          const numberOfLines = containerHeight / lineHeight
          console.info(numberOfLines, 'numberOfLines', containerHeight)
          this.showMore = numberOfLines > 10
          this.isExtend = false
        }
      })
    },
    handleShowMore() {
      this.isExtend = !this.isExtend
    },
    getTime() {
      const data = new Date()
      console.info('time', moment(data).format('MMMM DD'))
    },
    handleClick(val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.princeple {
  min-width: 480px;
}
.date {
  font-family: Roboto;
  font-weight: 400;
}
.only10 {
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}
.showMore {
  display: block;
  color: var(--blue) !important;
  // float: right;
  width: 100%;
  text-align: right;
  cursor: pointer;
  font-size: 13px;
}
.tooltip-down {
  margin-left: 6px;
  right: 0;
}

.tooltip-up {
  margin-left: 6px;
  transform: rotate(180deg);
}
.principle-val-info {
  margin: 20px 0px 4px 0px;
}
.buttom {
  margin-bottom: 20px;
}
.btn-wrap {
  cursor: pointer;
}
.content-box{
  max-height: calc(80vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  // min-height: 500px;
}
.principle-select {
  display: flex;
  // position: fixed;
  // position: absolute;
  // align-items: center;
}

.btn-wrap{
  color: #ADADAD;
  margin-left: 12px;
}
.arrow-down {
  margin-left: 6px;
}
@media screen and (max-width: 900px) {
  .princeple {
    min-width: 250px;
  }
  .buttom {
    margin-bottom: 0px;
  }
  .content-box{
  max-height: 100%;
  overflow: visible;
  }
}

</style>
