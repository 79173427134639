<template>
  <div class="swiper home" v-if="isApp">
    <div class="swiper-wrapper" ref="transformedElement">
      <div class="swiper-slide menu">
        <Side ref="side" class="side-box" @close="close"></Side>
      </div>
      <div class="swiper-slide content">
        <div
          class="tour-overlay"
          v-if="showOverlay && opacity > 0.15"
          :style="{ opacity: opacity }"
        ></div>
        <div class="menu-button">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
        <div
          class="mobile-box mobile"
          v-if="!($route.name !== 'chatHome' && $store.state.isMin900Width)"
        >
          <div class="click-box" @click="open">
            <svg-icon
              class="header-icon history"
              :class="[$store.state.appHelps ? 'appHelps' : '']"
              icon-class="new-history"
            />
          </div>

          <div
            style="
              position: fixed;
              text-align: center;
              width: 100%;
              top: 22px;
              z-index: 100;
              height: 0px;
              font-size: 10px;
            "
          >
            <p class="mobile prototype">(Alpha){{ $store.state.deviceId }}</p>
          </div>
          <div
            class="right-box"
            :class="[$store.state.appHelps ? 'appHelps' : '']"
          >
            <svg-icon
              class="header-icon"
              style="margin-right: 16px; font-size: 24px"
              icon-class="add"
              @click="add"
            />
            <div class="right-item">
              <svg-icon class="header-icon" icon-class="menu" @click="user" />
              <span class="red-point" v-if="$store.state.isShowPoint"></span>
            </div>
          </div>
        </div>

        <User ref="user" @help="add"></User>
        <Helps
          @setSettingDropdownShow="setSettingDropdownShow"
          @setUserDropdownShow="setUserDropdownShow"
        ></Helps>

        <div class="layout-box">
          <LanguageChange></LanguageChange>
          <el-container>
            <el-header
              v-if="!($route.name !== 'chatHome' && $store.state.isMin900Width)"
            >
              <Header v-if="!isLoginPage" ref="headerRef" />
            </el-header>
            <el-main>
              <router-view v-if="isReloadAlive" />
            </el-main>
          </el-container>
        </div>
      </div>
    </div>
  </div>
  <div class="layout" v-else>
    <Side ref="side" class="side-box"></Side>
    <!-- <div class="icon-box pc" @click="open" v-if="!$store.state.showSide">
      <svg-icon icon-class="shrink" />
    </div> -->

    <div
      class="mobile-box mobile"
      v-if="!($route.name !== 'chatHome' && $store.state.isMin900Width)"
    >
      <div class="click-box" @click="open">
        <svg-icon
          class="header-icon history"
          :class="[$store.state.appHelps ? 'appHelps' : '']"
          icon-class="new-history"
        />
      </div>

      <div
        style="
          position: fixed;
          text-align: center;
          width: 100%;
          top: 22px;
          z-index: 100;
          height: 0px;
          font-size: 10px;
        "
      >
        <p class="mobile prototype">(Alpha){{ $store.state.deviceId }}</p>
      </div>
      <div class="right-box" :class="[$store.state.appHelps ? 'appHelps' : '']">
        <svg-icon
          class="header-icon"
          style="margin-right: 16px; font-size: 24px"
          icon-class="add"
          @click="add"
        />
        <div class="right-item">
          <svg-icon class="header-icon" icon-class="menu" @click="user" />
          <span class="red-point" v-if="$store.state.isShowPoint"></span>
        </div>
      </div>
    </div>

    <!-- <User ref="user" @help="add" v-if="false"></User> -->
    <Helps
      @setSettingDropdownShow="setSettingDropdownShow"
      @setUserDropdownShow="setUserDropdownShow"
    ></Helps>
    <div class="layout-box">
      <LanguageChange></LanguageChange>
      <el-container>
        <el-header
          v-if="!($route.name !== 'chatHome' && $store.state.isMin900Width)"
        >
          <Header v-if="!isLoginPage" ref="headerRef" />
        </el-header>
        <el-main>
          <router-view v-if="isReloadAlive" />
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // User: () => import('@/views/header/components/UserDialog/index.vue'),
    Header: () => import('@/views/header'),
    Helps: () => import('@/views/home/components/ChatHelps/index.vue'),
    Side: () => import('@/views/side/index.vue'),
    LanguageChange: () =>
      import('@/views/home/components/LanguageChange/index.vue')
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  watch: {
    showOverlay: {
      handler(val) {
        console.info(val, 'show')
      },
      deep: true
    }
  },
  data() {
    return {
      isReloadAlive: true,
      showOverlay: false,
      swiper: null,
      opacity: 0
    }
  },

  computed: {
    isLoginPage() {
      return this.$route.name === 'login'
    },
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    }
    // headerHeight() {
    //   return (this.isLoginPage ? 0 : 46) + 'px'
    // }
  },
  created() {},
  mounted() {
    this.$store.commit('setTourActive', false)
    this.$store.commit('setSendInput', '')
    this.init()
    this.initTransformObserver()

    // this.$store.dispatch('initTTS')
  },
  methods: {
    initTransformObserver() {
      const element = this.$refs.transformedElement
      if (element) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (
              mutation.attributeName === 'style' ||
              mutation.attributeName === 'transform'
            ) {
              const transform = getComputedStyle(element).transform
              if (transform && transform !== 'none') {
                const match = transform.match(/matrix.*\((.+)\)/)
                if (match) {
                  const values = match[1].split(', ')
                  const translateX = values[4]
                  if (translateX) {
                    this.opacity =
                      1 - Math.abs(translateX) / (window.screen.width * 0.8)
                  } else {
                    this.opacity = 0
                  }
                }
              }
            }
          })
        })

        observer.observe(element, {
          attributes: true,
          attributeFilter: ['style', 'transform']
        })
      }
    },
    init() {
      this.swiper = new Swiper('.swiper', {
        slidesPerView: 'auto',
        initialSlide: 1,
        resistanceRatio: 0,
        slideToClickedSlide: true,
        allowTouchMove: this.$store.state.canSwiper
      })
      this.swiper.on('touchStart', (swiper) => {
        this.$nextTick(() => {
          setTimeout(() => {
            if (!this.$store.state.canSwiper) {
              swiper.allowTouchMove = false
              this.showOverlay = false
            } else {
              swiper.allowTouchMove = true
              this.showOverlay = true
            }
          }, 300)
        })
      })
      this.swiper.on('touchEnd', (swiper) => {
        swiper.allowTouchMove = this.$store.state.canSwiper
      })
      this.swiper.on('touchMove', (swiper) => {
        console.info('touchMove========')
      })
      this.swiper.on('slideChangeTransitionStart', (swiper) => {
        console.info(swiper.activeIndex, 'st')
        if (this.$store.state.canSwiper) {
          this.showOverlay = true
        }
      })
      this.swiper.on('slideChange', (swiper) => {
        if (this.$store.state.canSwiper) {
          if (swiper.activeIndex === 1) {
            this.showOverlay = false
          } else if (swiper.activeIndex === 0) {
            this.showOverlay = true
          }
          this.$store.commit('setSwiperOpen', swiper.activeIndex === 0)
          this.$forceUpdate()
        }
      })
      this.swiper.on('slideChangeTransitionEnd', (swiper) => {
        console.info(swiper.activeIndex, 'ed', swiper)
        if (swiper.activeIndex === 1) {
          this.showOverlay = false
        } else if (swiper.activeIndex === 0) {
          this.showOverlay = true
        }
        this.$forceUpdate()
      })
      const history = document.querySelector('.history')
      if (history) {
        history.addEventListener('click', (e) => {
          e.preventDefault()
          this.swiper.slidePrev()
        })
      }
    },
    close() {
      if (this.swiper) {
        this.swiper.slideTo(1)
      }
    },
    user() {
      this.$router.push('/setting')

      // this.$refs.user.open()
    },
    add() {
      this.$refs.side.newChat()
    },
    open() {
      console.info('===============测试')
      if (this.swiper && this.isApp) {
        this.swiper.slideTo(0)
      }
      this.$store.commit('setShowSide', true)
    },
    reload() {
      this.isReloadAlive = false
      this.$nextTick(() => {
        this.isReloadAlive = true
      })
    },
    setSettingDropdownShow(val) {
      const headerInstance = this.$refs.headerRef
      if (headerInstance) {
        headerInstance.setSettingDropdownShow(val)
      }
    },
    setUserDropdownShow(val) {
      const headerInstance = this.$refs.headerRef
      if (headerInstance) {
        headerInstance.setUserDropdownShow(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  // text-align: center;
  // font-size: 18px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.menu {
  width: 80vw;
}

.content {
  width: 100%;
}

.menu-button {
  position: absolute;
  top: 0px;
  left: 0px;

  padding: 15px;

  cursor: pointer;

  -webkit-transition: 0.3s;
  transition: 0.3s;

  // background-color: #2c8dfb;
}

.menu-button .bar:nth-of-type(1) {
  margin-top: 0px;
}

.menu-button .bar:nth-of-type(3) {
  margin-bottom: 0px;
}

.bar {
  position: relative;
  display: block;

  width: 50px;
  height: 5px;

  margin: 10px auto;
  background-color: #fff;

  border-radius: 10px;

  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.menu-button:hover .bar:nth-of-type(1) {
  -webkit-transform: translateY(1.5px) rotate(-4.5deg);
  -ms-transform: translateY(1.5px) rotate(-4.5deg);
  transform: translateY(1.5px) rotate(-4.5deg);
}

.menu-button:hover .bar:nth-of-type(2) {
  opacity: 0.9;
}

.menu-button:hover .bar:nth-of-type(3) {
  -webkit-transform: translateY(-1.5px) rotate(4.5deg);
  -ms-transform: translateY(-1.5px) rotate(4.5deg);
  transform: translateY(-1.5px) rotate(4.5deg);
}

.cross .bar:nth-of-type(1) {
  -webkit-transform: translateY(15px) rotate(-45deg);
  -ms-transform: translateY(15px) rotate(-45deg);
  transform: translateY(15px) rotate(-45deg);
}

.cross .bar:nth-of-type(2) {
  opacity: 0;
}

.cross .bar:nth-of-type(3) {
  -webkit-transform: translateY(-15px) rotate(45deg);
  -ms-transform: translateY(-15px) rotate(45deg);
  transform: translateY(-15px) rotate(45deg);
}

.cross:hover .bar:nth-of-type(1) {
  -webkit-transform: translateY(13.5px) rotate(-40.5deg);
  -ms-transform: translateY(13.5px) rotate(-40.5deg);
  transform: translateY(13.5px) rotate(-40.5deg);
}

.cross:hover .bar:nth-of-type(2) {
  opacity: 0.1;
}

.cross:hover .bar:nth-of-type(3) {
  -webkit-transform: translateY(-13.5px) rotate(40.5deg);
  -ms-transform: translateY(-13.5px) rotate(40.5deg);
  transform: translateY(-13.5px) rotate(40.5deg);
}
.icon-box {
  position: fixed;
  z-index: 500;
  width: 42px;
  text-align: center;
  justify-content: center;
  margin-left: 8px;
  color: var(--white);
  cursor: pointer;
  top: 20px;
  left: 20px;
  font-size: 18px;
  cursor: pointer;
  border: 0.5px solid var(--br);
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 42px;
  transform: rotate(180deg);
  text-align: center;
  padding-left: 2px;
}
.icon-box:hover {
  background: var(--h-text);
  border: 0.5px solid var(--h-text);
}
.right-box {
  display: flex;
  align-items: center;
  .right-item {
    position: relative;
    .red-point {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--red);
      position: absolute;
      right: -2px;
      top: 2px;
    }
  }
}
.mobile-box {
  position: fixed;
  z-index: 50;
  text-align: center;
  justify-content: space-between;
  color: #fff;
  cursor: pointer;
  top: calc(env(safe-area-inset-top) + 6px);
  left: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.header-icon {
  font-size: 24px;
  color: var(--white);
}
.layout {
  overflow-x: overlay;
  overflow-y: hidden;
  width: 100vw;
  height: calc(100% - 0px);
  background: var(--black);
  position: absolute;
  display: flex;
}
.layout-box {
  width: 100%;
  background: var(--s-bg);
  height: fit-content;
  position: relative;
  max-height: 100vh;
  overflow: hidden;
}

::v-deep .el-container {
  height: 100%;
}
::v-deep .el-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 64px !important;
}
::v-deep .el-main {
  padding: 0;
  min-height: calc(100vh - 60px);
  background: var(--s-bg);
}
.mobile {
  display: none;
}
.pc {
  display: inline-flex;
}
.side-box {
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 200;
}
.history {
  font-size: 18px;
}
.prototype {
  margin-top: calc(env(safe-area-inset-top) + 10px);
  margin-left: -30px;
  color: #ffffff88;
}
.home {
  position: fixed;
  height: 100%;
  width: 100%;
  background: var(--black);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}
.click-box {
  height: 38px;
  width: 38px;
  // background: red;
  display: flex;
  align-items: center;
  z-index: 100;
  // justify-content: center;
  top: 0;
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 900px) {
  .layout-box {
    width: 100%;
    background: var(--bg);
  }
  ::v-deep .el-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 47px !important;
  }
  ::v-deep .el-main {
    padding: 0;
    min-height: calc(100vh - 47px);
  }
  // .icon-box{
  //   display: none;
  // }
  // .side{
  //   display: none;
  // }
  .mobile {
    display: inline-flex;
  }
  .pc {
    display: none;
  }
}
.appHelps {
  z-index: 800 !important;
}
// .w{
//   display: block;
//   width: 1000px;
// }
</style>
