var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.src
    ? _c("img", {
        staticClass: "image-cached",
        attrs: { crossorigin: "anonymous", alt: "", src: _vm.source },
      })
    : _vm.$store.state.profile.name
    ? _c("div", { staticClass: "userbox" }, [
        _c("span", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.$store.state.profile.name.charAt(0))),
        ]),
      ])
    : _c("img", {
        staticClass: "image-cached",
        attrs: {
          crossorigin: "anonymous",
          alt: "",
          src: require("../../assets/images/default-head.png"),
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }