<template>
  <div>
    <v-tour name="myTour" :steps="steps" :options="options" :callbacks="myCallbacks"></v-tour>
  </div>
</template>

<script>
// import { login } from '@/api/webchat'

export default {
  name: 'my-tour',
  data() {
    return {
      myCallbacks: {
        onFinish: this.skipTour,
        onSkip: this.skipTour
      }

    }
  },
  computed: {
    options() {
      const res = {
        labels: {
          buttonSkip: this.$t('help.skip'),
          buttonPrevious: this.$t('help.previous'),
          buttonNext: this.$t('help.next'),
          buttonStop: this.$t('help.finish')
        }
      }
      return res
    },
    steps() {
      const arr = []
      const res = [
        {
          target: '#v-step-1',
          content: this.$t('help.step1')
        },
        {
          target: '#v-step-2',
          content: this.$t('help.step2')
        },
        {
          target: '#v-step-3',
          content: this.$t('help.step3')
        },
        {
          target: '#v-step-4',
          content: this.$t('help.step4')
        },
        {
          target: '#v-step-5',
          content: this.$t('help.step5')
        },
        {
          target: '#v-step-6',
          content: this.$t('help.step6')
        },
        {
          target: '#v-step-7',
          content: this.$t('help.step7')
        },
        {
          target: '#v-step-8',
          content: this.$t('help.step8')
        },
        {
          target: '#v-step-9',
          content: this.$t('help.step9')
        },
        {
          target: '#v-step-10',
          content: this.$t('help.step10')
          // after: () => {
          //   this.$emit('setSettingDropdownShow', true)
          //   this.$emit('setUsergDropdownShow', false)
          // }
        },
        {
          target: '#v-step-11',
          content: this.$t('help.step11'),
          before: () => {
            this.$store.commit('setShowSide', true)
          }
        },
        {
          target: '#v-step-12',
          content: this.$t('help.step12')
        },
        {
          target: '#v-step-13',
          content: this.$t('help.step13')
        }
        // {
        //   target: '#v-step-2-1-show_principle',
        //   content: 'You can see the priciples',
        //   params: {
        //     placement: 'left'
        //   },
        //   before: () => {
        //     this.$emit('setSettingDropdownShow', true)
        //     this.$emit('setUsergDropdownShow', false)
        //   },
        //   after: () => {
        //     this.$emit('setSettingDropdownShow', false)
        //     this.$emit('setUsergDropdownShow', false)
        //   }
        // },
        // {
        //   target: '#v-step-2-1-tailor_answer_job',
        //   content: 'You will get more detailed answers about job',
        //   params: {
        //     placement: 'left'
        //   },
        //   before: () => {
        //     this.$emit('setSettingDropdownShow', true)
        //     this.$emit('setUsergDropdownShow', false)
        //   }
        // },
        // {
        //   target: '#v-step-2-1-tailor_answer_personality',
        //   content: 'You will get more detailed answers about personality',
        //   params: {
        //     placement: 'left'
        //   }
        // },
        // {
        //   target: '#v-step-2-1-connect_internet',
        //   content: 'Connect to Internet service, get more detailed answer',
        //   params: {
        //     placement: 'left'
        //   },
        //   after: () => {
        //     this.$emit('setSettingDropdownShow', false)
        //     this.$emit('setUsergDropdownShow', true)
        //   }
        // },
        // {
        //   target: '#v-step-my-profile',
        //   content: 'Setup my profile',
        //   params: {
        //     placement: 'left'
        //   },
        //   before: () => {
        //     this.$emit('setUserDropdownShow', true)
        //     this.$emit('setSettingDropdownShow', false)
        //   }
        // },
        // {
        //   target: '#v-step-logout',
        //   content: 'Logout',
        //   params: {
        //     placement: 'left'
        //   },
        //   before: () => {
        //     this.$emit('setUserDropdownShow', true)
        //     this.$emit('setSettingDropdownShow', false)
        //   }
        // }
      ]
      const m = [
        {
          target: '#m-step-1',
          content: this.$t('help.step11'),
          before: () => {
            this.$store.commit('setShowSide', true)
          }
        },
        {
          target: '#m-step-2',
          content: this.$t('help.step12')
        },
        {
          target: '#m-step-3',
          content: this.$t('help.step13')
        }
      ]
      if (window.innerWidth > 750) {
        for (const i of res) {
          let add = true
          if (i.target === '#v-step-1') {
            add = false
          }
          if (i.target === '#v-step-2') {
            add = false
          }
          if (i.target === '#v-step-6') {
            add = false
          }
          // if (i.target === '#v-step-2') {
          //   add = false
          // }
          if (i.target === '#v-step-9') {
            add = false
          }
          if (i.target === '#v-step-7') {
            add = false
          }
          if (i.target === '#v-step-5') {
            add = false
          }
          if (i.target === '#v-step-13') {
            add = false
          }
          if (add) {
            arr.push(i)
          }
        }
      } else {
        for (const i of m) {
          let add = true
          if (i.target === '#v-step-1') {
            add = false
          }
          if (i.target === '#v-step-6') {
            add = false
          }
          // if (i.target === '#v-step-2') {
          //   add = false
          // }
          // if (i.target === '#v-step-6' && this.$i18n.locale !== 'en') {
          //   add = false
          // }
          if (i.target === '#v-step-7') {
            add = false
          }
          if (add) {
            arr.push(i)
          }
        }
      }

      return arr
    }
  },
  watch: {
    '$store.state.helps': {
      handler(val) {
        if (val) {
          this.start()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function () {
    // if (this.$store.state.helps === '') {
    //   this.$nextTick(
    //     () => {
    //       setTimeout(() => {
    //         this.start()
    //       }, 300)
    //     }
    //   )
    // }
    // this.$store.commit('setHelps', false)
  },
  methods: {

    start() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$tours.myTour.start()
          this.onStart()
          this.$store.commit('setHelps', false)
          this.$store.commit('setTourActive', true)
        }, 30)
      })
    },
    skipTour() {
      // this.$tour.close()
      this.onEnd()
      this.$emit('setSettingDropdownShow', false)
      this.$emit('setUserDropdownShow', false)
      this.$store.commit('setTourActive', false)
    },
    onStart() {
      // 在导览开始时添加蒙层
      this.addOverlay()
    },
    onEnd() {
    // 在导览结束时移除蒙层
      this.removeOverlay()
    },
    addOverlay() {
    // 创建蒙层元素并添加到 body
      const overlay = document.createElement('div')
      overlay.className = 'tour-overlay'
      overlay.id = 'tour-overlay-id'
      document.body.appendChild(overlay)
    },
    removeOverlay() {
    // 移除蒙层元素
      const overlay = document.querySelector('#tour-overlay-id')
      if (overlay) {
        document.body.removeChild(overlay)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tour-overlay{
  z-index: 300 !important;
}
::v-deep .v-step[data-v-54f9a632] {
    background: var(--black);
    font-size: 15px !important;
    border-radius: 10px;
    z-index: 500;
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,.2);
}
::v-deep .v-step__button-next, ::v-deep .v-step__button-stop{
    border-radius: 10px !important;
    border: 1px solid var(--white) !important;
    background: #856db400 !important;
    color: var(--white) !important;
}
::v-deep .v-step__button-skip, ::v-deep .v-step__button-previous{
    border-radius: 10px !important;
    border:none !important;;
    background: var(--h-text) !important;
    color: var(--white) !important;
}
::v-deep .v-step__button-skip:hover, ::v-deep .v-step__button-previous:hover{
    border-radius: 10px !important;
    border:none !important;;
    background: var(--h-text) !important;
    color: var(--white) !important;
}
::v-deep .v-step__button-next:hover, ::v-deep .v-step__button-stop:hover{
    border-radius: 10px !important;
    border: 1px solid var(--white) !important;
    background: #856db400 !important;
    color: var(--white) !important;
}

</style>
