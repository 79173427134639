<template>
  <div>
    <div v-for="(item, index) in $store.state.language" :key="index" class="item" @click="handleCommand(item)">
      <span>{{ item }}</span>
      <svg-icon
        icon-class="enter"
        class="enter"
        v-if="$i18n.locale===index"
        @click.stop="changeName(item, items)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  inject: ['reload'],

  methods: {
    handleCommand(val) {
      let code = ''
      for (const i in this.$store.state.language) {
        if (this.$store.state.language[i] === val) {
          code = i
        }
      }
      this.$store.commit('setLangCode', code)
      this.$emit('language')
      if (this.$store.state.history.length > 0) {
        if (code !== this.$i18n.locale) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$store.commit('setLangShow', true)
            })
          }, 0)
        }
      } else {
        this.$i18n.locale = code
        const data = {
          language: this.$i18n.locale
        }
        this.$store.dispatch('updateProfile', data)
        this.$store.commit('setSessionId', '')
        this.$store.commit('setHistory', [])
        this.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item{
    display: flex;
    justify-content: space-between;
    height: 56px;
    align-items: center;
    border-bottom: 0.5px solid var(--br);
    span{
        font-family: Roboto;
font-size: 15px;
font-weight: 400;
line-height: 20px;
text-align: left;
color: var(--text);

    }
    .enter{
        color: var(--green);
    }
}
</style>
