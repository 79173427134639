<template>
  <div class="app-dialog tour-overlay" v-show="visible || closing">
    <Transition
      name="custom-classes"
      :enter-active-class="enterClass"
      :leave-active-class="leaveClass"
    >
      <div
        class="dialog"
        v-if="visible || closing"
        :class="full ? 'full' : ''"

        :style="{
          transform: $store.state.isMin900Width? `translate(0, ${translateY}px)`:'',
          transition: transitionStyle,
          marginLeft: !$store.state.isMin900Width && $store.state.showSide? '130px':'0px'
        }"
      >
      <div class="touch-box"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd">
        <div class="titel-box" :class="title ? '' : 'notitle'"   >
          <div v-if="titleType==='flag'" class="flag-title">
            <svg-icon icon-class="flag" class="flag-icon" />
            <span class="title flag-title">{{ title }}</span>
          </div>
          <span class="title title-center" v-else>{{ title }}</span>
          <div class="close-box" @click="close">
            <svg-icon icon-class="close" class="close-icon" />
          </div>
        </div>
      </div>
        <div class="dialog-content-wrap">
          <slot></slot>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    titleType: { type: String, default: '' },
    full: { type: Boolean, default: false }
  },
  data() {
    return {
      visible: false,
      closing: false,
      translateY: 0,
      startY: 0,
      isDragging: false,
      transitionStyle: 'none'
    }
  },
  computed: {
    enterClass() {
      return this.$store.state.isMin900Width
        ? 'animate__animated animate__fadeInUp animate__faster'
        : 'animate__animated animate__fadeIn animate__faster'
    },
    leaveClass() {
      return this.$store.state.isMin900Width
        ? 'animate__animated animate__fadeInDown animate__faster'
        : 'animate__animated animate__fadeOut animate__faster'
    }
  },
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        console.info('======guanbi')
        this.hidden()
        // console.info('===========')
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector('body')
      if (body.append) {
        body.append(this.$el)
      } else {
        body.appendChild(this.$el)
      }
    })
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  methods: {
    hidden() {
      this.visible = false
      this.closing = false
      this.translateY = 0
      this.$emit('close')
    },
    close() {
      if (this.closing) return
      this.closing = true
      const dialogHeight = this.$el.querySelector('.dialog').offsetHeight
      this.transitionStyle = 'transform 0.3s ease'
      this.translateY = dialogHeight

      setTimeout(() => {
        this.visible = false
        this.closing = false
        this.translateY = 0
        this.$emit('close')
      }, 300)
    },
    open() {
      this.visible = true
      this.translateY = 0
      this.transitionStyle = 'none'
    },
    onTouchStart(event) {
      if (this.closing) return
      this.startY = event.touches[0].clientY
      this.isDragging = true
      this.transitionStyle = 'none'
    },
    onTouchMove(event) {
      if (!this.isDragging || this.closing) return
      const currentY = event.touches[0].clientY
      const diff = currentY - this.startY
      if (diff > 0) {
        this.translateY = diff
      }
    },
    onTouchEnd() {
      if (!this.isDragging || this.closing) return
      this.isDragging = false
      const dialogHeight = this.$el.querySelector('.dialog').offsetHeight
      this.transitionStyle = 'transform 0.3s ease'

      if (this.translateY > dialogHeight * 0.2) {
        this.close()
      } else {
        this.translateY = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
}
.dialog {
  position: fixed;
  box-shadow: 0px 10px 24px 0px #0000003d;
  background: var(--b-bg);
  padding: 20px;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
  z-index: 1000;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  min-width: 60vw;
  overflow: hidden;
  // transition: transform 0.3s ease;
  transform: translate(-50%, -50%);
  .titel-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-family: Century-Gothic;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 40px);
    color: var(--text);
  }
  .close-box {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: var(--text);
  }
  .dialog-content-wrap {
    max-height: calc(92vh - 64px - env(safe-area-inset-bottom));
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    // position: relative;
  }
}
.notitle {
  position: absolute;
  right: 0;
  padding: 0 20px;
  z-index: 10;
}
.close-icon{
  color: var(--text);
}
.flag-title{
    color: var(--red) !important;
  }
  .flag-icon{
    margin-right: 8px;
  }

@media screen and (max-width: 900px) {
  .touch-box{
    position: relative;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: -20px;
    padding: 16px 20px 16px 20px;
}
  .title-center{
    display: flex;
    justify-content: center;
    margin-left: 24px;
  }
  .full {
    min-height: calc(92vh - env(safe-area-inset-bottom));
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .dialog {
    max-height: calc(92vh - env(safe-area-inset-bottom));
    top: auto;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    width: 100vw;
    transform: translate(0%, 0%);
    will-change: transform;
  }
  .app-dialog {
    pointer-events: none;
  }

  .dialog {
    pointer-events: auto;
  }
  .title {
    font-size: 15px !important;
    line-height: 20px !important;
  }
}
</style>
