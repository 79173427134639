var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.showSide
    ? _c(
        "div",
        [
          _c("div", { staticClass: "tour-overlay", on: { click: _vm.close } }),
          _c(
            "div",
            {
              ref: "sideRef",
              staticClass: "side",
              class: [_vm.isApp ? "app-side" : ""],
              on: { click: _vm.closeSideList },
            },
            [
              !_vm.$store.state.isMin900Width
                ? _c("div", { staticClass: "title-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "icon-box pc",
                        attrs: { id: "v-step-11" },
                        on: { click: _vm.close },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "extend-icon",
                          attrs: { "icon-class": "extend" },
                        }),
                      ],
                      1
                    ),
                    false
                      ? _c("span", { staticClass: "mobile" }, [
                          _vm._v(_vm._s(_vm.$t("common.history"))),
                        ])
                      : _vm._e(),
                    false
                      ? _c(
                          "div",
                          {
                            staticClass: "flag-box",
                            on: { click: _vm.goPrinciple },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "mobile-flag",
                              attrs: { "icon-class": "flag" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "pc",
                        attrs: { id: "v-step-12" },
                        on: { click: _vm.newChat },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "chat-icon",
                          staticStyle: { color: "var(--ss-text)" },
                          attrs: { "icon-class": "add" },
                        }),
                        _c("span", { staticClass: "new-chat" }, [
                          _vm._v(_vm._s(_vm.$t("side.newChat"))),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "conversationList",
                  staticClass: "content",
                  on: { scroll: _vm.handleScroll },
                },
                [
                  _c("div", [
                    _c("span", { staticClass: "chat-title" }, [
                      _vm._v(_vm._s(_vm.$t("common.principles"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "side-item",
                        class: !_vm.isHome ? "is-cilck" : "",
                        staticStyle: { "margin-bottom": "16px" },
                        on: { click: _vm.goPrinciple },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [
                            _c("svg-icon", {
                              staticClass: "flag-icon",
                              attrs: { "icon-class": "flag" },
                            }),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("home.myPrinciples"))),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c("span", { staticClass: "chat-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("common.chat") + " " + _vm.$t("common.history")
                      )
                    ),
                  ]),
                  _vm._l(_vm.listData, function (item, index) {
                    return _c("div", { key: index }, [
                      item.length > 0
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "date" }, [
                                _vm._v(_vm._s(_vm.$t("date." + item[0].date))),
                              ]),
                              _vm._l(item, function (items, indexs) {
                                return _c(
                                  "div",
                                  {
                                    key: indexs,
                                    staticClass: "side-item",
                                    class: {
                                      "is-cilck": items.click,
                                      "is-touch": items.id === _vm.touchId,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleClickItem(item, items)
                                      },
                                      touchstart: function ($event) {
                                        return _vm.handleTouchStart(
                                          $event,
                                          item,
                                          items,
                                          index,
                                          indexs
                                        )
                                      },
                                      touchmove: _vm.handleTouchMove,
                                      touchend: _vm.handleTouchEnd,
                                      mousedown: function ($event) {
                                        return _vm.handleTouchStart(
                                          $event,
                                          item,
                                          items,
                                          index,
                                          indexs
                                        )
                                      },
                                      mouseup: _vm.handleTouchEnd,
                                      contextmenu: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-center" },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "chat-icon",
                                          attrs: { "icon-class": "chat" },
                                        }),
                                        items.edit
                                          ? _c("el-input", {
                                              staticClass: "item-input",
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeinputName(
                                                    item,
                                                    items
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.changeinputName(
                                                    item,
                                                    items
                                                  )
                                                },
                                              },
                                              model: {
                                                value: items.name,
                                                callback: function ($$v) {
                                                  _vm.$set(items, "name", $$v)
                                                },
                                                expression: "items.name",
                                              },
                                            })
                                          : _c(
                                              "span",
                                              {
                                                class: items.click
                                                  ? "click-box"
                                                  : "text-box",
                                              },
                                              [_vm._v(_vm._s(items.name))]
                                            ),
                                      ],
                                      1
                                    ),
                                    !_vm.isPhone
                                      ? [
                                          items.edit
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.changeName(
                                                            item,
                                                            items
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        staticClass: "enter",
                                                        attrs: {
                                                          "icon-class": "enter",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.handleCancel(
                                                            item,
                                                            items
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        staticClass: "cancel",
                                                        attrs: {
                                                          "icon-class": "close",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : items.click && !items.edit
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleEdit(
                                                            item,
                                                            items
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        staticStyle: {
                                                          "margin-right": "8px",
                                                        },
                                                        attrs: {
                                                          "icon-class": "edit",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDel(
                                                            item,
                                                            items,
                                                            index,
                                                            indexs
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          "icon-class":
                                                            "side-del",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ])
                  }),
                  _vm.loading
                    ? _c("div", { staticClass: "loading-indicator" }, [
                        _c("i", { staticClass: "el-icon-loading" }),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              false
                ? _c(
                    "div",
                    {
                      staticClass: "title-box",
                      attrs: { id: "v-step-13" },
                      on: { click: _vm.clear },
                    },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _vm._v(_vm._s(_vm.$t("side.clearAllChats"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm.isSideListOpen
            ? _c(
                "div",
                {
                  staticClass: "side-list",
                  style: {
                    top: _vm.sideListY + 20 + "px",
                    left: _vm.sideListX + 20 + "px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "sl-line", on: { click: _vm.rename } },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "edit2" } }),
                      _c("div", { staticClass: "sl-text" }, [
                        _vm._v(_vm._s(_vm.$t("side.rename"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "sl-line", on: { click: _vm.deleteFromSL } },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "delete2" } }),
                      _c("div", { staticClass: "sl-text" }, [
                        _vm._v(_vm._s(_vm.$t("common.delete"))),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              staticClass: "rename-frame",
              attrs: {
                visible: _vm.isRenameOpen,
                "append-to-body": "",
                title: _vm.$t("side.renameChat"),
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isRenameOpen = $event
                },
                closed: _vm.closeSideList,
              },
            },
            [
              _c("el-input", {
                staticClass: "rename-input",
                attrs: {
                  placeholder: _vm.$t("side.plsInputChat"),
                  size: "mini",
                },
                model: {
                  value: _vm.renameName,
                  callback: function ($$v) {
                    _vm.renameName = $$v
                  },
                  expression: "renameName",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmRename },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.done")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : !_vm.$store.state.showSide && !_vm.$store.state.isMin900Width
    ? _c("div", { staticClass: "hide-side" }, [
        _c(
          "div",
          { staticClass: "hide-icon-box", attrs: { id: "v-step-11" } },
          [
            _c(
              "div",
              {
                staticClass: "hide-add-box",
                staticStyle: { border: "none" },
                on: { click: _vm.open },
              },
              [
                _c("svg-icon", {
                  staticClass: "hide-extend",
                  attrs: { "icon-class": "extend" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "hide-add-box", on: { click: _vm.newChat } },
              [
                _c("svg-icon", {
                  staticClass: "hide-chat-icon",
                  staticStyle: { color: "var(--ss-text)" },
                  attrs: { "icon-class": "add" },
                }),
              ],
              1
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }