<template>
  <div class="chat-message-list" ref="messages" >
    <ChatMessageItem
      v-for="(msg) in messages"
      :key="msg.id+'@'+msg.mine"
      :sender="sender"
      v-bind="msg"
      :generating='generating'
      :showFaq="showFaq"
      @update:typing="handleUpdateTyping(msg, $event)"
      @update:stopped="handleUpdateStopped(msg, $event)"
      @update:comments="handleUpdateComments(msg, $event)"
      @update:rating="handleUpdateRating(msg, $event)"
      @update:faq='handleUpdateFaq(msg, $event)'
      @changePrinciple="handleChangePrinciple(msg,$event)"
      @makeShorter="(val)=>handleShortAnwser(val,msg.id)"
      @changeFaq='handleChangeFaq'
      @regenerate='handleRegenerate(msg.id)'
      @translation="(val)=>handleTranslation(val,msg.id)"
      @forceResend="handleForceResend"
      @callQuickQuestion="handleQuickQuestion"
    />
    <ChatMessageItem :loading="true" :mine="false" :sender="sender" v-if="loading" />
  </div>
</template>

<script>
export default {
  components: {
    ChatMessageItem: () => import('./message-item.vue')
  },
  props: {
    showFaq: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    },
    generating: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    sender: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      messagesHeight: 0
    }
  },
  mounted() {
    this.observeMessagesHeight()
  },
  beforeDestroy() {
    this.unobserveMessagesHeight()
  },
  watch: {
    messagesHeight: {
      handler() {
        if (this.messages[this.messages.length - 1]) {
          if (!this.messages[this.messages.length - 1].stopped && this.messages[this.messages.length - 1].typing) {
            this.scrollToBottom()
          }
        }
      },
      immediate: true
    }
  },
  methods: {

    scrollToBottom() {
      this.$emit('scrollToBottom', this.messagesHeight)
      this.$nextTick(() => {
        const el = this.$el
        el.scrollTop = el.scrollHeight
      })
    },
    async observeMessagesHeight() {
      await this.$nextTick()
      const element = this.$refs.messages
      if (this.resizeObserver || !element) return
      this.resizeObserver = new ResizeObserver(() => {
        this.messagesHeight = element.clientHeight
      })
      this.resizeObserver.observe(element)
    },
    unobserveMessagesHeight() {
      if (!this.resizeObserver) return
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    },
    handleUpdateTyping(msg, value) {
      this.$set(msg, 'typing', value)
    },
    handleUpdateStopped(msg, value) {
      this.$set(msg, 'stopped', value)
    },
    handleUpdateComments(msg, value) {
      this.$set(msg, 'comments', value)
      if (msg.switchStatus) {
        this.$set(msg, 'shortComments', value)
      } else {
        this.$set(msg, 'longComments', value)
      }
    },
    handleUpdateRating(msg, value) {
      this.$set(msg, 'rating', value)
      if (msg.switchStatus) {
        this.$set(msg, 'shortRating', value)
      } else {
        this.$set(msg, 'longRating', value)
      }
    },
    handleShortAnwser(val, id) {
      this.$emit('makeShorter', { val: val, id: id })
    },
    handleTranslation(val, id) {
      this.$emit('translation', { val: val, id: id })
    },
    handleUpdateFaq(msg, value) {
      this.$set(msg, 'faq', value)
    },
    handleForceResend() {
      this.$emit('forceResend')
    },
    handleChangeFaq(val) {
      this.$emit('changeFaq', val)
    },
    handleRegenerate(val) {
      console.info('regenerate2')
      this.$emit('regenerate', val)
    },
    handleQuickQuestion(val) {
      this.$emit('callQuickQuestion', val)
    },
    handleChangePrinciple(msg, value) {
      const temp = msg.principles
      for (const i in temp) {
        if (temp[i].title === value.title) {
          if (value.action === 'add' || value.action === 'edit') {
            temp[i].saved_to_my_principle = true
          }
          if (value.action === 'del') {
            temp[i].saved_to_my_principle = false
          }
        }
      }

      this.$set(msg, 'principle', temp)
      console.info('msg', msg, value)
      // this.$set(msg, 'faq', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width: 900px) {
  .chat-message-list{
    padding-left: 16px;
    margin-left: -16px;
  }
}
</style>
