var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Dialog",
        { ref: "initDom", attrs: { title: _vm.info.title, full: true } },
        [
          _vm.infoData.length > 1
            ? _c("div", { staticClass: "nav-box" }, [
                _c(
                  "div",
                  { staticClass: "container borderXwidth" },
                  _vm._l(_vm.infoData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item-box",
                        on: {
                          click: function ($event) {
                            return _vm.clickMenu(item, index)
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              _vm.activeName == index ? "red-name" : "name",
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("home.sources") +
                                  " " +
                                  _vm.intToRoman(Number(index + 1))
                              )
                            ),
                          ]
                        ),
                        _c("div", {
                          class: [_vm.activeName == index ? "on" : ""],
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "content-box" }, [
            _vm.info.chapter
              ? _c("span", { staticClass: "chapter" }, [
                  _vm._v(" " + _vm._s(_vm.info.chapter) + " "),
                ])
              : _vm._e(),
            _c("div", { staticClass: "sub-title" }, [
              _vm._v(" " + _vm._s(_vm.info.sub_title) + " "),
            ]),
            _vm.info.tag?.length > 0
              ? _c(
                  "div",
                  { staticClass: "tag-box" },
                  _vm._l(_vm.info.tag, function (item, index) {
                    return _c("div", { key: index, staticClass: "tag" }, [
                      _vm._v(_vm._s(item)),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "text-box" },
              [_c("vue-markdown", { attrs: { source: _vm.showContent } })],
              1
            ),
          ]),
          _vm.info.url
            ? _c(
                "div",
                { staticClass: "lint-box", on: { click: _vm.linkTo } },
                [
                  _vm._v(" " + _vm._s(_vm.$t("home.exploreMore")) + " "),
                  _c("svg-icon", {
                    staticClass: "link-right",
                    attrs: { "icon-class": "share" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.info.url
            ? _c(
                "el-button",
                { staticClass: "btn", on: { click: _vm.linkTo } },
                [
                  _vm._v(" " + _vm._s(_vm.$t("home.exploreMore")) + " "),
                  _c("svg-icon", {
                    staticClass: "link-right",
                    attrs: { "icon-class": "share" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }