var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment-box" }, [
    _vm.audioStatus === "start"
      ? _c("div", { staticClass: "cover-box" })
      : _vm._e(),
    false
      ? _c(
          "div",
          { ref: "audioBox", staticClass: "audio-box" },
          _vm._l(_vm.audios, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticClass: "play-box-wrap" },
              [
                _c(
                  "div",
                  {
                    staticClass: "play-box",
                    style: {
                      width: `calc(48px + ${Number(item.duration / 2)}vw)`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.play(item, index)
                      },
                    },
                  },
                  [
                    !item.isPlaying
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/images/audio.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    item.isPlaying
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/images/audio.gif"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.showTime(item.duration)))]),
                    _c("audio", {
                      ref: item.id,
                      refInFor: true,
                      attrs: { src: item.data },
                    }),
                  ]
                ),
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { "icon-class": "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.del(item, index)
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.audioStatus === "start",
            expression: "audioStatus === 'start'",
          },
        ],
        staticClass: "wave-box",
      },
      [_vm._m(0)]
    ),
    false
      ? _c(
          "div",
          { staticClass: "btn-box" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                class: [_vm.audioStatus === "end" ? "" : "click-btn"],
                on: { blur: _vm.audioEnd },
                nativeOn: {
                  mousedown: function ($event) {
                    return _vm.audioStart.apply(null, arguments)
                  },
                  mouseup: function ($event) {
                    return _vm.audioEnd.apply(null, arguments)
                  },
                  touchstart: function ($event) {
                    return _vm.audioStart.apply(null, arguments)
                  },
                  touchend: function ($event) {
                    return _vm.audioEnd.apply(null, arguments)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "sound-on",
                  attrs: { "icon-class": "sound-on1" },
                }),
                _vm._v(" " + _vm._s(_vm.$t("home.audioTip")) + " "),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "questionBox",
        staticClass: "question",
        class: [_vm.extend ? "extend" : "no-extend"],
        on: { click: _vm.handleExtend },
      },
      [
        _vm.showExtend
          ? _c("svg-icon", {
              class: [_vm.extend ? "extend-icon" : "no-extend-icon"],
              attrs: { "icon-class": "right" },
            })
          : _vm._e(),
        _c("span", [_vm._v(_vm._s(_vm.question))]),
      ],
      1
    ),
    _c(
      "div",
      { ref: "textarea", staticClass: "input-box" },
      [
        _c(
          "div",
          { staticClass: "suffix" },
          [
            _c(
              "el-button",
              { staticClass: "btn-send", attrs: { type: "text" } },
              [
                _c("svg-icon", {
                  staticClass: "send",
                  attrs: { "icon-class": "close" },
                  on: { click: _vm.clear },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-input", {
          ref: "inputRef",
          style: { height: _vm.inputH },
          attrs: {
            type: "textarea",
            resize: "none",
            placeholder: _vm.$t("footer.comment"),
          },
          on: { clear: _vm.handleInput },
          model: {
            value: _vm.comment,
            callback: function ($$v) {
              _vm.comment = $$v
            },
            expression: "comment",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn-box save-box" },
      [
        _c(
          "el-button",
          {
            staticClass: "save-btn",
            attrs: { loading: _vm.loading },
            on: { click: _vm.handleSend },
          },
          [_vm._v(" " + _vm._s(_vm.$t("common.commentSave")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sound-wave-wrapper" }, [
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box long-bar" }),
      _c("div", { staticClass: "box medium-bar" }),
      _c("div", { staticClass: "box short-bar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }