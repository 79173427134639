// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content[data-v-817c20de] {\n  width: 100%;\n  min-height: 100vh;\n}\n.content .box[data-v-817c20de] {\n  position: relative;\n  width: 100%;\n  background-color: var(--black);\n  color: var(--white);\n}\n.content .box[data-v-817c20de]:nth-child(2n) {\n  background-color: #171717;\n}\n.box[data-v-817c20de] {\n  padding: 0 120px;\n}\n.box[data-v-817c20de]:first-child {\n  padding: 0px;\n}\n.wrap[data-v-817c20de] {\n  max-width: 1200px;\n  margin: auto;\n}\n.banner[data-v-817c20de] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: cover;\n}\n@media screen and (max-width: 1200px) {\n.box[data-v-817c20de] {\n    padding: 0 20px;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
