var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "boarding" }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("../../assets/images/Digital Ray.png"),
            alt: "",
          },
        }),
        _c("p", { staticStyle: { color: "#ffffff88" } }, [_vm._v("(Alpha)")]),
        _vm._m(0),
        _vm._m(1),
        _vm._m(2),
        _c("el-button", { staticClass: "btn", on: { click: _vm.goHome } }, [
          _vm._v(_vm._s(_vm.$t("login.btn"))),
        ]),
        _vm._m(3),
        _c("span", { staticClass: "Powered" }, [
          _vm._v(
            "Powered by VR OPCO LTD (V " +
              _vm._s(_vm.$store.state.version) +
              ")"
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/product-3.png"), alt: "" },
      }),
      _c("span", [_vm._v("Solving Problems via Principled-Thinking Process")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/product-4.png"), alt: "" },
      }),
      _c("span", [
        _vm._v(
          "Understanding the Essentials of Ray Dalio and the Insights from His Previous Experience"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/product-10.png"), alt: "" },
      }),
      _c("span", [_vm._v("Dialogue with Ray Dalio for Diverse Tasks")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "a-box" }, [
      _c("a", { attrs: { target: "_blank", rel: "noopener noreferrer" } }, [
        _vm._v("Disclaimer"),
      ]),
      _c("a", { attrs: { target: "_blank", rel: "noopener noreferrer" } }, [
        _vm._v("Terms of Service"),
      ]),
      _c("a", { attrs: { target: "_blank", rel: "noopener noreferrer" } }, [
        _vm._v("Privacy Policy"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }