var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-message-tools" },
    [
      _c(
        "Dialog",
        { ref: "showTime", attrs: { full: false, title: "Time Detail" } },
        _vm._l(_vm.showTimeData.data, function (timeItem, index) {
          return _c(
            "div",
            { key: index, staticClass: "showtime-content-wrapper" },
            [
              _vm._l(timeItem, function (value, key) {
                return _c("p", { key: key, staticClass: "showtime-content" }, [
                  _vm._v(" " + _vm._s(key + ": " + value) + " "),
                ])
              }),
              _c("br"),
            ],
            2
          )
        }),
        0
      ),
      ((this.dest_lang !== "en" &&
        this.detect_lang == _vm.$store.state.profile.language) ||
        this.polish) &&
      false
        ? _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { command: _vm.handleCommand },
            },
            [
              _c(
                "div",
                { staticClass: "dot-box" },
                [
                  _c("svg-icon", {
                    staticClass: "tools-icon",
                    attrs: { "icon-class": "dot" },
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "polish", disabled: _vm.generating } },
                    [_vm._v(_vm._s(_vm.$t("home.polish")))]
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "en", disabled: _vm.lang === "en" } },
                    [_vm._v(_vm._s(_vm.$t("home.viewEnglish")))]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        command: "translate",
                        disabled: _vm.lang !== "en",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("home.viewTranslate", {
                            name: _vm.$t(
                              "home." + this.dest_lang ||
                                _vm.$store.state.profile.language
                            ),
                          })
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSwitch && false
        ? _c(
            "div",
            { staticClass: "switch-box" },
            [
              _c(
                "Tab",
                {
                  attrs: { active: _vm.active },
                  on: { change: _vm.handleMakeShorter },
                },
                [
                  _c("el-tab-pane", {
                    attrs: {
                      disabled: _vm.generating || _vm.active == "rich",
                      label: _vm.$t("home.original"),
                      name: "rich",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      disabled: _vm.generating || _vm.active == "concise",
                      label: _vm.$t("home.concise"),
                      name: "concise",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "wrap-box" }, [
        _c(
          "div",
          { staticClass: "wrap-box-left" },
          [
            !_vm.isProd && _vm.showTimeDetail
              ? _c(
                  "el-button",
                  { staticClass: "btn", on: { click: _vm.handleShowTime } },
                  [_vm._v("Time Detail")]
                )
              : _vm._e(),
            this.dest_lang !== "en" && this.translateContent
              ? _c(
                  "div",
                  { staticClass: "tools-box-lang" },
                  [
                    this.lang !== "en"
                      ? _c("svg-icon", {
                          staticClass: "tools-icon",
                          attrs: { "icon-class": "cn-en" },
                          on: { click: _vm.changeAnsLang },
                        })
                      : _vm._e(),
                    this.lang === "en"
                      ? _c("svg-icon", {
                          staticClass: "tools-icon",
                          attrs: { "icon-class": "en-cn" },
                          on: { click: _vm.changeAnsLang },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            this.isSubModuleSuccess !== false
              ? _c(
                  "div",
                  { staticClass: "rate-box" },
                  [
                    _c("el-rate", {
                      attrs: {
                        colors: ["var(--text)", "var(--text)", "var(--text)"],
                        "void-color": "var(--text)",
                        max: 5,
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.rate,
                        callback: function ($$v) {
                          _vm.rate = $$v
                        },
                        expression: "rate",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            this.isSubModuleSuccess !== false
              ? _c(
                  "div",
                  { staticClass: "tools-box", on: { click: _vm.openComments } },
                  [
                    _c("svg-icon", {
                      staticClass: "tools-icon",
                      attrs: { "icon-class": "comment" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        this.isSubModuleSuccess !== false
          ? _c(
              "div",
              {
                staticClass: "tools-box",
                staticStyle: { "margin-left": "2px" },
                on: { click: _vm.copy },
              },
              [
                _c("svg-icon", {
                  staticClass: "tools-icon",
                  attrs: { "icon-class": "copybtn" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }