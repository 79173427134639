var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "menu-box",
        class: [
          _vm.$store.state.homeScroll
            ? "scroll animate__animated animate__fadeInDown"
            : "noScroll",
        ],
      },
      [
        _c("img", {
          attrs: {
            src: require("../../assets/images/Digital Ray.png"),
            alt: "",
          },
        }),
        _c(
          "div",
          { staticClass: "btn-box" },
          [
            _c(
              "div",
              { staticClass: "container borderXwidth" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item-box",
                    on: {
                      click: function ($event) {
                        return _vm.clickMenu(item, index)
                      },
                    },
                  },
                  [
                    _c("a", [_vm._v(_vm._s(item.label))]),
                    _c("div", {
                      class: [_vm.$route.name == item.name ? "on" : ""],
                    }),
                  ]
                )
              }),
              0
            ),
            _c(
              "el-button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.$router.replace({ name: "login" })
                  },
                },
              },
              [_vm._v("Log In")]
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "mobile-box",
        class: [
          _vm.$store.state.homeScroll && !_vm.menuActive
            ? "scroll"
            : "noScroll",
        ],
      },
      [
        _c("img", {
          attrs: {
            src: require("../../assets/images/Digital Ray.png"),
            alt: "",
          },
        }),
        _c(
          "div",
          {
            staticClass: "button_container",
            class: [_vm.menuActive ? "active" : ""],
            on: { click: _vm.handleClick },
          },
          [
            _c("span", { staticClass: "top" }),
            _c("span", { staticClass: "middle" }),
            _c("span", { staticClass: "bottom" }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "overlay",
            class: [_vm.menuActive ? "open" : ""],
            attrs: { id: "overlay" },
          },
          [
            _c("nav", { staticClass: "overlay-menu" }, [
              _c(
                "ul",
                [
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.clickMobileMenu(item, index)
                          },
                        },
                      },
                      [_c("a", [_vm._v(_vm._s(item.label))])]
                    )
                  }),
                  _c("li", { on: { click: _vm.login } }, [
                    _c("a", [_vm._v("Log In")]),
                  ]),
                ],
                2
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }