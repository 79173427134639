var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tts-box",
      class: [_vm.audioType === "play" ? "networking-bubble" : "chart-bubble"],
    },
    [
      _vm.audioType === "stop"
        ? _c("span", [_vm._v(_vm._s(_vm.$t("home.paused")))])
        : _vm._e(),
      _vm.audioType === "play"
        ? _c("span", { staticClass: "loading" }, [
            _vm._v(_vm._s(_vm.$t("home.speaking"))),
          ])
        : _vm._e(),
      _vm.audioType === "stop"
        ? _c(
            "el-button",
            {
              staticClass: "audio-btn",
              attrs: { disabled: _vm.ttsLoad },
              on: { click: _vm.open },
            },
            [
              !_vm.ttsLoad
                ? _c("svg-icon", {
                    staticClass: "icon",
                    attrs: { "icon-class": "tts-normal" },
                  })
                : _vm._e(),
              _vm.ttsLoad
                ? _c("i", { staticClass: "loading-icon el-icon-loading" })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.$t("common.Play"))),
            ],
            1
          )
        : _vm._e(),
      _vm.audioType === "play"
        ? _c(
            "el-button",
            {
              staticClass: "audio-btn",
              attrs: { disabled: _vm.closeLoad },
              on: { click: _vm.pause },
            },
            [
              !_vm.closeLoad
                ? _c("svg-icon", {
                    staticClass: "icon",
                    attrs: { "icon-class": "tts-stop" },
                  })
                : _vm._e(),
              _vm.closeLoad
                ? _c("i", { staticClass: "loading-icon el-icon-loading" })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.$t("common.stop"))),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }