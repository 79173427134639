<template>
  <div v-if="this.showAppHelp">
    <div class="top-cover"></div>
    <div class="app-help" >
      <el-button class="skip-btn" @click="this.endAppHelp">{{ $t('help.skip') }}</el-button>
      <img class="img-top-left" src="../../../../assets/images/app-help-top-left.png" alt="" />
      <img class="img-top-right" src="../../../../assets/images/app-help-top-right.png" alt="" />
      <!-- <img class="img-bottom-left" src="../../../../assets/images/app-help-bottom-left.png" alt="" /> -->
      <img class="img-bottom-right" src="../../../../assets/images/app-help-bottom-right.png" alt="" />
      <!-- <img class="img-bottom" src="../../../../assets/images/app-help-bottom.png" alt="" /> -->
      <span class="step1">{{ $t('help.appStep1') }}</span>
      <span class="step2">{{ $t('help.appStep2') }}</span>
      <span class="step3">{{ $t('help.appStep3') }}</span>
      <!-- <span class="step4">{{ $t('help.appStep4') }}</span> -->
      <span class="step5">{{ $t('help.appStep5') }}</span>

    </div>
      <div class="cover"></div>
  </div>
</template>

<script>

export default {
  name: 'app-help',
  data() {
    return {
      showAppHelp: false
    }
  },
  computed: {
  },
  watch: {
    '$store.state.appHelps': {
      handler(val) {
        if (val) {
          this.startAppHelp()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function () {
  },
  methods: {
    startAppHelp() {
      this.showAppHelp = true
    },
    endAppHelp() {
      this.showAppHelp = false
      this.$store.commit('setAppHelps', false)
    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep .footer-wrap{
//   z-index: 800 !important;
// }
.top-cover{
  position: fixed;
  z-index: 10000;
  top: 0;
  width: 100%;
  height: 200px;
}
.cover{
  position: fixed;
  z-index: 10000;
  bottom: 0;
  width: 100%;
  height: 200px;
}
.skip-btn {
  width: 240px;
  background: transparent;
  border: 1px solid var(--white);
  color: var(--white);
  font-size: 15px;
  font-family: Roboto;
  border-radius: 6px;
  line-height: 0;
  height: 44px;
  z-index: 200;
}
.app-help {
  position: fixed;
  top:  calc(env(safe-area-inset-top) + 47px);
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--cover);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-top-left {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 47px);
  left: 30px;
  width: auto;
  height: 114px;
  background: transform;
  z-index: 1001;
  display: flex;
}
.img-top-right {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 47px);
  right: 20px;
  width: auto;
  height: 152px;
  background: transform;
  z-index: 1001;
  display: flex;
}
.img-bottom-left {
  position: fixed;
  bottom:  calc(env(safe-area-inset-top) + 70px);
  left: 30px;
  width: auto;
  height: 105px;
  background: transform;
  z-index: 1001;
  display: flex;
}
.img-bottom-right {
  position: fixed;
  bottom: calc(env(safe-area-inset-top) + 70px);
  right: 35px;
  width: auto;
  height: 53px;
  background: transform;
  z-index: 1001;
  display: flex;
}
.img-bottom {
  position: fixed;
  bottom: calc(env(safe-area-inset-top) + 0px);
  left: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  width: 100%;
  background: transform;
  z-index: 1001;
  display: flex;
}
.step1 {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 165px);
  left: 32px;
  background: transform;
  color: #fff;
  font-size: 15px;
  font-family: Roboto;
  z-index: 1001;
  display: flex;
    flex-wrap: wrap;
    max-width: 50%;
}
.step2 {
    position: fixed;
    top: calc(env(safe-area-inset-top) + 120px);
    right: 62px;
    background: transform;
    color: #fff;
    font-size: 15px;
    font-family: Roboto;
    z-index: 1001;
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
}
.step3 {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 200px);
  right: 32px;
  width: 180px;
  background: transform;
  color: #fff;
  font-size: 15px;
  font-family: Roboto;
  z-index: 1001;
  display: flex;
}
.step4 {
  position: fixed;
  bottom:calc(env(safe-area-inset-top) + 180px);
  left: 20px;
  width: 180px;
  background: transform;
  color: #fff;
  font-size: 15px;
  font-family: Roboto;
  z-index: 1001;
  display: flex;
}
.step5 {
  position: fixed;
  bottom: calc(env(safe-area-inset-top) + 120px);
  right: 22px;
  width: 240px;
  background: transform;
  color: #fff;
  font-size: 15px;
  font-family: Roboto;
  z-index: 1001;
  display: flex;
}
.ask-anything {
  position: fixed;
  bottom: 36px;
  left: 86px;
  background: transform;
  color: #fff;
  font-size: 15px;
  font-family: Roboto;
  z-index: 1001;
  display: flex;
}
</style>
