var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "bubble" }, [
    _vm.loading
      ? _c("div", { staticClass: "chart-bubble" }, [
          _vm.loading
            ? _c("span", { staticClass: "chat-message-cursor" }, [_vm._v("▍")])
            : _vm._e(),
        ])
      : _c(
          "div",
          { staticClass: "message-content-container" },
          [
            _vm.$store.state.profile.audio_switch !== null &&
            _vm.value &&
            _vm.isSubModuleSuccess !== false &&
            !_vm.type &&
            !_vm.mine &&
            !_vm.change_lang_alarm &&
            _vm.isLast &&
            !_vm.toggleMessage
              ? _c("TTS", {
                  attrs: {
                    id: _vm.id,
                    content: _vm.value,
                    typing: _vm.typing,
                    loading: _vm.loading,
                    isSubModuleSuccess: _vm.isSubModuleSuccess,
                  },
                  on: { audioType: _vm.handleAudioType },
                })
              : _vm._e(),
            _vm.sender === "DFO" &&
            !_vm.mine &&
            _vm.thumbnails.length > 0 &&
            _vm.isSubModuleSuccess !== false
              ? _c(
                  "div",
                  {
                    ref: "thumbnail",
                    staticClass: "thumbnail",
                    on: {
                      touchstart: function ($event) {
                        return _vm.toggleSwiper(true)
                      },
                      touchmove: function ($event) {
                        return _vm.toggleSwiper(true)
                      },
                      touchend: function ($event) {
                        return _vm.toggleSwiper(false)
                      },
                    },
                  },
                  [
                    !_vm.$store.state.isMin900Width
                      ? _c(
                          "ScrollBox",
                          [
                            _c("Thumbnail", {
                              attrs: { thumbnails: _vm.thumbnails },
                            }),
                          ],
                          1
                        )
                      : _c("Thumbnail", {
                          attrs: { thumbnails: _vm.thumbnails },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.mine &&
                      !_vm.isIntroMessage &&
                      _vm.aotProcessInfo.aotSearch &&
                      _vm.showAot &&
                      _vm.isSubModuleSuccess !== false,
                    expression:
                      "\n        !mine &&\n        !isIntroMessage &&\n        aotProcessInfo.aotSearch &&\n        showAot &&\n        isSubModuleSuccess !== false\n      ",
                  },
                ],
                staticClass: "chart-bubble",
              },
              [
                _c("AOT", {
                  attrs: {
                    aotProcessInfo: _vm.aotProcessInfo,
                    aotFaq: _vm.aotFaq,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasPrinciple && false,
                    expression: "hasPrinciple && false",
                  },
                ],
                staticClass: "principles",
                class: _vm.mine ? "mine-chart-bubble" : "principle-bubble",
              },
              [_vm._v(" " + _vm._s(_vm.$t("home.aotTip")) + " ")]
            ),
            _vm._l(_vm.principles, function (principle, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSubModuleSuccess !== false,
                      expression: "isSubModuleSuccess !== false",
                    },
                  ],
                  key: index,
                  staticClass: "principle-bubble",
                  class: true
                    ? "principle-bubble-content"
                    : "principle-bubble-nolink-content",
                  on: {
                    click: function ($event) {
                      return _vm.linkTo(principle)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "principle-key" }, [
                    _c("span", { staticClass: "principle-line" }, [
                      _vm._v(" " + _vm._s(principle.type) + " "),
                    ]),
                    principle.type !== "PRINCIPLE OF THE DAY"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "long",
                                rawName: "v-long",
                                value: (val) =>
                                  _vm.addPrinciple(val, principle),
                                expression:
                                  "(val)=>addPrinciple(val,principle)",
                              },
                            ],
                            staticClass: "flag-box",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "add-p",
                              attrs: {
                                "icon-class": principle.saved_to_my_principle
                                  ? "flag"
                                  : "add-flag",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span", { staticClass: "principle-key-content" }, [
                      _vm._v(_vm._s(principle.title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "principle-val only2" }, [
                    _c("span", { staticStyle: { "font-size": "13px" } }, [
                      _vm._v(_vm._s(principle.content)),
                    ]),
                  ]),
                ]
              )
            }),
            _vm.sender === "DFO" &&
            _vm.network &&
            _vm.networkError &&
            _vm.networkText
              ? _c("div", { staticClass: "chart-bubble" }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.networkText) },
                  }),
                ])
              : _vm.sender === "DFO" &&
                _vm.networkText &&
                _vm.typing &&
                !_vm.value
              ? _c("div", { staticClass: "networking-bubble loading" }, [
                  _vm._v(" " + _vm._s(_vm.networkText) + " "),
                ])
              : _vm._e(),
            (_vm.formatContent.length == 0 && _vm.sender !== "DFO") ||
            (!_vm.networkText && _vm.formatContent.length == 0 && _vm.typing)
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.aotProcessInfo.aotSearch,
                        expression: "!aotProcessInfo.aotSearch",
                      },
                    ],
                    staticClass: "chart-bubble",
                  },
                  [
                    _c("span", { staticClass: "chat-message-cursor" }, [
                      _vm._v("▍"),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.mine && _vm.faq && _vm.faq.chooseId === "default",
                    expression: "!mine && faq && faq.chooseId === 'default'",
                  },
                ],
              },
              [
                _c("div", { staticClass: "chart-bubble" }, [
                  _vm._v(" " + _vm._s(_vm.$t("home.faqTip")) + " "),
                ]),
                _vm.getFaqList.length > 0
                  ? _c("FAQ", {
                      attrs: { tabs: _vm.getFaqList },
                      on: { changeFaq: _vm.changeFaq },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.formatContent.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.formatContent, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.content,
                        class: [
                          _vm.mine
                            ? "mine-chart-bubble"
                            : "chart-bubble markdown",
                          _vm.toggleMessage ? "toggle-message-bubble" : "",
                          _vm.isSubModuleSuccess === false &&
                          !_vm.type &&
                          !_vm.mine
                            ? "red"
                            : "",
                          _vm.language !== "en" &&
                          _vm.typing &&
                          !_vm.stopped &&
                          !_vm.polish
                            ? "translation"
                            : "",
                        ],
                        style: {
                          width:
                            !_vm.typing &&
                            _vm.sender === "DFO" &&
                            item.tools &&
                            item.showComment
                              ? "100%"
                              : "fit-content",
                        },
                      },
                      [
                        _vm.isForceUpdated
                          ? _c(
                              "div",
                              {
                                class: [
                                  "chat-message-markdown",
                                  {
                                    "is-stopped": _vm.stopped,
                                    "is-typing": _vm.typing,
                                  },
                                ],
                              },
                              [
                                _vm.mine
                                  ? _c(
                                      "div",
                                      [
                                        _c("vue-markdown", {
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                          attrs: {
                                            source: item.content,
                                            html: false,
                                            typographer: false,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.mine && !_vm.isIntroMessage
                                  ? _c("div", [
                                      _vm.typing && !_vm.stopped
                                        ? _c("div", [
                                            item.finish
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("vue-markdown", {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                      },
                                                      attrs: {
                                                        source: item.content,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("vue-markdown", {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                      },
                                                      attrs: {
                                                        source:
                                                          item.content +
                                                          "<span class=" +
                                                          "chat-message-cursor" +
                                                          ">▍</span>",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c("vue-markdown", {
                                                attrs: { source: item.content },
                                              }),
                                            ],
                                            1
                                          ),
                                    ])
                                  : _vm._e(),
                                !_vm.typing &&
                                index == _vm.formatContent.length - 1 &&
                                _vm.toggleMessage &&
                                _vm.sender === "DFO" &&
                                _vm.isSubModuleSuccess === false &&
                                _vm.isLast
                                  ? _c("Guess", {
                                      ref: "guessRef",
                                      refInFor: true,
                                      attrs: {
                                        toggleMessage: _vm.toggleMessage,
                                      },
                                      on: { change: _vm.handleRegenerate },
                                    })
                                  : _vm._e(),
                                !_vm.typing &&
                                index == _vm.formatContent.length - 1 &&
                                _vm.evidences.evidences &&
                                _vm.evidences.evidences.length > 0 &&
                                _vm.sender === "DFO" &&
                                _vm.isSubModuleSuccess !== false
                                  ? _c("Evidences", {
                                      attrs: {
                                        evidences: _vm.evidences,
                                        id: _vm.id,
                                      },
                                      on: { click: _vm.handleClickFaqSource },
                                    })
                                  : _vm._e(),
                                !_vm.typing &&
                                index == _vm.formatContent.length - 1 &&
                                _vm.principles?.length > 0 &&
                                _vm.sender === "DFO" &&
                                !(
                                  _vm.evidences.evidences &&
                                  _vm.evidences.evidences.length > 0
                                ) &&
                                _vm.isSubModuleSuccess !== false
                                  ? _c("MyPrinciple")
                                  : _vm._e(),
                                _c("div"),
                                !_vm.typing &&
                                index == _vm.formatContent.length - 1 &&
                                _vm.useInternet === "internet search success" &&
                                item.isSubModuleSuccess === false
                                  ? _c(
                                      "div",
                                      { staticClass: "logo" },
                                      [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            "margin-left": "8px",
                                            "font-size": "20px",
                                            "margin-right": "4px",
                                          },
                                          attrs: { "icon-class": "net-search" },
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("home.information"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isSubModuleSuccess === false &&
                                !_vm.type &&
                                !_vm.mine &&
                                !_vm.change_lang_alarm &&
                                _vm.isLast &&
                                !_vm.toggleMessage
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "regenerate",
                                        attrs: { loading: _vm.isLoading },
                                        on: { click: _vm.handleRegenerate },
                                      },
                                      [
                                        !_vm.isLoading
                                          ? _c("svg-icon", {
                                              staticClass: "chat-icon",
                                              attrs: {
                                                "icon-class": "refresh",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("footer.regenerate"))
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("PrincipleDialog", {
              ref: "principleDom",
              attrs: { principle: _vm.principle },
            }),
            _c("Dialog", {
              ref: "initDom",
              attrs: {
                content: _vm.$t("intro.content"),
                title: _vm.$t("intro.title"),
                full: true,
              },
            }),
            _c("MyPrincipleDialog", {
              ref: "dialog",
              attrs: { type: _vm.principleType, item: _vm.nowItem },
              on: {
                edit: () => {
                  _vm.principleType = "edit"
                },
                change: _vm.handleChangePrinciple,
              },
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }