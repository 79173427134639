<template>
  <div class="comment-box">
    <div class="cover-box" v-if="audioStatus === 'start'"></div>
    <div class="audio-box" ref="audioBox" v-if="false">
      <div v-for="(item, index) in audios" :key="item.id" class="play-box-wrap">
        <div
          class="play-box"
          @click="play(item, index)"
          :style="{ width: `calc(48px + ${Number(item.duration / 2)}vw)` }"
        >
          <img
            v-if="!item.isPlaying"
            src="../../../../assets/images/audio.png"
            alt
          />
          <img
            v-if="item.isPlaying"
            src="../../../../assets/images/audio.gif"
            alt
          />
          <span>{{ showTime(item.duration) }}</span>
          <audio :ref="item.id" :src="item.data"></audio>
        </div>
        <svg-icon class="icon" icon-class="delete" @click="del(item, index)" />
      </div>
    </div>
    <div class="wave-box" v-show="audioStatus === 'start'">
      <div class="sound-wave-wrapper">
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
      </div>
    </div>
    <div class="btn-box" v-if="false">
      <el-button
        class="btn"
        :class="[audioStatus === 'end' ? '' : 'click-btn']"
        @mousedown.native="audioStart"
        @mouseup.native="audioEnd"
        @touchstart.native="audioStart"
        @touchend.native="audioEnd"
        @blur="audioEnd"
      >
        <svg-icon icon-class="sound-on1" class="sound-on" />
        {{ $t("home.audioTip") }}
      </el-button>
    </div>

    <div
      class="question"
      ref="questionBox"
      :class="[extend ? 'extend' : 'no-extend']"
      @click="handleExtend"
    >
      <svg-icon
        v-if="showExtend"
        :class="[extend ? 'extend-icon' : 'no-extend-icon']"
        icon-class="right"
      />
      <span>{{ question }}</span>
    </div>

    <div class="input-box" ref="textarea">
      <div class="suffix">
        <el-button type="text" class="btn-send">
          <!-- <el-tooltip :content="'Send'" effect="dark" placement="top"> -->
          <svg-icon icon-class="close" class="send" @click="clear" />
        </el-button>
      </div>
      <el-input
        :style="{ height: inputH }"
        type="textarea"
        resize="none"
        v-model="comment"
        ref="inputRef"
        :placeholder="$t('footer.comment')"
        @clear="handleInput"
      ></el-input>
    </div>
    <div class="btn-box save-box">
      <el-button class="save-btn" @click="handleSend" :loading="loading">
        {{ $t("common.commentSave") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { VoiceRecorder } from 'capacitor-voice-recorder'
import { v4 as uuidv4 } from 'uuid'
import * as webchat from '@/api/webchat'
import { Keyboard } from '@capacitor/keyboard'
export default {

  data() {
    return {
      comment: '',
      audios: [],
      audioStatus: 'end',
      startTime: '',
      endTime: '',
      extend: false,
      showExtend: false,
      currentAudio: null,
      loading: false,
      lastHeight: 0,
      keyboardHeight: 0,
      question: '',
      inputH:
        'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 220px) !important'
    }
  },
  watch: {
    comment: {
      handler(val) {
        this.checkHeightChange()
      },
      deep: true
    },
    '$store.state.currentChatId': {
      handler(val) {
        if (val) {
          this.getlist()
        }
      },
      deep: true
    }
  },
  computed: {
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    }
  },
  mounted() {
    this.handleResize = this.throttle(this.onResize, 10)
    window.addEventListener('resize', this.handleResize)

    if (this.$store.state.currentChatId) {
      this.getlist()
    }

    // this.initKeyborad()
  },
  beforeDestroy() {},
  methods: {
    initKeyborad() {
      if (this.isApp) {
        Keyboard.addListener('keyboardWillShow', (info) => {
          this.keyboardHeight = info.keyboardHeight
          console.log('keyboard will show with height', info.keyboardHeight)
          this.initHeight()
        })

        Keyboard.addListener('keyboardWillHide', (info) => {
          // alert(info.keyboardHeight)
          this.$nextTick(() => {
            setTimeout(() => {
              this.keyboardHeight = 0
              this.initHeight()
            }, 100)
          })
          console.log('keyboard did show with height', info.keyboardHeight)
        })
      }
    },
    initHeight() {
      let inputH = 30
      if (this.$refs.questionBox) {
        if (this.$refs.questionBox.clientHeight) {
          inputH = this.$refs.questionBox.clientHeight
        }
      }
      this.inputH = `calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - ${Number(
        inputH + 170
          )}px) !important`
    },
    handleExtend() {
      this.extend = !this.extend
      this.initHeight()
    },
    throttle(func, limit) {
      let inThrottle
      return function () {
        const args = arguments
        const context = this
        if (!inThrottle) {
          func.apply(context, args)
          inThrottle = true
          setTimeout(() => {
            inThrottle = false
          }, limit)
        }
      }
    },

    handleInput() {
      this.checkHeightChange()
    },
    isShowExtend() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.questionBox) {
            if (
              this.$refs.questionBox.clientWidth <
              this.$refs.questionBox.scrollWidth
            ) {
              this.showExtend = true
            } else {
              this.showExtend = false
            }
          } else {
            this.showExtend = false
          }
        }, 300)
      })
    },
    getlist() {
      this.comment = ''
      for (const i of this.$store.state.history) {
        if (i.id === this.$store.state.currentChatId && !i.mine) {
          if (!i.comments) {
            // for (const j of i.formatContent) {
            //   this.comment = this.comment + j.content
            // }
          } else {
            this.comment = i.comments
          }
        }
        if (i.id === this.$store.state.currentChatId && i.mine) {
          this.question = i.content
          this.isShowExtend()
        }
      }
      this.$nextTick(() => {
        // this.$refs.inputRef.focus()
        this.initHeight()
        this.checkHeightChange()
      })
      // webchat.getTextComment(this.$store.state.currentChatId).then(res => {
      //   if (res.data.comments) {
      //     this.comment = res.data.comments
      //   }
      //   this.$nextTick(() => {
      //     this.$refs.inputRef.focus()
      //     this.checkHeightChange()
      //   })
      // })
      // webchat.getAudioComment(this.$store.state.currentChatId).then(res => {
      //   if (res.data.audios) {
      //     this.audios = []

      //     for (const i of res.data.audios) {
      //       this.audios.unshift({
      //         id: i.id,
      //         data: i.data,
      //         duration: i.duration,
      //         isPlaying: false
      //       })
      //     }
      //     this.$nextTick(() => {
      //       this.scrollToBottom()
      //     })
      //   }
      // })
    },
    saveAudio(audio) {
      const data = {
        id: this.$store.state.currentChatId,
        audios: [audio]
      }
      const id = this.$store.state.currentChatId
      this.$emit('change', {
        rating: null,
        comment: this.comment,
        id: id,
        audio: this.audios.length > 0 ? this.audios : ''
      })
      webchat.updateAudioComment(data).then((res) => {})
    },
    saveComment() {
      this.loading = true
      const id = this.$store.state.currentChatId
      const data = {
        type: 1,
        sessionId: this.$store.state.sessionId,
        recordId: this.$store.state.currentChatId,
        content: this.comment
      }
      webchat
        .comment(data)
        .then((res) => {
          this.loading = false
          this.$message.success({
            message: this.$t('common.comment'),
            customClass: 'message-success',
            duration: 2000
          })
          this.close()
          this.$emit('change', {
            rating: null,
            comment: this.comment,
            id: id,
            audio: this.audios.length > 0 ? this.audios : ''
          })
        })
        .catch((res) => {
          this.loading = false
        })
    },

    close(e) {
      this.$store.commit('setAppComment', false)
      this.$store.commit('setCurrentChatId', '')
    },
    play(val, index) {
      this.audios.forEach((el, i) => {
        const item = this.$refs[el.id][0]
        if (i === index) {
          if (this.audios[i].isPlaying) {
            item.pause()
          } else {
            item.play()
            item.addEventListener('ended', () => {
              this.$set(this.audios[i], 'isPlaying', false)
            })
          }
          this.$set(this.audios[i], 'isPlaying', !this.audios[i].isPlaying)
        } else {
          item.pause()
          this.$set(this.audios[i], 'isPlaying', false)
        }
      })
    },
    del(val, index) {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('home.deleteAudio'),
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('home.deleteAudioTip'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.audios.splice(index, 1)
        webchat.delAudioComment(val.id).then((res) => {})
      })
    },
    handleSend() {
      this.saveComment()
    },
    clear() {
      this.comment = ''
    },
    audioStart(event) {
      console.info('start')
      this.audioStatus = 'start'
      this.startRecording()
      event.preventDefault()
      // this.$refs.inputRef.blur()
    },
    audioEnd(event) {
      console.info('end')
      this.audioStatus = 'end'
      this.stopRecording()
      event.preventDefault()
      this.$refs.inputRef.blur()
    },
    async startRecording() {
      try {
        await VoiceRecorder.hasAudioRecordingPermission()
        await VoiceRecorder.startRecording({ audio: true })
        this.startTime = Date.now()
      } catch (error) {
        await VoiceRecorder.requestAudioRecordingPermission()
        console.error('Error starting recording:', error)
      }
    },
    onResize() {
      this.isShowExtend()
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     const textarea = this.$refs.textarea
      //     if (textarea) {
      //       console.info('textarea', textarea)
      //       const currentHeight = textarea.scrollHeight
      //       this.lastHeight = currentHeight
      //       const elAudioBox = this.$refs.audioBox
      //       if (elAudioBox) {
      //         elAudioBox.style.maxHeight = `calc(100vh - env(safe-area-inset-top) - ${Number(this.lastHeight + 190)}px)`
      //         this.$forceUpdate()
      //       }
      //     }
      //   })
      // }, 100)
    },
    checkHeightChange() {
      // 获取 textarea DOM 元素
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     const textarea = this.$refs.textarea
      //     if (textarea) {
      //       console.info('textarea', textarea)
      //       const currentHeight = textarea.scrollHeight
      //       if (this.lastHeight !== currentHeight) {
      //         console.log(`行高变化：从 ${this.lastHeight}px 到 ${currentHeight}px`)
      //         this.lastHeight = currentHeight
      //         const elAudioBox = this.$refs.audioBox
      //         if (elAudioBox) {
      //           elAudioBox.style.maxHeight = `calc(100vh - env(safe-area-inset-top) - ${Number(this.lastHeight + 190)}px)`
      //           this.$forceUpdate()
      //         }
      //       }
      //     }
      //   })
      // }, 100)
    },
    showTime(val) {
      if (val < 60) {
        return val + '’’'
      } else {
        const minTotal = Math.floor(val / 60) // 分钟
        const sec =
          Math.floor(val % 60) > 9
            ? Math.floor(val % 60)
            : '0' + Math.floor(val % 60) // 余秒
        if (minTotal < 60) {
          const showmin = minTotal > 9 ? minTotal : '0' + minTotal
          return showmin + '’' + sec + '’’'
        }
      }
    },
    getWidth(val) {
      let res = '0px'
      if (val < 60) {
        res = `calc(0.4/60vw * ${val})`
      }
      if (val > 60) {
        res = 'calc(0.6vw)'
      }
      return res
    },
    formattedDuration(duration) {
      const minutes = Math.floor(duration / 60)
      const seconds = Math.floor(duration % 60)
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    },
    scrollToBottom() {
      // const container = this.$refs.audioBox
      // container.scrollTop = container.scrollHeight
    },
    async stopRecording() {
      try {
        const result = await VoiceRecorder.stopRecording()
        this.endTime = Date.now()
        const item = {
          id: uuidv4(),
          duration: moment(this.endTime).diff(
            moment(this.startTime),
            'seconds'
          ),
          data:
            'data:' +
            result.value.mimeType +
            ';base64,' +
            result.value.recordDataBase64,
          isPlaying: false
        }

        this.audios.unshift(item)
        this.$nextTick(() => {
          this.scrollToBottom()
          this.saveAudio(item)
        })
      } catch (error) {
        console.error('Error stopping recording:', error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.question {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--text);
  padding: 6px 10px;
  border: 0.5px solid var(--br);
  max-width: 100%;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 8px;
  padding-right: 20px;
  margin-top: 8px;
}
.no-extend {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.extend {
  white-space: normal;
}
.no-extend-icon {
  transform: rotate(90deg);
  position: absolute;
  right: 24px;
  margin-top: 3px;
}
.extend-icon {
  transform: rotate(270deg);
  position: absolute;
  right: 24px;
  margin-top: 3px;
}
.btn-box {
  width: 100%;
  text-align: center;
  margin: auto;

}
.save-box{
  // position: fixed;
  bottom: calc(
    env(safe-area-inset-bottom) + 10px
  );
  width: calc(100%);
}
.save-btn {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: var(--bg);
  background: var(--blue);
  border: none;
  margin: auto;
  margin-top: 4vh;
  margin-bottom: 8px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-textarea__inner::placeholder{
  font-family: Roboto;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--ss-text);
}
::v-deep .el-textarea__inner {
  background: var(--dropdown-bg);
  color: var(--text);
  height: 100%;
  left: 16px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  // margin-top: -16px;
  padding: 8px 40px 8px 16px;
}
.comment-box {
  padding-bottom: calc(env(safe-area-inset-bottom) + 26px);
  display: flex;
  flex-wrap: wrap;
  max-height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 60px
  ) !important;
  bottom: 0 !important;
  overflow: hidden;
  .btn {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 1px solid var(--br);
    background: none;
    color: var(--text);
    margin-bottom: 12px;
    width: calc(100vw - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .click-btn {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 1px solid var(--br);
    background: none;
    color: var(--h-text);
    background: var(--bg);
    width: calc(100vw - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn-send {
  }
  .input-box {
    margin-bottom: 0px;
    width: calc(100vw - 32px);
    position: relative;
  }
  .suffix {
    right: 6px;
    top: 0px;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
  }
  .send {
    color: var(--text);
  }
  .wave-box {
    z-index: 500;
    display: flex;
    justify-content: center;
    height: calc(30px);
    width: calc(100% - 32px);
    align-items: center;
    margin-top: 40px;
    zoom: 0.8;
    position: absolute;
  }
  .audio-box {
    height: 50px;
    overflow: auto;
    width: 100%;
    display: flex;
    margin: 16px 0;
  }
  .audio-box::-webkit-scrollbar {
    display: none;
  }
  .play-box {
    height: 32px;
    border-radius: 8px;
    background-color: var(--green);
    max-width: 60vw;
    // width: fit-content;
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin-bottom: 16px;
    margin-top: 16px;
    margin-right: 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .play-box:last-child {
    margin-bottom: 8px !important;
    margin-top: 8px;
  }
}
.short-bar {
  animation-name: quiet;
}
.medium-bar {
  animation-name: normal;
}
.long-bar {
  animation-name: loud;
}
.box {
  transform: scaleY(0.2);
  height: 100%;
  width: var(--boxSize);
  background: #ffffff;
  animation-duration: 1.4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 4px;
}
.btn-box {
  z-index: 500;
}
.sound-wave {
  display: flex;
  justify-content: center;
  height: 60px;
  z-index: 500;
}
.sound-wave-wrapper {
  display: flex;
  justify-content: space-between;
  height: 42px;
  --boxSize: 4px;
  width: 217px;
}
.play-box-wrap {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.icon {
  font-size: 15px;
  margin-top: 10px;
  // margin-bottom: 2px;
}
.cover-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--cover); /* 半透明背景 */
  z-index: 100;
  top: 0;
  left: 0;
}
@keyframes quiet {
  50% {
    transform: scaleY(0.2);
  }
}
@keyframes normal {
  50% {
    transform: scaleY(0.6);
  }
}
@keyframes loud {
  50% {
    transform: scaleY(1);
  }
}
</style>
