var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tools-box" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Tools in Ecosystem")]),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "home-tile bg1" }, [
        _vm._m(0),
        _c("div", { staticClass: "actions" }, [
          _c(
            "a",
            {
              staticClass: "link label",
              attrs: {
                target: "_blank",
                href: "https://apps.apple.com/us/app/principles-in-action/id1211294305",
              },
            },
            [
              _c("span", [_vm._v("IOS")]),
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { "icon-class": "arrow-icon-red" },
              }),
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "link label",
              attrs: {
                target: "_blank",
                href: "https://play.google.com/store/apps/details?id=com.principles.pia",
              },
            },
            [
              _c("span", [_vm._v("Android")]),
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { "icon-class": "arrow-icon-red" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "home-tile bg2" }, [
        _vm._m(1),
        _c("div", { staticClass: "actions" }, [
          _c(
            "a",
            {
              staticClass: "link label",
              attrs: { target: "_blank", href: "https://principlesyou.com/" },
            },
            [
              _c("span", [_vm._v("PrinciplesYou")]),
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { "icon-class": "arrow-icon-red" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "home-tile bg3" }, [
        _vm._m(2),
        _c("div", { staticClass: "actions" }, [
          _c(
            "a",
            {
              staticClass: "link label",
              attrs: { target: "_blank", href: "https://principlesus.com/" },
            },
            [
              _c("span", [_vm._v("PrinciplesUs")]),
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { "icon-class": "arrow-icon-red" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "home-tile bg4" }, [
        _vm._m(3),
        _c("div", { staticClass: "actions" }, [
          _c(
            "a",
            {
              staticClass: "link label",
              attrs: {
                target: "_blank",
                href: "https://principles.com/redirect",
              },
            },
            [
              _c("span", [_vm._v("Principles on the Web")]),
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { "icon-class": "arrow-icon-red" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "tile-heading" }, [
        _vm._v("Principles in Action"),
      ]),
      _c("p", { staticClass: "tile-body body-2" }, [
        _vm._v(" A free app that brings to life "),
        _c("i", [_vm._v("Principles: Life & Work")]),
        _vm._v(". "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "tile-heading" }, [_vm._v("PrinciplesYou")]),
      _c("p", { staticClass: "tile-body body-2" }, [
        _vm._v(" The free personality assessment for individuals. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "tile-heading" }, [_vm._v("PrinciplesUs")]),
      _c("p", { staticClass: "tile-body body-2" }, [
        _vm._v(" The NEW personality assessment for teams. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "tile-heading" }, [
        _vm._v("Principles on the Web"),
      ]),
      _c("p", { staticClass: "tile-body body-2" }, [
        _vm._v(" Learn more by exploring Principles online "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Contact & Support")]),
      _c("div", { staticClass: "sub-title" }, [_vm._v("Contact us")]),
      _c("div", { staticClass: "sub-text" }, [_vm._v("contact@digitalray.ai")]),
      _c("div", { staticClass: "sub-title" }, [_vm._v("Support")]),
      _c("div", { staticClass: "sub-text" }, [_vm._v("support@digitalray.ai")]),
      _c("div", { staticClass: "footer-box" }, [
        _c("div", { staticClass: "ray-box" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/Digital Ray.png"),
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "text-box" }, [
          _c("span", [_vm._v("DIGITAL RAY © 2023")]),
          _c("span", [_vm._v("Terms of Service")]),
          _c("span", [_vm._v("Privacy Policy")]),
        ]),
        _c("div", { staticClass: "img-box" }, [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.facebook.com/raydalio/",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/contact-1.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "a",
            {
              attrs: { target: "_blank", href: "https://twitter.com/RayDalio" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/contact-2.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.linkedin.com/in/raydalio",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/contact-3.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.instagram.com/principles/?hl=en",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/contact-4.png"),
                  alt: "",
                },
              }),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }