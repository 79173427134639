<template>
  <div
    class="chat-input"
    ref="box"
    :class="[message.length > 900 ? 'showLimit' : '']"
  >
    <el-input
      id="v-step-8"
      ref="input"
      v-model="message"
      @keydown.native="handleKeyUp"
      :placeholder="isFocused ? '' : $t('footer.ask')"
      maxlength="1000"
      :show-word-limit="message.length > 900"
      type="textarea"
      :autosize="{ minRows: 1, maxRows: 4 }"
      @focus="adjustInputPosition"
      @blur="resetInputPosition"
      resize="none"
      v-if="showInput"
    >
    </el-input>
    <div class="suffix" slot="suffix"    v-if="isApp"  @touchstart="handleSend">
      <el-button
        data-action="send"
        id="v-step-10"
        type="text"
        slot="suffix"
        class="btn-send"
        :disabled="!canSned"
        :class="[canSned? 'noSent' : 'canSent']"
      >
        <svg-icon icon-class="send" class="send" />
      </el-button>

    </div>
    <div class="suffix" slot="suffix" v-else   @click="handleSend" >
      <el-button
        data-action="send"
        id="v-step-10"
        type="text"
        slot="suffix"
        class="btn-send"
        :disabled="!canSned"
        :class="[canSned? 'noSent' : 'canSent']"
      >
        <svg-icon icon-class="send" class="send" />
      </el-button>

    </div>
  </div>
</template>

<script>

export default {
  inject: ['goBottom'],
  components: {
    // Mic: () => import('./mic.vue')
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    generating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDev() {
      return process.env.VUE_APP_ENV === 'dev'
    },
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    },
    networkStatus() {
      return this.$store.state.networkStatus
    },
    canSned() {
      let res = true
      if (!this.message || this.message.trim() === '') {
        // 如果输入为空或者只有空格，不执行发送操作
        res = false
      }
      if (this.generating) {
        // 如果输入为空或者只有空格，不执行发送操作
        res = false
      }
      if (this.message.length > 1000) {
        res = false
      }
      return res
    },
    message: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      isFocused: false,
      canASR: false,
      messagesHeight: 0,
      showInput: true,
      isComposing: false // 是否处于输入法组合输入状态
    }
  },
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        this.updateInputHeight()
      },
      deep: true
    },
    // '$store.state.sendInput': {
    //   handler(val) {
    //     this.message = val
    //   },
    //   immediate: true
    // },
    canASR: {
      handler() {
        this.$emit('update:canASR', this.canASR)
      },
      immediate: true
    }
  },
  mounted() {
    // this.message = this.$store.state.sendInput
  },
  beforeDestroy() {

  },
  methods: {

    adjustInputPosition(event) {
      this.isFocused = true

      // this.goBottom()
    },
    resetInputPosition() {
      this.isFocused = false
    },
    focus() {
      this.$refs.input.focus()
    },
    handleKeyUp(event) {
      if (
        (event.isComposing || event.keyCode === 229) &&
        this.$store.state.platform === 'pc'
      ) {
        // 正在使用输入法
        this.isComposing = true
      } else {
        // 输入法结束
        this.isComposing = false
      }
      if (
        event.key === 'Enter' &&
        !event.shiftKey &&
        !this.isComposing &&
        this.$store.state.platform === 'pc'
      ) {
        console.info(event, 'event')
        event.preventDefault()
        if (!this.message || this.message.trim() === '') {
          // 如果输入为空或者只有空格，不执行发送操作
          return
        }
        if (!this.isComposing) {
          this.handleSend()
        }
      }
    },
    updateInputHeight() {
      this.showInput = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.showInput = true
        }, 100)
      })
    },

    handleSend(event) {
      console.info('eeeeee')
      // event.preventDefault()
      // event.stopPropagation()
      if (!this.message || this.message.trim() === '') {
        // 如果输入为空或者只有空格，不执行发送操作
        return
      }
      const reg = /!\[([^\]]*)\]\(.*\)/
      if (reg.test(this.message)) {
        this.$message.warning({
          message: this.$t('error.photo'),
          customClass: 'message-error',
          duration: 2000
        })
        return
      }
      if (!this.networkStatus.connected) {
        this.$message.warning({
          message: this.$t('error.noInternet'),
          customClass: 'message-error',
          duration: 2000
        })
        return
      }
      this.$store.commit('setSendInput', this.message)
      if (!this.generating && this.$store.state.sessionId) {
        // console.info('==============this.message', this.message)
        // this.message = this.escapeHTML(this.message)
        // this.message = DOMPurify.sanitize(this.message)
        this.$refs.input.blur()
        this.$emit('send')
      }
    },
    escapeHTML(input) {
      const doc = new DOMParser().parseFromString(input, 'text/html')
      return doc.body.textContent || ''
    },

    handleASR(text) {
      this.message = this.message + text
      this.$store.commit('setSendInput', this.message)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-textarea .el-input__count {
  right: 0px !important;
  background: none;
  bottom: 8px;
  width: fit-content;
  margin-bottom: 16px;
  text-align: right;
  border-top: none;
  margin-right: 8px;
}

::v-deep .el-textarea__inner {
  min-height: 56px !important;
  background: none;
  color: var(--text);
  border: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 48px;
  padding-left: 16px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  border-radius: 12px;
  border: 1px solid var(--h-text);
}
.showLimit {
  ::v-deep .el-textarea__inner {
    // margin-bottom: 18px;
    // border-bottom-left-radius: 0px;
    // border-bottom-right-radius: 0px;
    // border-bottom: none;
  }
}

.btn-send {
  // margin: 0 8px 0 4px;
  font-size: 16px;
  font-weight: bold;
  color: #374151;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  line-height: 0;
  background: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

::v-deep .el-input__inner {
  border-color: #e5e7eb;
}
::v-deep .el-textarea__inner:focus {
  border-color: var(--blue) !important;
}
.suffix {
  position: absolute;
  right: 0;
  top: 6px;
  display: flex;
  align-items: center;
}

.send {
  color: var(--bg);
  font-size: 28px;
}

.canSent {
  background: var(--no-blue) !important;
}
.noSent {
  background: var(--blue) !important;
}
::v-deep .el-textarea__inner::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 900px) {
  .suffix {
    // bottom: -3px;
    top: 4px;
  }
  .send {
  color: var(--bg);
  font-size: 18px;
}
  ::v-deep .el-textarea__inner::-webkit-scrollbar {
    display: none;
  }
  .btn-send {
    // margin: 0 8px 0 4px;
    font-size: 16px;
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }

  ::v-deep .el-textarea .el-input__count {
  }
  ::v-deep .el-textarea__inner {
    min-height: 40px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 15px;
    border: 1px solid var(--h-text);
    padding-left: 16px;
  }

  // ::v-deep .el-textarea__inner {
  //   min-height: 24px !important;
  // }
  // ::v-deep .el-input .el-input__count .el-input__count-inner {
  //   background: #fff0;
  //   line-height: initial;
  //   display: inline-block;
  //   margin-top: -12px;
  // }
  // ::v-deep .el-textarea__inner {
  //   padding-top: 8px;
  //   padding-left: 12px;
  //   padding-bottom: 0px;
  //   margin-bottom:14px;
  // }
  // ::v-deep .el-textarea .el-input__count{
  //   padding-left: 12px;
  //   bottom: 0px;
  //   padding-bottom: 1px;
  // }
}
</style>
