var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    false
      ? _c(
          "div",
          {
            class: [
              "chat-operations",
              {
                "is-horizontal": _vm.horizontal,
                "is-vertical": !_vm.horizontal,
              },
            ],
          },
          [
            _c("TTS", {
              model: {
                value: _vm.canTTS,
                callback: function ($$v) {
                  _vm.canTTS = $$v
                },
                expression: "canTTS",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "btn-stop",
                attrs: { type: "text", disabled: !_vm.generating },
                on: { click: _vm.handleStop },
              },
              [
                _c("svg-icon", { attrs: { "icon-class": "stop" } }),
                _vm._v(" Stop generating "),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "btn-clear",
                attrs: { icon: "el-icon-delete", type: "text" },
                on: { click: _vm.handleClear },
              },
              [_vm._v("Clear History")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "btn-box" },
      [
        false
          ? _c(
              "el-button",
              {
                staticClass: "btn",
                class: [_vm.$store.state.appHelps ? "appHelps btn" : "btn"],
                attrs: { id: "v-step-5" },
                on: { click: _vm.handleClear },
              },
              [
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { "icon-class": "delete" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.$store.state.profile.language == "en"
          ? _c("TTS", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              model: {
                value: _vm.canTTS,
                callback: function ($$v) {
                  _vm.canTTS = $$v
                },
                expression: "canTTS",
              },
            })
          : _vm._e(),
        !_vm.isProd
          ? _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                staticClass: "btn",
                attrs: { disabled: _vm.generating, id: "v-step-7" },
                on: { click: _vm.handleCallRay },
              },
              [
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { "icon-class": "call" },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }