<template>
  <div class="container">
    <div
      class="scroll-button scroll-left"
      v-show="showLeftArrow"
      @click="scrollLeft"
    >
    <svg-icon class="arrow-left" icon-class="down" />
    </div>
    <div class="cards-wrapper" ref="cardsWrapper">
      <slot></slot>
    </div>

    <div
      class="scroll-button scroll-right"
      v-show="showRightArrow"
      @click="scrollRight"
    >
      <svg-icon class="arrow-right" icon-class="down" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLeftArrow: false,
      showRightArrow: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(() => {
      setTimeout(() => {
        this.handleResize()
      }, 10)
    })
  },
  methods: {

    handleResize() {
      this.checkArrows()
      this.setupScrollListener()
    },
    scrollLeft() {
      this.$refs.cardsWrapper.scrollBy({
        left: -200,
        behavior: 'smooth'
      })
    },
    scrollRight() {
      this.$refs.cardsWrapper.scrollBy({
        left: 200,
        behavior: 'smooth'
      })
    },
    checkArrows() {
      const cardsWrapper = this.$refs.cardsWrapper
      if (cardsWrapper) {
        this.showLeftArrow = cardsWrapper.scrollLeft > 0
        this.showRightArrow =
        cardsWrapper.scrollLeft <
        cardsWrapper.scrollWidth - cardsWrapper.clientWidth - 6
      }
    },
    setupScrollListener() {
      const cardsWrapper = this.$refs.cardsWrapper
      if (cardsWrapper) {
        cardsWrapper.addEventListener('scroll', this.checkArrows)
      }
    }
  },
  beforeDestroy() {
    const cardsWrapper = this.$refs.cardsWrapper
    cardsWrapper.removeEventListener('scroll', this.checkArrows)
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.arrow-left {
  transform: rotate(90deg);
  color: var(--s-text);
}
.arrow-right{
  transform: rotate(270deg);
  color: var(--s-text);
}

.cards-wrapper {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.cards-wrapper::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: var(--chart-bg);
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 6px;
}

.scroll-left {
  left: 0;
  margin-left: 0px !important;
}

.scroll-right {
  right: 0;
  margin-right: 0px !important;
}

</style>
