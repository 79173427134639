var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "faq" },
    [
      _c(
        "Dialog",
        {
          ref: "itemDom",
          staticClass: "dialog-content",
          attrs: { title: "", full: true },
        },
        [
          _c("span", { staticClass: "dialog-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("vue-markdown", { attrs: { source: _vm.content } }),
        ],
        1
      ),
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "top-wrap" }, [
          _vm._m(0),
          _c("div", { staticClass: "faq-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("faq.title")))]),
          ]),
          _c(
            "div",
            { staticClass: "item-wrap" },
            _vm._l(_vm.pageItems, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "items-box" },
                [
                  _c("svg-icon", {
                    staticClass: "items-icon",
                    attrs: { "icon-class": item.icon },
                  }),
                  _c("div", [
                    _c("span", { staticClass: "items-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("span", { staticClass: "items-text" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "text-container" },
        [
          _c("vue-markdown", { attrs: { source: _vm.$t("faq.text") } }),
          _c("div", { staticClass: "showMore" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMore,
                    expression: "showMore",
                  },
                ],
                on: { click: _vm.handleShowMore },
              },
              [
                _vm._v(_vm._s(_vm.$t("home.showMore"))),
                _c("svg-icon", {
                  staticClass: "tooltip-down",
                  attrs: { "icon-class": "down" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-collapse-transition",
            [
              !_vm.showMore || !_vm.$store.state.isMin900Width
                ? _c("vue-markdown", { attrs: { source: _vm.$t("faq.more") } })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "showMore" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showMore,
                    expression: "!showMore",
                  },
                ],
                on: { click: _vm.handleShowMore },
              },
              [
                _vm._v(_vm._s(_vm.$t("home.showLess"))),
                _c("svg-icon", {
                  staticClass: "tooltip-up",
                  attrs: { "icon-class": "down" },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-wrap" },
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass: "title-box item-wrap",
                    on: {
                      click: function ($event) {
                        return _vm.handleExtend(item, index)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.category)),
                    ]),
                    _vm.$store.state.isMin900Width
                      ? _c("svg-icon", {
                          staticClass: "arrow",
                          class: item.click ? "tooltip-up" : "tooltip-down",
                          attrs: { "icon-class": "down" },
                        })
                      : _c("svg-icon", {
                          staticClass: "add arrow",
                          attrs: { "icon-class": item.click ? "dec" : "add" },
                        }),
                  ],
                  1
                ),
                _c("el-collapse-transition", [
                  item.click
                    ? _c(
                        "div",
                        _vm._l(item.data, function (items, indexs) {
                          return _c(
                            "div",
                            {
                              key: indexs,
                              staticClass: "item-box",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick(
                                    item,
                                    index,
                                    items,
                                    indexs
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "title-box" },
                                [
                                  _c("span", { staticClass: "sub-title" }, [
                                    _vm._v(" " + _vm._s(items.question) + " "),
                                  ]),
                                  !_vm.$store.state.isMin900Width
                                    ? _c("svg-icon", {
                                        staticClass: "add arrow",
                                        attrs: {
                                          "icon-class": items.click
                                            ? "dec"
                                            : "add",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("el-collapse-transition", [
                                items.click
                                  ? _c(
                                      "div",
                                      { staticClass: "answer" },
                                      [
                                        _c("vue-markdown", {
                                          attrs: { source: _vm.content },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image-container" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/images/about-1.png"),
          alt: "Your Image",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }