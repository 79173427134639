<template>
  <div>
    <div
      :class="[
        'chat-operations',
        { 'is-horizontal': horizontal, 'is-vertical': !horizontal },
      ]"
      v-if="false"
    >
      <TTS v-model="canTTS" />
      <el-button
        class="btn-stop"
        type="text"
        :disabled="!generating"
        @click="handleStop"
      >
        <svg-icon icon-class="stop" />
        Stop generating
      </el-button>
      <el-button
        class="btn-clear"
        icon="el-icon-delete"
        type="text"
        @click="handleClear"
        >Clear History</el-button
      >
    </div>
    <div class="btn-box">
      <el-button
        class="btn"
        :class="[$store.state.appHelps ? 'appHelps btn' : 'btn']"
        @click="handleClear"
        id="v-step-5"
        v-if="false"
        ><svg-icon class="icon" icon-class="delete"
      /></el-button>
      <TTS
        v-model="canTTS"
        v-if="$store.state.profile.language == 'en'"
        v-show="false"
      />
      <el-button
        :disabled="generating"
        v-if="!isProd"
        class="btn"
        id="v-step-7"
        @click="handleCallRay"
        v-show="false"
        ><svg-icon class="icon" icon-class="call"
      /></el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TTS: () => import('./tts.vue')
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    generating: {
      type: Boolean,
      default: false
    },
    generatable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      canTTS: this.$store.state.canTTS,
      operationsWidth: 0
    }
  },
  watch: {
    canTTS: {
      handler() {
        this.$emit('update:canTTS', this.canTTS)
      },
      immediate: true
    }
  },
  computed: {
    horizontal() {
      return this.operationsWidth >= 490
    },
    isProd() {
      return process.env.VUE_APP_ENV === 'prod'
    }
  },
  mounted() {
    console.info(this.$store.state.canTTS, 'this.$store.state.canTTS')
    this.observeOperationsWidth()
  },
  beforeDestroy() {
    this.unobserveOperationsWidth()
  },
  methods: {
    observeOperationsWidth() {
      if (this.resizeObserver) return
      this.resizeObserver = new ResizeObserver(() => {
        this.operationsWidth = this.$el.clientWidth
      })
      this.resizeObserver.observe(this.$el)
    },
    unobserveOperationsWidth() {
      if (!this.resizeObserver) return
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    },
    handleClear() {
      if (!this.$store.state.history.length > 0) {
        return
      }
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('side.del'),
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('side.delTip')),
          h(
            'span',
            { style: 'font-weight: 600;' },
            this.$t('home.currentChat')
          )
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.$emit('clear')
      })
    },
    handleStop() {
      this.$emit('stop')
    },
    handleCallRay() {
      this.$emit('callRay')
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-operations {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  &.is-horizontal {
    flex-direction: row;
  }

  &.is-vertical {
    flex-direction: column;

    .btn-stop,
    .btn-clear {
      margin: 0;
    }
  }
}

.btn-stop,
.btn-clear {
  margin: 0 10px 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #374151;

  ::v-deep [class^="el-icon-"] {
    font-weight: bold;
  }

  ::v-deep .svg-icon {
    color: #374151;
    margin: 0;
  }

  &.is-disabled {
    ::v-deep .svg-icon {
      color: #c0c4cc !important;
    }
  }
}

::v-deep .el-button.is-disabled {
  color: #c0c4cc !important;
}
.btn-box {
  display: flex;
  align-items: center;
}
.btn {
  // width: 60px;
  // height: 60px;
  // border-radius: 60px;
  height: 56px;
  width: 56px;
  background: none;
  border: none;
  color: var(--ss-text);
  line-height: 0;
  margin-left: 0;
  margin-right: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 32px;
  }
}
.appHelps {
  color: var(--br) !important;
}
@media screen and (max-width: 900px) {
  .btn {
    height: 40px;
    width: 22px;
    background: none;
    border: none;
    margin-left: -5px;
    .icon {
      font-size: 24px;
    }
  }
}
</style>
