import Vue from 'vue'
// import * as webchat from '@/api/webchat'
import store from '../store/index.js'
import VueRouter from 'vue-router'
// import { App } from '@capacitor/app'
Vue.use(VueRouter)

// App.addListener('appUrlOpen', function (event) {
//   // Example url: https://beerswift.app/tabs/tabs2
//   // slug = /tabs/tabs2
//   // alert(event)
//   const slug = event.url.split('.ai').pop()

//   // We only push to the route if there is a slug present
//   if (slug) {
//     router.push({
//       path: slug
//     })
//   }
// })
// function parseTokenAndType(url) {
//   const regex = /[?&]([^=#]+)=([^&#]*)/g
//   const params = {}
//   let match
//   while ((match = regex.exec(url))) {
//     params[match[1]] = match[2]
//   }
//   return params
// }

const routes = [

  {
    meta: {
      requiresAuth: true
    },
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/home/components/ExportPdf/index.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/header/components/UserDialog/index.vue')
  },
  {
    path: '/boarding',
    name: 'boarding',
    component: () => import('../views/boarding/index.vue')
  },
  {
    path: '/recommended',
    name: 'recommended',
    component: () => import('../views/login/recommended.vue')
  },
  {
    path: '/createName',
    name: 'createName',
    component: () => import('../views/login/createName.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/header/components/ProfileDialog/profile.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../views/redirect/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: { requiresUnauth: true }
  },
  // {
  //   path: '/pclogin',
  //   name: 'pclogin',
  //   component: () => import('../views/login/pcLogin.vue')
  // },
  {
    path: '/faq',
    name: 'faqList',
    component: () => import('../views/faq/index.vue')
  },

  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/maintenance/index.vue')
  },
  {
    path: '/landing',
    name: 'Landing',
    redirect: '/home',
    component: () => import('../views/Landing/index.vue'),
    children: [
      {
        path: '/landing/home',
        name: 'home',
        component: () => import('../views/Landing/home.vue')

      },
      {
        path: '/landing/faq',
        name: 'faq',
        component: () => import('../views/Landing/faq.vue')

      },
      {
        path: '/landing/pricing',
        name: 'pricing',
        component: () => import('../views/Landing/pricing.vue')

      }
    ]
  }
]

routes.unshift(
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: () => import('../views/layout/index.vue'),
    children: [
      {
        meta: {
          requiresAuth: true
        },
        path: '/home',
        name: 'chatHome',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/principles',
        name: 'principles',
        component: () => import('../views/home/components/MyPrinciple/index.vue')
      }
    ]
  }
)

const router = new VueRouter({
  mode: 'history',
  routes: routes
})
router.beforeEach((to, from, next) => {
  // if (process.env.VUE_APP_ENV === 'prod') {
  //   if (to.name !== 'maintenance') {
  //     next({
  //       name: 'maintenance'
  //     })
  //   } else {
  //     next()
  //   }
  // }

  const w = '0px'
  console.info(to, 'to')
  if (!(to.name === 'chatHome' || to.name === 'principles')) {
    document.body.style.setProperty('--message-margin-left', w)
  }

  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const token = store.state.token
    if (!token) {
      next({
        name: 'login'
      })
    }
    next()
  } else {
    next() // make sure to always call next()!
  }
})

export default router
