var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "faq-box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("FAQ")]),
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "content-box" }, [
          _c("span", { staticClass: "sub-title" }, [
            _vm._v(_vm._s(item.title)),
          ]),
          _c("span", { staticClass: "text" }, [_vm._v(_vm._s(item.content))]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }