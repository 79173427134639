<template>
  <div class="faq-box">
    <div class="title">Pricing</div>
    <div class="sub-title">Get Access to Everything On Digital Ray</div>
    <div class="content-box" v-for="(item, index) in list" :key="index">
      <div class="check-icon">
        <svg-icon icon-class="check" class="check" />
      </div>
      <span class="text">{{ item.content }}</span>
    </div>
    <div class="bg-box">
      <div class="bg" v-for="(item, index) in card" :key="index">
        <span class="title">{{ item.title }}</span>
        <span class="pirce">{{ item.pirce }}</span>
        <span class="save">{{ item.save }}</span>
        <span class="text">{{ item.text }}</span>
        <el-button class="btn">{{ item.btn }}</el-button>
      </div>
    </div>

    <div class="footer">
      <span>Need Additional query allowances?</span>
      <svg-icon icon-class="down" class="down" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    card() {
      const res = [
        {
          title: 'Free',
          pirce: '$0',
          text: '5 days of access',
          save: '',
          btn: 'Choose Free'
        },
        {
          title: 'Monthly',
          pirce: '$4.99',
          text: '1 month of access',
          save: '',
          btn: 'Choose Monthly'
        },
        {
          title: 'Quarterly',
          pirce: '$11.99',
          text: '3 months of access',
          save: '(Save 20%)',
          btn: 'Choose Quarterly'
        },
        {
          title: 'Annual',
          pirce: '$40.99',
          text: '12 months of access',
          save: '(Save 32%)',
          btn: 'Choose Annual'
        }
      ]
      return res
    },
    list() {
      const res = [
        {
          content:
            'Principles and Principled Thinking Framework for enhanced problem-solving and decision-making'
        },
        {
          content:
            'Customized responses designed to suit individual professional and personal profiles'
        },
        {
          content: "Direct communication feature: 'Call Ray'"
        },
        {
          content:
            'Advanced online search tools for precise, up-to-date information'
        },
        {
          content:
            'Trending and personalized user inquiries to spark dynamic dialogues with Digital Ray'
        },
        {
          content: 'Daily recommendation of life principles'
        },
        {
          content:
            'Multilingual support for the 5 most commonly spoken languages'
        }
      ]
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-box {
  min-height: 100vh;
  background: var(--black);
  color: var(--white);
  padding: 140px 120px;
}
.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.content-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.sub-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 36px;
  margin-bottom: 32px;
}
.check-icon {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: var(--green);
  border: none;
  color: #fff;
  line-height: 0;
  margin-left: 0;
  margin-right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 0.5;
  .check,
  .cross {
    font-size: 24px;
    margin-left: 5px;
  }
}
.footer {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  margin-top: 36px;
  text-align: center;
}
.down {
  margin-left: 4px;
  cursor: pointer;
}
.bg {
  background-image: url("../../assets/images/card-border.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 285px 285px;
  width: 285px;
  height: 285px;
  border-radius: 20px;
  text-align: center;
  position: relative;
  padding-top: 32px;
  margin-right: 20px;
  margin-bottom: 20px;

  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-wrap: wrap;
  .title {
    margin-bottom: 36px;
    display: block;
    width: 100%;
    text-align: center;

    // margin: auto;
  }
  .pirce {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .save {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--green);
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: block;
      width: 100%;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 40px;
  }
  .btn {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--red);
    border: 1px solid var(--red);
    width: 221px;
    height: 48px;
    background: none;
    border-radius: 48px;
  }
}
.bg-box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: auto;
    margin-top: 80px;
}
@media screen and (max-width: 1200px) {
  .faq-box{
    padding: 140px 20px;
  }
}
@media screen and (max-width: 1500px) {
  .bg-box{
    width: 640px;
    // background: red;
    margin: auto;
    margin-top: 80px;
  }

}
@media screen and (max-width: 900px) {
  .bg-box{
    width: 310px;
    // background: red;
    margin: auto;
    margin-top: 80px;
  }

}
</style>
