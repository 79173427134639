var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isForceUpdated
    ? _c(
        "div",
        {
          ref: "scrollDiv",
          staticClass: "chat-messages",
          on: { scroll: _vm.handleScroll },
        },
        [
          _vm.disclaimerShow
            ? _c("div", { staticClass: "disclaimer" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.$t("footer.disclaimer")) },
                }),
                _c(
                  "div",
                  [
                    _c("svg-icon", {
                      staticClass: "mobile",
                      staticStyle: {
                        "font-size": "12px",
                        "margin-left": "4px",
                      },
                      attrs: { "icon-class": "close" },
                      on: { click: _vm.close },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "head-box" },
            [
              _c("img", {
                attrs: {
                  src: require("../../../../assets/images/head1.png"),
                  alt: "",
                },
              }),
              this.$store.state.profile.name
                ? _c("span", {
                    staticClass: "introTitle",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("home.intro", {
                          name: this.$store.state.profile.name,
                        })
                      ),
                    },
                  })
                : _c("span", { staticClass: "introTitle" }, [
                    _vm._v(" " + _vm._s(_vm.$t("home.introNoName")) + " "),
                  ]),
              _c("span", { staticClass: "introTip" }, [
                _vm._v(_vm._s(_vm.$t("home.introTip"))),
              ]),
              _c("svg-icon", {
                staticClass: "qa-icon",
                attrs: { "icon-class": "qa" },
                on: { click: _vm.handleClickQa },
              }),
            ],
            1
          ),
          _c("PrincepleDialog", {
            ref: "princeple",
            attrs: { principles: _vm.dayList },
            on: { change: _vm.handleDay },
          }),
          !_vm.messages.length > 0 && _vm.dayData.title
            ? _c("div", { staticClass: "principle-contet" }, [
                _c(
                  "div",
                  { on: { click: _vm.handleOpenDay } },
                  [
                    _c("Princeple", {
                      staticClass: "principle-bubble principle-bubble-content",
                      attrs: { principle: _vm.dayData, isDay: true },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.$store.state.chatLoading
            ? _c("div", { staticClass: "loading-indicator" }, [
                _c("i", { staticClass: "el-icon-loading" }),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "chat-messages-wrap" },
            [
              false ? _c("ChatTips", { attrs: { value: _vm.tips } }) : _vm._e(),
              _c("ChatTitle", { attrs: { value: _vm.title } }),
              _c("ChatMessageList", {
                attrs: {
                  messages: _vm.messages,
                  loading: _vm.loading,
                  sender: _vm.sender,
                  generating: _vm.generating,
                  showFaq: _vm.showFaq,
                },
                on: {
                  scrollToBottom: _vm.handleScrollBottom,
                  makeShorter: _vm.handleShortAnwser,
                  translation: _vm.handleTranslation,
                  forceResend: _vm.handleForceResend,
                  changeFaq: _vm.handleChangeFaq,
                  regenerate: _vm.handleRegenerate,
                  callQuickQuestion: _vm.handleQuickQuestion,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }