var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "boarding" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "wrap" },
        [
          _c("p", { staticClass: "tip" }, [
            _vm._v("Select Topics of Interest"),
          ]),
          _c("p", { staticClass: "sub-tip" }, [
            _vm._v("Select Topics of Interest"),
          ]),
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: item.text,
                staticClass: "item",
                class: [item.click ? "able" : "disable"],
                on: {
                  click: function ($event) {
                    return _vm.clickItem(item, index)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "close-icon",
                  attrs: { "icon-class": item.icon },
                }),
                _c("span", [_vm._v(_vm._s(item.text))]),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      class: [_vm.canNext ? "canNext" : ""],
                      attrs: { loading: _vm.loading, disabled: !_vm.canNext },
                      on: { click: _vm.next },
                    },
                    [_vm._v("NEXT")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "skip",
                  attrs: { type: "text", loading: _vm.skipLoading },
                  on: { click: _vm.close },
                },
                [_vm._v("SKIP")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box wrap" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../../assets/images/Digital Ray.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }