var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    { ref: "contactDom", attrs: { title: _vm.$t("user.contact"), full: true } },
    [_c("span", { domProps: { innerHTML: _vm._s(_vm.$t("user.contactTip")) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }