<template>
  <div class="app-box">
    <div class="content-box">
      <span class="title">
        Introduce DIGITAL RAY on 'Principles in Action'
      </span>
      <span>
        Our API is now available for integration with our partner product,
        'Principles in Action.' Users are invited to experience enriched
        conversations with DIGITAL RAY within this platform, particularly on
        topics pertaining to principles-based discussions.
      </span>
    </div>
    <img src="../../assets/images/phone.png" alt="" class="phone" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-box {
  padding: 120px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-box {
  padding-right: 72px;
  margin-bottom: 24px;
}
.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
  margin-bottom: 24px;
}
.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.phone {
  max-width: 840px;
  height: auto;
  width: 70vw;
  margin: auto;
}
@media screen and (max-width: 1200px) {
  .app-box{
    flex-wrap: wrap;
  }
}
</style>
