<template>
  <div
    class="chat-messages"
    v-if="isForceUpdated"
    ref="scrollDiv"
    @scroll="handleScroll"
  >
    <div class="disclaimer" v-if="disclaimerShow">
      <div v-html="$t('footer.disclaimer')"></div>
      <div>
        <svg-icon
          style="font-size: 12px; margin-left: 4px"
          class="mobile"
          icon-class="close"
          @click="close"
        />
      </div>
    </div>
    <div class="head-box">
      <img src="../../../../assets/images/head1.png" alt="" />
      <!-- <span v-html="$t('footer.desc')"></span> -->
        <span v-if="this.$store.state.profile.name" class="introTitle" v-html="$t('home.intro', { name: this.$store.state.profile.name })">
        </span>
        <span v-else class="introTitle">
          {{
            $t("home.introNoName")
          }}
        </span>
        <span class="introTip">{{ $t('home.introTip') }}</span>
        <svg-icon
          icon-class="qa"
          class="qa-icon"
          @click="handleClickQa"
        ></svg-icon>
    </div>
    <PrincepleDialog ref="princeple" :principles="dayList" @change="handleDay"></PrincepleDialog>
    <div class="principle-contet"  v-if="!messages.length>0 && dayData.title">
      <div @click="handleOpenDay">
        <Princeple class="principle-bubble principle-bubble-content" :principle="dayData" :isDay="true" ></Princeple>
      </div>
    </div>

    <div v-if="$store.state.chatLoading" class="loading-indicator">
      <i class="el-icon-loading"></i>
    </div>
    <div class="chat-messages-wrap">
      <ChatTips :value="tips" v-if="false" />
      <ChatTitle :value="title" />
      <ChatMessageList
        :messages="messages"
        :loading="loading"
        :sender="sender"
        :generating="generating"
        :showFaq="showFaq"
        @scrollToBottom="handleScrollBottom"
        @makeShorter="handleShortAnwser"
        @translation="handleTranslation"
        @forceResend="handleForceResend"
        @changeFaq="handleChangeFaq"
        @regenerate="handleRegenerate"
        @callQuickQuestion="handleQuickQuestion"
      />
    </div>
  </div>
</template>
<script>
import forceUpdateMixin from '@/mixins/forceUpdateMixin'
import * as api from '@/api/session'
import * as webchat from '@/api/webchat'
export default {
  components: {
    Princeple: () => import('../PrincipleDay/index.vue'),
    PrincepleDialog: () => import('../PrincipleDay/dialog.vue'),
    ChatTips: () => import('./tips.vue'),
    ChatTitle: () => import('./title.vue'),
    ChatMessageList: () => import('./message-list.vue')
  },
  mixins: [forceUpdateMixin],
  props: {
    showFaq: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    },
    generating: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    sender: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disclaimerShow: true,
      msgHeight: 0,
      canLoad: false,
      scrollPosition: 0,
      dayList: [],
      dayData: {}
    }
  },
  watch: {
    messages() {
      this.forceUpdate()
    },
    isForceUpdated(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            const scrollElem = this.$refs.scrollDiv
            scrollElem.addEventListener('scroll', () => {
              this.setIsScrollBottom()
            })
          }, 100)
        })
      }
    }
  },
  activated() {},
  mounted() {
    setTimeout(() => {
      if (!this.messages.length > 0) {
        this.getDayData()
      }
    }, 500)

    this.$store.commit('setChatCanLoad', false)
    // this.$store.commit('setScrollToBottom', false)
    this.$nextTick(() => {
      if (this.$store.state.historyOrigin.total_count) {
        if (
          this.$store.state.historyOrigin.total_count * 2 + 1 >
          this.messages.length
        ) {
          this.$store.commit('setChatCanLoad', true)
          this.canLoad = true
        }
      }
      const scrollElem = this.$refs.scrollDiv
      if (scrollElem) {
        this.setIsScrollBottom()
        scrollElem.addEventListener('scroll', () => {
          this.setIsScrollBottom()
        })
      }
      if (scrollElem && scrollElem.scrollHeight > scrollElem.clientHeight) {
        scrollElem.scrollTo({ top: scrollElem.scrollHeight })
      }
    })
  },
  methods: {
    getDayData() {
      const data = {
        start_date: moment().add(-6, 'd').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
      }
      this.dayList = []
      webchat.getPotd(data).then(res => {
        if (res.data) {
          if (res.data.length > 0) {
            this.dayList = res.data.sort((a, b) => {
              return b.date - a.date
            })
            this.dayData = this.dayList[this.dayList.length - 1]
            console.info(this.dayList)
          }
        }
      })
    },
    handleOpenDay() {
      this.$refs.princeple.open()
    },
    close() {
      this.disclaimerShow = false
    },
    handleClickQa() {
      this.$router.push({ name: 'faqList' })
    },
    setIsScrollBottom() {
      // if (this.$store.state.chatLoading) {
      //   return
      // }
      // if (!this.generating) {
      //   return
      // }
      const scrollElem = this.$refs.scrollDiv
      if (scrollElem) {
        if (scrollElem.scrollTop > 20) {
          this.$store.commit('setScrollToBottom', true)
        } else {
          this.$store.commit('setScrollToBottom', false)
        }
      }
    },
    handleScrollBottom(h) {
      const ch = h - this.msgHeight
      setTimeout(() => {
        this.$nextTick(() => {
          const scrollElem = this.$refs.scrollDiv

          if (scrollElem && this.isScrolledToBottom(scrollElem, h, ch)) {
            this.goBottom()
          }
        })
      }, 100)
    },
    goBottom() {
      const scrollElem = this.$refs.scrollDiv
      scrollElem.scrollTo({ top: scrollElem.scrollHeight })
      this.setIsScrollBottom()
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollDiv
      if (scrollContainer) {
        if (scrollContainer.scrollTop === 0 && !this.$store.state.chatLoading) {
          console.info('this.$store.state.historyOrigin.total_count')
          this.loadMoreItems()
        }
      }
    },
    loadMoreItems() {
      if (!this.canLoad) {
        return
      }
      this.$store.commit('setChatLoading', true)
      setTimeout(() => {
        this.$store.commit('setChatLoading', false)
      }, 3000)
      console.info(' this.messages', this.messages)
      let id = ''
      for (const i of this.messages) {
        if (i.id) {
          id = i.id
          break
        }
      }
      const data = {
        cursor: id,
        limit: 20
      }
      this.scrollPosition = this.$refs.scrollDiv.scrollHeight
      api
        .getSessionDetail(this.$store.state.sessionId, data)
        .then((res) => {
          if (res.data) {
            const data = res.data.record_list
            if (data.length > 0) {
              this.$emit('loadChat', res.data.record_list)
              this.$nextTick(() => {
                this.$refs.scrollDiv.scrollTop =
                  this.$refs.scrollDiv.scrollHeight - this.scrollPosition
                setTimeout(() => {
                  this.$store.commit('setChatLoading', false)
                }, 500)
              })
            } else {
              this.canLoad = false
              this.$store.commit('setChatCanLoad', false)
              this.$store.commit('setChatLoading', false)
            }
          }
        })
        .catch((res) => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$store.commit('setChatLoading', false)
            }, 500)
          })
        })
    },
    isScrolledToBottom(element, h, ch) {
      this.msgHeight = h
      const totalHeight = element.scrollHeight

      // div 已滚动的高度
      const scrollTop = element.scrollTop

      // div 可见区域的高度
      const visibleHeight = element.clientHeight

      // 底部区域的高度阈值（例如：100px）
      const bottomThreshold = 100 + ch
      return totalHeight - scrollTop - visibleHeight <= bottomThreshold
    },
    handleShortAnwser(val) {
      this.$emit('makeShorter', val)
    },
    handleTranslation(val) {
      this.$emit('translation', val)
    },
    handleForceResend() {
      this.$emit('forceResend')
    },
    handleChangeFaq(val) {
      this.$emit('changeFaq', val)
    },
    handleRegenerate(val) {
      this.$emit('regenerate', val)
    },
    handleQuickQuestion(val) {
      this.$emit('callQuickQuestion', val)
    },
    handleDay(val) {
      this.$emit('principleDay', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-messages {
  max-height: calc(100vh);
  min-height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 200px;
  padding-top: 40px;
  margin: auto;
  padding-left: 12vw;
  padding-right: 12vw;
  // background: red;
  // width: calc(100vw - 240px);
  // margin-left: 120px;
  // padding-top:80px;
  // min-width: 740px;
}
.disclaimer {
  background: #e713134d;
  padding: 10px;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--text);
  display: none;
  justify-content: space-between;
}
.chat-messages-wrap {
  margin-bottom: 180px;
}
.head-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    height: 64px;
    width: 64px;
    margin-top: 16px;
  }
  .introTitle{
    display: block;
    font-family: Century-Gothic;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: var(--text);
    width: 100%;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 6px;
  }
  .introTip {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--s-text);
    // width: 100%;
    // margin-top: 8px;
    // margin-bottom: -4px;
  }
  .qa-icon {
    color: var(--blue);
    margin-left: 6px;
  }
}
.principle-contet{
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .chat-messages {
    position: fixed;
    margin-top: calc(env(safe-area-inset-top) + 0px);
    top: 48px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
  }
  .disclaimer {
    display: flex;
  }
}
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  margin-top: 16px;
}
.chat-messages::-webkit-scrollbar {
  display: none;
}
.chat-messages-wrap {
  margin-bottom: 160px;
}
</style>
