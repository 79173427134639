var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "header-icon-box",
          on: {
            click: function ($event) {
              return _vm.toggleDropdown($event)
            },
          },
        },
        [
          _c(
            "el-badge",
            {
              staticClass: "item",
              attrs: { "is-dot": "", hidden: !_vm.$store.state.isShowPoint },
            },
            [
              _c("svg-icon", {
                staticClass: "header-icon settings",
                attrs: { "icon-class": "bell" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-top" } },
        [_vm.visible ? _c("Info") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }