<template>
  <div class="faq">
    <Dialog ref="itemDom" :title="''" :full="true" class="dialog-content">
      <span class="dialog-title">{{ title }}</span>
      <vue-markdown :source="content"></vue-markdown>
    </Dialog>
    <div class="wrap">
      <div class="top-wrap">
        <div class="image-container">
          <img src="../../assets/images/about-1.png" alt="Your Image" />
        </div>
        <div class="faq-title">
          <span>{{ $t("faq.title") }}</span>
        </div>
        <div class="item-wrap">
          <div
            v-for="(item, index) in pageItems"
            :key="index"
            class="items-box"
          >
            <svg-icon :icon-class="item.icon" class="items-icon" />
            <div>
              <span class="items-title">{{ item.title }}</span>
              <span class="items-text">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-container">
      <vue-markdown :source="$t('faq.text')"></vue-markdown>
      <div class="showMore">
        <span @click="handleShowMore" v-show="showMore"
        >{{ $t("home.showMore")
        }}<svg-icon icon-class="down" class="tooltip-down"
      /></span>

      </div>
      <el-collapse-transition>
      <vue-markdown :source="$t('faq.more')" v-if="!showMore||!$store.state.isMin900Width"></vue-markdown>
      </el-collapse-transition>

      <div class="showMore">
        <span @click="handleShowMore" v-show="!showMore"
        >{{ $t("home.showLess")
        }}<svg-icon icon-class="down" class="tooltip-up"
      /></span>
      </div>
    </div>

    <div class="content-wrap">
      <div v-for="(item, index) in list" :key="index">
        <div>
          <div @click="handleExtend(item, index)" class="title-box item-wrap">
            <span class="title">{{ item.category }}</span>
            <svg-icon
              icon-class="down"
              class="arrow"
              :class="item.click ? 'tooltip-up' : 'tooltip-down'"
              v-if="$store.state.isMin900Width"
            />
            <svg-icon
              class="add arrow"
              :icon-class="item.click ? 'dec' : 'add'"
              v-else
            />
          </div>

          <el-collapse-transition>
            <div v-if="item.click">
              <div
                class="item-box"
                v-for="(items, indexs) in item.data"
                :key="indexs"
                @click="handleClick(item, index, items, indexs)"
              >
                <div class="title-box">
                  <span class="sub-title">
                    {{ items.question }}
                  </span>

                  <svg-icon
                    class="add arrow"
                    :icon-class="items.click ? 'dec' : 'add'"
                    v-if="!$store.state.isMin900Width"
                  />
                </div>
                <el-collapse-transition>
                  <div class="answer" v-if="items.click">
                    <vue-markdown :source="content"></vue-markdown>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as webchat from '@/api/webchat'
export default {
  components: {
    VueMarkdown: () => import('vue-markdown'),
    Dialog: () => import('@/components/Dialog/index.vue')

  },
  data() {
    return {
      title: '',
      content: '',
      list: [],
      showMore: true
    }
  },
  computed: {
    pageItems() {
      const res = [
        {
          title: this.$t('faq.personalized'),
          text: this.$t('faq.personalizedTip'),
          icon: 'faq-1'
        },
        {
          title: this.$t('faq.AIPowered'),
          text: this.$t('faq.AIPoweredTip'),
          icon: 'faq-3'
        },
        {
          title: this.$t('faq.principled'),
          text: this.$t('faq.principledTip'),
          icon: 'faq-2'
        }
      ]
      return res
    }
  },
  mounted() {
    this.getlist()
  },
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        if (!val) {
          this.$refs.itemDom.close()
        }
      },
      deep: true
    }
  },
  methods: {
    handleExtend(item, index) {
      item.click = !item.click
    },

    handleClick(item, index, items, indexs) {
      console.info(item, 'item')
      this.title = items.question
      this.content = items.answer
      if (this.$store.state.isMin900Width) {
        this.$refs.itemDom.open()
      } else {
        console.info(item, 'item')
        const click = !items.click
        for (const i in item.data) {
          item.data[i].click = false
        }
        items.click = click
      }
    },
    handleShowMore() {
      this.showMore = !this.showMore
    },
    getlist() {
      webchat.faqList().then((res) => {
        if (res.data.faqs.length > 0) {
          this.list = []
          for (const i of res.data.faqs) {
            i.click = true
            for (const j of i.data) {
              j.click = false
            }
          }
          this.list = res.data.faqs
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// p{
//   line-height: 26px !important;
// }
.faq {
  background: var(--s-bg);
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  bottom: 0;
  overflow: auto;
  margin-left: 0;
}

.dialog-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  margin-top: 16px;
  display: block;
}

.tooltip-down {
  margin-left: 6px;
  right: 0;
}

.tooltip-up {
  margin-left: 6px;
  transform: rotate(180deg);
}

.arrow {
  color: var(--blue);
}

.item-box {
  padding: 40px 0;
  border-bottom: 0.5px solid var(--br);
  color: var(--text);
}

.item-box:last-child {
  border-bottom: none;
}

.title-box {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.add {
  font-size: 28px;
}

.min-add {
  font-size: 20px;
}

.image-container {
  // margin-right: 80px;
  margin-bottom: 32px;
  width: 160px;
  height: 160px;
  margin: auto;

  img {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.answer {
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 40px;
  font-size: 20px;
  max-width: 80%;

  p {
    line-height: 26px;
  }
}

.text-container {
  color: var(--text);
  font-size: 18px;
  font-weight: 400;
  line-height: 26px !important;
  // height: 360px;
  overflow: auto;
  // margin-top: 24px;
  padding: 40px 8vw 60px 8vw;

  p {
    line-height: 26px !important;
  }

  background: var(--side-bg) !important;
  width: 100vw;
  // margin-left:;
}

// .dialog-content{
//   line-height: 20px !important;
// }
.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--blue);
}

.sub-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.content-wrap {
  padding: 0 8vw;
  padding-bottom: 8vw;
  width: 100vw;
}

.top-wrap {
  padding: 0 5vw;
}

.wrap {
  padding: 60px 8vw 40px 8vw;
  overflow: hidden;
  background: var(--s-bg);
  width: 100vw;
  // display: flex;
}

.wrap::-webkit-scrollbar {
  display: none;
}

.items-icon {
  min-width: 42px;
  font-size: 42px;
  color: var(--blue-faq-text);
  margin-right: 0px;
  margin-bottom: 16px;
}

.items-title {
  color: var(--blue-faq-text);
  display: block;
  font-family: Century-Gothic;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}

.items-text {
  color: var(--text);
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.faq-title {
  //styleName: Title / 4;
  font-family: Century-Gothic;
  font-size: 32px;
  font-weight: 800;
  line-height: 48px;
  text-align: center;
  color: var(--text);
  margin-top: 28px;
}

.item-wrap {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}

.items-box {
  text-align: center;
  width: 268px;
}
.showMore{
  display: none;
}

@media screen and (max-width: 1200px) {
  .top-wrap {
    zoom: 0.86;
  }
}

@media screen and (max-width: 900px) {
  .top-wrap {
    padding: 0 !important;
  }
  .showMore{
  display: block;
  color: var(--blue);
  // float: right;
  width: 100%;
  text-align: right;
  cursor: pointer;
  font-size: 13px;
  }
  .top-wrap{
    zoom: 1;
  }
  .faq-title {
    //styleName: Title / 4;
    font-family: Century-Gothic;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    color: var(--text);
    margin-top: 28px;
  }
  .items-box {
    display: flex;
    margin-top: 24px;
    align-items: center;
  }

  .items-icon {
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .top-wrap {
    padding: 0;
  }

  .image-container {
    // float: left;
    // margin-right: 8px;
    margin-bottom: 0px;
    margin: auto;
    width: 80px;
    height: 80px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  .faq::-webkit-scrollbar {
    display: none;
  }

  .wrap::-webkit-scrollbar {
    display: none;
  }

  .sub-title {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  /* 设置文字容器 */
  .text-container {
    height: 100%;
    overflow: visible;
    margin-top: 0px;
    padding: 8px 16px 16px 16px;
    font-size: 15px;
    line-height: 20px !important;

    p {
      line-height: 20px !important;
    }
  }

  .title {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .wrap {
    padding: 16px;
    padding-top: 28px;
    overflow: auto;
    display: block;
    // max-height: calc(45vh);
    background: var(--s-bg);
  }

  .item-box {
    padding: 16px 0;
  }

  .item-wrap {
    margin-top: 24px;
    display: block;
  }
  .items-box {
    text-align: left;
    width: 100%;
  }

  .content-wrap {
    padding: 0 16px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }
}
</style>
