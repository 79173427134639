<template>
  <div>
    <div @click="handleClick" class="slot">
      <slot></slot>
    </div>
    <transition name="el-zoom-in-top">
      <div class="dropdown" v-if="visible">
        <div class="dropdown-wrap">
          <span
          :class="item.disable? 'dropdown-disable':''"
            @click.stop="handleCommand(item)"
            v-for="item in items"
            :key="item[label]"
            >{{  item[label] }}</span
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Array
    },
    label: {
      type: String,
      default: 'label'
    },
    langKey: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      items: []
    }
  },

  watch: {
    option() {
      this.items = this.option
    }
  },
  mounted() {
    this.items = this.option
    this.$root.$on('dropdown-toggled', (dropdown) => {
      if (dropdown !== this) {
        this.visible = false
      }
    })
    document.addEventListener('click', this.closeDropdownsOutside)
  },
  destroyed() {
    document.removeEventListener('click', this.closeDropdownsOutside)
  },
  methods: {

    toggleDropdown() {
      this.visible = !this.visible
      this.$root.$emit('closeDropdowns')
    },
    selectItem(item) {
      this.selected = item
      this.visible = false
      // this.$root.$emit('closeDropdowns')
    },
    closeDropdownsOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.visible = false
        this.$root.$emit('closeDropdowns')
      }
    },
    close() {
      this.visible = false
    },
    handleClick() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.visible = true
        }, 200)
      })
    },
    handleCommand(val) {
      if (!val.disable) {
        this.$emit('change', val)
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    width: 4px;
    background: var(--ss-text);
    border-radius: 35px;
}

.dropdown-wrap {
  max-height: 284px;
  padding: 8px 0px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
  .slot {
  //   position: relative;
  }

.dropdown {
  z-index: 100 !important;
  border-radius: 8px;
  background: var(--dropdown-bg);
  box-shadow: 4px 4px 15px 0px #00000014;
  color:var(--ss-text);
  padding-right: 4px;
  margin-top: 10px;
  position: absolute;
  span {
    display: block;
    font-size: 15px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    width: calc(100% - 8px);
    border-radius: 4px;
    margin-left: 8px;
    z-index: 100;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    font-weight: 400;
    font-family: 'Roboto';
  }

  span:hover {
    background: var(--dropdown-hover-bg);
    cursor: pointer;
    color:var(--tab-text);
  }

  .user-line {
    height: 1px;
    width: 100%;
    background: #16182333;
  }
  .dropdown-disable{
    cursor: not-allowed !important;
    color: #FFFFFF66;
  }
}
</style>
