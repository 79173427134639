<template>
  <div id="faq">
    <div class="mySwiper swiper">
      <div class="swiper-wrapper">
        <div
          v-for="(tab, index) in list"
          :key="index"
          ref="slide"
          class="swiper-slide wrapper-item"
          :style="{ height: domH ? domH + 'px' : '100%' }"
        >
          <div class="tab-content-wrapper chart-bubble" ref="dom">
            <div
              v-if="index != list.length - 1"
              class="animate__animated"
              :class="[
                isLeft === 'left'
                  ? 'animate__slideInRight'
                  : isLeft === 'right'
                  ? 'animate__slideInLeft'
                  : 'animate__fadeIn',
              ]"
            >
              <span class="s-tip">{{ $t("home.q") }}</span>
              <span>{{ tab.question }}</span>
              <div>
                <span class="s-tip" style="margin-top: 8px">{{
                  $t("home.a")
                }}</span>
                <vue-markdown
                  style="display: inline-block"
                  :source="tab.answer"
                ></vue-markdown>
              </div>
              <div class="btn-box">
                <el-button class="btn" @click="handleClick(tab)">{{
                  $t("common.Select")
                }}</el-button>
              </div>
            </div>
            <div
              v-else
              class="animate__animated"
              :class="[
                isLeft === 'left'
                  ? 'animate__slideInRight'
                  : isLeft === 'right'
                  ? 'animate__slideInLeft'
                  : 'animate__fadeIn',
              ]"
            >
              <div class="btn-box re-box">
                <svg-icon icon-class="refresh" class="chat-icon"></svg-icon>
                <span>{{ $t("home.re") }}</span>
                <el-button class="btn" @click="handleClick(tab)">{{
                  $t("footer.regenerate")
                }}</el-button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="swiper-pagination pagination"></div>
    </div>

    <div class="btn-container" v-if="list.length > 1">
      <el-button class="scroll-button scroll-left pre">
        <svg-icon class="arrow-left" icon-class="down" />
      </el-button>
      <span class="tip">{{ currentTab + "/" + list.length }}</span>
      <el-button class="scroll-button scroll-right next">
        <svg-icon class="arrow-right" icon-class="down" />
      </el-button>
    </div>
  </div>
</template>

<script>
// import { Swiper, Navigation, Pagination } from 'swiper'
// Swiper.use([Navigation, Pagination])
export default {
  components: { VueMarkdown: () => import('vue-markdown') },
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      domH: 0,
      currentTab: 1
    }
  },
  computed: {
    list() {
      const item = [
        {
          id: null,
          showExtend: false
        }
      ]
      return [...this.tabs, ...item]
    }
  },
  mounted() {
    // this.$store.commit('setFaqHeight', '')

    // this.swiper = document.querySelector('swiper-container')
    const swiper = new Swiper('.mySwiper', {
      loop: true,
      spaceBetween: '20',
      slidesPerView: '1',
      centeredSlides: true,
      pagination: {
        el: '.pagination',
        clickable: true
      }
    })
    swiper.on('slideChange', (swiper) => {
      this.onSlideChange(swiper)
    })
    swiper.on('touchStart', (swiper) => {
      this.$store.commit('setCanSwiper', false)
    })
    swiper.on('touchEnd', (swiper) => {
      this.$store.commit('setCanSwiper', true)
    })

    // const swiperEl = document.querySelector('swiper-container')
    // const swiper = swiperEl.swiper
    const next = document.querySelector('.next')
    const pre = document.querySelector('.pre')
    if (next) {
      next.addEventListener('click', function (e) {
        e.preventDefault()
        swiper.slideNext()
      })
    }
    if (pre) {
      pre.addEventListener('click', function (e) {
        e.preventDefault()
        swiper.slidePrev()
      })
    }
    window.addEventListener('resize', this.updateVH)
    this.updateVH()
  },
  methods: {
    updateVH() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.dom[this.currentTab - 1]) {
            console.info(
              this.domH,
              '====',
              this.$refs.dom[this.currentTab - 1].scrollHeight,
              this.$refs.dom[this.currentTab - 1].clientHeight
            )
            this.domH = this.$refs.dom[this.currentTab - 1].clientHeight
              ? this.$refs.dom[this.currentTab - 1].clientHeight + 30
              : ''
          }
          console.info(this.domH)
        }, 100)
      })
    },
    handleClick(item) {
      this.$emit('changeFaq', item)
    },
    onSlideChange(swiper) {
      try {
        if (swiper) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.currentTab = swiper?.realIndex + 1
              if (this.$refs.dom[swiper?.realIndex]) {
                this.domH = this.$refs.dom[swiper?.realIndex].clientHeight
                  ? this.$refs.dom[swiper?.realIndex].clientHeight + 30
                  : ''
              }
              console.info(this.domH)
            }, 100)
          })
        }
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-button {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  background-color: var(--chart-bg);
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px !important;
  padding: 0px !important;
}
.scroll-button:hover {
  background-color: var(--chart-bg);
}
.tip {
  display: block;
  color: var(--s-text);
  margin: 0 8px;
  font-size: 13px;
}
.s-tip {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  display: block;
  color: var(--ss-text);
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-left {
  transform: rotate(90deg);
  color: var(--s-text);
}
.arrow-right {
  transform: rotate(270deg);
  color: var(--s-text);
}

.scroll-left {
  left: 0;
  margin-left: 0px !important;
}

.scroll-right {
  right: 0;
  margin-right: 0px !important;
}

.tab-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.tab {
  cursor: pointer;
}
// .tab-content {
//   display: none;
// }
.tab-content.active {
  display: block;
}
.dot-container {
  text-align: center;
}
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--br);
  border-radius: 50%;
  margin: 0 4px;
  cursor: pointer;
}
::v-deep .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--blue) !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #6da9e9 !important;
}

.dot.active {
  background-color: var(--blue);
}
.btn-box {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 4px;
}
.re-box {
  display: block;
  text-align: center;
  .chat-icon {
    font-size: 33px;
    color: var(--s-text);
    display: block;
    text-align: center;
    margin: auto;
  }
  span {
    display: block;
    // width: 100%;
    // max-width: fit-content;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 36px;
  }
}
.btn {
  height: 32px;
  border-radius: 8px;
  color: var(--bg);
  background: var(--blue);
  border: none;
  line-height: 0;
  padding: 0 12px;
  font-weight: 500;
  font-size: 13px;
  margin: auto;
}
.show-more {
  color: var(--blue-t-text) !important;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
  margin-top: 8px;
  cursor: pointer;
}
.item-box {
  overflow-x: hidden;
  overflow-y: auto;
}
.no-extend {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 11;
  overflow: hidden;
}
.extend {
  overflow: hidden;
  white-space: normal;
}
.tab-content-wrapper {
  display: flex;
  overflow: hidden;
}
.wrapper-item {
  padding-bottom: 20px;
  padding-right: 2px;
}
::v-deep #faq .swiper-pagination-bullet-active {
  /* 你的样式 */
  background: red !important;
}
</style>
