// const messageKeys = ['id', 'content', 'mine', 'stopped', 'typing', 'comments', 'error', 'timestamp']

// const toStorageName = (name) => {
//   return `polaris.web-chat.${name.toLowerCase()}`
// }

// const dbMap = {}

// const getStorage = (name) => {
//   let db = dbMap[name]
//   if (db) return db

//   dbMap[name] = db = new Dexie(toStorageName(name))
//   db.version(1).stores({
//     messages: messageKeys.join(',')
//   })
//   return db
// }

// export { getStorage }
