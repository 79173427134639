<template>
<div class="mask" v-if="show"  ref="mask">
  <div class="content">
    <div class="callray-close-box"><svg-icon class="callray-close" icon-class="close" @click="callrayClose" /></div>
    <div class="content-icon"><ImageCached :src="icon" /></div>
    <div class="instruction">
      <p>Hold down the <b>"T"</b> key (<span class="keyboard-key">T</span>) on your keyboard while speaking to Ray. Release the <b>"T"</b> key to stop recording.</p>
    </div>
    <!-- Sound wave -->
    <div v-if="this.userSpeaking" class="sound-wave">
      <div class="sound-wave-wrapper">
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box long-bar"></div>
        <div class="box medium-bar"></div>
        <div class="box short-bar"></div>
      </div>
    </div>
    <div v-if="!this.userSpeaking" >
      <div v-if="this.rayThinking===undefined" class="text-status-box"></div>
      <div v-if="this.rayThinking===true" class="text-status-box"><p>Ray is thinking...</p></div>
      <div v-if="this.rayThinking===false" class="text-status-box"><p>Ray is speaking...</p></div>
    </div>
    <div class="hangup-wrapper">
      <el-button class="btn-hang-up" @click="handleHangUp" :disabled="this.callStatus === 'connecting'">
        {{ this.callBtnMsgDict[this.callStatus] }}
      </el-button>
    </div>
  </div>
</div>
</template>

<script>

import { Index } from 'wizvoicesdktest'
// import md5 from 'js-md5'
import ImageCached from '@/components/ImageCached'
export default {
  components: {
    ImageCached
  },
  data() {
    return {
      callStatus: 'pending',
      wiz: null,
      accountCode: 'qpmGY60uOl1zfqmk',
      name: 'wiz',
      email: 'wiz@wiz.ai',
      phone: '087776122464',
      templateCode: 'T_0IwQ5guq21oZ',
      startTime: '',
      endTime: '',
      callBtnMsgDict: {
        pending: 'Recall',
        connecting: 'Connecting...',
        connected: 'Hang up'
      },
      userSpeaking: false,
      rayThinking: undefined,
      thinkingTimer: undefined
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      return require('@/assets/images/head1.png')
    }
  },
  watch: {
    show(val) {
      if (val) {
        window.addEventListener('keydown', this.handleKeyPress)
        window.addEventListener('keyup', this.handleKeyRelease)
      } else {
        window.removeEventListener('keydown', this.handleKeyPress)
        window.removeEventListener('keyup', this.handleKeyRelease)
      }
    }
  },
  mounted() {
    this.initSdk()
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress)
    window.removeEventListener('keyup', this.handleKeyRelease)

    this.wiz.hangUp()
  },
  methods: {
    handleKeyPress(event) {
      event.preventDefault()
      if ((event.key === 't' || event.key === 'T') && this.callStatus === 'connected' && !this.userSpeaking) {
        this.userSpeaking = true
        this.wiz.unmute(200)
      }
    },
    handleKeyRelease(event) {
      event.preventDefault()
      if ((event.key === 't' || event.key === 'T') && this.callStatus === 'connected' && this.userSpeaking) {
        this.userSpeaking = false
        this.rayThinking = true
        this.wiz.mute()
      }
    },
    handleHangUp() {
      if (this.callStatus === 'pending') {
        this.startCall()
      } else if (this.callStatus === 'connected') {
        this.wiz.hangUp()
        this.callStatus = 'pending'
        this.callrayClose()
      }
    },
    callrayClose() {
      this.endTime = Date.now()
      this.wiz.hangUp()
      this.callStatus = 'pending'
      const time = this.showTime(moment(this.endTime).diff(moment(this.startTime), 'seconds'))
      console.info(time, 'time')
      this.$emit('hangUp', time)
    },
    startCall() {
      this.rayThinking = undefined
      this.handleStart()
    },
    showTime(val) {
      if (val < 60) {
        return val + 's'
      } else {
        const minTotal = Math.floor(val / 60) // 分钟
        const sec =
          Math.floor(val % 60) > 9
            ? Math.floor(val % 60)
            : '0' + Math.floor(val % 60) // 余秒
        if (minTotal < 60) {
          const showmin = minTotal > 9 ? minTotal : '0' + minTotal
          return '00:' + showmin + ':' + sec
        } else {
          const hourTotal =
            Math.floor(minTotal / 60) > 9
              ? Math.floor(minTotal / 60)
              : '0' + Math.floor(minTotal / 60) // 小时数
          const min =
            Math.floor(minTotal % 60) > 9
              ? Math.floor(minTotal % 60)
              : '0' + Math.floor(minTotal % 60) // 余分钟
          return hourTotal + ':' + min + ':' + sec
        }
      }
    },
    formatDate() {
      // Using beijing time to call WIZ server
      const now = new Date()
      const offset = 4
      const utc = now.getTime() + (now.getTimezoneOffset() * 60000)
      const abudabiTime = new Date(utc + (3600000 * offset))
      const date = abudabiTime
      const YY = date.getFullYear()
      const MM =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      const DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const mm =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const ss =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return `${YY}${MM}${DD}${hh}${mm}${ss}`
    },
    initSdk() {
      const wizSdk = Index.init({ url: 'https://uae-opencall.wiz.ai' })
      this.wiz = wizSdk
      wizSdk.on('status', (status) => {
        if (status === 'start') {
          this.callStatus = 'connected'
        }

        if (status === 'ended') {
          this.callStatus = 'pending'
          if (this.thinkingTimer) {
            clearTimeout(this.thinkingTimer)
          }
          this.rayThinking = undefined
        }
      })
      wizSdk.on('message', (message) => {
        console.log(message)
        this.rayThinking = false
        if (this.thinkingTimer) {
          clearTimeout(this.thinkingTimer)
        }
        this.thinkingTimer = setTimeout(() => {
          this.rayThinking = undefined
        }, message.data.period + 300)
      })
      wizSdk.on('error', () => {
        this.callStatus = 'pending'
      })
    },
    async handleStart() {
      this.startTime = Date.now()
      this.callStatus = 'connecting'
      const timestamp = this.formatDate()
      const userId = '263594323486785539'
      // const trainingToken = md5(md5(`ACCWIZ${userId}${timestamp}`))
      const trainingToken = 'abcd'
      const infoObj = {
        accountCode: this.accountCode,
        name: this.name,
        email: this.email,
        phone: this.phone,
        tokenInfo: {
          userId,
          timestamp,
          trainingToken
        },
        templateCode: this.templateCode,
        callLog: false,
        ringSetting: {
          localRing: true
        }
      }
      await this.wiz.start(infoObj)
    }
  }
}
</script>

<style lang="scss" scoped>
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    display: block;
  }
  .btn-hang-up {
    width: 217px;
    padding: 4px 20px;
    background: var(--black);
    border: 1px solid var(--white);
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    border-radius: 36px;
    line-height: 0;
    height: 36px;
  }
  .text-status-box {
    display: flex;
    justify-content: center;
    height: 60px;
    p {
      width: 217px;
      background: var(--black);
      color: white;
      background-color: rgba(60, 60, 60, 0.5);
      font-size: 16px;
      font-weight: 400;
      border-radius: 36px;
      line-height: 35px;
      height: 36px;
      text-align: center;
      vertical-align: middle;
    }
  }
  .content-icon {
    margin: 0 30px;
    display: flex;
    justify-content: center;
    img {
      width: 217px;
      height: 217px;
      border-radius: 50%;
    }
  }
  .instruction {
    margin: 20px 30px;
    display: flex;
    justify-content: center;
    p {
      width: 480px;
      color: white;
      font-size: 17px;
      text-align: center;
    }
  }
  .keyboard-key {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 24px;
    height: 24px;
    padding: 3px 4px;
    border: 2px solid #9e9d9d;
    background-color: transparent;
    border-radius: 2px;
    margin: 5px;
    font-size: 13px;
    font-weight: bold;
    color: #9e9d9d;
  }
  .hangup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .callray-close-box {
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .callray-close {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }
.loading::after {
  animation: wait 2s linear infinite;
  content: "";
}
@keyframes wait {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
@keyframes quiet {
  50%{
    transform: scaleY(.2);
  }
}

@keyframes normal {
  50%{
    transform: scaleY(.6);
  }
}
@keyframes loud {
  50%{
    transform: scaleY(1);
  }
}
.sound-wave{
  display: flex;
  justify-content: center;
  height: 60px;
}
.sound-wave-wrapper{
  display: flex;
  justify-content: space-between;
  height: 42px;
  --boxSize: 4px;
  width: 217px;
}

.box{
  transform: scaleY(.2);
  height: 100%;
  width: var(--boxSize);
  background: #ffffff;
  animation-duration: 1.4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 4px;
}
.short-bar{
  animation-name: quiet;
}
.medium-bar{
  animation-name: normal;
}
.long-bar{
  animation-name: loud;
}

</style>
