<template>
  <div class="tools-box">
    <div class="title">Tools in Ecosystem</div>
    <div class="container">
      <div class="home-tile bg1">
        <div class="content">
          <h2 class="tile-heading">Principles in Action</h2>
          <p class="tile-body body-2">
            A free app that brings to life <i>Principles: Life &amp; Work</i>.
          </p>
        </div>
        <div class="actions">
          <a
            class="link label"
            target="_blank"
            href="https://apps.apple.com/us/app/principles-in-action/id1211294305"
          >
            <span>IOS</span>
            <svg-icon icon-class="arrow-icon-red" class="icon" />
          </a>

          <a
            class="link label"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.principles.pia"
          >
            <span>Android</span>
            <svg-icon icon-class="arrow-icon-red" class="icon" />
          </a>
        </div>
      </div>

      <div class="home-tile bg2">
        <div class="content">
          <h2 class="tile-heading">PrinciplesYou</h2>
          <p class="tile-body body-2">
            The free personality assessment for individuals.
          </p>
        </div>
        <div class="actions">
          <a
            class="link label"
            target="_blank"
            href="https://principlesyou.com/"
          >
            <span>PrinciplesYou</span>
            <svg-icon icon-class="arrow-icon-red" class="icon" />
          </a>
        </div>
      </div>

      <div class="home-tile bg3">
        <div class="content">
          <h2 class="tile-heading">PrinciplesUs</h2>
          <p class="tile-body body-2">
            The NEW personality assessment for teams.
          </p>
        </div>
        <div class="actions">
          <a
            class="link label"
            target="_blank"
            href="https://principlesus.com/"
          >
            <span>PrinciplesUs</span>
            <svg-icon icon-class="arrow-icon-red" class="icon" />
          </a>
        </div>
      </div>

      <div class="home-tile bg4">
        <div class="content">
          <h2 class="tile-heading">Principles on the Web</h2>
          <p class="tile-body body-2">
            Learn more by exploring Principles online
          </p>
        </div>
        <div class="actions">
          <a
            class="link label"
            target="_blank"
            href="https://principles.com/redirect"
          >
            <span>Principles on the Web</span>
            <svg-icon icon-class="arrow-icon-red" class="icon" />
          </a>
        </div>
      </div>
    </div>

    <div class="contact-box">
      <div class="title">Contact & Support</div>
      <div class="sub-title">Contact us</div>
      <div class="sub-text">contact@digitalray.ai</div>
      <div class="sub-title">Support</div>
      <div class="sub-text">support@digitalray.ai</div>
      <div class="footer-box">
        <div class="ray-box">
          <img src="../../assets/images/Digital Ray.png" alt="" />
        </div>
        <div class="text-box">
          <span>DIGITAL RAY © 2023</span>
          <span>Terms of Service</span>
          <span>Privacy Policy</span>
        </div>

        <div class="img-box">
          <a target="_blank" href="https://www.facebook.com/raydalio/">
            <img src="../../assets/images/contact-1.png" alt=""
          /></a>
          <a target="_blank" href="https://twitter.com/RayDalio">
            <img src="../../assets/images/contact-2.png" alt=""
          /></a>
          <a target="_blank" href="https://www.linkedin.com/in/raydalio">
            <img src="../../assets/images/contact-3.png" alt=""
          /></a>
          <a target="_blank" href="https://www.instagram.com/principles/?hl=en">
            <img src="../../assets/images/contact-4.png" alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bg1 {
  background: url("../../assets/images/tools-1.png") no-repeat center;
}
.bg2 {
  background: url("../../assets/images/tools-2.png") no-repeat center;
}
.bg3 {
  background: url("../../assets/images/tools-3.png") no-repeat center;
}
.bg4 {
  background: url("../../assets/images/tools-4.png") no-repeat center;
}
.home-tile {
  width: 280px;
  height: 510px;
  background-size: cover;
  margin-bottom: 24px;
  text-align: center;
}
.tile-heading {
  font-family: "Century-Gothic W01 Bold", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}
.tile-body {
  height: 3rem;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}
.tools-box {
  padding: 120px 0;
}
.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 36px;
}
.ray-box {
  margin-bottom: 24px;
  img {
    height: 38px;
  }
}
.img-box {
  margin-bottom: 24px;
  img {
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
  }
}
.text-box {
  margin-bottom: 24px;
  span {
    display: inline-block;
    margin-right: 48px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.footer-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;
  flex-wrap: wrap;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
  margin-bottom: 8px;
}
.sub-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}
.contact-box {
  margin-top: 160px;
}
.icon {
  color: var(--red);
  margin-left: 2px;
  position: absolute;
  right: -30px;
}

@keyframes blink {
  50% {
    right: -40px;
  }
}
.content {
  margin-top: 370px;
}
.actions {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 100%;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 60px;
    margin-left: 60px;
  }
  a:hover {
    text-decoration: underline;
    .icon {
      animation: blink 1s infinite;
    }
  }
}
@media screen and (max-width: 1400px) {
  .container {
    width: 600px;
    margin: auto;
  }
  .item{
    margin-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .container {
    width: 280px;
    margin: auto;
  }
  .item{
    margin-right: 0;
  }
}
.body-2{
  padding: 0px 20px;
}
</style>
