export const longPress = {
  bind: function(el, binding, vnode) {
    const longPressDuration = 500 // 长按触发时间,单位毫秒
    const handler = binding.value

    el.longPressData = {
      pressTimer: null,
      startTime: 0,
      startX: 0,
      startY: 0,
      isTouch: false,

      start: function(e) {
        if (e.type === 'mousedown' && e.button !== 0) return // 只响应左键点击
        e.preventDefault()
        this.isTouch = e.type === 'touchstart'
        this.startTime = new Date().getTime()
        if (this.isTouch) {
          this.startX = e.touches[0].clientX
          this.startY = e.touches[0].clientY
        } else {
          this.startX = e.clientX
          this.startY = e.clientY
        }

        this.pressTimer = setTimeout(() => {
          handler({ type: 'long' })
        }, longPressDuration)
      },

      end: function(e) {
        e.preventDefault()
        const endTime = new Date().getTime()
        let endX, endY
        if (this.isTouch) {
          endX = e.changedTouches[0].clientX
          endY = e.changedTouches[0].clientY
        } else {
          endX = e.clientX
          endY = e.clientY
        }
        const moveDistance = Math.sqrt(Math.pow(endX - this.startX, 2) + Math.pow(endY - this.startY, 2))

        clearTimeout(this.pressTimer)

        if (endTime - this.startTime < longPressDuration && moveDistance < 10) {
          handler({ type: 'click' })
        }
      },

      cancel: function(e) {
        clearTimeout(this.pressTimer)
      }
    }

    // 添加鼠标和触摸事件监听器
    el.addEventListener('mousedown', el.longPressData.start)
    el.addEventListener('touchstart', el.longPressData.start, { passive: false })
    el.addEventListener('mouseup', el.longPressData.end)
    el.addEventListener('mouseleave', el.longPressData.cancel)
    el.addEventListener('touchend', el.longPressData.end)
    el.addEventListener('touchcancel', el.longPressData.cancel)
  },

  unbind: function(el) {
    // 移除事件监听器
    el.removeEventListener('mousedown', el.longPressData.start)
    el.removeEventListener('touchstart', el.longPressData.start)
    el.removeEventListener('mouseup', el.longPressData.end)
    el.removeEventListener('mouseleave', el.longPressData.cancel)
    el.removeEventListener('touchend', el.longPressData.end)
    el.removeEventListener('touchcancel', el.longPressData.cancel)

    // 清理数据
    delete el.longPressData
  }
}
