<template>
  <div class="faq">
    <div class="faq-box" v-if="faq.list">
      <div class="faq-box-span">
        <span v-if="faq.list.length > 1">{{ $t("home.whichFAQ") }}</span>
        <span v-if="faq.list.length == 1">{{ $t("home.isThisFAQ") }}</span>
      </div>
      <div v-if="faq.list.length > 1" class="box">
        <el-button
          class="btn faq-btn"
          v-for="(item, index) in faq.list"
          :key="item.value"
          @click="handleClick(item)"
          >{{ "FAQ # " + Number(index + 1) }}</el-button
        >
        <el-button class="btn faq-btn" @click="handleRgenerate">
          <svg-icon class="tread" icon-class="refresh" />
          {{ $t("home.findAnother") }}</el-button
        >
      </div>
      <div v-if="faq.list.length == 1" class="box">
        <el-button
          class="btn"
          v-for="item in faq.list"
          :key="item.value"
          @click="handleClick(item)"
        >
          {{ $t("home.yes") }}
        </el-button>
        <el-button class="btn" @click="handleRgenerate">
          <svg-icon class="tread" icon-class="refresh" />
          {{ $t("home.findAnother") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faq: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClick(item) {
      const data = {
        faq: this.faq,
        choose: item
      }
      this.$emit('change', data)
    },
    handleRgenerate() {
      const data = {
        faq: this.faq
      }
      this.$emit('change', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  font-size: 13px;
  color: #fff;
  border: none;
  background: var(--s-bg);
  border-radius: 20px;
  height: 28px;
  line-height: 0;
  padding: 0 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
}
.faq-box {
  font-size: 15px;
  color: #fff;
  // height: 60px;
  // border: 1px solid #856db4;
  border-radius: 80px;
  background: var(--s-bg);
  padding: 6px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.faq {
  margin-bottom: 26px;
}
.faq-box-span {
  // line-height: 60px;
}
.tread {
  font-size: 15px;
}
.box {
  display: flex;
  max-width: 100%;
  overflow: auto;
}
@media screen and (max-width: 900px) {
  .faq-box {
    display: block;
    background: none;
    flex-wrap: nowrap;
  }
  .btn {
    background: var(--green);
  }
  .faq {
    margin-bottom: 0px;
  }
  .box::-webkit-scrollbar {
    display: none;
  }
  .faq-box-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
