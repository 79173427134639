var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "faq-box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Pricing")]),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v("Get Access to Everything On Digital Ray"),
      ]),
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "content-box" }, [
          _c(
            "div",
            { staticClass: "check-icon" },
            [
              _c("svg-icon", {
                staticClass: "check",
                attrs: { "icon-class": "check" },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "text" }, [_vm._v(_vm._s(item.content))]),
        ])
      }),
      _c(
        "div",
        { staticClass: "bg-box" },
        _vm._l(_vm.card, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "bg" },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _c("span", { staticClass: "pirce" }, [
                _vm._v(_vm._s(item.pirce)),
              ]),
              _c("span", { staticClass: "save" }, [_vm._v(_vm._s(item.save))]),
              _c("span", { staticClass: "text" }, [_vm._v(_vm._s(item.text))]),
              _c("el-button", { staticClass: "btn" }, [
                _vm._v(_vm._s(item.btn)),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("span", [_vm._v("Need Additional query allowances?")]),
          _c("svg-icon", {
            staticClass: "down",
            attrs: { "icon-class": "down" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }