var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "banner-box" }, [
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _vm._m(0),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            ' Embark on a transformative journey with DIGITAL RAY, a revolutionary product that channels Ray Dalio\'s principles into a unique "Principled Thinking Frame" to navigate complex challenges. '
          ),
        ]),
        _c("div", { staticClass: "text last" }, [
          _vm._v(
            " Improve your decision-making, personal growth, and financial expertise under the guidance, quality-assured by Ray Dalio himself. DIGITAL RAY is more than a product—it's a partner for excellence. Start exploring Ray's digital wisdom for a better future now. "
          ),
        ]),
        _c(
          "el-button",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.$router.replace({ name: "chatHome" })
              },
            },
          },
          [_vm._v("Chat with DIGITAL RAY")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "red-text" }, [_vm._v("DIGITAL RAY")]),
      _vm._v(" - Chat with the Wisdom of Ray Dalio "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }