var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("CallRay", {
        ref: "callRayComponent",
        attrs: { show: _vm.showCallRay },
        on: { hangUp: _vm.handleHangUp },
      }),
      _c("AppHelp"),
      _vm.$store.state.appComment
        ? _c("AppComment", { on: { change: _vm.ratingChange } })
        : _vm._e(),
      _c("ChatDialogRating", {
        ref: "chatDialogRating",
        attrs: { msg: _vm.lastAskmeMessage },
        on: { change: _vm.ratingChange },
      }),
      _c(
        "div",
        {
          staticClass: "chat-wrap",
          class: _vm.$store.state.showSide ? "chat-wrap-width" : "",
        },
        [
          _c(
            "div",
            { staticClass: "chart-content", attrs: { id: "chartContent" } },
            [
              _c("ChatMessages", {
                ref: "msg",
                attrs: {
                  sender: "DFO",
                  tips: "Scroll down and start chatting",
                  title: "",
                  messages: _vm.visibleAskmeMessagesWithIntro,
                  loading: _vm.askmeLoading,
                  generating: _vm.isGenerating,
                  showFaq: _vm.getFaqChoose === "default",
                },
                on: {
                  translation: _vm.handleTranslation,
                  callQuickQuestion: _vm.handleQuickQuestion,
                  makeShorter: (val) => _vm.handleShortAnwser(val, "DFO"),
                  forceResend: _vm.handleForceResend,
                  changeFaq: _vm.faqChange,
                  loadChat: _vm.handleLoadChat,
                  regenerate: _vm.handleChatRegenerate,
                  principleDay: _vm.handlePrincipleDay,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.getFaqChoose !== "default"
        ? _c(
            "div",
            {
              staticClass: "footer-wrap",
              class: [
                _vm.$store.state.showSide ? "chat-wrap-width" : "",
                _vm.$store.state.appHelps ? "appHelps" : "",
              ],
            },
            [
              false
                ? _c(
                    "div",
                    [
                      _c("ChatFaq", {
                        attrs: { faq: _vm.getFaq },
                        on: { change: _vm.faqChange },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", [
                _c(
                  "div",
                  {
                    ref: "topic",
                    staticClass: "operation-cover",
                    on: {
                      touchstart: function ($event) {
                        return _vm.toggleSwiper(true)
                      },
                      touchmove: function ($event) {
                        return _vm.toggleSwiper(true)
                      },
                      touchend: function ($event) {
                        return _vm.toggleSwiper(false)
                      },
                    },
                  },
                  [
                    !_vm.showTopic
                      ? _c(
                          "div",
                          { ref: "quick", staticClass: "quick-questions" },
                          [
                            false
                              ? _c("QuickQuestions", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.$store.state.isMin900Width,
                                      expression: "$store.state.isMin900Width",
                                    },
                                  ],
                                  on: {
                                    callQuickQuestion: _vm.handleQuickQuestion,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showTopic
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$store.state.appHelps,
                                expression: "!$store.state.appHelps",
                              },
                            ],
                            staticClass: "quick-questions",
                          },
                          [
                            _c("ChatTopic", {
                              on: { change: _vm.handleTopic },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "operation-header" },
                      [
                        _c("ChatOperationHeader", {
                          attrs: {
                            loading: _vm.isLoading,
                            generating: _vm.isGenerating,
                            generatable: _vm.isGeneratable,
                          },
                          on: {
                            stop: function ($event) {
                              return _vm.handleStop(false, true)
                            },
                            regenerate: _vm.handleRegenerate,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    _c("ChatOperations", {
                      attrs: {
                        loading: _vm.isLoading,
                        generating: _vm.isGenerating,
                        generatable: _vm.isGeneratable,
                        canTTS: _vm.canTTS,
                      },
                      on: {
                        "update:canTTS": function ($event) {
                          _vm.canTTS = $event
                        },
                        "update:can-t-t-s": function ($event) {
                          _vm.canTTS = $event
                        },
                        stop: function ($event) {
                          return _vm.handleStop(false, true)
                        },
                        clear: _vm.handleClear,
                        callRay: _vm.handleCallRay,
                      },
                    }),
                    _c("ChatInput", {
                      ref: "input",
                      staticClass: "input-box",
                      attrs: {
                        canASR: _vm.canASR,
                        generating: _vm.isGenerating,
                      },
                      on: {
                        "update:canASR": function ($event) {
                          _vm.canASR = $event
                        },
                        "update:can-a-s-r": function ($event) {
                          _vm.canASR = $event
                        },
                        send: _vm.handleSend,
                      },
                      model: {
                        value: _vm.message,
                        callback: function ($$v) {
                          _vm.message = $$v
                        },
                        expression: "message",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", {
                  staticClass: "disclaimer",
                  domProps: { innerHTML: _vm._s(_vm.$t("footer.disclaimer")) },
                }),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }