var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-wrap" },
    [
      _c("span", { staticClass: "hintTips" }, [
        _vm._v(_vm._s(_vm.$t("footer.hintTips"))),
      ]),
      _vm._l(_vm.$store.state.quickQuestions, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleQuickQuestion(item)
              },
            },
          },
          [
            _c("Hints", { staticClass: "dialog-confirm" }, [
              _vm._v(_vm._s(item.question_translated)),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }