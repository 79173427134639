var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLeftArrow,
            expression: "showLeftArrow",
          },
        ],
        staticClass: "scroll-button scroll-left",
        on: { click: _vm.scrollLeft },
      },
      [
        _c("svg-icon", {
          staticClass: "arrow-left",
          attrs: { "icon-class": "down" },
        }),
      ],
      1
    ),
    _c(
      "div",
      { ref: "cardsWrapper", staticClass: "cards-wrapper" },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRightArrow,
            expression: "showRightArrow",
          },
        ],
        staticClass: "scroll-button scroll-right",
        on: { click: _vm.scrollRight },
      },
      [
        _c("svg-icon", {
          staticClass: "arrow-right",
          attrs: { "icon-class": "down" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }