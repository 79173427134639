<template>
    <div class="profile-box">
      <div class="box-wrap">
        <div class="user-title-box">
          <div class="dialog-user-box">
            <ImageCached :src="userIcon" class="user-head" />
            <!-- <span>{{ $store.state.profile.name }}</span> -->
          </div>

          <div
            class="item"
            :class="{ 'item-focused': focusedIndex === 1 }"
            @click="onFocus(1)"
          >
            <div class="input-box">
              <span class="tip">{{ $t("user.username") }}</span>
              <span class="content-text" v-if="type==='edit'">{{ user.name }}</span>
              <el-input
                @focus="onFocus(1)"
                @blur="onFocus(0)"
                @input="changeInput"
                v-model="user.name"
                type="textarea"
                autosize
                resize="none"
                v-else
                maxlength="20"
              ></el-input>
            </div>

          </div>

        </div>

        <div class="item-box">

          <div class="btn-box">
            <el-button
              :class="[canSave ? 'btn' : 'btn dis-btn']"
              @click="confirm"
              :loading="loading"
              :disabled="!canSave"
            >
            {{ $t("common.saveChange") }}
            </el-button>
          </div>
        </div>
        <!-- <div class="line-box">

        </div>
        <div class="b-tip-box">
            <span class="b-tip">{{ $t('user.accountTip1') }}</span >|<span class="b-tip">{{ $t('user.accountTip2') }}</span>
        </div> -->

      </div>
    </div>
  </template>

<script>
import ImageCached from '@/components/ImageCached'
export default {
  components: {
    ImageCached
  },
  props: {
    type: {
      type: String,
      default: 'save'
    }
  },

  data() {
    return {
      loading: false,
      canSave: false,
      focusedIndex: null,
      list: [
        {
          icon: 'recommended-1',
          text: 'Problem Solving',
          val: 'Problem Solving',
          click: false
        },
        {
          icon: 'recommended-2',
          text: 'Effective Decision-Making',
          val: 'Decision-Making',
          click: false
        },
        {
          icon: 'recommended-3',
          text: 'Succeeding at Work',
          val: 'Succeeding at work',
          click: false
        },
        {
          icon: 'recommended-4',
          text: 'People Management',
          val: 'People Management',
          click: false
        },
        {
          icon: 'principles',
          text: 'Principle Fundamentals',
          val: 'Principles Fundamentals',
          click: false
        }
      ],
      user: {
        name: '',
        topics: []
      }
    }
  },
  mounted() {
    this.user.name = this.$store.state.profile.name
    if (this.$store.state.profile.topics) {
      if (this.$store.state.profile.topics.length > 0) {
        for (const i of this.$store.state.profile.topics) {
          for (const j of this.list) {
            if (j.val === i) {
              j.click = true
            }
          }
        }
      }
    }
  },
  methods: {
    onFocus(index) {
      if (this.type === 'save') {
        this.focusedIndex = index
      }
      // this.focusedIndex = index
    },
    clickItem(item, index) {
      this.canSave = true
      item.click = !item.click
      this.list.splice(index, 1, item)
      console.info(this.list)
    },
    changeInput() {
      this.canSave = true
    },
    close() {
      this.$emit('close')
    },
    async confirm() {
      this.loading = true
      this.user.topics = []

      for (const i of this.list) {
        if (i.click) {
          this.user.topics.push(i.val)
        }
      }
      this.$store
        .dispatch('updateProfile', this.user)
        .then((res) => {
          this.$emit('close')
          this.$message.success({
            message: this.$t('common.accountSave'),
            customClass: 'message-success',
            duration: 2000
          })
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    }
  }
}
</script>

  <style lang="scss" scoped>
  .profile-box {
    padding: 0 16px;
  }
  .box-wrap {
    position: relative;
    width: 100%;
    margin-top: 15vh;
    // margin-left: 15vw;
  }
  .dialog-close-box {
    width: 100%;
    text-align: right;
    margin-bottom: 40px;
    cursor: pointer;
    color: var(--text) !important;
  }
  .input-close-box {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 10;
    line-height: 20px;
    margin-top: 10px;
  }
  .topics {
    margin-top: 60px;
  }
  .dialog-user-box {
    img {
      width: 64px;
      height: 64px;
    }
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--text);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }
  .user-head {
    width: 64px;
    height: 64px;
    min-width: 64px;
    ::v-deep .text {
      font-size: 24px !important;
    }
    // zoom: 1.6;
    // margin-bottom: 20px;
    // margin: 4px 8px 0px 4px;
  }
  .item-box {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    border: 1px solid var(--br);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    width: 360px;
    margin-bottom: 16px;
    align-items: center;
    position: relative;
    margin: auto;
  }
  .able {
    color: var(--black) !important;
    border-color: var(--blue) !important;
    background: var(--blue) !important;
  }
  .dis-btn {
    background: var(--dis-red) !important;
    color: var(--white) !important;
  }
  .disable {
    // color: #707070;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    display: block;
    margin-bottom: 12px;
    color: var(--text);
  }
  .tip {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 20px;
    color: var(--ss-text);
  }
  .btn-box {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
  }
  .btn {
    height: 40px;
    border-radius: 8px;
    padding: 0px 16px;
    font-size: 15px;
    font-weight: 500;
    color: var(--white);
    background: var(--red);
    border: none;
    margin: auto;
    margin-bottom: 8px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-family: "Century-Gothic";
  }
  .btn:hover {
    background: var(--red-hover);
  }
  ::v-deep .el-textarea__inner {
    border: none !important;
    border-radius: 0;
    background-color: #ffffff00;
    padding: 0;
    color: var(--text);
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
  }
  ::v-deep .el-textarea__inner:focus {
    border: none;
  }
  .close-icon {
    margin-right: 8px;
  }
  .item-focused {
    border-color: var(--blue-t-text);
  }
  .line-box{
    height: 2px;
    width: 360px;
    margin: auto;
    background: var(--br);
    margin-top: 192px;

}
::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background-color: #ffffff00 !important;
    border-color: none !important;
    color: var(--text) !important;
    cursor: not-allowed;
}
.b-tip-box{
    text-align: center;
    //styleName: Subtitle / 4;
font-family: Roboto;
font-size: 13px;
font-weight: 500;
line-height: 16px;
text-align: left;
color: var(--ss-text);
display: flex;
    justify-content: center;
    margin-top: 32px;
    .b-tip{
        margin:0 8px
    }
}
.content-text{
  display: block;
  color: var(--text);
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
}
  @media screen and (max-width: 900px) {

    .item {

      width: 100%;

    }
    .item-box,.line-box,.b-tip-box{
        display: none;
    }
    .box-wrap{
        margin-top: 36px;
    }

  }
  </style>
