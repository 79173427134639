const data = {
  common: {
    cancel: 'Cancel',
    delete: 'Delete',
    save: 'SAVE',
    edit: 'Edit',
    sSave: 'Save',
    copy: 'Copied Successfully',
    comment: 'Comment Successfully Saved',
    profile: 'Profile Successfully Saved',
    accountSave: 'Account Successfully Saved',
    notifications: "What's New",
    account: 'Account',
    Select: 'Select',
    settings: 'Settings',
    system: 'System',
    history: 'History',
    stop: 'Stop',
    Play: 'Play',
    version: 'Version',
    new: 'New',
    commentSave: 'SAVE MY COMMENT',
    done: 'Done',
    interface: 'Interface',
    interactions: 'Interactions',
    status: 'Status',
    principles: 'Principles',
    chat: 'Chat',
    description: 'Description',
    title: 'Title',
    addPrinciples: 'ADD TO MY PRINCIPLES',
    savePrinciples: 'SAVE TO MY PRINCIPLES',
    addedPrinciple: 'Added to My Principles',
    savedPrinciple: 'You’ve modified and saved your principle',
    saveChange: 'SAVE CHANGES',
    versionTip: 'This version of the app is no longer supported. To continue your conversations with Digital Ray, please update to the latest version. ',
    close: 'Close'
  },
  version: [
    {
      version: '0.9.18',
      list: [
        'My Profile: You can now edit or remove any profile information Digital Ray learned during your conversation.',
        'Interaction Optimization: Mobile users can rename or delete a chat history by long-pressing a chat in the left sidebar.'
      ],
      date: '2024-8-09'
    },
    {
      version: '0.9.17',
      list: [
        "Create Your Principle: You can save Ray's principles as your own or modify it or write your own principles."
      ],
      date: '2024-7-31'
    },
    {
      version: '0.9.15',
      list: [
        'User Profiling: Personalize your journey with our new user profiling feature.',
        'Digital Ray Interactive Asking Questions: Digital Ray will ask you questions interactively.',
        'Question Hints: Our new Question Hints feature provides better guidance during your interactions.',
        'Expression Style Optimization: Enjoy clearer and more natural communication with Digital Ray!'
      ],
      date: '2024-6-26'
    },
    {
      version: '0.9.7',
      list: [
        'Log in using PrincipleYou.',
        'The latest version of the user homepage, supporting the editing of nickName.',
        'A brand new design for the chat interface.'
      ],
      date: '2024-03-11'
    }
  ],
  home: {
    intro: 'Hello, <span class="blue-text">{name}</span>',
    introNoName: 'Hello',
    introTip: 'How can I help you today',
    // intro: 'Hi, I’m here to share principles you can use to approach the situations you are facing in your work and life most effectively. What would you like to discuss',
    // introName: 'Hi, {name}. I’m here to share principles you can use to approach the situations you are facing in your work and life most effectively. What would you like to discuss',
    // mobileIntro: "I'm Ray Dalio, the founder of Bridgewater Associates.",
    // mobileIntroNoName: 'Hi, I’m here to share principles you can use to approach the situations you are facing in your work and life most effectively. What would you like to discuss',
    // mobileIntroName: 'Hi, {name}. I’m here to share principles you can use to approach the situations you are facing in your work and life most effectively. What would you like to discuss',
    QAIntro:
      'Please directly ask your professional inquiries here regarding my books (Principles for Dealing with the Changing World Order, Principles for Navigating Big Debt Crises, Principles: Life and Work), Linkedin posts, Tweets, Youtube interviews and TED talks (No chit-chat here).',
    original: 'Original',
    concise: 'Concise',
    showAnswer: 'Show Answer',
    FAQTip:
      'This is a frequently asked question (FAQ) and here are some relevant FAQ(s) and answer(s):',
    typing: 'Ray is typing',
    networkText:
      'Thank you. My response below is based on information obtained from a recent online search to ensure accuracy and timeliness. For a comprehensive understanding, please consult additional reputable sources.',
    networkTextFailed: 'Network error, please try again.',
    networkTextNoSearch:
      "I apologize, but I couldn't find the relevant information on Google. Please try using a different browser to conduct your search.",
    stoppedGenerating: 'Stopped generating',
    aotTip:
      'I think the following principle(s) will help answer your questions:',
    questionReceived: 'Question Received',
    searchingAOT: 'Searching AOT',
    relevantAOTFound: 'Relevant AOT Found',
    matchingAOTWithPrinciples: 'Matching AOT with Principles',
    relevantPrinciplesFound: 'Relevant Principles Found',
    generatedAnswer: 'Generated Answer',
    thinkAnswer: 'I think this answer will help:',
    principledThinking: 'Principled thinking',
    whichFAQ: 'Which FAQ answers your question?',
    isThisFAQ: 'Does this FAQ answer your question?',
    findAnother: 'Find another answer for me',
    yes: 'Yes',
    lifePrinciple: 'LIFE PRINCIPLE',
    workPrinciple: 'WORK PRINCIPLE',
    politicalPrinciple: '(GEO-) POLITICAL PRINCIPLE',
    economicPrinciple: 'ECONOMIC PRINCIPLE',
    investmentPrinciple: 'INVESTMENT PRINCIPLE',
    principle: 'PRINCIPLE',
    polish: 'Polish the answer',
    viewEnglish: 'View in English Answer',
    viewTranslate: 'View in {name} Answer',
    en: 'English',
    'zh-CN': 'Simplified Chinese',
    es: 'Spanish',
    fr: 'French',
    ar: 'Arabic',
    'ru-RU': 'Russian',
    pt: 'Portuguese',
    de: 'German',
    ja: 'Japanese',
    it: 'Italian',
    display: 'Display in {name}',
    selectDisplay: 'Select {name} as Display Language?',
    selectTip:
      "If you would like to continue using {name}, We'll display content in your chosen language, powered by Bing and we’ll open a new chat.",
    remind: "Don't remind",
    sourceFrom: 'Source',
    information: 'Information Derived from Online Resources',
    editTheAnswer: 'Comment',
    deleteAudio: 'Delete Audio Message',
    deleteAudioTip: 'Are you certain you want to delete this audio message?',
    audioTip: 'Press & Hold for Audio Comment',
    faqQue: 'Your question might be answered by the following responses.',
    faqTip: 'This reminds me of a question I’m frequently asked:',
    faqWidth: 'Which answer do you prefer?',
    findAnswer: 'Generate another answer',
    Answer: 'Answer',
    currentChat: 'Current Chat',
    allChats: 'All Chats',
    exploreMore: 'Explore Full Content',
    tryAsking: 'Try asking the following questions to start',
    sources: 'Sources',
    source: 'Source',
    faq: 'FAQ about Digital Ray',
    faqRe:
      'None of the previous is relevant. I would like to get another answer.',
    q: 'Question',
    a: 'My Answer',
    re: 'None of the previous is relevant. I would like to get another answer.',
    continue: 'Continue',
    showMore: 'Show More',
    showLess: 'Show Less',
    paused: 'Speaking stopped.',
    speaking: 'Ray is speaking',
    myPrinciples: 'My Principles',
    myPrinciple: 'My Principle',
    myPrinciplesTip: 'You Haven’t Saved or Created Any Principles',
    createMyPrinciples: 'CREATE MY PRINCIPLE',
    delPrinciple: 'Delete Principle?',
    delPrincipleTip: 'Are you sure to delete this principle?',
    savePrinciple: 'Save Principle',
    modifyPrinciple: 'Modify Principle',
    addPrinciple: 'Add Principle'
  },
  header: {
    searchOnline: 'Search Online',
    lightMode: 'Light Mode',
    allowKnowYou: 'Allow Digital Ray to Know You',
    guess: 'Make a Conjecture for Me',
    audio: 'Auto Sound Play',
    logOut: 'Log Out',
    logOutTip: 'Are you sure you want to logout?',
    logout: 'Log out',
    chooseLanguage: 'Choose Display Language',
    chooseLanguageTip:
      'Please choose the language for displaying answers, headlines, buttons, and other text from Digital Ray.',
    myAccount: 'My Account',
    raySpeaking: 'Ray Speaking',
    learnAbount: 'Learn About Me',
    respondWith: 'Respond with Conjectures'
  },
  footer: {
    ask: 'Type Your Questions Here',
    automatic: 'Automatic Speech Recognition:',
    transcribing: 'Transcribing',
    recording: 'Recording',
    clickToRecord: 'Click to record',
    disclaimer:
      '<b>Disclaimer</b>: As a digital representation of Ray Dalio, Digital Ray does not provide financial, economic, investment or other advice and may produce inaccurate or inappropriate information about people, places, or facts. Additionally, it may present perspectives or viewpoints that do not reflect Ray’s official stance. You are responsible for verifying all information provided by Digital Ray before relying on such information for any purpose.',
    bottomDisclaimer:
      'Digital Ray may produce inaccurate information about people, places, or facts.',
    regenerate: 'Regenerate',
    stopGenerating: 'Stop generating',
    desc: 'I’m Ray Dalio, the founder of <br/>Bridgewater Associates.',
    hintTips:
      'Feel free to ask whatever you like to help you, but here might be some suggested questions:',
    comment: 'Feel free to comment on the answers and questions...'
  },
  side: {
    del: 'Clear Chat?',
    delTip: 'This will clear ',
    newChat: 'New Chat',
    clearAllChats: 'Clear All Chats',
    rename: 'Rename',
    renameChat: 'Rename this chat',
    plsInputChat: 'Please input the chat name'
  },
  user: {
    profile: 'My Profile',
    account: 'My Account',
    profileTip:
      'This profile is used to tailor the answer to your job and personality. But you can still use our service without creating one.',
    profileAppTip: 'Profile modification is unavailable. To delete your profile, click the "Edit" button.',
    job: 'Job',
    quickExample: 'Quick Example',
    description: 'Description about Yourself',
    personality: 'Personality Assessed by',
    linkTip: 'Please choose the archetype that you are most like.',
    linkText: 'Not knowing your archetype',
    chooseArchetype: 'Choose Archetype',
    yourJob: 'Your job',
    yourExperience: 'Your experience, personality, etc.',
    help: 'Help',
    exportPdf: 'Export PDF',
    model: 'Model Configuration',
    language: 'Language',
    username: 'Nickname',
    topics: 'Preferred Topics',
    contact: 'Contact Us',
    contactTip:
      'Should you need help or desire to share your thoughts, please contact us at <a href="mailto:support@digitalray.ai">support@digitalray.ai.</a>',
    topicsTip:
      "Based on your choices, we'll recommend relevant queries to enhance your experience.",
    profileBottomTip:
      'The more Digital Ray learns about you, the better its responses become. The data collected above will give you more personalized responses in conversations. ',
    profileNodata:
      "Digital Ray doesn't know anything about you now. Go to chat with Digital Ray!",
    accountTip1: 'Terms & Conditions',
    accountTip2: 'Legal'

  },
  help: {
    skip: 'Skip',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    step1: 'You can export the current chat to print or save it as a PDF file',
    step2: 'You can switch languages',
    step3: 'You can set different parameters to get the chat you want',
    step4: 'You can set profile and logout',
    step5: 'You can clear the current chat history',
    step6: 'You can switch the TTS voice on and off',
    step7: 'You can have a call with Ray',
    step8:
      'You can send the content you want to send by pressing the Enter key',
    step9: 'You can convert your voice into text',
    step10: 'You can send a message',
    step11: 'You can expend or shrink the chat history list',
    step12: 'You can create a new Chat',
    step13: 'You can clear all your chat history',
    appStep1: 'View chat history',
    appStep2: 'Start new chat',
    appStep3:
      'Edit your profile, select language preferences, and enable online search',
    appStep4: 'Erase chat history on current page',
    appStep5: 'Enter your question for Digital Ray and press the "Send" button'
  },
  error: {
    err: 'Something went wrong. Please "Regenerate" the answer.',
    noInternetErr:
      'No Internet. Please check your connections and “Regenerate” the answer.',
    timeOut:
      'Due to possible network latency, a timeout occurred. Please "Regenerate" for the answer.',
    change: 'Please ask me questions in {name}.',
    check_lang:
      "Are you using {change_lang} or {original_lang}? We'll display content in your chosen language, powered by Bing.",
    faq: 'Something is wrong, please select another option.',
    noInternet: 'No Internet connections. Please try again.',
    stop: 'The answer generation process is terminated by user. Please “Regenerate” the answer or ask another new question below.',
    lang: 'Please input the question in {name} or “Regenerate” the answer.',
    loginFailed: 'Login Failed',
    restricted: 'Registration is temporarily closed. Please try again later.',
    invalid: 'Invalid invitation links.',
    photo:
      'It looks like you’ve attempted to send a picture, which is not allowed in our system. Please remove the picture and try again.'
  },
  date: {
    Today: 'Today',
    Yesterday: 'Yesterday',
    'Previous 7 Days': 'Previous 7 Days',
    'Previous 30 Days': 'Previous 30 Days',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
  },
  rating: {
    placeholder: 'Leave your comment here',
    defaultTitle: 'Feedback Required To Proceed',
    ratingTitle: 'Thanks For Your Rating',
    lowRatingTitle: 'Feedback Required To Proceed',
    finishTitle: 'Thanks For Your Feedback',
    defaultPre:
      'To continue, kindly rate or leave a comment on our latest reply.',
    defaultNext: 'Please note that rating is mandatory.',
    rating:
      'To proceed, please press the “CONTINUE” button. Feel free to leave a comment if you wish!',
    lowRating:
      'Please leave a comment to let us know how we can better improve our product.',
    finish:
      'To proceed, please press the “CONTINUE” button. Please note that you can edit your comment at anytime you want.',
    leave: 'LEAVE COMMENT',
    continue: 'CONTINUE',
    commentToContinue: 'COMMENT TO CONTINUE'
  },
  day: {
    principleOfTheDay: 'Principle of the Day',
    allPrinciples: 'All Principles',
    principle: 'PRINCIPLE',
    ofTheDay: 'OF THE DAY',
    readMore: 'Read More',
    tip: 'Try asking the following questions to start:'
  },
  login: {
    ask: 'Ask questions. Get answers. From Ray.',
    about: 'Ask Ray about...',
    faq: 'FAQ',
    t1: 'Personalized Coaching',
    t2: 'Principled Guidance',
    t3: 'AI-powered Mentorship',
    p1: 'Tailored guidance based on your unique situations in life and work',
    p2: 'Responses drawing from Ray’s decades of experience',
    p3: 'Thought partnership on topics such as goal achievement, problem solving, entrepreneurship and personal development',
    app1: '<span class="text-red">Personalized Coaching</span> Tailored guidance based on your unique situations in life and work',
    app2: '<span class="text-red">Principled Guidance</span> Responses drawing from Ray’s decades of experience',
    app3: '<span class="text-red">AI-powered Mentorship</span> Thought partnership on topics such as goal achievement, problem solving, entrepreneurship and personal development',
    what: 'What is Digital Ray?',
    whatInfo:
      'Digital Ray is an AI tool trained on the insights and decision-making principles of Ray Dalio, founder of Bridgewater Associates and bestselling author of Principles: Life & Work. Unlike more generic AI tools, Digital Ray was developed in close cooperation with Ray Dalio and his team to ensure a higher fidelity in producing guidance aligned with what Ray himself would give.  It can provide personalized coaching on a variety of topics including career uncertainty, interpersonal conflicts, prioritization, and problem solving, drawing from Ray’s experience in developing the principles he used to create Bridgewater out of a two-bedroom apartment and grow it into the world’s largest hedge fund. He is now at a stage in his life where he wants to pass along these principles to others for them to judge for themselves and use in whatever ways they find helpful.',
    get: 'Get Digital Ray on the go!',
    getInfo: 'The free app is now available for iOS and Android!',
    feeApp:
      'The free app that brings to life Ray Dalio’s best-selling book Principles: Life & Work now features Digital Ray!',
    btn1: 'What are your thoughts on US and China relations?',
    btn2: 'The US and China relationship is complicated, but they have an opportunity to...',
    footer1: 'DIGITAL RAY © 2023',
    footer2: 'Terms of Service',
    footer3: 'Privacy Policy',
    ios: 'iOS',
    android: 'Android',
    login: 'Login/signup on Principles',
    loginTip:
      'Login/signup is powered by Principles. Please click "CONTINUE" to proceed. You will be directed to Principles login/signup page to complete the process.',
    continue: 'CONTINUE',
    btn: 'Chat with Digital Ray',
    a1: 'principles',
    a2: 'career advice',
    a3: 'decision-making',
    a4: 'and much more!',
    at1: 'Why is it important to have principles?',
    at2: 'How do you deal with it when you feel lost?',
    at3: 'How do you find who is believable?'
  },
  intro: {
    aot: '“Another One of Those" (AOT) is a term Ray Dalio developed to describe the fact that most everything we encounter in life has happened before, if not to us than to other people in other times and places. Instead of dealing with each “case at hand” coming at you as a one-off, understanding which “one of those” a situation is allows you to learn and develop principles for dealing with it most effectively. The more you do this, the more you will be able to see things from a higher level and develop and refine great principles to help you make better decisions. As you interact with Digital Ray, you will see this concept applied to the specific situations you ask about, encouraging you to develop this high-level perspective for everything you encounter.',
    aotTitle: 'What is AOT',
    title: 'Digital Ray’s Capabilities ',
    principlePre: 'Click',
    principleNext: 'to save this principle as your own or modify it.',
    principleAppPre:
      'Do you think my principle is the one that you want and agree with? You can save it by tapping',
    principleAppNext:
      'or modify it by long pressing',
    principleAppLast: ' or write your own.',
    content: `Digital Ray is an AI tool that shares Ray Dalio’s unique insights and principles for decision-making. It distills and personalizes Ray’s extensive knowledge and expertise, offering individuals direct access to his insights in a highly interactive and practical way.

    \n\nWhile Digital Ray is currently focused on providing guidance related to life and work, future updates are anticipated to draw on Ray’s decades of experience as a global macro investor to provide tailored advice based on his investment and economic principles.

    \n\nFor now, you’ll find Digital Ray can provide personalized coaching or guidance on a variety of topics, such as:
    \n- Personal development and goal setting, including career development and planning
    \n- Problem-solving and effective decision-making, and specifically how to balance logic and intuition and navigate different perspectives
    \n- Self-awareness, including understanding your own strengths and weaknesses and how to approach mistakes and failures
    \n- People management as it relates to understanding team dynamics, conflict resolution, and delegating work
    \n- Talent management and hiring, firing, and transfer decisions
    \n- Entrepreneurship and business management, including organizational design and development, succession planning, and spotting and dealing with problems

    \n\nYou can also ask Digital Ray questions related to Ray’s personal experience and preferences.
    `
  },
  faq: {
    title:
      'Digital Ray is an AI tool that shares Ray Dalio’s unique insights and principles for decision-making.',
    personalized: 'Personalized Coaching',
    principled: 'Principled Guidance',
    AIPowered: 'AI-powered Mentorship',
    personalizedTip:
      'Tailored guidance based on your unique situations in life and work',
    principledTip: 'Responses drawing from Ray’s decades of experience',
    AIPoweredTip:
      'Thought partnership on topics such as goal achievement, problem solving, entrepreneurship and personal development',
    text: `Digital Ray distills and personalizes Ray’s extensive knowledge and expertise, offering individuals direct access to his insights in a highly interactive and practical way.
    \n\nWhile Digital Ray is currently focused on providing guidance related to life and work, future updates are anticipated to draw on Ray’s decades of experience as a global macro investor to provide tailored advice based on his investment and economic principles.
    \n\nFor now, you’ll find Digital Ray can provide personalized coaching or guidance on a variety of topics, such as:`,
    more: `+ Personal development and goal setting, including career development and planning
    \n+ Problem-solving and effective decision-making, and specifically how to balance logic and intuition and navigate different perspectives
    \n+ Self-awareness, including understanding your own strengths and weaknesses and how to approach mistakes and failures
    \n+ People management as it relates to understanding team dynamics, conflict resolution, and delegating work
    \n+ Talent Management and hiring, firing, and transfer decisions
    \n+ Entrepreneurship and business management, including organizational design and development, succession planning, and spotting and dealing with problems

    \nYou can also ask Digital Ray questions related to Ray's personal experience and preferences.`,
    faq: `Ray Dalio, a global macro investor with over five decades of experience, founded Bridgewater Associates from his two-bedroom apartment in New York City. Over 47 years, he transformed it into the world's largest hedge fund and one of the US's most significant private companies, as recognized by Fortune Magazine. Dalio's innovative strategies, such as risk parity, alpha overlay, and All Weather, revolutionized institutional investing. His insights have earned him several lifetime achievement awards and a spot on TIME magazine’s “100 Most Influential People in the World” list. He is also a #1 New York Times bestselling author, with titles including "Principles: Life and Work."

    \n\nDalio’s principles are a combination of personal and professional guidelines, promoting a culture of honesty, innovation, and continuous improvement. These principles, crucial to Bridgewater Associates' success, have significantly influenced individuals and organizations globally.

    \n\nEnter Digital Ray, an AI system rooted in natural language processing, created under Dalio’s guidance. It mirrors his principles-based approach and harnesses technology to enhance decision-making and understanding of complex systems. Digital Ray serves as a conversational interface, imparting insights and helping users grasp Ray's systematic approach to life and work. It's akin to receiving personal guidance from Dalio, offering strategies for life’s challenges and exploring concepts like idea meritocracy and radical transparency.

    \n\nDigital Ray embodies Ray Dalio's "Principled Thinking Framework," a unique process aligning specific cases with relevant principles. This approach ensures responses are not just accurate but also reflect Dalio’s foundational principles, offering a structured method for problem-solving and decision-making.

    \n\nFeatures of Digital Ray include:

    \n\n- Problem-Solving via Principled-Thinking: Strategies for identifying, analyzing, and resolving challenges.
    \n- Effective Decision-Making: Utilizing believability-weighted decision-making for impactful choices.
    \n- Success in Work: Guidelines for professional achievement.
    \n- Organizational and People Management: Principles for leading and managing teams.
    \n- Principles Fundamentals: Core philosophies and approaches underpinning Dalio's principles.
    \n- Insights from Dalio’s Experience: Comprehensive overview of Dalio's career, milestones, and insights.
    \n- Personal and Family Background: Detailed professional journey and major accomplishments.
    \n- Dialogue with Ray Dalio: Engaging in conversations, providing real-time information, and offering practical wisdom.

    \n\nSo, Digital Ray encapsulates Ray Dalio's expertise, offering a powerful AI tool for insights into problem-solving, decision-making, and management through his principled-thinking process. It serves as a conversational interface for users to engage with and gain insights into Ray Dalio's principles and experiences. Digital Ray is designed to help users understand and apply a systematic, principles-based approach to life, work, and investing, akin to receiving personal guidance from Ray Dalio himself. `
  }
}
export default data
