var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header scroll" }, [
    _c("div", { staticClass: "header-wrap" }, [
      _c("div", { staticClass: "header-content" }, [
        _c("div", { staticClass: "header-img-box" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/Digital Ray.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "text-box" }, [
            _c("span", { staticClass: "header-version" }, [
              _vm._v(_vm._s("V " + _vm.$store.state.version)),
            ]),
            _vm.isDev
              ? _c("span", { staticClass: "header-title" }, [
                  _vm._v("(Dev Environment)"),
                ])
              : _vm._e(),
            _vm.isStage
              ? _c("span", { staticClass: "header-title" }, [
                  _vm._v("(Staging Environment)"),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "header-icon-wrap" },
          [
            _c("Bell"),
            _c("User", {
              ref: "userRef",
              staticClass: "header-user",
              attrs: { id: "v-step-4" },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }