var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "aot-process-header",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.aotProcessData.aotProcessCollapse =
                !_vm.aotProcessData.aotProcessCollapse
            },
          },
        },
        [
          _vm.aotProcessInfo.principleFound === true
            ? _c("span", [_vm._v(_vm._s(_vm.$t("home.aotTip")))])
            : _vm._e(),
          _vm.aotProcessInfo.principleFound === false
            ? _c("span", [_vm._v(_vm._s(_vm.$t("home.thinkAnswer")))])
            : _vm._e(),
          _vm.aotProcessInfo.principleFound === undefined
            ? _c("span", [
                _vm._v(_vm._s(_vm.$t("home.principledThinking")) + ":"),
              ])
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "extend-btn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.aotProcessData.aotProcessCollapse =
                    !_vm.aotProcessData.aotProcessCollapse
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "chevron-up principles",
                attrs: { "icon-class": "principles" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.aotProcessData.aotProcessCollapse,
                expression: "!aotProcessData.aotProcessCollapse",
              },
            ],
            staticClass: "apt-process-content",
          },
          [
            _c("div", { staticClass: "process-line" }, [
              _c(
                "div",
                { staticClass: "check-icon" },
                [
                  _c("svg-icon", {
                    staticClass: "check",
                    attrs: { "icon-class": "check" },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v(_vm._s(_vm.$t("home.questionReceived")))]),
            ]),
            _c("div", {
              staticClass: "step-line",
              class: {
                "link-line-border": _vm.aotProcessInfo.aotSearchShow === true,
              },
            }),
            _c(
              "div",
              { staticClass: "process-line" },
              [
                _vm.aotProcessInfo.aotSearchShow === true
                  ? _c(
                      "div",
                      { staticClass: "check-icon" },
                      [
                        _vm.aotProcessInfo.aotSearchShow === true
                          ? _c("svg-icon", {
                              staticClass: "check",
                              attrs: { "icon-class": "check" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.aotProcessInfo.aotSearchShow == false
                  ? _c("div", { staticClass: "empty-icon" })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.$t("home.searchingAOT")))]),
                _c("svg-icon", {
                  staticClass: "qa-icon",
                  attrs: { "icon-class": "qa" },
                  on: { click: _vm.handleClickQa },
                }),
              ],
              1
            ),
            _c("div", {
              staticClass: "step-line",
              class: {
                "link-line-border": _vm.aotProcessInfo.aotFound != undefined,
              },
            }),
            _c(
              "div",
              { staticClass: "process-line" },
              [
                _vm.aotProcessInfo.aotFound != undefined
                  ? _c(
                      "div",
                      {
                        class: _vm.aotProcessInfo.aotFound
                          ? "check-icon"
                          : "cross-icon",
                      },
                      [
                        _vm.aotProcessInfo.aotFound === true
                          ? _c("svg-icon", {
                              staticClass: "check",
                              attrs: { "icon-class": "check" },
                            })
                          : _vm._e(),
                        _vm.aotProcessInfo.aotFound === false
                          ? _c("svg-icon", {
                              staticClass: "cross",
                              attrs: { "icon-class": "cross" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.aotProcessInfo.aotFound === undefined
                  ? _c("div", { staticClass: "empty-icon" })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "gray-text": _vm.aotProcessInfo.aotFound === false,
                      "aot-frame-blink":
                        _vm.aotProcessInfo.aotFound === undefined &&
                        _vm.aotProcessInfo.aotSearchShow === true,
                    },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.aotProcessData.aotCollapse =
                          !_vm.aotProcessData.aotCollapse
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("home.relevantAOTFound")))]
                ),
                _vm.aotProcessInfo.aotFound === true
                  ? _c(
                      "el-button",
                      {
                        staticClass: "chevron-icon",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.aotProcessData.aotCollapse =
                              !_vm.aotProcessData.aotCollapse
                          },
                        },
                      },
                      [
                        !_vm.aotProcessData.aotCollapse
                          ? _c("svg-icon", {
                              staticClass: "chevron-up",
                              attrs: { "icon-class": "chevron-up" },
                            })
                          : _vm._e(),
                        _vm.aotProcessData.aotCollapse
                          ? _c("svg-icon", {
                              staticClass: "chevron-down",
                              attrs: { "icon-class": "chevron-down" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("el-collapse-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.aotProcessData.aotCollapse,
                      expression: "!aotProcessData.aotCollapse",
                    },
                  ],
                  staticClass: "aot-list-div",
                  attrs: { id: "aot-list" },
                },
                [
                  _c("div", { staticClass: "aot-list-wrapper" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.aotFaq.aot, function (aot, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "aot-list" },
                          [_vm._v(" " + _vm._s(aot) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]),
            _c("el-collapse-transition", [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.aotProcessData.aotCollapse,
                    expression: "aotProcessData.aotCollapse",
                  },
                ],
                staticClass: "step-line",
                class: {
                  "link-line-border":
                    _vm.aotProcessInfo.matchingAotShow === true,
                },
              }),
            ]),
            _c("div", { staticClass: "process-line" }, [
              _vm.aotProcessInfo.aotFound != undefined &&
              _vm.aotProcessInfo.matchingAotShow === true
                ? _c(
                    "div",
                    {
                      class: _vm.aotProcessInfo.aotFound
                        ? "check-icon"
                        : "cross-icon",
                    },
                    [
                      _vm.aotProcessInfo.aotFound === true
                        ? _c("svg-icon", {
                            staticClass: "check",
                            attrs: { "icon-class": "check" },
                          })
                        : _vm._e(),
                      _vm.aotProcessInfo.aotFound === false
                        ? _c("svg-icon", {
                            staticClass: "cross",
                            attrs: { "icon-class": "cross" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.aotProcessInfo.matchingAotShow === false
                ? _c("div", { staticClass: "empty-icon" })
                : _vm._e(),
              _c(
                "span",
                {
                  class: {
                    "gray-text":
                      _vm.aotProcessInfo.aotFound != true ||
                      _vm.aotProcessInfo.matchingAotShow === false,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("home.matchingAOTWithPrinciples")))]
              ),
            ]),
            _c("div", {
              staticClass: "step-line",
              class: {
                "link-line-border":
                  _vm.aotProcessInfo.principleFound != undefined,
              },
            }),
            _c("div", { staticClass: "process-line" }, [
              _vm.aotProcessInfo.principleFound != undefined
                ? _c(
                    "div",
                    {
                      class: _vm.aotProcessInfo.principleFound
                        ? "check-icon"
                        : "cross-icon",
                    },
                    [
                      _vm.aotProcessInfo.principleFound === true
                        ? _c("svg-icon", {
                            staticClass: "check",
                            attrs: { "icon-class": "check" },
                          })
                        : _vm._e(),
                      _vm.aotProcessInfo.principleFound === false
                        ? _c("svg-icon", {
                            staticClass: "cross",
                            attrs: { "icon-class": "cross" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.aotProcessInfo.principleFound === undefined
                ? _c("div", { staticClass: "empty-icon" })
                : _vm._e(),
              _c(
                "span",
                {
                  class: {
                    "gray-text":
                      _vm.aotProcessInfo.principleFound != true &&
                      _vm.aotProcessInfo.aotFound != true,
                    "aot-frame-blink":
                      _vm.aotProcessInfo.principleFound === undefined &&
                      _vm.aotProcessInfo.aotFound == true,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("home.relevantPrinciplesFound")))]
              ),
            ]),
            _c("div", {
              staticClass: "step-line",
              class: {
                "link-line-border":
                  _vm.aotProcessInfo.generatedAnswer != undefined,
              },
            }),
            _c("div", { staticClass: "process-line" }, [
              _vm.aotProcessInfo.generatedAnswer != undefined
                ? _c(
                    "div",
                    {
                      class: _vm.aotProcessInfo.generatedAnswer
                        ? "check-icon"
                        : "cross-icon",
                    },
                    [
                      _vm.aotProcessInfo.generatedAnswer === true
                        ? _c("svg-icon", {
                            staticClass: "check",
                            attrs: { "icon-class": "check" },
                          })
                        : _vm._e(),
                      _vm.aotProcessInfo.generatedAnswer === false
                        ? _c("svg-icon", {
                            staticClass: "cross",
                            attrs: { "icon-class": "cross" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.aotProcessInfo.generatedAnswer === undefined
                ? _c("div", { staticClass: "empty-icon" })
                : _vm._e(),
              _c(
                "span",
                {
                  class: {
                    "gray-text":
                      (_vm.aotProcessInfo.generatedAnswer === undefined &&
                        _vm.aotProcessInfo.principleFound === undefined) ||
                      _vm.aotProcessInfo.generatedAnswer === false,
                    "aot-frame-blink":
                      _vm.aotProcessInfo.principleFound !== undefined &&
                      _vm.aotProcessInfo.generatedAnswer === undefined,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("home.generatedAnswer")))]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("Dialog", {
        ref: "initDom",
        attrs: {
          content: _vm.$t("intro.aot"),
          title: _vm.$t("intro.aotTitle"),
          full: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }