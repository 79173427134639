<template>
  <div>
    <div class="userDialog">
      <div class="dialog-close-box" v-if="!active">
        <div class="dialog-close-btn-box" @click="close"><svg-icon class="dialog-close" icon-class="close" /></div>
      </div>
      <div class="dialog-title-box" v-if="active" :class="active === $t('user.profile')?'no-border':''">
        <div class="back-box" @click="init">
          <svg-icon class="dialog-back" icon-class="right"  />
        </div>

        <span>{{ active }}</span>
        <div class="action-box" @click="edit">
          <span v-if="(active === $t('user.account')||active === $t('user.profile'))&&type==='edit'">{{ $t('common.edit') }}</span>
          <span v-if="(active === $t('user.account')||active === $t('user.profile'))&&type==='save'">{{ $t('common.sSave') }}</span>
        </div>
      </div>
      <div
        class="box"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <Info
          v-if="!active"
          @logout="logout"
          @change="infoChange"
          @help="help"
          @faq="handleFaq"
          @contact="handleContact"
        ></Info>
        <Language
          v-if="active === $t('user.language')"
          @language="close"
        ></Language>
        <Profile ref="profile" :type="type" v-if="active === $t('user.profile')" @close="profileChange"></Profile>
        <Account ref="account" :type="type" v-if="active === $t('user.account')" @close="profileChange"></Account>
        <FAQ v-if="active === $t('home.faq')"></FAQ>
        <Bell v-if="active === $t('common.notifications')"></Bell>
        <div v-if="active === $t('user.contact')">
          <span class="contact" v-html="$t('user.contactTip')"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Profile from '../ProfileDialog/profile.vue'
import Account from '../ProfileDialog/account.vue'
import { Capacitor } from '@capacitor/core'
export default {
  inject: ['reloadApp'],
  components: {
    Info: () => import('./info.vue'),
    Bell: () => import('./bell.vue'),
    Language: () => import('./language.vue'),
    FAQ: () => import('../../../faq/content.vue'),
    // Contact: () => import('../../contact.vue'),
    Profile,
    Account
  },
  data() {
    return {
      dialogVisible: true,
      active: '',
      online: false,
      startX: 0,
      endX: 0,
      type: 'edit',
      edgeThreshold: 30, // 边缘阈值
      minSwipeDistance: 50 // 最小滑动距离，避免误触发
    }
  },
  watch: {
    '$store.state.isMin900Width': {
      handler(val) {
        this.close()
      },
      deep: true
    },
    dialogVisible() {
      // this.$router.go(-1)
    },
    '$route.query': {
      handler(val) {
        console.info(val)
        if (val.active) {
          this.active = val.active
        } else {
          this.active = ''
        }
      },
      deep: true
    }
  },
  computed: {
    isApp() {
      return process.env.VUE_APP_PLATFORM === 'APP'
    }
  },
  activated() {
    this.dialogVisible = true
  },
  created() {
    this.dialogVisible = true
  },
  mounted() {

  },
  methods: {
    edit() {
      console.info('edit', this.type)
      if (this.type === 'save') {
        this.type = 'edit'
        if (this.active === this.$t('user.account')) {
          this.$refs.account.confirm()
        }
        if (this.active === this.$t('user.profile')) {
          this.$refs.profile.confirm()
        }
      } else if (this.type === 'edit') {
        this.type = 'save'
      }
    },

    handleTouchStart(event) {
      this.startX = event.touches[0].clientX
    },
    handleTouchEnd(event) {
      this.endX = event.changedTouches[0].clientX
      const swipeDistance = this.endX - this.startX

      // 检查边缘左滑触发
      if (Capacitor.getPlatform() === 'ios') {
        if (
          this.startX < this.edgeThreshold &&
          swipeDistance > this.minSwipeDistance
        ) {
          this.move()
        }

        // 检查边缘右滑触发
        if (
          this.startX > window.innerWidth - this.edgeThreshold &&
          swipeDistance < -this.minSwipeDistance
        ) {
          this.move()
        }
      }
    },
    move() {
      if (this.isApp) {
        if (this.active) {
          this.init()
        } else {
          this.close()
        }
      }
    },
    profileChange() {
      this.type = 'edit'
    },
    infoChange(val) {
      this.active = val
      this.type = 'edit'
      if (val === this.$t('common.notifications')) {
        this.$store.dispatch('handleVersionRead')
      }
      this.$router.push({
        path: '/setting',
        query: {
          active: val
        }
      })
    },
    handleFaq() {
      // this.dialogVisible = false
      this.$router.push('/faq')
    },
    logout() {
      const h = this.$createElement
      this.$msgbox({
        title: `${this.$t('header.logOut')}?`,
        message: h('p', null, [
          h('span', { style: 'var(--s-text)' }, this.$t('header.logOutTip'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('header.logout'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$store.dispatch('logout')
          }, 100)
        })
        // this.dialogVisible = false
      })
    },
    help() {
      // this.dialogVisible = false
      this.$store.commit('setSessionId', '')
      this.$store.commit('setHistory', [])
      this.$emit('help')
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setAppHelps', true)
          this.reloadApp()
          this.close()
        }, 100)
      })
    },
    close() {
      this.$router.push({
        name: 'chatHome'
      })
      this.dialogVisible = false
    },
    init() {
      this.active = ''
      this.$store.dispatch('fetchUserProfile').then(res => {
        this.$router.push({
          path: '/setting',
          query: {
            active: ''
          }
        })
      })
    },
    open() {
      this.init()
      this.dialogVisible = true
    },
    handleContact() {
      // this.close()
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.$refs.contact.open()
      //   }, 100)
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  padding: 20px 16px !important;
  border-radius: 0px !important;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  margin: 0 !important;
  background: var(--s-bg);
  color: var(--text);
  position: relative;
  .el-dialog__header {
    padding: 0 !important;
    /* padding: 20px 20px 10px; */
  }

  .el-dialog__body {
    // max-width: 1200px;
    margin: auto;
    padding: 0 !important;
  }

  .el-dialog__headerbtn {
    position: absolute;
    top: 20px;
    right: 40px;
  }
}

::v-deep .el-textarea__inner {
  border: none;
  border-bottom: 0.5px solid var(--br);
  border-radius: 0;
  padding: 0;
  background: #ffffff00;
  font-size: 15px;
  color: var(--text);
  padding-right: 20px;
}
::v-deep .el-textarea__inner:focus {
  border: none;
  border-bottom: 0.5px solid var(--br);
  border-radius: 0;
  padding: 0;
  background: #ffffff00;
  font-size: 15px;
  color: var(--text);
  padding-right: 20px;
}

::v-deep .el-textarea .el-textarea__count .el-textarea__count-inner {
  padding: 0 0px;
  background: var(--ss-text);
}

::v-deep .el-textarea .el-input__count {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  bottom: -20px;
  background: none;
}

::v-deep .el-form--label-top .el-form-item__label {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 26px;
  color: var(--text);
  font-family: "Century-Gothic" !important;
}
::v-deep .el-textarea__inner::placeholder {
  color: var(--s-text);
  font-weight: 400px;
  font-size: 15px;
}

::v-deep .popper__arrow {
  display: none !important;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-form-item {
  margin-bottom: 60px;
}
.dialog-close-box {
  margin: 0 -20px 0;
  height: 40px;
  padding: 0 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-bottom: 0.5px solid var(--border-setting);
  .dialog-close {
    font-size: 20px;
    color: var(--title-text);
    font-weight: 600;
  }
}
.dialog-back {
  font-size: 18px;
  color: var(--text);
  transform: rotate(180deg);
  // text-align: left;
  // position: absolute;
  // left: 16px;
  // margin-top: 12px;
}
.dialog-title-box {
  width: 100vw;
  text-align: center;
  top: 16px;
  margin-left: -16px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  color: var(--text);

  border-bottom: 0.5px solid var(--br);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.box {
  position: fixed;
  width: 100vw;
  margin-left: -16px;
  height: calc(100vh - 80px);
  // background: red;
  overflow: auto;
  overflow-x: hidden;
}
// .box::-webkit-scrollbar {
//   display: none;
// }
.box::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.box::-webkit-scrollbar-thumb {
  width: 4px;
  background: var(--ss-text);
  border-radius: 35px;
}
.contact {
  font-size: 15px;
  color: var(--text);
  margin-top: 6px;
  display: block;
  padding: 0 16px;
}
.userDialog {
  padding: env(safe-area-inset-top) 16px 20px 16px !important;
  background: var(--s-bg);
  color: var(--text);
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.dialog-close-btn-box{
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-border{
  border: none;
}
.action-box{
  width: 50px;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  align-items: center;
  cursor: pointer;
}
.back-box{
  width: 50px;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  ::v-deep .el-dialog {
    padding: 20px 16px !important;
    // padding-top: calc(env(safe-area-inset-top) + 20px);
    padding-top: calc(env(safe-area-inset-top) + 20px) !important;
  }
}
@media screen and (max-width: 900px) {
  ::v-deep .faq {
    margin-left: -16px;
  }
  .dropdown-box {
    position: relative;
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
  }
  ::v-deep .el-dialog {
    padding: 20px 16px !important;
    // padding-top: calc(env(safe-area-inset-top) + 20px);
    padding-top: calc(env(safe-area-inset-top) + 0px) !important;
  }
}
</style>
